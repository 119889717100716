import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class BlockConfigStyle
{
  static closureDateContainerStyle(isOutdated: boolean): CSSProperties
  {
    return {
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${ (isOutdated) ? CssVariableEnum['--color-error-200'] : CssVariableEnum['--color-blue-light-200'] }`,
      borderRadius: 8,
      marginTop: 10,
      backgroundColor: (isOutdated) ? CssVariableEnum['--color-error-100'] : CssVariableEnum['--color-blue-light-100']
    };
  }

  static closureDateBodyStyle(isOutdated: boolean): CSSProperties
  {
    return {
      borderRight: `1px solid ${ (isOutdated) ? CssVariableEnum['--color-error-500'] : CssVariableEnum['--color-blue-light-500'] }`,
      height: '30px',
      width: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
    };
  }

  static closureDateSpanStyle(isOutdated: boolean): CSSProperties
  {
    return {
      color: (isOutdated) ? CssVariableEnum['--color-error-500'] : CssVariableEnum['--color-blue-500'],
      fontSize: 12,
      padding: 5
    };
  }
}