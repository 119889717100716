export function paramsToString(filterParamsState: any): string {
	const queryParams = new URLSearchParams();

	for (const [key, value] of Object.entries(filterParamsState)) {
		if (key !== 'filters' && value !== null && value !== undefined) {
			queryParams.append(key, value.toString());
		}
	}

	if (filterParamsState.filters && Object.keys(filterParamsState.filters).length > 0) {
		const filtersForStringify = [];
		for (const [key, value] of Object.entries(filterParamsState.filters)) {
			filtersForStringify.push({ [key]: value });
		}
		queryParams.append('filters', JSON.stringify(filtersForStringify));
	}

	return queryParams.toString();
}
