export default class TvaEnum
{
	value: string;
	description: string;
	label: number;

	constructor(value: string, description: string, label: number)
	{
		this.label = label
		this.description = description
		this.value = value
	}

	static NORMAL_FEES = new TvaEnum('NORMAL_FEE', 'Taux normal',20);
	static INTERMEDIATE_FEES = new TvaEnum('INTERMEDIATE_FEES', 'Taux intermédiaire', 10);
	static REDUCE_FEES = new TvaEnum('REDUCE_FEES', 'Taux réduit (inchangé)', 5.5);
	static PARTICULAR_FEES = new TvaEnum('PARTICULAR_FEES', 'Taux particulier (inchangé)',2.1)

	static options: TvaEnum[] = [
		TvaEnum.NORMAL_FEES,
		TvaEnum.INTERMEDIATE_FEES,
		TvaEnum.REDUCE_FEES,
		TvaEnum.PARTICULAR_FEES
	]

	static findByValue(value: number): TvaEnum | undefined
	{
		return this.options.find(tva => tva.label === value)
	}

}