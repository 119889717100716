import React, { ReactElement } from 'react';
import HeroSection from '@/Modules/App/Components/Atom/Content/HeroSection';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import Subtitle from '@/Modules/App/Components/Atom/Title/Subtitle';
import { LuArrowUp } from 'react-icons/lu';
import Skeleton from 'react-loading-skeleton';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { ApiAdminAppService } from '@/Service/Admin/ApiAdminAppService';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import UserCivilityEnum from '@/Enum/UserCivilityEnum';
import { NavigateFunction } from 'react-router-dom';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { DashboardAdminViewStyle } from '@/Modules/Dashboard/Style/DashboardAdminViewStyle';
import LoadingWrapper from '@/Modules/App/Components/Atom/Loader/LoadingWrapper';
import Content from '@/Modules/App/Components/Atom/Content/Content';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';

interface ViewProps
{
	companyContext: AuthCompanyContextType,
	navigation: NavigateFunction
}

interface ViewState
{
	isLoading: boolean,
	countUsers: {
		active: number,
		inactive: number
	},
	countClients: {
		active: number,
		inactive: number
	},
	countLegalNotices: {
		all: number,
		week: number
	},
	userConnected: UserInterface | null
}

export default class DashboardAdminView extends React.Component<ViewProps, ViewState>
{
	appAdminService: ApiAdminAppService;
	apiAdminUserService: ApiAdminUserService;
	apiAdminLegalNoticeService: ApiAdminLegalNoticeService;
	apiAdminLegalNoticeFileService: ApiAdminLegalNoticeFileService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.appAdminService = new ApiAdminAppService();
		this.apiAdminUserService = new ApiAdminUserService();
		this.apiAdminLegalNoticeService = new ApiAdminLegalNoticeService();
		this.apiAdminLegalNoticeFileService = new ApiAdminLegalNoticeFileService();

		// Document title
		document.title = 'Publisur - ADMIN - Dashboard';
	}

	render(): ReactElement
	{
		return (
			<>
				<HeroSection title="Dashboard" icon={ null }/>
				<Content>
					<MainWrapper>
						<div style={ { marginBottom: 20, width: '100%', marginTop: 30 } }>
							{ this.state.isLoading
								? <Skeleton width={ 300 } height={ 20 }/>
								: <>
									<Title type={ 'h6' } style={ { marginBottom: 4, marginTop: 0 } }>
										👋 Bonjour { this.state.userConnected && this.handleUserCivility(this.state.userConnected.civility) }
										{ this.state.userConnected && <> { this.state.userConnected.firstname } </> }
										{ this.state.userConnected && <> { this.state.userConnected.lastname } </> }
									</Title>
									<Subtitle>Bienvenue sur votre tableau de bord</Subtitle>
								</>
							}
						</div>

						{ this.counterCardListContent() }
						<hr/>
						{ this.exportContent() }
					</MainWrapper>
				</Content>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.fetchData(true);
		this.setState({ isLoading: false });
	}

	async componentDidUpdate(prevProps: any): Promise<void>
	{
		if (prevProps.companyContext.authCompany !== this.props.companyContext.authCompany) {
			this.setState({ isLoading: true });
			await this.fetchData(false);

			setTimeout(() =>
			{
				this.setState({ isLoading: false });
			}, 2000); // Timeout  5 second
		}
	}

	private initState(): ViewState
	{
		return {
			isLoading: true,
			countUsers: {
				active: 0,
				inactive: 0
			},
			countClients: {
				active: 0,
				inactive: 0
			},
			countLegalNotices: {
				all: 0,
				week: 0
			},
			userConnected: null as UserInterface | null,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private counterCardListContent(): ReactElement
	{
		return (
			<div style={ { display: 'flex', gap: 20 } }>
				{ this.cardCounter('/admin/legal-notice', 'Annonces Légales', this.state.countLegalNotices, false) }
				{ this.cardCounter('/admin/clients', 'Donneur d\'ordre', this.state.countClients, true) }
				{ this.cardCounter('/admin/users', 'Utilisateurs', this.state.countUsers, true) }
			</div>
		);
	}

	private exportContent(): ReactElement
	{
		return (
			<>
				<Title type={ 'h6' } style={ { marginBottom: 4, marginTop: 0 } }>
					📁 Export PDF des annonces l'égales
				</Title>
				<div>
					<div style={ DashboardAdminViewStyle.containerCardExport() }>
						<p>
							Nombre d'annonce publiées cette semaine : <b>{ this.state.countLegalNotices.week }</b>
						</p>

						<Button
							type={ 'tag-blue' }
							style={ { marginTop: '10px' } }
							disabled={ true }
							onClick={ () => this.onClickDownloadXml() }
						>
							🚧 UNDER CONSTRUCTION
						</Button>
					</div>
				</div>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private cardCounter(urlNavigate: string, title: string, stateCounter: any, isActiveInactiveCounter: boolean): ReactElement
	{
		return (
			<>
				<div
					style={ DashboardAdminViewStyle.containerCardCounter() }
					onClick={ (): void => this.props.navigation(urlNavigate) }
				>
					<LoadingWrapper isLoading={ this.state.isLoading } height={ 18 } width={ '50%' }>
						<Title type={ 'h6' } style={ { marginTop: 0, marginBottom: 0 } }>{ title }</Title>
					</LoadingWrapper>
					<div style={ { display: 'flex', alignItems: 'center', gap: 20 } }>
						<LoadingWrapper isLoading={ this.state.isLoading } width={ 50 } height={ 60 }>
							<div style={ DashboardAdminViewStyle.bigNumberStats() }>
								{ (isActiveInactiveCounter) ? stateCounter.active : stateCounter.all }
							</div>
						</LoadingWrapper>

						<LoadingWrapper isLoading={ this.state.isLoading } width={ '50%' } height={ 18 }>
							<div style={ DashboardAdminViewStyle.statsCompare() }>
								<LuArrowUp/>
								<div>11%</div>
							</div>
						</LoadingWrapper>
					</div>

					<LoadingWrapper isLoading={ this.state.isLoading } width={ 50 } height={ 60 }>
						{ isActiveInactiveCounter &&
              <div style={ DashboardAdminViewStyle.textStatusActive() }>
                Inactif : { stateCounter.inactive }
              </div>
						}
					</LoadingWrapper>
				</div>
			</>
		);
	}

	private async onClickDownloadXml(): Promise<void>
	{
		try {
			const blob = await this.apiAdminLegalNoticeFileService.exportXml();

			if (blob.size > 0) {
				const downloadUrl = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = downloadUrl;
				a.download = 'export-legal-notice.xml';
				document.body.appendChild(a);
				a.click();
				a.remove();
				window.URL.revokeObjectURL(downloadUrl); // Clean up
			} else {
				console.error('Blob is empty');
			}
		} catch (error) {
			console.error('Failed to download XML export:', error);
		}
	}

	private handleUserCivility(civility: string): string
	{
		if (civility !== UserCivilityEnum.M.value) {
			if (civility === UserCivilityEnum.W.value) {
				return 'Mme';
			} else {
				return '';
			}
		}

		return civility;
	}

	private async fetchData(isComponentDidMount: boolean): Promise<void>
	{
		const responseCounters = await this.appAdminService.counters();
		const responseUser = await this.getUser();

		// State
		this.setState({
			countUsers: {
				active: responseCounters.counterUsers,
				inactive: responseCounters.counterInactiveUsers
			},
			countClients: {
				active: responseCounters.counterClients,
				inactive: responseCounters.counterInactiveClients
			},
			countLegalNotices: {
				all: responseCounters.counterLegalNotices,
				week: responseCounters.counterLegalNoticesPublishCurrentWeek
			},
			userConnected: responseUser
		}, async () => {
			if (isComponentDidMount && this.state.userConnected?.role === 'ROLE_ADMIN') {
				await this.props.companyContext.refreshCompany(this.state.userConnected.company.id, false);
			}
		});
	}

	private async getUser(): Promise<any>
	{
		try {
			const userId = LocalStorageService.getUserLocalStorage().userId;
			if (userId) {
				return await this.apiAdminUserService.show(userId);
			}
		} catch (error: any) {
			console.log('Something wrong with getting user details', error.message);
		}
	}

	//</editor-fold>
}
