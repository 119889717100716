import { useNavigate } from 'react-router-dom';
import React from 'react';
import AuthView from '@/Modules/Auth/View/AuthView';

const AuthViewWrapper = (props: any) => {
  const navigate = useNavigate();

  return <AuthView navigation={navigate} {...props} />;
};

export default AuthViewWrapper;