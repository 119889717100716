import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalContext } from '@/Provider/ModalProvider';
import CreateLegalNoticeGuideView from '@/Modules/LegalNotice/View/CreateLegalNoticeGuideView';
import { AuthContext } from '@/Provider/AuthProvider';
import { AuthClientContext } from '@/Provider/AuthClientProvider';

const CreateLegalNoticeGuideViewWrapper = (props: any) => {
	const navigate = useNavigate();
	const location = useLocation();
	const flashMessageContext = useContext(FlashMessageContext);
	const modalContext = useContext(ModalContext);
	const authContext = useContext(AuthContext);
	const clientContext = useContext(AuthClientContext);

	return <CreateLegalNoticeGuideView
		flashMessageContext={ flashMessageContext }
		modalContext = { modalContext }
		location={ location }
		navigation={ navigate }
		authContext={ authContext }
		clientContext={ clientContext }
		{ ...props }
	/>;
};

export default CreateLegalNoticeGuideViewWrapper;