import React, { ReactElement } from 'react';
import TableCell from '@/Modules/App/Components/Atom/Table/TableCell';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import { TableHeaderColumnInterface } from '@/Modules/App/Components/Atom/Interface/TableHeaderColumnInterface';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { LuArrowUp, LuArrowDown } from 'react-icons/lu';

export default class TableHeader extends React.Component
	<
		{
			columnList?: any[] | null,
			columnHeaderList?: TableHeaderColumnInterface[] | null,
			isFilters: boolean,
			params?: (orderBy: string, sort: string) => void,
			onRefreshList?: () => Promise<void>,
			isCheckboxInput?: boolean,
		},
		{
			tableColumns: any[] | null;
			tableColumnHead: TableHeaderColumnInterface[] | null;
			clickedColumnIndex: number | null; // New state to track clicked column index
			isAscendingOrder: boolean; // New state to track ascending order
			params: {
				orderBy: string,
				sort: string,
			},
		}
	>
{
	state = {
		tableColumns: [],
		tableColumnHead: [],
		clickedColumnIndex: null, // Initialize clicked column index as null
		isAscendingOrder: true, // Initialize ascending order as true
		params: {
			orderBy: '',
			sort: '',
		}
	};

	componentDidMount()
	{
		if (this.props.columnHeaderList || this.props.columnList) {
			this.setState({
				tableColumns: (this.props.columnList) ? this.props.columnList : null,
				tableColumnHead: (this.props.columnHeaderList) ? this.props.columnHeaderList : null,
			});
		}
	}

	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					width: '100%',
					height: '32px',
					display: 'flex',
					color: CssVariableEnum['--color-grey-400'],
					backgroundColor: CssVariableEnum['--color-grey-100'],
					borderRadius: '6px',
				} }>
					<div style={ {
						minWidth: 'calc(100% - 70px)',
						height: '100%',
						display: 'flex',
					} }>
						<TableRow borderBottom={ false } isCheckboxInput={ this.props.isCheckboxInput }>
							{
								this.state.tableColumns && this.state.tableColumns.map((columnName, index) =>
									(
										<React.Fragment key={ columnName }>
											<div style={ {
												width: `calc(100% / ${ this.props.columnList && this.props.columnList.length })`,
												height: '100%'
											}
											}>
												<TableCol isBorderRight={ false }>
													<TableCell>
														{ columnName }
													</TableCell>
												</TableCol>
											</div>
										</React.Fragment>
									))
							}
							{
								this.state.tableColumnHead && this.state.tableColumnHead.map((columnName: TableHeaderColumnInterface, index: number) =>
									(
										<React.Fragment key={ columnName.columnTitle }>
											<TableCol isBorderRight={ false } columnSize={ columnName.columnSize }>
												<TableCell
													style={ { fontSize: '12px', fontWeight: 600 } }
													onClick={ (this.props.isFilters) ? () => this.handleColumnClick(index, columnName.name) : () => null }
												>
													{ columnName.columnTitle }
													{ this.state.clickedColumnIndex === index ?
														(this.state.isAscendingOrder
																? <span style={ { marginLeft: '4px' } }><LuArrowUp fontSize={ 14 }/></span>
																: <span style={ { marginLeft: '4px' } }><LuArrowDown fontSize={ 14 }/></span>
														)
														: null }
												</TableCell>
											</TableCol>
										</React.Fragment>
									))
							}
						</TableRow>
					</div>
					<div style={ {
						minWidth: '70px',
						height: '100%',
						display: 'flex',
						alignItems: 'center'
					} }>
						<TableCell style={ { fontSize: '12px', fontWeight: 600 } }>
							Actions
						</TableCell>
					</div>
				</div>
			</>
		);
	}

	handleColumnClick(index: number, name: string)
	{
		if (this.state.clickedColumnIndex === index) {
			this.setState(prevState => ({
				isAscendingOrder: !prevState.isAscendingOrder, // Toggle ascending order
				params: {
					...prevState.params,
					sort: prevState.isAscendingOrder ? 'desc' : 'asc',
					orderBy: name
				}
			}), () =>
			{
				if (this.props.params) {
					this.props.params(this.state.params.orderBy, this.state.params.sort);
				}
			});
		} else {
			this.setState({
				clickedColumnIndex: index,
				isAscendingOrder: true,
				params: {
					...this.state.params,
					orderBy: name,
					sort: 'asc'
				}
			}, () =>
			{
				if (this.props.params) {
					this.props.params(this.state.params.orderBy, this.state.params.sort);
				}
			});
		}
	}

}
