export async function fetchWrapper(url: string, options: RequestInit = {}): Promise<any>
{
  const response = await fetch(url, options);

  if (!response.ok) {
    const errorData = await response.json();
    const errorMessage = errorData.message.toLowerCase();

    if (errorMessage.includes('Invalid JWT Token')) {
      window.location.href = '/auth';
      return;
    }

    throw new Error(errorData.message || 'An error occurred');
  }

  return response.json();
}