import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthClientContext } from '@/Provider/AuthClientProvider';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { CollaboratorView } from '@/Modules/Collaborator/View/CollaboratorView';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { AuthContext } from '@/Provider/AuthProvider';
import { ModalContext } from '@/Provider/ModalProvider';

const CollaboratorViewWrapper = (props: any) =>
{
	const navigate = useNavigate();
	const authContext = useContext(AuthContext);
	const clientContext = useContext(AuthClientContext);
	const flashMessageContext = useContext(FlashMessageContext);
	const modalContext = useContext(ModalContext);

	// LoaderFullPageComponent
	if (!clientContext?.authClient) {
		return <LoaderFullPageComponent/>;
	}

	return <CollaboratorView
		clientContext={ clientContext }
		authContext={ authContext }
		flashMessageContext={ flashMessageContext }
		navigation={ navigate }
		modalContext={ modalContext }
		{ ...props }
	/>;
};

export default CollaboratorViewWrapper;