import { ApiAppService } from '@/Service/Api/ApiAppService';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { ApiAdminFormBuilderCategoryService } from '@/Service/Admin/ApiAdminFormBuilderCategoryService';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { ApiAdminPricingService } from '@/Service/Admin/ApiAdminPricingService';

export class LegalNoticeService
{
  formBuilderCategoryService: ApiAdminFormBuilderCategoryService;
  appService: ApiAppService;
  publicService: ApiPublicService;
  legalNoticeService: ApiAdminLegalNoticeService;
  apiPricingService: ApiAdminPricingService;

  constructor()
  {
    this.formBuilderCategoryService = new ApiAdminFormBuilderCategoryService();
    this.appService = new ApiAppService();
    this.publicService = new ApiPublicService();
    this.legalNoticeService = new ApiAdminLegalNoticeService();
    this.apiPricingService = new ApiAdminPricingService();
  }

  async categoryList(): Promise<FormBuilderCategoryInterface[]>
  {
    return await this.formBuilderCategoryService.list();
  }

  async parentCategory(categoryId: number): Promise<FormBuilderCategoryInterface>
  {
    return await this.formBuilderCategoryService.show(categoryId)
  }

  async departmentList(): Promise<DepartmentInterface[]>
  {
    return await this.appService.departmentList();
  }

  async newspaperList(department: DepartmentInterface, newspaperType: NewspaperTypeEnum|string): Promise<NewspaperInterface[]>
  {
    return await this.publicService.newspaperByDepartmentAndNewspaperType(
      department.id,
      (typeof newspaperType === 'string') ? newspaperType : newspaperType.value
    );
  }

  async create(legalNoticeData: any): Promise<void>
  {
    return await this.legalNoticeService.create(legalNoticeData);
  }

  async edit(legalNoticeId: number, legalNoticeData: any): Promise<void>
  {
    return await this.legalNoticeService.edit(legalNoticeId, legalNoticeData);
  }
}