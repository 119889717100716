import React, { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import CreateUserFormComponent from '@/Modules/User/Components/Form/CreateUserFormComponent';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuX } from 'react-icons/lu';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';

interface ComponentProps {
	onClosed: (event: any, isAddNewUser: boolean) => void
	onCreate: (formData: any, event?: any) => void
	errorMessage: string,
	resetErrorMessage: () => void,
};

export default class CreateUserToAffiliate extends React.Component
	<ComponentProps, any>
{
	userService: ApiAdminUserService;

	constructor(props: any)
	{
		super(props);

		// Service
		this.userService = new ApiAdminUserService();
	}

	render(): ReactElement
	{
		return (
			<>
				<div
					style={ {
						position: 'absolute',
						zIndex: 3000,
						top: 0,
						left: 0,
						backdropFilter: 'blur(2px)',
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					} }>
					<div style={ {
							position: 'relative',
							width: 500,
							height: 500,
							backgroundColor: 'white',
							border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
							borderRadius: '8px'
						} }>
						<Button
							type={ 'closed' }
							iconLeft={ <LuX/> }
							style={ {
								position: 'absolute',
								zIndex: 3001,
								right: 5,
								top: 5,
							} }
							onClick={ (event: any) => this.props.onClosed(event, false) }
						/>

						<CreateUserFormComponent
							isAdmin={ true }
							onFormSubmit={ this.props.onCreate }
							allowedUserRole={ false }
							errorMessage={ this.props.errorMessage }
							resetErrorMessage={ this.props.resetErrorMessage }
						/>
					</div>
				</div>
			</>
		);
	};
}