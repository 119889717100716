import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardAdminView from '@/Modules/Dashboard/Admin/View/DashboardAdminView';
import { AuthCompanyContext } from '@/Provider/AuthCompanyProvider';

const DashboardAdminViewWrapper = (props: any) => {
  const navigate = useNavigate();
  const companyContext = useContext(AuthCompanyContext);

  return <>
    <DashboardAdminView
      companyContext={ companyContext }
      navigation={ navigate }
      { ...props }
    />
  </>;
};

export default DashboardAdminViewWrapper;