import React, { ReactElement } from 'react';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { BsPlusLg } from 'react-icons/bs';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';
import { ApiAdminCollaboratorService } from '@/Service/Admin/ApiAdminCollaboratorService';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import CreateUserToAffiliate from '@/Modules/User/Components/CreateUserToAffiliate';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import Radio from '@/Modules/App/Components/Atom/Form/Radio';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { SearchSellsyInterface } from '@/Modules/Client/Interface/SearchSellsyInterface';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { ContactSellsyInterface } from '@/Modules/Client/Interface/ContactSellsyInterface';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { Alert } from 'react-bootstrap';
import { FormDataCreateUserInterface } from '@/Modules/User/Components/Form/CreateUserFormComponent';

interface ComponentProps
{
	client: ClientInterface | null,
	authCompanyContext: AuthCompanyContextType,
	onRefresh: (selectedClientId: number) => void,
	modalContext: ModalContextType,
	companyId: number,
	flashMessageContext: FlashMessageContextType,
}

interface ComponentState
{
	formData: {
		collaboratorMail: string | null,
		role: CollaboratorRoleEnum | null
		selectedUser: UserInterface | null,
		extSellsyId: string
	},
	sellsyData: SearchSellsyInterface,
	isSellsyDataLoading: boolean,
	collaboratorExistingExtSellsyIds: string[],
	isEmailCollaboratorManual: boolean,
	isUserSelected: boolean,
	userSearchResults: UserInterface[],
	errorMessage: string | null,
	isAddNewUser: boolean,
	isOpen: boolean,
}

export default class AdminCreateCollaboratorForm extends React.Component
	<ComponentProps, ComponentState>
{
	userService: ApiAdminUserService;
	collaboratorService: ApiAdminCollaboratorService;
	sellsyClientService: ApiAdminSellsyService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.userService = new ApiAdminUserService();
		this.collaboratorService = new ApiAdminCollaboratorService();
		this.sellsyClientService = new ApiAdminSellsyService();

		// Bind
		this.resetUserSelection = this.resetUserSelection.bind(this);
		this.onClosedAddNewUser = this.onClosedAddNewUser.bind(this);
		this.onCreateCollaborator = this.onCreateCollaborator.bind(this);
		this.onCreateNewUser = this.onCreateNewUser.bind(this);
	}

	render(): ReactElement
	{
		if (this.state.isSellsyDataLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				{ this.adminCreateHtmlContent() }
				{ this.state.isAddNewUser &&
          <CreateUserToAffiliate
            onClosed={ this.onClosedAddNewUser }
            onCreate={ this.onCreateNewUser }
            errorMessage={ this.state.errorMessage || '' }
            resetErrorMessage={ () => this.setState({ errorMessage: '' }) }
          />
				}
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		if (this.props.authCompanyContext && this.props.client) {
			// Filter ext sellsy ids
			const existingExtSellsyIds = this.getExistingCollaboratorExtSellsyIds();

			// Get data sellsy contact
			const listSellsyContact = await this.sellsyClientService.listCompanyContact(
				this.props.companyId,
				parseInt(this.props.client.extSellsyId as string)
			);

			this.setState({
				sellsyData: listSellsyContact,
				isSellsyDataLoading: false,
				collaboratorExistingExtSellsyIds: existingExtSellsyIds
			});
		}
	}

	private initState(): ComponentState
	{
		return {
			formData: {
				collaboratorMail: '',
				role: null as CollaboratorRoleEnum | null,
				selectedUser: null as UserInterface | null,
				extSellsyId: ''
			},
			sellsyData: {} as SearchSellsyInterface,
			isSellsyDataLoading: true,
			collaboratorExistingExtSellsyIds: [] as string[],
			isEmailCollaboratorManual: true,
			isUserSelected: false,
			userSearchResults: [],
			errorMessage: '',
			isAddNewUser: false,
			isOpen: false,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private adminCreateHtmlContent(): ReactElement
	{
		return (
			<div style={ {
				position: 'relative',
				borderRadius: '6px',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
			} }>
				<div style={ { padding: '20px 0', display: 'flex', flexDirection: 'column', gap: '10px' } }>
					<div style={ {
						display: 'flex',
						paddingRight: '20px',
						paddingLeft: '20px',
						position: 'relative'
					} }>
						{ this.associateUserSearchHtml() }
					</div>

					{ this.state.formData.selectedUser &&
            <>
              <div style={ { marginTop: '10px', paddingRight: '20px', paddingLeft: '20px' } }>
                <Radio
                  label="Voulez-vous utiliser l'email de l'utilisateur pour le collaborateur propriétaire ?"
                  options={ [{ label: 'Oui', value: 'true' }, { label: 'Non', value: 'false' }] }
                  name="is-email-collaborator-owner"
                  selectedValue={ this.state.isEmailCollaboratorManual.toString() }
                  onSelectedOption={ (event: any) => this.onSelectedIsUserMailForCollaborator(event) }
                />

								{ !this.state.isEmailCollaboratorManual &&
                  <div style={ { marginTop: '10px' } }>
                    <Input
                      type="text"
                      width={ '100%' }
                      label={ 'Email collaborateur *' }
                      name="inviteMail"
                      placeholder="john.doe@unknow.com"
                      value={ this.state.formData.collaboratorMail || '' }
                      style={ { flex: '1 0 0%' } }
                      onChange={
												event =>
													this.setState((prevState: { formData: any; }) => ({
														formData: {
															...prevState.formData,
															collaboratorMail: event.target.value
														}
													}))
											}
                    />
                  </div>
								}

                <div style={ {
									marginTop: '10px',
									gap: '20px',
								} }>
                  <SelectComponent
                    label={ 'Rôle' }
                    listOptions={ CollaboratorRoleEnum.roleOptions.filter(option => option.value !== 'ROLE_OWNER') }
                    renderOptionLabel={ option => option.label }
                    onSelectedOption={ (option: any) => this.setState(prevState => ({
											formData: {
												...prevState.formData,
												role: this.collaboratorRole(option.value)
											}
										})) }
                  />
                </div>
              </div>
            </>
					}
				</div>

				{ this.state.formData.selectedUser &&
          <>
            <hr/>
            <div style={ { padding: '0px 20px', } }>
							{ this.filterSellsyContacts().length > 0 ? (
								<>
									<Title type={ 'h6' } style={ { marginTop: '0', fontSize: '14px' } }>
										Associer le Collaborateur à un contact Sellsy
									</Title>
									<SelectComponent
										style={ { marginBottom: '15px' } }
										listOptions={ this.filterSellsyContacts() }
										onSelectedOption={ (event: any) => this.handleContactSellsySelect(event) }
										renderOptionLabel={ (contact) => `${ contact.email } #${ contact.id }` }
									/>
								</>
							) : (
								<>
									<Alert key={ 'warning' } variant={ 'warning' } style={ { marginTop: '0', fontSize: '14px' } }>
										Tout les contacts Sellsy sont affectés, merci d'en créer un nouveau
									</Alert>
									{ (!this.state.formData.extSellsyId)
										? <Button
											type="default-dark"
											onClick={ () => this.handleCreateNewSellsyContact() }
											style={ { marginBottom: '40px' } }
										>
											Créer un nouveau contact Sellsy
										</Button>
										: <>
											<div>
												<img
													style={ {
														width: '35px',
														marginLeft: '15px',
														marginTop: '21px',
														padding: '5px',
														borderRadius: '5px',
														backgroundColor: CssVariableEnum['--color-purple-200']
													} }
													src="/img/logo-sellsy.png" alt="Sellsy Logo"
												/>
												<div style={ { color: CssVariableEnum['--color-purple-200'] } }>Contact lié avec Sellsy</div>
											</div>
										</>
									}
								</>
							) }
            </div>
          </>
				}

				<div style={ {
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-end',
					height: '50px',
					alignItems: 'flex-end',
					padding: '20px'
				} }>
					<Button
						type={ 'default-blue' }
						iconLeft={ <BsPlusLg/> }
						onClick={ () => this.onCreateCollaborator() }
					>
						Nouveau collaborateur
					</Button>
				</div>
			</div>
		);
	}

	private associateUserSearchHtml(): ReactElement
	{
		return (
			<>
				{ !this.state.isUserSelected ? (
					<>
						<Input
							type="text"
							width={ '100%' }
							name={ 'associate-user' }
							label={ 'Associer un utilisateur' }
							placeholder="Rechercher un utilisateur"
							autocomplete={ 'off' }
							onChange={ (event) => this.onChangeUserSearch(event) }
							style={ { flex: '0 0 300px' } }
							onFocus={ () => this.setState({ isOpen: true }) }
						/>
						{ this.state.isOpen && (
							<>
								<div style={ {
									position: 'absolute',
									zIndex: 200,
									backgroundColor: CssVariableEnum['--color-white'],
									borderRadius: '8px',
									boxShadow: '0 2px 20px #00000029',
									width: '100%',
									padding: '5px 10px',
									top: 58,
									fontWeight: 500,
									border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
									transitionProperty: 'opacity, transform',
									transitionDuration: '270ms',
									transitionTimingFunction: 'ease'
								} }>
									<div style={ {
										width: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-end',
										borderBottom: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
										marginBottom: 8,
									} }>
										<Button
											type={ 'inline-default-blue' }
											style={ { marginBottom: 5 } }
											onClick={ (event: any) => this.onClickAddNewUser(event) }>
											Ajouter un utilisateur
										</Button>
									</div>
									{ this.state.userSearchResults.length > 0 &&
                    <div style={ { overflowY: 'scroll' } }>
                      <div style={ { maxHeight: 150 } }>
												{ this.state.userSearchResults.map((user: UserInterface) => (
													<div
														key={ user.id }
														style={ { cursor: 'pointer', padding: '5px 10px' } }
														onClick={ (event: any) => this.handleUserSelect(event, user) }
													>
													<span
														style={ { fontWeight: 500 } }>{ this.stringToCapitalize(user.lastname) } { this.stringToCapitalize(user.firstname) }</span>
														<span style={ {
															fontSize: 12,
															color: CssVariableEnum['--color-grey-400']
														} }>&nbsp; { user.email }</span>
														<span
															style={ {
																marginLeft: '5px',
																fontSize: 12,
																borderRadius: '3px',
																border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
																padding: '2px 4px'
															} }>
														#{ user.tag }
													</span>
													</div>
												)) }
                      </div>
                    </div>
									}
								</div>
							</>
						) }
					</>
				) : (
					<>
						<div style={ { display: 'flex', width: '100%', alignItems: 'flex-end' } }>
							<Input
								type="text"
								width={ '100%' }
								name={ 'associate-user' }
								label={ 'Associer un utilisateur' }
								onChange={ () => null }
								style={ { flex: '1 0 0%' } }
								value={ this.state.formData.selectedUser &&
									`${ (this.state.formData.selectedUser as UserInterface).lastname } ${ (this.state.formData.selectedUser as UserInterface).firstname }(${ (this.state.formData.selectedUser as UserInterface).email }) #${ (this.state.formData.selectedUser as UserInterface).tag }` }
							/>

							<Button
								type={ 'default' }
								onClick={ (event: any) => this.resetUserSelection(event) }
							>
								Modifier
							</Button>
						</div>
					</>
				) }
			</>
		);
	}

	private onClickAddNewUser(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();
		this.setState({ isAddNewUser: true });
	}

	private collaboratorRole(collaboratorRole: string): any
	{
		return CollaboratorRoleEnum.roleOptions.find(role => role.value === collaboratorRole);
	}

	//<editor-fold desc="User search methods" defaultstate="collapsed">

	private async searchUser(term: string): Promise<void>
	{
		const results = await this.userService.search(term, UserRoleEnum.ROLE_USER.value);
		this.setState({ userSearchResults: results });
	}

	private async onChangeUserSearch(event: React.ChangeEvent<any>)
	{
		event.stopPropagation();

		const term = event.target.value;
		await this.searchUser(term);
	}

	private handleUserSelect(event: any, user: UserInterface)
	{
		event.stopPropagation();

		this.setState(prevState => ({
			...prevState,
			isUserSelected: true,
			formData: {
				...prevState.formData,
				selectedUser: user
			}
		}));
	}

	private async onCreateNewUser(formData: FormDataCreateUserInterface, event: any): Promise<void>
	{

		event.stopPropagation();
		formData.status = ActiveStatusEnum.ACTIVE.value;
		formData.role = UserRoleEnum.ROLE_USER.value;

		try {
			const newUser = await this.userService.create(formData);

			if (newUser.errorMessage) {
				this.setState({
					errorMessage: newUser.errorMessage,
				});

				return;
			}

			this.setState({ isAddNewUser: false });
			this.props.flashMessageContext?.flashMessage(
				'Nouvel Utilisateur',
				'L\'utilisateur ' + formData.firstname + formData.lastname + ' a bien été créé',
				'success'
			);

			this.setState((prevState: any) => ({
				isUserSelected: true,
				formData: {
					...prevState.formData,
					selectedUser: newUser
				}
			}));

		} catch (error: any) {
			console.log('Issue from create User [AdminCreateCollaboratorForm]', error.errorMessage);
		}
	}

	private onClosedAddNewUser(event: any, isAddNewUser: boolean): void
	{
		event.stopPropagation();
		this.setState({ isAddNewUser });
	}

	private resetUserSelection(event: any)
	{
		event.stopPropagation();

		this.setState(prevState => ({
			...prevState,
			isUserSelected: false,
			formData: {
				...prevState.formData,
				selectedUser: null
			}
		}));
	}

	//</editor-fold>

	private async onCreateCollaborator(): Promise<void>
	{
		if (
			!this.state.formData.role ||
			!this.state.formData.selectedUser ||
			!this.props.client?.id
		) {
			this.props.modalContext.errorMessage('Tous les champs doivent être remplis ');
		} else {
			const collaboratorData = {
				email: this.state.formData.collaboratorMail,
				status: ActiveStatusEnum.ACTIVE.value,
				role: this.state.formData.role.value,
				user: this.state.formData.selectedUser.id,
				client: this.props.client?.id,
				extSellsyId: this.state.formData.extSellsyId
			};

			try {
				await this.collaboratorService.create(collaboratorData, this.props.client?.id)
					.then(async (response: any) =>
					{
						if (response.errorMessage) {
							this.setState({ errorMessage: response.errorMessage });
							this.props.modalContext.errorMessage(response.errorMessage);
							this.props.modalContext.isOpen(true);
							return;
						}

						this.setState({
							errorMessage: '',
							isUserSelected: false,
							formData: {
								role: null,
								collaboratorMail: null,
								selectedUser: null,
								extSellsyId: ''
							}
						});

						this.props.modalContext.isOpen(false);
						this.props.modalContext.errorMessage('');
						this.props.flashMessageContext.flashMessage(
							'Collaborateur créé avec succés',
							`Le Collaborateur a bien été crée avec l'adresse email ${ collaboratorData.email }`,
							'success'
						);

					})
					.catch((error: any) =>
					{
						this.setState({ errorMessage: error.message });
					});


				await this.props.onRefresh(this.props.client?.id);

			} catch (error: any) {
				console.log('Issue from create collaborator : ', error.errorMessage);
			}
		}
	}

	private stringToCapitalize(stringToConvert: string): string
	{
		return stringToConvert[0].toUpperCase() + stringToConvert.slice(1).toLowerCase();
	}

	private onSelectedIsUserMailForCollaborator(event: any): void
	{
		if (event === 'true') {
			this.setState((prevState: any) => ({
				formData: {
					...prevState.formData,
					collaboratorMail: ''
				},
				isEmailCollaboratorManual: true
			}));
		} else {
			this.setState({ isEmailCollaboratorManual: false });
		}
	}

	//<editor-fold desc="Sellsy methods" defaultstate="collapsed">

	private handleContactSellsySelect(contact: ContactSellsyInterface): any
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				extSellsyId: contact.id
			}
		}));
	}

	private async handleCreateNewSellsyContact(): Promise<void>
	{
		const newContact = await this.sellsyClientService.createContact(
			this.props.companyId,
			{
				civility: (this.state.formData.selectedUser?.civility === 'M')
					? 'mr'
					: 'mrs'
				,
				first_name: this.state.formData.selectedUser?.firstname,
				last_name: this.state.formData.selectedUser?.lastname,
				email: (this.state.formData.collaboratorMail === '')
					? this.state.formData.selectedUser?.email
					: this.state.formData.collaboratorMail
				,
				phone_number: this.state.formData.selectedUser?.phone,
				position: this.state.formData.role?.label,
				note: 'New Collaborator from Publisur.dev'
			}
		);

		if (this.props.client && newContact) {
			console.log(newContact);

			const linkContact = await this.sellsyClientService.linkContactToCompany(
				this.props.companyId,
				parseInt(this.props.client.extSellsyId),
				newContact.id,
				false
			);

			if (linkContact.httpStatus === 201) {
				this.setState((prevState: any) => ({
					formData: {
						...prevState.formData,
						extSellsyId: newContact.id
					}
				}));

				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'SELLSY - Contact créé avec succès',
					`Votre nouveau contact a bien été créé et associé à Sellsy`,
					'success'
				);
			}
		}
	}

	private getExistingCollaboratorExtSellsyIds(): string[]
	{
		if (this.props.client && this.props.client.collaborators) {
			return this.props.client.collaborators
				.map((collaborator: any) => collaborator.extSellsyId)
				.filter((id: string | null) => id !== null)
				.map((id: string | null) => id!.toString());
		}

		return [];
	}

	private filterSellsyContacts(): ContactSellsyInterface[]
	{
		if (Array.isArray(this.state.sellsyData.data) && this.state.sellsyData.data.every(item => 'id' in item)) {
			return (this.state.sellsyData.data as ContactSellsyInterface[]).filter(
				(contact: ContactSellsyInterface) => !this.state.collaboratorExistingExtSellsyIds.includes(contact.id.toString())
			);
		}

		return [];
	}

	//</editor-fold>

	//</editor-fold>
}