import React, { ReactElement } from 'react';
import HeroSection from '@/Modules/App/Components/Atom/Content/HeroSection';
import Content from '@/Modules/App/Components/Atom/Content/Content';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';

export default class DashboardView extends React.Component
{
	render(): ReactElement
	{
		return (
			<>
				<HeroSection title="Dashboard" icon={ null }/>
				<Content>
					<MainWrapper>

					</MainWrapper>
				</Content>
			</>
		);
	}
}
