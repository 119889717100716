import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class AuthViewStyle
{
  static h2(): CSSProperties
  {
    return { fontSize: 28, fontWeight: 700, textAlign: 'center' };
  }

  static mainContainer(): CSSProperties
  {
    return {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 20
    };
  }

  static border(): CSSProperties
  {
    return { height: 1, width: '100%', backgroundColor: CssVariableEnum['--color-grey-800'] };
  }

  static flexJustifyContentCenter(): CSSProperties
  {
    return { width: 100, display: 'flex', justifyContent: 'center' };
  }

  static textUnderForm(): CSSProperties
  {
    return { textAlign: 'center', marginBottom: 10, padding: '0px 20px' };
  }
}