import { useNavigate, useParams } from 'react-router-dom';
import React, { useContext } from 'react';
import ForgotPasswordView from '@/Modules/App/View/ForgotPasswordView';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';

const ForgotPasswordWrapper = (props: any) => {
  const flashMessageContext = useContext(FlashMessageContext);
  const navigate = useNavigate();
  const urlParams = useParams();

  return <ForgotPasswordView
    flashMessageContext={ flashMessageContext }
    navigation={ navigate }
    token={ urlParams.token }
    { ...props }
  />;
};

export default ForgotPasswordWrapper;