import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default class FormBuilderInputSkeleton extends React.Component
	<
		{ count: number },
		any
	>

{
		render()
		{
			return (
				Array.from({ length: this.props.count }).map((_, index: number) => (
					<div className="skeleton-input" key={index}>
						<div className="left-col">
							<Skeleton circle height={21} width={21} />
						</div>
						<div className="right-col">
							<Skeleton height={33} />
						</div>
					</div>
				))
			);
		}
}