import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import { App } from '@/App';
import { AuthProvider } from '@/Provider/AuthProvider';
import { AuthClientProvider } from '@/Provider/AuthClientProvider';
import { AuthCompanyProvider } from '@/Provider/AuthCompanyProvider';

const root = ReactDOM.createRoot(
  document.getElementById('publisur-app') as HTMLElement
);

document.title = 'Publisur';

const app = new App();

root.render(
  <AuthProvider>
    <AuthCompanyProvider>
      <AuthClientProvider>
        <RouterProvider router={ app.router() } />
      </AuthClientProvider>
    </AuthCompanyProvider>
  </AuthProvider>
);