import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import {
	FormBuilderSectionInterface
} from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';

export class ApiAdminFormBuilderSectionService extends BaseService
{

	formBuilderUrl: string = `${ BaseService.API_URL }/v1/admin/form-builder/sections`;

	async list(): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async show(sectionId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl + "/" + sectionId,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async search(term: string): Promise<any>
	{
		try {
			const url = `${this.formBuilderUrl}/search?term=${encodeURIComponent(term)}`;
			const response = await fetch(url, {
				method: HttpVerbEnum.GET,
				headers: this.buildHeaders(),
			});

			return await response.json();
		} catch (error: any) {
			console.error('Error searching section:', error.message);
		}
	}

	async create(formBuilderSection: Partial<FormBuilderSectionInterface>): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(formBuilderSection),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async createSubSection(parentSectionId: number, formBuilderSection: Partial<FormBuilderSectionInterface>): Promise<any>
	{
		const url: string = `${this.formBuilderUrl}/sub-sections/${parentSectionId}`;

		try {
			const response: Response = await fetch(
				url,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(formBuilderSection),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async edit(editSectionData: any, sectionId: number): Promise<any>
	{
		try {
			const response = await fetch(
					this.formBuilderUrl + '/' + sectionId,
					{
						method: HttpVerbEnum.PATCH,
						headers: this.buildHeaders(),
						body: JSON.stringify(editSectionData)
					},
				)
			;

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async editIsDuplicable(isDuplicable: boolean, sectionId: number): Promise<any>
	{
		try {
			const response = await fetch(
					this.formBuilderUrl + '/' + sectionId,
					{
						method: HttpVerbEnum.PATCH,
						headers: this.buildHeaders(),
						body: JSON.stringify({
							isDuplicable
						})
					},
				)
			;

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async delete(sectionId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.formBuilderUrl }/${ sectionId }`,
				{
					method: HttpVerbEnum.DELETE,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async addFormBuilderCategory(sectionId: number, categoryId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.formBuilderUrl }/categories/add/${ sectionId }/${ categoryId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async removeFormBuilderCategory(sectionId: number, categoryId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.formBuilderUrl }/categories/remove/${ sectionId }/${ categoryId }`,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async switchSortOrder(sectionId: number, categoryId: number, sortOrder: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.formBuilderUrl }/sort-order/${ sectionId }/${ categoryId }`,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify({ sortOrder: sortOrder })
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}