import React, { ReactElement } from 'react';
import LegalNoticeFomCardComponent from '@/Modules/LegalNotice/Components/Form/Admin/FormCardComponent';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import LegalNoticeSendToFormComponent, { SendToSelections } from '@/Modules/LegalNotice/Components/Form/LegalNoticeSendToFormComponent';

interface ComponentProps
{
  isDisplayBlock: boolean,
  selectedClient: ClientInterface|null,
  onSelectionMail: (selections: SendToSelections) => void
  sendTo: {},
  fieldsToShow?: string[]
}

export default class BlockSendToComponent extends React.Component<ComponentProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        { this.props.isDisplayBlock &&
          <>
            <LegalNoticeFomCardComponent
              title={"Envoi des documents"}
              children={
                <>
                  <LegalNoticeSendToFormComponent
                    selectedClient={ this.props.selectedClient }
                    sendTo={ this.props.sendTo }
                    onSelections={ this.props.onSelectionMail }
                    fieldsToShow={ this.props.fieldsToShow }
                  />
                </>
              }
            />
          </>
        }
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">
  //</editor-fold>
}