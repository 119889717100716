export class ClientDiscountPreferenceEnum
{
	value: string;
	label: string;

	constructor(value: string, label: string) {
		this.value = value;
		this.label = label;
	}

	static NO_PROFESSIONAL_DISCOUNT = new ClientDiscountPreferenceEnum('NO_PROFESSIONAL_DISCOUNT', 'Pas de remise professionelle');
	static STATEMENT_OF_FEES = new ClientDiscountPreferenceEnum( 'STATEMENT_OF_FEES', 'Relevé d\'honoraire');
	static CREDIT_DEDUCTED = new ClientDiscountPreferenceEnum( 'CREDIT_DEDUCTED', 'Avoir déduit');
	static CREDIT_NOTE_SETTLED = new ClientDiscountPreferenceEnum( 'CREDIT_NOTE_SETTLED', 'Avoir réglé');

	static options: ClientDiscountPreferenceEnum[] = [
		ClientDiscountPreferenceEnum.NO_PROFESSIONAL_DISCOUNT,
		ClientDiscountPreferenceEnum.STATEMENT_OF_FEES,
		ClientDiscountPreferenceEnum.CREDIT_DEDUCTED,
		ClientDiscountPreferenceEnum.CREDIT_NOTE_SETTLED
	];

	static findByValue(selectedOption: string): any
	{
		return ClientDiscountPreferenceEnum.options.find(
			option => option.value === selectedOption
		);
	}
}