import React, { ReactElement } from 'react';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import LegalNoticeShowComponent from '@/Modules/LegalNotice/Components/LegalNoticeShowComponent';
import OffCanvasComponent from '@/Modules/App/Components/Atom/OffCanvas/OffCanvasComponent';
import HeroSection from '@/Modules/App/Components/Atom/Content/HeroSection';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';
import { LuCircleDollarSign, LuFiles, LuStar } from 'react-icons/lu';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import { Location, NavigateFunction } from 'react-router-dom';
import Pagination from '@/Modules/App/Components/Atom/Table/Pagination';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { FilterParamsInterface } from '@/Modules/App/Interface/FilterParamsInterface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import Swal from 'sweetalert2';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import TableComponent, { TableHeaderListInterface } from '@/Modules/App/Components/Atom/Table/TableComponent';
import TagEnum from '@/Modules/App/Components/Atom/Tags/TagEnum';
import { BsCopy } from 'react-icons/bs';
import TableFilters from '@/Modules/App/Components/Atom/Table/TableFilters';
import { FilterInterface } from '@/Modules/App/Components/Atom/Table/Filters';
import Content from '@/Modules/App/Components/Atom/Content/Content';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';
import { NotificationContextType } from '@/Provider/NotificationProvider';

interface ViewProps
{
	modalContext: ModalContextType,
	location: Location,
	flashMessageContext: FlashMessageContextType,
	notificationContext: NotificationContextType,
	navigation: NavigateFunction,
}

interface ViewState
{
	paginate: PaginateInterface,
	filterParams: FilterParamsInterface,
	selectedLegalNotice: LegalNoticeInterface | null,
	selectedLegalNoticeFiles: any[],
	selectedLegalNoticeIndex: number,
	selectedTabOffCanvas: number,
	isLoading: boolean,
	currentItemId: number | null,
	isOpenShowOffCanvas: boolean,
	fromUrl: string | null,
	errorMessage: string | null
	isCreatedMode: boolean,
	paymentProcessed: number
}

export default class LegalNoticeAdminPaymentView extends React.Component
	<ViewProps, ViewState>
{
	legalNoticePriceService: LegalNoticePriceService;
	apiLegalNoticeService: ApiAdminLegalNoticeService;
	apiLegalNoticeFileService: ApiAdminLegalNoticeFileService;
	navigation: NavigateFunction;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Service
		this.legalNoticePriceService = new LegalNoticePriceService(true);
		this.apiLegalNoticeService = new ApiAdminLegalNoticeService();
		this.apiLegalNoticeFileService = new ApiAdminLegalNoticeFileService();

		// Navigation
		this.navigation = props.navigation;

		// Bind
		this.refreshList = this.refreshList.bind(this);
		this.onRowClick = this.onRowClick.bind(this);
		this.checkUrl = this.checkUrl.bind(this);
		this.itemPerPage = this.itemPerPage.bind(this);
		this.selectedFilters = this.selectedFilters.bind(this);
		this.onDuplicateLegalNotice = this.onDuplicateLegalNotice.bind(this);
		this.onAnswerQuote = this.onAnswerQuote.bind(this);
		this.onDeletedLegalNotice = this.onDeletedLegalNotice.bind(this);
		this.buildFilterParams = this.buildFilterParams.bind(this);
		this.payedProcess = this.payedProcess.bind(this)

		// Document title
		document.title = 'Publisur - ADMIN - Annonce légales';
	}

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title="Gérer les réglements"
					icon={ null }
					tableFilter={
						<TableFilters
							searchInput={
								{
									name: 'search-legal-notice',
									placeholder: 'Rechercher une annonce légale...',
									search: this.state.filterParams.search || '',
									onChangeSearch: (event: any) => this.onChangeLegalNoticeSearch(event),
									resetSearchInput: this.resetSearchInput.bind(this)
								}
							}
							filters={ { list: this.buildFilterLegalNoticeList(), selectedFilters: this.selectedFilters } }
							buttonCreate={ null }
						/>
					}
				/>
				<Content>
					<MainWrapper>
						{ !this.state.isLoading &&
              <TableComponent
                paginateList={ this.state.paginate.items }
                tableHeaderList={ this.buildTableHeaderLegalNotice() }
                params={ this.buildFilterParams }
                onClickRow={ this.onRowClick }
                notFoundValuesText={ 'Aucune annonce légale n\'a été trouvée' }
                onRefreshList={ this.refreshList }
                isResizeColumns={ true }
                isStickyHeader={ true }
              />
						}
						<Pagination
							currentPage={ this.state.filterParams.page }
							tag={ 'legal-notice' }
							list={ this.state.paginate }
							itemsPerPage={ this.itemPerPage }
						/>
					</MainWrapper>
				</Content>
				<OffCanvasComponent
					show={ this.state.isOpenShowOffCanvas }
					onHide={ () => this.setState({
						isOpenShowOffCanvas: false,
						selectedTabOffCanvas: 1,
						selectedLegalNotice: null
					}) }
					onPrevious={ () => this.navigateLegalNotice(true) }
					onNext={ () => this.navigateLegalNotice(false) }
					tabs={ this.buildTabsOffCanvas }
					isNavigateArrow={ true }
					isTabNeeded={ true }
					onTabChange={ (selectedTab: number) => this.handleTabChange(selectedTab) }
					renderTitle={ this.renderTitle() }
				>
					{ this.state.selectedLegalNotice && (
						<LegalNoticeShowComponent
							key={ `${this.state.selectedLegalNotice.id}` }
							modalContext={ this.props.modalContext }
							selectedTabOffCanvas={ this.state.selectedTabOffCanvas }
							selectedLegalNotice={ this.state.selectedLegalNotice }
							notificationContext={ this.props.notificationContext }
							selectedLegalNoticeFiles={ this.state.selectedLegalNoticeFiles }
							navigation={ this.navigation }
							flashMessageContext={ this.props.flashMessageContext }
							currentItemId={ this.state.currentItemId }
							onDeleted={ this.onDeletedLegalNotice }
							fromUrl={ this.state.fromUrl }
							onDuplicate={ this.onDuplicateLegalNotice }
							onAnswerQuote={ this.onAnswerQuote }
							onPaymentProcess={ this.refreshList }
							isLoading={ this.state.isLoading }
						/>
					) }
				</OffCanvasComponent>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.refreshList();
	}

	componentDidUpdate(prevProps: any): void
	{
		if (this.props.location && this.props.location.search !== prevProps.location.search) {
			this.checkForReload();
		}
	}

	private initState(): ViewState
	{
		return {
			paginate: {} as PaginateInterface,
			filterParams: {
				page: 1,
				itemsPerPage: 10,
				search: null,
				filters: null,
				orderBy: null,
				sort: null,
			},
			selectedLegalNotice: null as LegalNoticeInterface | null,
			selectedLegalNoticeFiles: [],
			selectedLegalNoticeIndex: -1,
			selectedTabOffCanvas: 1,
			isLoading: true,
			currentItemId: null,
			isOpenShowOffCanvas: false,
			fromUrl: null,
			errorMessage: null,
			isCreatedMode: false,
			paymentProcessed: 0
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private renderTitle(): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					alignItems: 'center',
					gap: '16px',
					marginBottom: '15px'
				} }>
					<div style={ {
						fontSize: '26px',
						fontWeight: 600,
						color: CssVariableEnum['--color-grey-900'],
					} }>
						Annonce légale
					</div>
					<Button
						type={ 'default' }
						iconRight={ <BsCopy style={ { fontSize: '10px' } }/> }
						onClick={ () => navigator.clipboard.writeText(`${ this.state.selectedLegalNotice?.tag }`) }
						style={ { fontSize: '12px' } }
					>
						{ this.state.selectedLegalNotice?.tag }
					</Button>
					{ this.state.selectedLegalNotice &&
            <TagEnum
              style={ { height: '32px', marginRight: '10px', minWidth: '71px' } }
              value={ (this.state.selectedLegalNotice) ? this.state.selectedLegalNotice.status.toString() : '' }
              enumName={ 'PublishStateEnum' }
            >
							{ PublishStateEnum.findByValue(this.state.selectedLegalNotice.status.toString())?.label }
            </TagEnum>
					}
				</div>
			</>
		);
	}

	private async onDeletedLegalNotice(): Promise<void>
	{
		if (this.state.selectedLegalNotice) {

			const result = await Swal.fire({
				title: `Voulez-vous vraiment Annuler cette annonce ?`,
				text: 'Attention cette action sera irréversible',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: `Oui, je veux Annuler !`,
				cancelButtonText: 'Non, Je ne veux pas Annuler'
			});

			if (result.isConfirmed) {
				Swal.fire({
					title: `Annulation en cours !`,
					text: `L'annonce va être Annuler.`,
					icon: 'info'
				});

				const nbCharacters = await this.legalNoticePriceService.nbCharacters(
					this.state.selectedLegalNotice.publishDepartment,
					this.state.selectedLegalNotice.formBuilderCategory,
					this.state.selectedLegalNotice,
					(this.state.selectedLegalNotice.option.isHeader)
						? this.legalNoticePriceService.countTempHeaderContent(this.state.selectedLegalNotice.consumer)
						: 0
				);

				await this.apiLegalNoticeService.delete(this.state.selectedLegalNotice.id, nbCharacters);

				this.setState({ isOpenShowOffCanvas: false }, () =>
				{
					this.refreshList();

					this.props.flashMessageContext.flashMessage(
						'Annonce légale annulée avec succès',
						'L\'annonce reste disponible, mais elle est en état "Annuler"',
						'success'
					);
				});
			}
		}
	}

	private async onDuplicateLegalNotice(): Promise<void>
	{
		if (this.state.selectedLegalNotice) {

			const result = await Swal.fire({
				title: `Voulez-vous vraiment Dupliquer cette annonce ?`,
				text: 'Attention cette action sera irréversible',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: `Oui, je veux Dupliquer !`,
				cancelButtonText: 'Non, Je ne veux pas Dupliquer'
			});

			if (result.isConfirmed) {
				Swal.fire({
					title: `Duplication en cours !`,
					text: `L'annonce va être Dupliquer.`,
					icon: 'info'
				});

				await this.apiLegalNoticeService.duplicate(this.state.selectedLegalNotice.id);

				this.setState({ isOpenShowOffCanvas: false }, () =>
				{
					this.refreshList();

					this.props.flashMessageContext.flashMessage(
						'Annonce légale dupliquée avec succès',
						'La nouvelle annonce légale est visible dans la liste',
						'success'
					);
				});
			}
		}
	}

	private async onAnswerQuote(): Promise<void>
	{
		try {
			const result = await Swal.fire({
				title: `Gestion du devis ?`,
				text: 'Voulez-vous accepter ce Devis ? Si oui, cette action va générer une facture et envoyer un mail',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: `Oui, j'accepte ce Devis !`,
				cancelButtonText: 'Non, Je n\'accepte pas ce Devis'
			});

			if (this.state.selectedLegalNotice) {
				const nbCharacters = await this.legalNoticePriceService.nbCharacters(
					this.state.selectedLegalNotice.publishDepartment,
					this.state.selectedLegalNotice.formBuilderCategory,
					this.state.selectedLegalNotice,
					(this.state.selectedLegalNotice.option.isHeader)
						? this.legalNoticePriceService.countTempHeaderContent(this.state.selectedLegalNotice.consumer)
						: 0
				);

				if (result.isConfirmed) {
					await this.apiLegalNoticeService.respondQuote(
						this.state.selectedLegalNotice?.id as number,
						{
							respond: 'ACCEPTED',
							legalNotice: { nbCharacters }
						}
					);

					// Call api to created file
					this.callApiFiles(this.state.selectedLegalNotice, nbCharacters);

					this.props.flashMessageContext.flashMessage(
						'Devis validé avec succès ! Facture créé et mail envoyé !',
						'L\'annonce est en attente de planification',
						'success'
					);
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					await this.apiLegalNoticeService.respondQuote(
						this.state.selectedLegalNotice?.id as number,
						{ respond: 'REFUSED', legalNotice: { nbCharacters: 0 } }
					);

					this.props.flashMessageContext.flashMessage(
						'Devis refusé avec succès ! ',
						'L\'annonce est bloquée',
						'info'
					);
				}
			}

			await this.refreshList();
			this.setState({ isOpenShowOffCanvas: false });
		} catch (error) {
			console.error('Error handling quote response:', error);
			this.props.flashMessageContext.flashMessage(
				'Erreur lors du traitement du devis',
				'Une erreur est survenue lors du traitement du devis. Veuillez réessayer.',
				'error'
			);
		}
	}

	private buildTableHeaderLegalNotice(): TableHeaderListInterface[]
	{
		return [
			{
				columnTitle: 'Donneur d\'ordre',
				orderTag: 'client',
				minWidth: 100,
				width: 250,
				justifyContent: 'left',
				fontWeight: 600,
				type: 'twoLinesString',
				dataTag: ['client.name', 'client.siren']
			},
			{
				columnTitle: 'N° d\'annonce',
				orderTag: 'tag',
				minWidth: 100,
				width: 100,
				justifyContent: 'center',
				fontWeight: 400,
				type: 'string',
				dataTag: 'tag'
			},
			{
				columnTitle: 'Publication au...',
				orderTag: 'publishDate',
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'date',
				dataTag: 'publishDate'
			},
			{
				columnTitle: 'Journal',
				orderTag: 'newspaper',
				minWidth: 100,
				width: 250,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'twoLinesString',
				dataTag: ['newspaper.name', 'newspaper.type']
			},
			{
				columnTitle: 'Status',
				orderTag: 'status',
				minWidth: 100,
				width: 200,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'tag',
				dataTag: { label: 'status', enumClass: PublishStateEnum }
			},
			{
				columnTitle: 'Paiement',
				orderTag: 'paymentStatus',
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'tag',
				dataTag: { label: 'paymentStatus', enumClass: PaymentStateEnum }
			},
			{
				columnTitle: 'Collaborateur',
				orderTag: 'collaborator',
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'string[]',
				dataTag: ['collaborator.user.firstname', 'collaborator.user.lastname']
			},
			{
				columnTitle: 'À régler',
				orderTag: 'price',
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'price',
				dataTag: 'price'
			},
		];
	}

	private itemPerPage(currentPage: number, itemPerPage: number): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				page: currentPage,
				itemsPerPage: itemPerPage
			}
		}), async () => await this.refreshList());
	}

	private onRowClick(event: any, legalNotice: LegalNoticeInterface): void
	{
		if (event) {
			event.stopPropagation();
		}

		const index: number = this.state.paginate.items.findIndex(
			(legalNoticeItem: LegalNoticeInterface) => legalNoticeItem.id === legalNotice.id
		);

		this.setState({
			selectedLegalNotice: legalNotice,
			isOpenShowOffCanvas: true,
			selectedLegalNoticeIndex: index,
			currentItemId: legalNotice.id
		});
	}

	private navigateLegalNotice(isPrevious: boolean): void
	{
		const { selectedLegalNoticeIndex, paginate } = this.state;
		let navigateIndex = isPrevious ? selectedLegalNoticeIndex - 1 : selectedLegalNoticeIndex + 1;

		// Check index
		if (navigateIndex >= 0 && navigateIndex < paginate.items.length) {
			this.setState({
				selectedLegalNotice: paginate.items[navigateIndex],
				selectedLegalNoticeIndex: navigateIndex,
				currentItemId: this.state.selectedLegalNotice?.id as number,
			});
		}
	}

	private buildFilterParams(orderBy: string, sort: string): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				orderBy: orderBy,
				sort: sort
			}
		}), async (): Promise<void> => await this.refreshList());
	}

	private payedProcess(data: any): void
	{
		console.log(data);
		// this.refreshList();
	}

	//</editor-fold>

	//<editor-fold desc="Handle filters / Params" defaultstate="collapsed">

	private buildFilterLegalNoticeList(): FilterInterface[]
	{
		const publishStatusfilteredOptions = PublishStateEnum.options.filter((option: PublishStateEnum) =>
			option.value !== PublishStateEnum.DRAFT.value
			&& option.value !== PublishStateEnum.QUOTE.value
			&& option.value !== PublishStateEnum.CANCEL.value
		);

		const paymentStatusFilterOptions = PaymentStateEnum.options.filter((option: PaymentStateEnum) =>
			option.value !== PaymentStateEnum.VALIDATE.value
			&& option.value !== PaymentStateEnum.CANCEL.value
			&& option.value !== PaymentStateEnum.PAID.value
		);

		return [
			{
				label: 'Statut',
				tag: 'status',
				icon: <LuStar/>,
				childList: publishStatusfilteredOptions,
				type: 'select'
			},
			{
				label: 'Paiement',
				tag: 'paymentStatus',
				icon: <LuCircleDollarSign/>,
				childList: paymentStatusFilterOptions,
				type: 'select'
			},
			{ label: 'Journal', tag: 'newspaper', icon: <LuFiles/>, childList: [], type: 'search' },
		];
	}

	private selectedFilters(selectedFilters: any[]): void
	{
		const filtersObject = selectedFilters.reduce((acc, filter) =>
		{
			acc[filter.filterName] = filter.filterValue;
			return acc;
		}, {});

		this.setState(prevState => ({
				filterParams: {
					...prevState.filterParams,
					filters: filtersObject
				}
			}), async () => await this.refreshList()
		);
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private async refreshList(): Promise<void>
	{
		this.setState({ isLoading: true });

		try {
			const legalNoticeListResponse = await this.apiLegalNoticeService.specificList(
				'payment',
				paramsToString(this.state.filterParams)
			);

			this.setState({
				paginate: {
					items: legalNoticeListResponse.items,
					itemsPerPage: legalNoticeListResponse.itemsPerPage,
					totalCount: legalNoticeListResponse.length,
					totalPages: legalNoticeListResponse.totalPages
				}
			}, () =>
			{
				this.setState({ isLoading: false });
				this.checkUrl();
			});
		} catch (error: any) {
			console.log('Something wrong on refresh user list', error.errorMessage);
		}
	}

	private checkForReload(): void
	{
		const searchParams = new URLSearchParams(this.props.location.search);
		if (searchParams.get('reload') === 'true') {
			this.refreshList();
			this.props.navigation(this.props.location.pathname);
		}
	}

	private buildTabsOffCanvas(): { id: number, name: string }[]
	{
		return [
			{ id: 1, name: 'Résumé' },
			{ id: 2, name: 'Contenu' },
			{ id: 3, name: 'Documents' },
			{ id: 4, name: 'Emails' },
		];
	}

	private async handleTabChange(selectedTab: number): Promise<void>
	{
		if (selectedTab === 3 && this.state.selectedLegalNotice) {
			await this.apiLegalNoticeFileService.getFiles(this.state.selectedLegalNotice?.id)
				.then((files: any[]) => {
					this.setState({ selectedLegalNoticeFiles: files });
				})
				.catch(console.error);
		}

		this.setState({ selectedTabOffCanvas: selectedTab });
	}

	private checkUrl(): void
	{
		const queryParams: URLSearchParams = new URLSearchParams(this.props.location.search);
		const legalNoticeId: string | null = queryParams.get('legalNoticeId');
		const fromUrl: string = queryParams.get('from') as string;
		const currentLegalNoticeId: number | null = this.state.selectedLegalNotice && this.state.selectedLegalNotice.id;

		if (legalNoticeId && Number(legalNoticeId) !== currentLegalNoticeId) {
			const selectedLegalNotice = this.state.paginate.items.find((legalNotice: LegalNoticeInterface) => legalNotice.id === Number(legalNoticeId));
			if (selectedLegalNotice) {
				this.setState({
					selectedLegalNotice,
					isOpenShowOffCanvas: true,
					isCreatedMode: false,
					fromUrl: fromUrl
				});
			}
		}
	}

	private async searchLegalNotice(term: string): Promise<void>
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				search: (term !== '') ? term : '',
				page: 1
			}
		}), async () => await this.refreshList());
	}

	private async onChangeLegalNoticeSearch(event: React.ChangeEvent<HTMLInputElement>): Promise<void>
	{
		const term = event.target.value;
		await this.searchLegalNotice(term);
	}

	private async resetSearchInput(): Promise<void>
	{
		await this.searchLegalNotice('');
	}

	private callApiFiles(legalNotice: LegalNoticeInterface, nbCharacters: number): void
	{
		const prepareDataForCertificate = {
			legalNotice: {
				title: legalNotice.title,
				content: legalNotice.content,
				signature: legalNotice.signature,
				price: legalNotice.price
			}
		}

		this.apiLegalNoticeFileService.createBillingFile(
			this.state.selectedLegalNotice?.id as number,
			{ legalNotice: { nbCharacters } }
		).then(() => {
			return this.apiLegalNoticeFileService.createCertificateFile(
				legalNotice.id as number,
				prepareDataForCertificate
			);
		}).then(() => {
			if (legalNotice.discount > 0) {
				return this.apiLegalNoticeFileService.createCreditNoteFile(
					legalNotice.id as number,
					prepareDataForCertificate
				);
			}
		}).catch(error => {
			console.error(error);
		});
	}

	//</editor-fold>
}
