import React, { ReactElement } from 'react';
import AddressModel from '@/Modules/Client/Model/AddressModel';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

interface ComponentProps
{
  sirenData: (sirenData: any) => void,
  sirenValue: string|null,
  isInseeApi: boolean,
  isDisabled?: boolean
}

interface ComponentState
{
  siren: string|null,
  errorMessage: string|null,
  isLoading: boolean
}

export default class SirenInputComponent extends React.Component<ComponentProps, ComponentState>
{
  appService: ApiAppService;
  state = {
    siren: this.props.sirenValue || null,
    errorMessage: null,
    isLoading: false
  };

  constructor(props: any)
  {
    super(props);

    // Service
    this.appService = new ApiAppService();

    // Bind
    this.callApiSiren = this.callApiSiren.bind(this);
    this.fillFormField = this.fillFormField.bind(this);
  }

  render(): ReactElement
  {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            type="text"
            label="Siren"
            style={{ marginBottom: '15px' }}
            name="siren"
            value={  this.state.siren || '' }
            containerDivWidth={ '250' }
            onChange={ (event) => this.onSirenEventField(event) }
            disabled={ this.props.isDisabled }
          />
          { this.state.isLoading
            && <div style={{ marginLeft: '10px', marginTop: '6px' }}>
              <LoaderComponent variant={ 'primary' } />
            </div>
          }
        </div>
        { this.state.errorMessage
          && <div className="error-message" role="alert">{ this.state.errorMessage }</div>
        }
      </>
    );
  }

  async onSirenEventField(event: React.ChangeEvent<any>): Promise<any>
  {
    const cleanSirenValue = (value: string) => value.replace(/\s+/g, '');

    // Init vars
    let inputSirenValue = event.target.value;
    inputSirenValue = cleanSirenValue(inputSirenValue);

    await this.callApiSiren(inputSirenValue);
  }

  private async callApiSiren(inputSirenValue: string): Promise<void>
  {
    this.setState({ siren: inputSirenValue });

    if (inputSirenValue.length === 9) {
      this.setState({ isLoading: true });
      try {

        let responseSiren = null;
        if (this.props.isInseeApi) {
          responseSiren = await this.appService.sirenInsee(inputSirenValue);
        } else {
          responseSiren = await this.appService.sirenInpi(inputSirenValue);
        }

        // Error message
        if (responseSiren.errorMessage) {
          this.setState({ errorMessage: responseSiren.errorMessage });
          return;
        } else {
          this.setState({ errorMessage: '' });
        }

        // Fill form field
        this.fillFormField(responseSiren);
      } catch (error: any) {
        console.log('something wrong on app siren: ', error);
      } finally {
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ errorMessage: '' });
    }
  }

  private fillFormField(responseSiren: any)
  {
    this.setState({ errorMessage: '' });

    this.props.sirenData({
      siren: responseSiren.siren || '',
      name: responseSiren.name || '',
      apeCode: responseSiren.apeCode || '',
      legalStatus: responseSiren.legalStatus || '',
      capital: responseSiren.capital,
      rcs: responseSiren.rcs,
      address: AddressModel.prepare(responseSiren.addressDto || {})
    });
  }
}