import React, { ReactElement } from 'react';
import LegalNoticeFomCardComponent from '@/Modules/LegalNotice/Components/Form/Admin/FormCardComponent';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

interface ComponentProps
{
  isDisplayBlock: boolean,
  client: ClientInterface,
  onNewName: (name: string) => void,
}

interface ComponentState
{
  formData: { name: string|null }
  isLoading: boolean
}

export default class BlockNewConsumerNameComponent extends React.Component<ComponentProps, ComponentState>
{
  sellsyService : ApiAdminSellsyService;

  constructor(props: any)
  {
    super(props);

    // Init state
    this.state = {
      formData: { name: null },
      isLoading: false
    };

    // Service
    this.sellsyService = new ApiAdminSellsyService();
  }

  render(): ReactElement
  {
    return (
      <>
        { this.props.isDisplayBlock &&
          <>
            <LegalNoticeFomCardComponent
              title={"Nouvelle Dénomination"}
              children={
                <>
                  <>
                    <Input
                      type="text"
                      label="Dénomination"
                      name="name"
                      style={ { display: 'flex' } }
                      value={ this.state.formData.name || '' }
                      onChange={ this.handleInputChange.bind(this) }
                    />

                    <div style={ { width: '100%', display: 'flex', justifyContent: 'flex-end' } }>
                      {this.state.isLoading && (
                        <div style={{ marginLeft: '10px', marginTop: '6px' }}>
                          <LoaderComponent variant={'primary'} />
                        </div>
                      )}

                      <Button
                        style={{ marginTop: 10 }}
                        type={ 'default-blue' }
                        onClick={ () => this.onFormSubmit() }
                        disabled={ !this.state.formData.name }
                      >
                        Enregistrer la nouvelle dénomination
                      </Button>
                    </div>
                  </>
                </>
              }
            />
          </>
        }
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private async onFormSubmit(): Promise<void> {
    this.setState({ isLoading: true });
    try {
      const response = await this.sellsyService.updateCompanyName(
        this.props.client.company.id,
        parseInt(this.props.client.extSellsyId),
        this.state.formData.name as string
      );

      this.props.onNewName(response.name);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void
  {
    const name = event.target.value;
    this.setState({ formData: { name } });
  }

  //</editor-fold>
}
