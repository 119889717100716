import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAdminSellsyService extends BaseService
{
  clientSellsyUrl: string = `${ BaseService.API_URL }/v1/admin/sellsy`;

  async createCompanyFromLegalNotice(sellsyCompanyId: number, createClientData: any): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/${sellsyCompanyId}`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(createClientData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async searchCompanyById(sellsyCompanyId: string, companyId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/search/${companyId}`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify({ 'sellsyCompanyId': parseInt(sellsyCompanyId) }),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async searchCompanyByTerm(companyId: number, term: string): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/search/${companyId}/${term}`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async getCompany(companyId: number, sellsyClientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/${companyId}/${sellsyClientId}`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async getCompanyContact(companyId: number, sellsyClientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/contacts/${companyId}/${sellsyClientId}`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async listCompanyContact(companyId: number, sellsyClientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/contacts/list/${companyId}/${sellsyClientId}`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async getContact(companyId: number, sellsyContactId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/contacts/${companyId}/${sellsyContactId}`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async createContact(companyId: number, contactData: any): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/contacts/${companyId}`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(contactData)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async linkContactToCompany(companyId: number, sellsyCompanyId: number, sellsyContactId: number, isCollaboratorOwner: boolean): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/contacts/link/${companyId}/${sellsyCompanyId}/${sellsyContactId}`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(
            { roles : (isCollaboratorOwner)
                ? 'main'
                : 'dunning'
            }
          )
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async getCompanyAddressList(companyId: number, sellsyCompanyId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/addresses/${companyId}/${sellsyCompanyId}`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async updateCompanyName(companyId: number, sellsyCompanyId: number, name: string): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/${companyId}/${sellsyCompanyId}`,
        {
          method: HttpVerbEnum.PUT,
          headers: this.buildHeaders(),
          body: JSON.stringify({ name })
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async updateCompanyAddress(companyId: number, sellsyCompanyId: number, sellsyAddressId: number, addressData: any): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/companies/addresses/${companyId}/${sellsyCompanyId}/${sellsyAddressId}`,
        {
          method: HttpVerbEnum.PUT,
          headers: this.buildHeaders(),
          body: JSON.stringify(addressData)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async getInvoice(companyId: number, sellsyCompanyId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.clientSellsyUrl }/invoice/${companyId}/${sellsyCompanyId}`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}