import React, { createRef, ReactElement } from 'react';
import { BsBoxArrowRight } from 'react-icons/bs';
import { dateHeader } from '@/Utils/DateUtils';
import MainNavigationComponent from '@/Modules/App/Components/Menu/MainNavigationComponent';
import CardActionComponent from '@/Modules/App/Components/Atom/Card/CardActionComponent';
import { AuthContext } from '@/Provider/AuthProvider';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { NavigateFunction } from 'react-router-dom';
import HeaderSwitchClientWrapper from '@/Modules/App/Wrapper/HeaderSwitchClientWrapper';
import AvatarComponentWrapper from '@/Modules/App/Wrapper/AvatarComponentWrapper';
import { LayoutAdminStyle } from '@/Modules/App/Style/Layout/LayoutAdminStyle';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { LuMoon } from 'react-icons/lu';

export interface ComponentLayoutProps
{
	children?: React.ReactNode;
	navigation: NavigateFunction;
}

interface ComponentState
{
	isAvatarCardOpen: boolean,
	isThemeDark: boolean,
}

export default class Layout extends React.Component
	<ComponentLayoutProps, ComponentState>
{
	static contextType = AuthContext;
	navigation: NavigateFunction;
	menuActionRef = createRef<HTMLDivElement>();

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Navigate
		this.navigation = props.navigation;

		// Bind
		this.handleLogout = this.handleLogout.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<div style={ LayoutAdminStyle.mainLayoutGrid() }>
					<div style={ LayoutAdminStyle.menuHeaderContainer(this.state.isThemeDark) }>
						<div style={ LayoutAdminStyle.menuHeaderGrid() }>
							<div style={ LayoutAdminStyle.menuHeaderLogo() }>
								<div
									style={ { width: 100, cursor: 'pointer' } }
									onClick={ (): void => this.props.navigation(`/`) }
								>
									<img src={ (this.state.isThemeDark) ? '/img/logo-publisur-white.svg' : '/img/logo-publisur-noir.svg' }
											 alt="logo publisur"/>
								</div>
							</div>
							<div style={ LayoutAdminStyle.menuHeaderMainGrid() }>
								<div>
									<HeaderSwitchClientWrapper isThemeDark={ this.state.isThemeDark }/>
								</div>
								<div style={ LayoutAdminStyle.menuHeaderProfilAndDate() }>
									<div style={ { cursor: 'pointer' } }
											 onClick={ () => this.setState(prevState => ({ isThemeDark: !prevState.isThemeDark })) }>
										<LuMoon fontSize={ 18 }
														color={ (this.state.isThemeDark) ? CssVariableEnum['--color-white'] : CssVariableEnum['--color-grey-900'] }/>
									</div>
									<div
										style={ FontStyle.normal((this.state.isThemeDark) ? CssVariableEnum['--color-white'] : CssVariableEnum['--color-grey-900']) }>
										{ dateHeader() }
									</div>
									<AvatarComponentWrapper onAvatarClick={ this.handleAvatarClick.bind(this) }/>
									<CardActionComponent
										className="menu-action-avatar"
										buildActionsContent={ this.buildCardActionsContent() }
										menuActionRef={ this.menuActionRef }
										isOpen={ this.state.isAvatarCardOpen }
										onClose={ this.closeAvatarCardAction.bind(this) }
									/>
								</div>
							</div>
						</div>
					</div>

					<div style={ LayoutAdminStyle.sidebarAndMainContentGrid() }>
						<div style={ LayoutAdminStyle.menuSidebar(this.state.isThemeDark) }>
							<MainNavigationComponent/>
						</div>
						<main style={ LayoutAdminStyle.transitionBackground(this.state.isThemeDark) }>
							<div style={ LayoutAdminStyle.mainContent() }>
								{ this.props.children }
							</div>
						</main>
					</div>
				</div>
			</>
		);
	}


	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	private initState(): ComponentState
	{
		return {
			isAvatarCardOpen: false,
			isThemeDark: true,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	handleAvatarClick = (): void =>
	{
		this.setState(prevState => ({ isAvatarCardOpen: !prevState.isAvatarCardOpen }));
	};

	closeAvatarCardAction = (): void =>
	{
		this.setState({ isAvatarCardOpen: false });
	};

	private buildCardActionsContent()
	{
		return [
			[
				{
					name: 'Déconnexion',
					isClickable: true,
					icon: <BsBoxArrowRight/>,
					action: this.handleLogout
				}
			]
		];
	}

	private handleLogout(): void
	{
		const authContext = this.context as AuthContextType;
		if (authContext && authContext.logout) {
			authContext.logout();
			this.navigation('/auth');
		}
	}

	//</editor-fold>

}