import React, { ReactElement } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';

interface ViewProps
{
	flashMessageContext: FlashMessageContextType,
	navigation: NavigateFunction,
	token: string
}

interface ViewState
{
	email: string,
	newPassword: string,
	user: UserInterface | null,
	errorMessage: string | null,
	isLoading: boolean
}

export default class ForgotPasswordView extends React.Component<ViewProps, ViewState>
{
	publicService: ApiPublicService;
	state = {
		email: '',
		newPassword: '',
		user: null,
		errorMessage: null,
		isLoading: true,
	};

	constructor(props: any)
	{
		super(props);

		// Services
		this.publicService = new ApiPublicService();

		// Bind
		this.submitNewPassword = this.submitNewPassword.bind(this);

		// Document title
		document.title = 'Publisur - Mot de passe oublié';
	}

	async componentDidMount(): Promise<void>
	{
		try {
			const getUserFromToken = await this.publicService.checkAccountExistFromPasswordToken(this.props.token);
			if (getUserFromToken.errorMessage) {
				this.props.navigation('/not-found');
			} else {
				this.setState({
					user: getUserFromToken as UserInterface,
					isLoading: false,
				});
			}
		} catch (error) {
			console.error('Failed to fetch user:', error);
		}
	}

	render(): ReactElement
	{
		if (this.state.isLoading && !this.state.user) {
			return <LoaderFullPageComponent/>;
		}

		return (
			this.state?.user
				? this.formForgotPassword(this.state.user)
				: <></>
		);
	}

	private formForgotPassword(user: UserInterface): ReactElement
	{
		return (
			<>
				<div style={ {
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				} }>
					{/* CARD */ }
					<div style={ {
						width: 500,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
						padding: 25,
						borderRadius: 15
					} }>
						<div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' } }>
							<div style={ FontStyle.h2() }>👋 Bonjour, { user.lastname } { user.firstname }</div>
							<p style={ { ...FontStyle.littleGrey(), textAlign: 'center', margin: '5px 0 15px 0', lineHeight: 1.1 } }>
								Afin de mettre à jour votre mot de passe, <br/>
								merci de renseigner votre nouveau mot de passe dans le champ ci-dessous.
							</p>

							{ this.state.errorMessage
								&& <div className="error-message" role="alert">{ this.state.errorMessage }</div>
							}

							<Input
								type={ 'password' }
								width={ '100%' }
								name={ 'new-password' }
								placeholder="Votre nouveau mot de passe"
								value={ this.state.newPassword }
								onChange={ (event) => this.onChangeNewPassword(event) }
								required={ true }
							/>

							<div style={ {
								display: 'flex',
								justifyContent: 'flex-end',
								gap: 20,
								height: 50,
								width: '100%',
								alignItems: 'flex-end'
							} }>
								<Button
									type={ 'danger' }
									label={ 'Annuler' }
									onClick={ () => this.props.navigation('/auth') }
								/>

								<Button
									type="default-blue"
									label={ 'Modifier mon mot de passe' }
									onClick={ this.submitNewPassword  }
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	private async submitNewPassword(event: React.FormEvent<HTMLFormElement>): Promise<void>
	{
		event.preventDefault();

		try {
			const response = await this.publicService.forgotPassword(
				{ password: this.state.newPassword },
				this.props.token
			);

			if (response.errorMessage) {
				this.setState({ errorMessage: response.errorMessage });
				return;
			}

			// Set Flash message
			this.props.flashMessageContext.flashMessage(
				'Mot de passe bien enregistré',
				'Votre nouveau mot de passe a été enregistré avec succés, connectez-vous avec votre nouveau mot de passe',
				'success'
			);
			// navigate
			this.props.navigation('/auth');
			this.setState({ errorMessage: null, user: response });
		} catch (error) {
			console.log(error);
		}
	}

	private onChangeNewPassword(event: React.ChangeEvent<any>): void
	{
		this.setState({ newPassword: event.target.value });
	}
}
