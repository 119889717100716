import React, { CSSProperties, ReactElement, ReactNode } from 'react';

export default class TableRow extends React.Component
	<{
		children: ReactNode;
		rowItem?: any
		stripedRowIndex?: number;
		isParentGroup?: boolean;
		isChildGroup?: boolean;
		isCheckboxInput?: boolean
		borderBottom?: boolean,
		onClick?: (event: any, item: any) => void;
		style?: CSSProperties;
	},
		{
			isHovered: boolean,
		}
	>
{
	state = {
		isHovered: false,
	};

	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					position: 'relative',
					width: '100%',
					minHeight: '32px',
					display: 'flex',
					alignItems: 'center',
					borderBottom: (this.props.borderBottom) ? '1px solid rgb(233, 233, 231)' : 'none',
					paddingLeft: (this.props.isChildGroup) ? '0px' : '0px',
					...this.props.style
				} }
						 onMouseEnter={ () => this.setState({ isHovered: true }) }
						 onMouseLeave={ () => this.setState({ isHovered: false }) }
						 onClick={ (event) => this.props.onClick && this.props.rowItem && this.props.onClick(event, this.props.rowItem) }
				>
					{ this.props.isCheckboxInput !== false &&
            <div style={ {
							minWidth: '32px',
							height: '100%',
						} }>
              <div style={ {
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								background: 'white',
								borderBottom: 'rgb(233, 233, 231)',
								width: '100%',
								height: '100%',
								opacity: (this.state.isHovered) ? '1' : '0',
								transitionProperty: 'opacity',
								transitionDuration: '270ms',
								transitionTimingFunction: 'ease',
							} }
              >
                <input type="checkbox" style={ { cursor: 'pointer' } }/>

              </div>
            </div>
					}
					{ this.props.children }
				</div>
			</>
		);
	}
}