import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import MenuItem from '@/Modules/App/Components/Menu/MenuItem';

class MainNavigationComponent extends React.Component
	<{ location: any }, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					height: '100%',
					paddingTop: 30,
					paddingBottom: 50,
					display: 'grid',
					gridTemplateRows: '70% auto'
				} }>
					<header>
						<nav>
							<ul>
								<MenuItem
									urlPath={ '/' }
									label={ 'Tableau de bord' }
									iconName={ 'LuLayoutDashboard' }
									location={ this.props.location }
								/>
								<MenuItem
									urlPath={ '/collaborators' }
									label={ 'Collaborateurs' }
									iconName={ 'LuUsers' }
									location={ this.props.location }
								/>
								<MenuItem
									urlPath={ '/legal-notices' }
									label={ 'Annonces légales' }
									iconName={ 'LuFile' }
									location={ this.props.location }
								/>
							</ul>
						</nav>
					</header>
					<div style={ { display: 'flex', alignItems: 'flex-end', height: '100%' } }>
						<div>
							<div style={ { opacity: 1, width: 100, marginLeft: '7px', marginBottom: 20 } }>
								<img src="/img/logo-hje-color.svg" alt="logo hérault juridique"/>
							</div>
							<nav>
								<ul>
									<MenuItem
										urlPath={ '#!' }
										label={ 'Historique' }
										iconName={ 'LuHistory' }
										location={ this.props.location }
									/>
									<MenuItem
										urlPath={ '#!' }
										label={ 'Paramètres' }
										iconName={ 'LuSettings' }
										location={ this.props.location }
									/>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const MainNavigationUserWithLocation = (props: any) =>
{
	const location = useLocation();
	return <MainNavigationComponent { ...props } location={ location }/>;
};

export default MainNavigationUserWithLocation;
