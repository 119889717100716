export default class LegalNoticeTypeEnum {
  value: string;
  label: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }

  static NONE = new LegalNoticeTypeEnum('NONE', 'Choisir une categorie');
  static NAME_CHANGE  = new LegalNoticeTypeEnum('NAME_CHANGE', 'Changement de nom');
  static CONSTITUTION  = new LegalNoticeTypeEnum('CONSTITUTION', 'Constitution');
  static ABSENCE_DECLARATIONS  = new LegalNoticeTypeEnum('ABSENCE_DECLARATIONS', 'Déclarations d\'absence');
  static DECLARATION_UNSUITABILITY  = new LegalNoticeTypeEnum('DECLARATION_UNSUITABILITY', 'Déclaration d\'insaisissabilité');
  static MODIFICATION  = new LegalNoticeTypeEnum('MODIFICATION', 'Modification');
  static BUSINESS_ASSETS  = new LegalNoticeTypeEnum('BUSINESS_ASSETS', 'Fonds de commerce');
  static MATRIMONIAL_REGIME  = new LegalNoticeTypeEnum('MATRIMONIAL_REGIME', 'Régime matrimonial');
  static HOLOGRAPHIC_TESTAMENT  = new LegalNoticeTypeEnum('HOLOGRAPHIC_TESTAMENT', 'Testament olographe');
  static ACCEPTANCE_NET_ASSETS  = new LegalNoticeTypeEnum('ACCEPTANCE_NET_ASSETS', 'Acceptation de l\'actif net');
  static JUDICIAL_COURT  = new LegalNoticeTypeEnum('JUDICIAL_COURT', 'Tribunal judiciaire');
  static COMMERCIAL_COURT  = new LegalNoticeTypeEnum('COMMERCIAL_COURT', 'Tribunal de commerce');

  static typeList: LegalNoticeTypeEnum[] = [
    LegalNoticeTypeEnum.NONE,
    LegalNoticeTypeEnum.CONSTITUTION,
    LegalNoticeTypeEnum.MODIFICATION,
    LegalNoticeTypeEnum.BUSINESS_ASSETS,
    LegalNoticeTypeEnum.NAME_CHANGE,
    LegalNoticeTypeEnum.ABSENCE_DECLARATIONS,
    LegalNoticeTypeEnum.DECLARATION_UNSUITABILITY,
    LegalNoticeTypeEnum.MATRIMONIAL_REGIME,
    LegalNoticeTypeEnum.HOLOGRAPHIC_TESTAMENT,
    LegalNoticeTypeEnum.ACCEPTANCE_NET_ASSETS,
    LegalNoticeTypeEnum.JUDICIAL_COURT,
    LegalNoticeTypeEnum.COMMERCIAL_COURT,
  ];

  static findByValue(value: string): LegalNoticeTypeEnum|undefined
  {
    return this.typeList.find(option => option.value === value);
  }
}
