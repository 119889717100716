import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class CreateUserFormComponentStyle
{
  static containerStyle(): CSSProperties
  {
    return {
      position: 'relative',
      borderRadius: '6px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    };
  }

  static headerContainerStyle(): CSSProperties
  {
    return  {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      borderBottom: `1px solid ${ CssVariableEnum['--color-grey-300'] }`
    };
  }

  static iconHeaderStyle(): CSSProperties
  {
    return {
      height: '40px',
      width: '40px',
      borderRadius: '6px',
      border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    };
  }

  static bodyContainerStyle(): CSSProperties
  {
    return {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      paddingRight: '20px',
      paddingLeft: '20px'
    };
  }

  static footerContainerStyle(): CSSProperties
  {
    return {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      height: '50px',
      alignItems: 'flex-end',
      padding: '20px'
    };
  }

  static displayFlexGap10(): CSSProperties
  {
    return {
      display: 'flex',
      alignItems: 'center',
      gap: '10px'
    }
  }

  static displayFlexGap10DirectionColumn(): CSSProperties
  {
    return {
      padding: '20px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    };
  }
}