import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class Block extends React.Component
	<
		{
			children: ReactNode,
			style?: CSSProperties,
			theme: 'light' | 'dark',
		},
		any
	>
{

	render(): ReactElement
	{
		return (
			<div style={ {
				position: 'relative',
				backgroundColor: (this.props.theme === 'light') ? CssVariableEnum['--color-white'] : CssVariableEnum['--color-grey-900'],
				padding: 10,
				borderRadius: 15,
				border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
				...this.props.style
			} }>
				{ this.props.children }
			</div>
		);
	};
}