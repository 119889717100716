import React, { ReactElement } from 'react';

export default class Section extends React.Component<any, any>
{
	render(): ReactElement
	{
		return (
			<>
				<section style={ { width: '100%' } }>
					{ this.props.children }
				</section>
			</>
		);
	}
}