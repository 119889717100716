import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import ClientAdminView from '@/Modules/Client/Admin/View/ClientAdminView';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalContext } from '@/Provider/ModalProvider';
import { AuthContext } from '@/Provider/AuthProvider';
import { AuthCompanyContext } from '@/Provider/AuthCompanyProvider';

const ClientAdminViewWrapper = (props: any) => {
  const flashMessageContext = useContext(FlashMessageContext);
  const authContext = useContext(AuthContext);
  const authCompanyContext = useContext(AuthCompanyContext);
	const navigate = useNavigate();
  const location = useLocation();
	const modalContext = useContext(ModalContext);

  return <>
    <ClientAdminView
      authContext={ authContext }
      authCompanyContext={ authCompanyContext }
      flashMessageContext={ flashMessageContext }
			navigation={ navigate }
      location={ location }
			modalContext={ modalContext }
      companyId={(authCompanyContext?.authCompany)
        ? authCompanyContext?.authCompany?.id
        : authContext?.user?.company?.id
      }
      { ...props }
    />
  </>;
};

export default ClientAdminViewWrapper;