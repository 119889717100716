import React, { ReactElement } from 'react';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { ApiAdminCollaboratorService } from '@/Service/Admin/ApiAdminCollaboratorService';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { LuRepeat } from 'react-icons/lu';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';

interface ComponentProps {
	client: ClientInterface,
	collaboratorOwner: CollaboratorInterface,
	onSwitchCollaboratorValidate: () => Promise<void>,
	modalContext: ModalContextType,
	flashMessageContext: FlashMessageContextType,
}

interface ComponentState {
	formData: {
		oldOwnerId: number,
		newOwnerId: number | null
	},
	errorMessage: string | null,
}

export default class AdminSwitchCollaboratorOwner extends React.Component
	<ComponentProps, ComponentState>
{

	collaboratorService: ApiAdminCollaboratorService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();
		// Services
		this.collaboratorService = new ApiAdminCollaboratorService();

		// Bind
		this.onSelectedNewOwner = this.onSelectedNewOwner.bind(this);
		this.onSwitchCollaborator = this.onSwitchCollaborator.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<>
					<div style={ { padding: '25px' } }>
						<label style={ { marginBottom: '10px' } }>
							Choisir un nouveau propriétaire.
							<br/>
							(Propriétaire actuel : <span
							style={ FontStyle.normalMedium() }>{ this.props.collaboratorOwner.email }</span>)
						</label>
						<SelectComponent
							listOptions={ this.buildListCollaborators() }
							label={ 'Collaborateur' }
							onSelectedOption={ (event: any) => this.onSelectedNewOwner(event) }
							renderOptionLabel={ (collaborator: any) => `${ collaborator.email }` }
						/>
					</div>
					<div style={ {
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						height: '50px',
						alignItems: 'flex-end',
						padding: '20px'
					} }>
						<Button
							type={ 'default-blue' }
							iconLeft={ <LuRepeat/> }
							onClick={ () => this.onSwitchCollaborator() }
						>
							Changement de propriétaire
						</Button>
					</div>
				</>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	private initState(): ComponentState
	{
		return {
			formData: {
				oldOwnerId: this.props.collaboratorOwner.id,
				newOwnerId: null
			},
			errorMessage: '',
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private buildListCollaborators(): any[]
	{
		const owner: CollaboratorInterface = this.props.collaboratorOwner;

		return this.props.client.collaborators.filter(
			(collaborator: CollaboratorInterface): boolean => collaborator.id !== owner.id
		);
	}

	private onSelectedNewOwner(event: any): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				newOwnerId: event.id
			}
		}));
	}

	private async onSwitchCollaborator(): Promise<void>
	{
		await this.collaboratorService.switchOwner(
			{ oldOwnerId: this.state.formData.oldOwnerId, newOwnerId: this.state.formData.newOwnerId },
			this.props.client.id
		);
		await this.props.onSwitchCollaboratorValidate();
	}

	//</editor-fold>
}