import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class Subtitle extends React.Component
	<
		{
			children: ReactNode;
			style?: CSSProperties;
			borderBottom?: boolean;
		},
		any
	>
{
	render(): ReactElement
	{
		//<editor-fold desc="// Switch Types" defaultstate="collapsed">

		const { children, style } = this.props;

		//</editor-fold>

		return (
			<>
				<div
					style={ {
						fontSize: '12px',
						color: CssVariableEnum['--color-grey-400'],
						fontWeight: 400,
						lineHeight: '16px',
						paddingBottom: (this.props.borderBottom) ? '4px' : '',
						borderBottom: (this.props.borderBottom) ? '1px solid rgb(233, 233, 231)' : '',
						...style
					} }>
					{ children }
				</div>
			</>
		);
	}
}