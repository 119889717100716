import React, { ReactElement } from 'react';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import Table from '@/Modules/App/Components/Atom/Table/Table';
import TableHeader from '@/Modules/App/Components/Atom/Table/TableHeader';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import TableCell from '@/Modules/App/Components/Atom/Table/TableCell';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import TableGroup from '@/Modules/App/Components/Atom/Table/TableGroup';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import { BsTrash } from 'react-icons/bs';
import { AnnexInterface } from '@/Modules/Pricing/Interface/AnnexInterface';
import { PricingCategoryInterface } from '@/Modules/Pricing/Interface/PricingCategoryInterface';
import InputTableCell from '@/Modules/App/Components/Atom/Form/Input/InputTableCell';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { TableHeaderColumnInterface } from '@/Modules/App/Components/Atom/Interface/TableHeaderColumnInterface';
import { LuMoreHorizontal, LuPencilLine, LuSave, LuTrash2 } from 'react-icons/lu';

export default class FixedPriceTableComponent extends React.Component
	<
		{
			annexList: any[];
			categoryList: FormBuilderCategoryInterface[];
			onCreate: (createCategoryData: { price: number, annexId: number }, childCategoryId: number) => Promise<void>;
			onEdit: (data: any[]) => void;
			onDelete: (pricingCategoryId: number) => Promise<void>;
		},
		{
			isLoadingComponent: boolean,
			annexList: AnnexInterface[],
			categoryList: FormBuilderCategoryInterface[],
			pricingCategoryList: PricingCategoryInterface[],
			editModePricing: number | null,
			formData: any[],
			pricingValue: number | null,
			openMenuActionId: number | null,
			hovered: number | null,
		}
	>
{
	actionMenuRef: React.RefObject<HTMLDivElement> | null;

	constructor(props: any)
	{
		super(props);
		this.state = {
			isLoadingComponent: false,
			annexList: [],
			categoryList: [],
			pricingCategoryList: [],
			editModePricing: null,
			formData: [],
			pricingValue: null,
			openMenuActionId: null,
			hovered: null,
		};

		// Ref
		this.actionMenuRef = React.createRef();

		// Bind
		this.refreshDataPage = this.refreshDataPage.bind(this);
		this.buildPricingCategoryList = this.buildPricingCategoryList.bind(this);
		this.getPriceForCell = this.getPriceForCell.bind(this);
		this.builderTableHeaderColumns = this.builderTableHeaderColumns.bind(this);
		this.editModePricing = this.editModePricing.bind(this);
		this.onEditPricing = this.onEditPricing.bind(this);
		this.onClickTableActions = this.onClickTableActions.bind(this);
		this.onCancelEdit = this.onCancelEdit.bind(this);
	}

	handleClickOutside = (event: MouseEvent): void =>
	{
		if (!this.actionMenuRef?.current?.contains(event.target as Node)) {
			this.setState({ openMenuActionId: null });
		}
	};

	onClickOpenMenuAction = (childCategoryId: number): void =>
	{
		this.setState({ openMenuActionId: childCategoryId });
	};

	async componentDidMount()
	{
		await this.refreshDataPage();
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	render()
	{
		return (
			<>
				<Title type={ 'h5' } style={ { marginTop: 20 } }>
					Grille de tarifs des annonces légales applicables à partir du 1er Janvier { new Date().getFullYear() }
				</Title>

				<div style={ {
					backgroundColor: CssVariableEnum['--color-white'],
					borderRadius: '8px',
					padding: '20px 10px',
					border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
				} }>
					<Table>
						{ !this.state.isLoadingComponent &&
              <TableHeader
                columnHeaderList={ this.builderTableHeaderColumns(this.state.annexList) }
                isFilters={ true }
                isCheckboxInput={ false }
              />
						}
						{ this.state.categoryList.map((category, index) => (
							<TableGroup key={ category.id }>
								<TableRow
									isParentGroup={ true }
									key={ category.id }
									isCheckboxInput={ false }
									borderBottom={ false }
									style={ {
										backgroundColor: CssVariableEnum['--color-grey-900'],
										borderRadius: 4,
										margin: '4px 0',
										color: CssVariableEnum['--color-white']
									} }
								>
									<TableCol columnSize={ 5 }>
										<TableCell
											isLoading={ this.state.isLoadingComponent }
											style={ { fontWeight: 600 } }
										>
											{ category.label }
										</TableCell>
									</TableCol>
								</TableRow>
								{ category.childCategories.map((childCategory, index) => (
									<TableRow
										isChildGroup={ true }
										isCheckboxInput={ false }
										borderBottom={ false }
										key={ childCategory.id }
										style={ { backgroundColor: (this.state.editModePricing && this.state.editModePricing === childCategory.id) ? CssVariableEnum['--color-grey-200'] : 'inherit' } }
									>
										<div style={ { display: 'flex', width: `calc(100% - 70px)` } }>
											<TableCol columnSize={ 5 }>
												<TableCell isLoading={ this.state.isLoadingComponent }>
													{ childCategory.label }
												</TableCell>
											</TableCol>
											{ this.state.annexList.map((annex) => (
												<TableCol
													columnSize={ 1 }
													key={ annex.id }
													isEditMode={ !!(this.state.editModePricing) }
												>
													{ this.getPriceForCell(childCategory, annex) }
												</TableCol>
											)) }
										</div>
										<div style={ {
											position: 'relative',
											minWidth: '70px',
											height: '100%'
										} }>
											<TableCell isLoading={ this.state.isLoadingComponent }>
												{ this.state.editModePricing !== childCategory.id &&
                          <Button
                            type={ 'inline-default' }
                            iconLeft={ <LuMoreHorizontal/> }
                            onClick={ () => this.onClickOpenMenuAction(childCategory.id) }
                          />
												}
												{(this.state.editModePricing === childCategory.id) &&
                          <Button
                            type="default-blue"
                            iconLeft={ <LuSave /> }
                            onClick={() => this.onEditPricing() }
                          >
                            Enregistrer
                          </Button>
												}
											</TableCell>
											{ this.state.openMenuActionId === childCategory.id &&
												this.menuActionRow(childCategory)
											}
										</div>
									</TableRow>
								)) }
							</TableGroup>
						)) }
					</Table>
				</div>
			</>
		);
	}

	//<editor-fold desc="Refresh Data" defaultstate="collapsed">

	private async refreshDataPage(): Promise<void>
	{
		this.setState({
			isLoadingComponent: true
		});

		try {
			const refreshAnnexList: any = await this.props.annexList;
			const refreshCategoryList: FormBuilderCategoryInterface[] = await this.props.categoryList;
			const pricingCategoryList: any[] = [];

			// check each category and each Annex to get price
			refreshCategoryList.forEach((category) =>
			{
				category.childCategories.forEach((childCategory) =>
				{
					refreshAnnexList.forEach((annex: any) =>
					{
						const pricing = this.buildPricingCategoryList(childCategory, annex);
						pricingCategoryList.push(...pricing);
					});
				});
			});

			this.setState({
				annexList: refreshAnnexList,
				categoryList: refreshCategoryList,
				pricingCategoryList,
				formData: [],
				editModePricing: null,
				isLoadingComponent: false,
			});

		} catch (error) {
			console.error('An error occurred while refreshing departments:', error);
			this.setState({ isLoadingComponent: false });
		}
	}

	//</editor-fold>

	//<editor-fold desc="Html content" defaultstate="collapsed"

	private menuActionRow(childCategory: any): ReactElement
	{
		return (
			<div
				ref={ this.actionMenuRef }
				style={ {
					position: 'absolute',
					zIndex: 400,
					backgroundColor: CssVariableEnum['--color-white'],
					width: 150,
					top: 0,
					left: -100,
					border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
					boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
					borderRadius: 8,
					padding: 10,
					cursor: 'pointer'
				} }>
				{ this.menuActionItem(
					1,
					this.buildButtonLabel(childCategory.id),
					<LuPencilLine/>,
					() => this.onClickTableActions(childCategory.id))
				}
				{ this.menuActionItem(2, 'Supprimer', <LuTrash2/>, () => this.onDeletePricingCategory(childCategory.id)) }
			</div>
		);
	}

	private menuActionItem(hoveredId: number, label: string, icon: ReactElement, action: () => void): ReactElement
	{
		return (
			<div
				style={ {
					padding: '3px 6px',
					borderRadius: 8,
					backgroundColor: (this.state.hovered === hoveredId) ? CssVariableEnum['--color-grey-100'] : 'inherit',
				} }
				onMouseEnter={ () => this.setState({ hovered: hoveredId }) }
				onMouseLeave={ () => this.setState({ hovered: null }) }
				onClick={ action }
			>
				{ icon } { label }
			</div>
		);
	}

	private actions(childCategory: any): ReactElement
	{
		return (
			<TableCol columnSize={ 2 }>
				<TableCell isLoading={ this.state.isLoadingComponent }>
					<div style={ { display: 'flex', justifyContent: 'end' } }>
						<Button
							type="inline-default-blue"
							onClick={ () => this.onClickTableActions(childCategory.id) }
						>
							{ this.buildButtonLabel(childCategory.id) }
						</Button>
						{ (this.state.editModePricing && this.state.editModePricing === childCategory.id) &&
              <Button
                type="warning"
                onClick={ () => this.setState({ editModePricing: null }) }
              >
                Annuler
              </Button>
						}
						<Button
							type="danger"
							onClick={ () => this.onDeletePricingCategory(childCategory.id) }
						>
							<BsTrash/>
						</Button>
					</div>
				</TableCell>
			</TableCol>
		);
	}

	private buildButtonLabel(childCategoryId: number): string
	{
		return this.isExistPricing(childCategoryId) ? 'Modifier' : 'Créer';
	}

	private editPricingContentHtml(pricingCategory: PricingCategoryInterface | undefined, annex: AnnexInterface): ReactElement
	{
		return (
			<>
				<InputTableCell
					isLoading={ this.state.isLoadingComponent }
					value={ this.state.formData[annex.number - 1].price || 0 }
					onChange={ (event) => this.onChangePricing(event, pricingCategory) }
					onKeyDown={ () => this.onEditPricing() }
				/>
			</>
		);
	}

	private getPriceForCell(childCategory: FormBuilderCategoryInterface, annex: AnnexInterface): string | ReactElement | null
	{
		const getPricingCategory: PricingCategoryInterface | undefined = this.state.pricingCategoryList.find((pricing) =>
			pricing.formBuilderCategoryId === childCategory.id &&
			pricing.annexNumber === annex.number
		);

		if (this.state.editModePricing === childCategory.id) {
			return this.editPricingContentHtml(getPricingCategory, annex);
		}

		return (
			<>
				<TableCell isLoading={ this.state.isLoadingComponent }>
					{ getPricingCategory ? `${ getPricingCategory.price } €` : null }
				</TableCell>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Handle Pricing" defaultstate="collapsed">

	private buildPricingCategoryList(childCategory: any, annex: any): any[]
	{
		const pricingCategoriesList: any[] = [];

		// Filter if pricing category exist and match with category
		const matchingPricingCategories = annex.pricingCategories.filter(
			(pricingCategory: any) =>
				pricingCategory.formBuilderCategory.id === childCategory.id
		);

		// add each value on pricing info
		if (matchingPricingCategories.length > 0) {
			matchingPricingCategories.forEach((pricingCategory: any) =>
			{
				pricingCategoriesList.push({
					price: pricingCategory.price,
					formBuilderCategoryId: pricingCategory.formBuilderCategory.id,
					annexNumber: annex.number,
					id: pricingCategory.id
				});
			});
		}

		return pricingCategoriesList;
	}

	private onChangePricing(event: React.ChangeEvent<HTMLInputElement>, pricingCategory: any): void
	{
		const newPrice = event.target.value !== '' ? parseFloat(event.target.value) : null;

		// Update pricing Value with new Value
		this.setState((prevState) => ({
			formData: prevState.formData.map((item) =>
				item.formBuilderCategoryId === pricingCategory.formBuilderCategoryId &&
				item.annexNumber === pricingCategory.annexNumber
					? { ...item, price: newPrice }
					: item
			),
		}));
	}

	private onEditPricing(): void
	{
		this.setState({
			isLoadingComponent: true
		});
		this.props.onEdit(this.state.formData);
	}

	//</editor-fold>

	//<editor-fold desc="Crud Methods" defaultstate="collapsed">

	private async onCreatePricing(childCategoryId: number): Promise<void>
	{
		this.setState({ isLoadingComponent: true });

		this.state.annexList.forEach((annex) =>
		{
			this.props.onCreate({ price: 0, annexId: annex.id as number }, childCategoryId);
		});

	}

	private editModePricing(childCategoryId: number): void
	{
		const editPricingCategories = this.state.pricingCategoryList.filter(pricingCategory =>
			pricingCategory.formBuilderCategoryId === childCategoryId
		);

		this.setState({
			editModePricing: childCategoryId,
			formData: editPricingCategories
		});
	}

	private onCancelEdit(): void
	{
		this.setState({ editModePricing: null });
	}

	private async onDeletePricingCategory(childCategoryId: number): Promise<void>
	{
		const getPricingCategories = this.state.pricingCategoryList.filter((id) => id.formBuilderCategoryId === childCategoryId);

		if (getPricingCategories.length > 0) {
			this.setState({ isLoadingComponent: true });
			getPricingCategories.forEach((pricingCategory: PricingCategoryInterface) =>
				this.props.onDelete(pricingCategory.id as number)
			);

		} else {
			console.log('Aucune correspondance trouvée');
		}
	}

	//</editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	private onClickTableActions(childCategoryId: number): void
	{
		const isExistPricing = this.isExistPricing(childCategoryId);

		if (!isExistPricing) {
			this.onCreatePricing(childCategoryId);
		} else if (this.state.editModePricing === null) {
			this.editModePricing(childCategoryId);
		}

		this.setState({ openMenuActionId: null });
	}

	private isExistPricing(childCategoryId: number): boolean
	{
		return this.state.pricingCategoryList.some((pricing) =>
			pricing.formBuilderCategoryId === childCategoryId
		);
	}

	private builderTableHeaderColumns(list: any[]): TableHeaderColumnInterface[]
	{
		let tableHeaderColumnList: TableHeaderColumnInterface[] = [
			{ columnTitle: 'Société', columnSize: 5, name: 'label' },
		];

		list.forEach((annex) =>
		{
			tableHeaderColumnList.push({
				columnTitle: `Annexe ${ annex.number }`,
				columnSize: 1,
				name: annex
			});
		});

		return tableHeaderColumnList;
	}

	//</editor-fold>
}