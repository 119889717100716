import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class LegalNoticeContentHeader
{
  static mainContainerStyle(): CSSProperties
  {
    return {
      backgroundColor: CssVariableEnum['--color-blue-light-50'],
      padding: '10px',
      borderRadius: '8px',
      border: `1px solid ${ CssVariableEnum['--color-blue-light-100'] }`,
      height: '100%'
    };
  }

  static mainContainerBodyStyle(): CSSProperties
  {
    return {
      fontSize: '15px',
      fontWeight: 500,
      marginBottom: '5px',
      display: 'flex',
      justifyContent: 'space-between'
    };
  }
}