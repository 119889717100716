export function stringAvatar(firstname: string, lastname: string): { color: string; avatar: string } {
  const capitalizedFirstName = firstname ? firstname[0].toUpperCase() : '';
  const capitalizedLastName = lastname ? lastname[0].toUpperCase() : '';
  const fullNameForColor = firstname + ' ' + lastname;
  const fullName = `${capitalizedFirstName}${capitalizedLastName}`;

  return {
    color: _stringToColor(fullNameForColor),
    avatar: fullName,
  };
}

function _stringToColor(string: string): string {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
}