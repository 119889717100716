import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentView from '@/Modules/Payment/View/PaymentView';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { ModalContext } from '@/Provider/ModalProvider';


const CreateLegalNoticeAdminViewWrapper = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const flashMessageContext = useContext(FlashMessageContext);
  const modalContext = useContext(ModalContext);

  return <PaymentView
    location={ location }
    flashMessageContext={ flashMessageContext }
    navigation={ navigate }
    modalContext={ modalContext }
    { ...props }
  />;
};

export default CreateLegalNoticeAdminViewWrapper;