import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class LegalNoticeRenderStyle
{
  static mainContainerStyle(): CSSProperties
  {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      alignItems: 'center'
    };
  }

  static contentContainerStyle(): CSSProperties
  {
    return {
      position: 'relative',
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: 40,
      borderLeft: '1px solid rgba(55, 53, 47, 0.09)',
      borderRadius: '15px',
      border: `1px solid ${CssVariableEnum['--color-grey-300']}`,
      backgroundColor: CssVariableEnum['--color-white'],
      fontSize: 12,
      width: '300px'
    };
  }

  static borderStyle(): CSSProperties
  {
    return {
      borderWidth: '2px',
      borderColor: '#000',
      width: '150px',
      margin: '10px 0 0 0',
      opacity: '1'
    };
  }

  static renderStaticMessage(): CSSProperties
  {
    return {
      width: '200px',
      fontSize: '10px',
      fontStyle: 'italic',
      lineHeight: '12px',
      marginTop: '10px',
      marginRight: '5px',
      color: '#9C9B98',
      textAlign: 'right'
    };
  }
}