import React, { ReactElement } from 'react';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { ClientService } from '@/Modules/Client/Service/ClientService';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { ApiCollaboratorService } from '@/Service/Api/ApiCollaboratorService';
import CollaboratorShowComponent from '@/Modules/Collaborator/Components/CollaboratorShowComponent';
import InviteCollaboratorForm from '@/Modules/Collaborator/Components/Form/InviteCollaboratorForm';
import InviteStateEnum from '@/Enum/InviteStateEnum';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { ApiInviteCollaboratorService } from '@/Service/Api/ApiInviteCollaboratorService';
import InviteCollaboratorModel from '@/Modules/Collaborator/Model/InviteCollaboratorModel';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { AuthClientContextType } from '@/Provider/Interface/AuthClient/AuthClientContextType';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import HeroSection from '@/Modules/App/Components/Atom/Content/HeroSection';
import Content from '@/Modules/App/Components/Atom/Content/Content';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';
import TableComponent, { TableHeaderListInterface } from '@/Modules/App/Components/Atom/Table/TableComponent';
import { ModalContextType } from '@/Provider/ModalProvider';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { FilterParamsInterface } from '@/Modules/App/Interface/FilterParamsInterface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import { LuPlus } from 'react-icons/lu';
import { UserAdminViewService } from '@/Modules/User/Service/Admin/UserAdminViewService';
import OffCanvasComponent from '@/Modules/App/Components/Atom/OffCanvas/OffCanvasComponent';
import { UserService } from '@/Service/UserService';

interface ViewProps
{
	authContext: AuthContextType;
	clientContext: AuthClientContextType;
	flashMessageContext: FlashMessageContextType;
	modalContext: ModalContextType
	location?: any,
}

interface ViewState
{
	paginate: {
		collaborator: PaginateInterface,
		invites: PaginateInterface
	},
	filterParams: FilterParamsInterface,
	collaborators: CollaboratorInterface[],
	selectedCollaborator: CollaboratorInterface | null,
	selectedCollaboratorIndex: number,
	isLoading: boolean,
	currentItemId: number | null;
	isOpenShowOffCanvas: boolean;
	isCreatedMode: boolean;
	isUpdatedSuccess: boolean;
	errorMessage: string | null;
}

export class CollaboratorView extends React.Component
	<ViewProps, ViewState>
{
	// Properties
	clientService: ClientService;
	apiClientService: ApiClientService;
	apiInviteService: ApiInviteCollaboratorService;
	collaboratorService: ApiCollaboratorService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.clientService = new ClientService();
		this.apiClientService = new ApiClientService();
		this.apiInviteService = new ApiInviteCollaboratorService();
		this.collaboratorService = new ApiCollaboratorService();

		// Bind
		this.onRowClick = this.onRowClick.bind(this);
		this.handleFormCreate = this.handleFormCreate.bind(this);
		this.handleDeleted = this.handleDeleted.bind(this);
		this.handleUpdated = this.handleUpdated.bind(this);
		this.refreshList = this.refreshList.bind(this);
	}

	render(): ReactElement
	{
		const collaborator = UserService.getCollaboratorFromUser(this.props.authContext.user, this.props.clientContext.authClient.id);
		const collaboratorRole = collaborator ? collaborator.role : undefined;
		const isOwner: boolean = (collaboratorRole !== undefined && CollaboratorRoleEnum.findByValue(collaboratorRole.toString()) === CollaboratorRoleEnum.ROLE_OWNER);

		if (this.state.isLoading) {
			return <LoaderFullPageComponent/>;
		}

		return (
			<>
				<HeroSection
					title="Collaborateurs"
					icon={ null }
					isLoading={ this.state.isLoading }
					buttonAction={
						(this.clientService.checkIsRoleOwner(this.state.paginate.collaborator.items, LocalStorageService.getUserLocalStorage().userId))
							? {
								buttonType: 'default-blue',
								buttonLabel: 'Ajouter un collaborateur',
								buttonIcon: <LuPlus/>,
								onClick: (event: any) => this.createCollaboratorModal(event)
							}
							: null
					}
				/>

				<Content>
					<MainWrapper>
						<div style={ { display: 'grid', gridTemplateColumns: (isOwner) ? 'auto 500px' : 'auto', columnGap: 20 } }>
							{/* TABLE COLLABORATORS */ }
							<TableComponent
								paginateList={ this.state.paginate.collaborator.items }
								tableHeaderList={ this.buildHeaderCollaborator() }
								params={ this.buildFilterParams }
								onClickRow={ this.onRowClick }
								notFoundValuesText={ 'Aucun collaborateur n\'a été trouvée' }
								onRefreshList={ this.refreshList }
								isResizeColumns={ true }
								isStickyHeader={ true }
							/>

							{/* TABLE INVITES */ }
							{ (isOwner) &&
                <TableComponent
                  paginateList={this.state.paginate.invites.items.filter(item => item.state !== InviteStateEnum.ACCEPTED.value )}
                  tableHeaderList={ this.buildHeaderInvites() }
                  params={ this.buildFilterParams }
                  onClickRow={ this.onRowClick }
                  notFoundValuesText={ 'Aucune invitation en attente' }
									isResizeColumns={ true }
									isStickyHeader={ true }
                />
							}
						</div>
					</MainWrapper>
				</Content>

				<OffCanvasComponent
					show={ this.state.isOpenShowOffCanvas }
					onHide={ () => this.setState({ isOpenShowOffCanvas: false }) }
					onPrevious={ () => this.navigateCollaborator(true) }
					onNext={ () => this.navigateCollaborator(false) }
					tabs={ UserAdminViewService.buildTabsOffCanvas }
					isNavigateArrow={ true }
					isTabNeeded={ false }
					title={ (this.state.selectedCollaborator)
						? `${ this.state.selectedCollaborator.user.firstname } ${ this.state.selectedCollaborator.user.lastname }`
						: null
					}
				>
					{ this.state.selectedCollaborator && (
						<CollaboratorShowComponent
							flashMessageContext={ this.props.flashMessageContext }
							selectedCollaborator={ this.state.selectedCollaborator }
							authContext={ this.props.authContext }
							clientContext={ this.props.clientContext }
							currentItemId={ this.state.currentItemId }
							handleDeleted={ this.handleDeleted }
							handleUpdated={ this.handleUpdated }
							isUpdatedSuccess={ this.state.isUpdatedSuccess }
						/>
					) }
				</OffCanvasComponent>
			</>
		)
			;
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.refreshList();
	}

	private initState(): ViewState
	{
		return {
			paginate: {
				collaborator: {} as PaginateInterface,
				invites: {} as PaginateInterface,
			},
			filterParams: {
				page: 1,
				itemsPerPage: 10,
				search: null,
				filters: null,
				orderBy: null,
				sort: null,
			},
			collaborators: [],
			selectedCollaborator: null as CollaboratorInterface | null,
			selectedCollaboratorIndex: -1,
			isLoading: true,
			currentItemId: null,
			isOpenShowOffCanvas: false,
			isCreatedMode: false,
			isUpdatedSuccess: false,
			errorMessage: null
		};
	}

	//</editor-fold>

	//<editor-fold desc="Header tables methods" defaultstate="collapsed">

	private async refreshList(): Promise<void>
	{
		if (this.props.clientContext.authClient) {
			const collaboratorListResponse = await this.collaboratorService.list(
				this.props.clientContext.authClient?.id,
				paramsToString(this.state.filterParams)
			);

			this.setState({
				paginate: {
					collaborator: {
						items: collaboratorListResponse,
						itemsPerPage: 10,
						totalCount: collaboratorListResponse.length,
						totalPages: 1
					},
					invites: {
						items: this.props.clientContext.authClient.invites,
						itemsPerPage: 10,
						totalCount: collaboratorListResponse.length,
						totalPages: 1
					}
				},
				isLoading: false,
			});
		}
	}

	private buildHeaderCollaborator(): TableHeaderListInterface[]
	{
		return [
			{
				columnTitle: 'Email',
				orderTag: 'email',
				minWidth: 100,
				width: 400,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'string',
				dataTag: ['email']
			},
			{
				columnTitle: 'Crée le...',
				orderTag: 'createdAt',
				minWidth: 100,
				width: 150,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'date',
				dataTag: 'createdAt'
			},
			{
				columnTitle: 'Rôle',
				orderTag: 'role',
				minWidth: 100,
				width: 150,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'tag',
				dataTag: { label: 'role', enumClass: CollaboratorRoleEnum }
			},
			{
				columnTitle: 'Statut',
				orderTag: 'status',
				minWidth: 100,
				width: 150,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'tag',
				dataTag: { label: 'status', enumClass: ActiveStatusEnum }
			}
		];
	}

	private buildHeaderInvites(): TableHeaderListInterface[]
	{
		return [
			{
				columnTitle: 'Email',
				orderTag: 'email',
				minWidth: 100,
				width: 350,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'string',
				dataTag: 'inviteEmail'
			},
			{
				columnTitle: 'Statut',
				orderTag: 'state',
				minWidth: 100,
				width: 100,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'tag',
				dataTag: { label: 'state', enumClass: InviteStateEnum }
			},
		];
	}

	private buildFilterParams(orderBy: string, sort: string): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				orderBy: orderBy,
				sort: sort
			}
		}), async (): Promise<void> => await this.refreshList());
	}

	//</editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	private createCollaboratorModal(event: any): void
	{
		event.stopPropagation();

		this.setState({ isCreatedMode: true, selectedCollaborator: null });

		this.props.modalContext.content(
			'Ajouter un collaborateur',
			<div style={ { padding: 15 } }>
				<InviteCollaboratorForm
					onFormSubmit={ this.handleFormCreate }
				/>
			</div>
		);
	}

	private onRowClick(event: any, item: CollaboratorInterface): void
	{

		if (event) {
			event.stopPropagation();
		}

		const index: number = this.state.collaborators.findIndex(
			(collaborator: CollaboratorInterface) => collaborator.id === item.id
		);

		this.setState({
			selectedCollaborator: item,
			isOpenShowOffCanvas: true,
			isCreatedMode: false,
			selectedCollaboratorIndex: index,
			currentItemId: item.id
		});
	}

	private async handleFormCreate(formData: InviteCollaboratorModel): Promise<void>
	{
		await this.apiInviteService.send(formData, LocalStorageService.getAuthClientLocalStorage())
			.then(async (response: any) =>
			{
				if (response.errorMessage) {
					this.setState({ errorMessage: response.errorMessage });
					return;
				}

				// Fetch Client
				const refreshClient = await this.apiClientService.show(LocalStorageService.getAuthClientLocalStorage());
				// Update context
				this.props.clientContext.refreshClient(refreshClient.id);
				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'Création réussie',
					`Le client ${ formData.client.name } a été créé avec succès`,
					'success'
				);
				// Close menu and OffCanvas, set refresh users
				this.setState({ isOpenShowOffCanvas: false, isCreatedMode: false, collaborators: refreshClient.collaborators });
				this.props.modalContext.isOpen(false);
			})
			.catch((error: any) =>
			{
				this.setState({ errorMessage: error.message });
			})
		;
	}

	private async handleDeleted(collaboratorId: number): Promise<void>
	{
		const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer cet collaborateur ?');
		if (confirmation) {
			try {
				await this.collaboratorService.delete(
					LocalStorageService.getAuthClientLocalStorage(),
					collaboratorId
				);

				// Fetch Client
				const refreshClient = await this.apiClientService.show(LocalStorageService.getAuthClientLocalStorage());
				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'Suppression réussie',
					'Le collaborateur a bien été supprimé',
					'success'
				);
				// Close menu and OffCanvas, set refresh users
				this.setState({ isOpenShowOffCanvas: false, collaborators: refreshClient.collaborators });
			} catch (error: any) {
				console.error('Something wrong on Deleted User :', error);
			}
		}
	}

	private async handleUpdated(updatedCollaboratorData: any, collaboratorId: number): Promise<void>
	{
		try {
			const updatedCollaborator = await this.collaboratorService.edit(
				updatedCollaboratorData,
				LocalStorageService.getAuthClientLocalStorage(),
				collaboratorId
			);

			// Fetch Client
			const refreshClient = await this.apiClientService.show(LocalStorageService.getAuthClientLocalStorage());

			if (refreshClient) {
				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'Mise à jour réussie',
					'Le client a été mis à jour',
					'success'
				);
				// Refresh State
				this.setState({
					collaborators: refreshClient.collaborators,
					selectedCollaborator: updatedCollaborator,
					isUpdatedSuccess: true
				});
				// Reset isUpdatedSuccess
				setTimeout(() =>
				{
					this.setState({ isUpdatedSuccess: false });
				}, 500);
			}

		} catch (error: any) {
			console.error('Something wrong on Updated Collaborator :', error);
		}
	}

	private navigateCollaborator(isPrevious: boolean): void
	{
		const { selectedCollaboratorIndex, collaborators } = this.state;
		let navigateIndex: number = isPrevious ? selectedCollaboratorIndex - 1 : selectedCollaboratorIndex + 1;

		// Check index
		if (navigateIndex >= 0 && navigateIndex < collaborators.length) {
			this.setState({
				selectedCollaborator: collaborators[navigateIndex],
				selectedCollaboratorIndex: navigateIndex,
				currentItemId: this.state.selectedCollaborator?.id as number,
			});
		}
	}

	//</editor-fold>


}