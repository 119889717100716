import React, { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { ComponentLayoutProps } from '@/Modules/App/Components/Layout/Layout';

export default class LayoutAuth extends React.Component
	<ComponentLayoutProps>
{
	render(): ReactElement
	{
		return (
			<>
				<div className="publisur-app-inner">
					<div style={ {
						width: '100%',
						height: '100vh',
						display: 'grid',
						gridTemplateColumns: '50% 50%'
					} }>
						<div style={ {
							backgroundColor: CssVariableEnum['--color-blue-800'],
							borderRadius: 25,
							margin: 20,
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							objectFit: 'contain',
							position: 'relative',
							boxShadow: '0px 0px 20px rgba(24, 73, 169, 0.8)'
						} }>
							<div style={ {
								height: '100%',
								width: '100%',
								position: 'absolute',
								//overflow: 'hidden',
								zIndex: 1
							} }>
								<img
									src={ '/img/publisur-signature-form.svg' }
									alt={'forme design signature'}
									style={ {
										objectFit: 'contain',
										height: '100vh',
										transform: 'translateX(-710px)',
										opacity: 0.1
									} }
								/>
							</div>
							<div style={ {
								height: '100%',
								width: '100%',
								display: 'grid',
								gridTemplateRows: '100px auto',
								position: 'relative',
								zIndex: 2
							} }>
								<div style={ {
									padding: 70,
									fontSize: 14,
									fontWeight: 600,
									color: CssVariableEnum['--color-white'],
									display: 'flex',
									justifyContent: 'center',
								} }>
									<div style={ {
										height: 50,
										display: 'flex',
										width: '100%',
										alignItems: 'center',
										justifyContent: 'center'
									} }>
										<div style={ { width: 200 } }>
											<img
												src={ '/img/logo-publisur-white.svg' }
												alt={ 'Logo publisur blanc' }
											/>
										</div>
									</div>
								</div>
								<div style={ {
									display: 'flex',
									alignItems: 'center',
									height: '100%',
									backgroundSize: 'cover',
									borderBottomLeftRadius: 25,
									borderBottomRightRadius: 25,
									padding: 70
								} }>
									<div style={ {
										width: '100%',
										height: '100%',
										backdropFilter: 'blur(8px)',
										borderRadius: 25,
										border: '1px solid rgba(251 251 251 / 10%)',
										padding: 40,
										position: 'relative',
										zIndex: 1,

									} }>
										<div style={ {
											width: '100%',
											position: 'absolute',
											zIndex: 2,
											bottom: 0,
											left: 0,
											borderRadius: 25,
										} }>
											<img
												src={ '/img/two-business-people.png' }
												alt={ 'deux personnes en train de regarder un iPad' }
												style={ {
													objectFit: 'contain',
													width: '130%',
												} }
											/>
										</div>
										<div>
											<h1 style={ {
												fontFamily: `"Bricolage Grotesque", sans-serif`,
												color: CssVariableEnum['--color-white'],
												fontSize: 44.5,
												lineHeight: '45px',
												letterSpacing: '-1.5px'
											} }>
												Publication & Gestion <br/> de vos annonces légales <br/> Pros
											</h1>
											<h2 style={ {
												fontFamily: `"Bricolage Grotesque", sans-serif`,
												fontSize: 17,
												marginTop: 20,
												color: CssVariableEnum['--color-white'],
												fontWeight: 400
											} }>
												Publiez vos annonces légales <br/>
												en toute autonomie, <br/>
												rapidement et sous l'aide <br/>
												des conseils<br/>
												de nos experts.
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
						<main style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' } }>
							<div style={ {
								width: 500,
								padding: 40,
								borderRadius: 25,
								backdropFilter: 'blur(10px)',
								position: 'relative',
								zIndex: 20,
								border: '1px solid rgba(0 0 0 / 5%)'
							} }>
								{ this.props.children }
							</div>
						</main>
					</div>
				</div>
			</>
		);
	}
}