import React, { ReactNode } from 'react';

export default class TableGroup extends React.Component
	<{ children: ReactNode }, any>
{

	render()
	{
		return (
			<>
				<div>
					{ this.props.children }
				</div>
			</>
		)
	}
}