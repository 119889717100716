import React, { ReactElement, ReactNode } from 'react';
import { OffCanvasComponentStyle } from '@/Modules/App/Style/OffCanvasComponentStyle';
import OffCanvasHeaderActionsComponent from '@/Modules/App/Components/Atom/OffCanvas/OffCanvasHeaderActionsComponent';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';

interface ComponentProps
{
	backdrop?: boolean | 'static',
	show: boolean,
	onHide: () => void,
	onPrevious?: () => void,
	onNext?: () => void,
	children?: React.ReactNode,
	isNavigateArrow: boolean,
	tabs?: (() => { id: number, name: string }[]) | undefined,
	selectedTab?: number,
	onTabChange?: (selectedTab: number) => void,
	isTabNeeded: boolean,
	title?: string | null,
	renderTitle?: ReactNode;
	isSellsyId?: boolean,
	height?: number,
	width?: number,
}

interface ComponentState
{
	showOffCanvas: boolean,
	selectedTab: string
}

export default class OffCanvasComponent extends React.Component
	<ComponentProps, ComponentState>
{
	constructor(props: any)
	{
		super(props);

		this.state = {
			showOffCanvas: false,
			selectedTab: `tab${ this.props.selectedTab || '1' }`,
		};

		// Bind
		this.handleTabs = this.handleTabs.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<div style={ OffCanvasComponentStyle.containerStyle(this.props) }>

					{ this.props.show &&
            <>
              <OffCanvasHeaderActionsComponent
                isNavigateArrow={ this.props.isNavigateArrow }
                onHide={ this.props.onHide }
                onPrevious={ this.props.onPrevious }
                onNext={ this.props.onNext }
                isSellsyId={ this.props.isSellsyId }
              />

              <div style={ OffCanvasComponentStyle.content() }>
								{/* TITLE */ }
                <div style={ { boxSizing: 'border-box', width: '100%' } }>
									{ this.props.renderTitle
										? this.props.renderTitle
										: <div style={ FontStyle.h2() }> { this.props.title }</div>
									}


									{/* TABS */ }
									{ this.props.isTabNeeded &&
										this.props.tabs && (
											<div style={ OffCanvasComponentStyle.tabsStyle() }>
												{ this.props.tabs().map(tab => (
													<React.Fragment key={ tab.id }>
														<div
															id={ 'tab' + tab.id }
															style={ OffCanvasComponentStyle.activeTabStyle(this.state, tab) }
															onClick={ (event: any) => this.handleTabs(event, tab.id) }
															key={ tab.id }
														>
															{ tab.name }
														</div>
													</React.Fragment>
												)) }
											</div>
										)
									}
                </div>
								{/* CHILDREN */ }
								{ this.props.children }
              </div>
            </>
					}
				</div>
			</>
		);
	}

	componentDidUpdate(prevProps: ComponentProps): void
	{
		if (this.props.show !== prevProps.show) {
			this.setState({ selectedTab: `tab${ this.props.selectedTab || '1' }` });
		}
	}

	private handleTabs(event: React.MouseEvent<HTMLDivElement, MouseEvent>, tabId: number): void
	{
		event.preventDefault();

		const clickedTab = event.currentTarget;

		// Remove css class 'selected' on all tabs
		document.querySelectorAll('.offcanvas-tabs__tab').forEach(tab =>
		{
			tab.classList.remove('selected');
			if (tab instanceof HTMLElement) {
				tab.style.zIndex = 'auto';
			}
		});

		// Add class on click tab
		clickedTab.classList.add('selected');

		// add css properties z-index on elem with the same ID on selected tab
		clickedTab.style.zIndex = '200';

		// Get id click tab
		const selectedTabId = clickedTab.getAttribute('id');
		if (selectedTabId) {
			this.setState({ selectedTab: selectedTabId });

			if (this.props.onTabChange) {
				this.props.onTabChange(tabId);
			}
		}
	}
}

