import React, { CSSProperties, ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuPlus, LuTrash2 } from 'react-icons/lu';

interface ComponentProps
{
	children: ReactElement,
	title: string,
	isContentWrap?: boolean
	duplicateOptions?: {
		duplicateContent: boolean
		duplicateContentTitle: string | null
		numberOfDuplicateContent: number
	}
}

interface ComponentState
{
	duplicatedSections: ReactElement[];
}

export default class LegalNoticeFomCardComponent extends React.Component<ComponentProps, ComponentState>
{
	constructor(props: ComponentProps)
	{
		super(props);
		this.state = {
			duplicatedSections: []
		};
	}

	render(): ReactElement
	{
		const { children, title, isContentWrap, duplicateOptions } = this.props;
		return (
			<>
				<div style={ { width: '100%', marginBottom: '20px' } }>
					<div style={ {
						width: '100%',
						backgroundColor: 'rgba(228, 231, 236, 0.8)',
						borderRadius: '15px',
						padding: 5
					} }>
						<div style={ { ...FontStyle.h4(), padding: 10 } }>{ title }</div>
						<div style={ { backgroundColor: CssVariableEnum['--color-white'], borderRadius: 15, } }>
							<div style={ (isContentWrap) ? styleIsContentWrap : { padding: 10 } }>
								{ children }
								{ this.state.duplicatedSections.map((section, index) => (
									<React.Fragment key={ index }>
										<div style={ { width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 20 } }>
											<div> { duplicateOptions?.duplicateContentTitle }</div>
											<Button
												type={ 'danger' }
												iconLeft={ <LuTrash2/> }
												onClick={ () => this.deleteDuplicateContent(index) }
											>
												Supprimer
											</Button>
										</div>
										{ section }
									</React.Fragment>
								)) }
							</div>
							{ duplicateOptions?.duplicateContent && this.state.duplicatedSections.length < duplicateOptions.numberOfDuplicateContent &&
                <div style={ {
									width: '100%',
									height: 45,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								} }>
                  <Button
                    type={ 'default-blue' }
                    iconLeft={ <LuPlus/> }
                    onClick={ this.duplicateSection.bind(this) }
                  />
                </div>
							}
						</div>
					</div>
				</div>
			</>
		);
	}

	private duplicateSection(): void
	{
		this.setState((prevState) => ({
			duplicatedSections: [...prevState.duplicatedSections, this.props.children]
		}));
	};

	private deleteDuplicateContent(index: number): void {
		this.setState((prevState) => {
			const updatedSections = [...prevState.duplicatedSections];
			updatedSections.splice(index, 1);
			return { duplicatedSections: updatedSections };
		});
	};

}

const styleIsContentWrap: CSSProperties = {
	padding: 10,
	display: 'flex',
	flexWrap: 'wrap',
	gap: 15
};