import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import { InputType } from '@/Modules/App/Components/Atom/Form/Input/Type/InputType';
import FormLabel from '@/Modules/App/Components/Atom/Form/FormLabel';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class Input extends React.Component
	<{
		type: InputType;
		label?: string;
		value?: any;
		name: string;
		placeholder?: string;
		onChange?: (event: any) => any,
		onPaste?: (event: any) => any,
		onBlur?: any;
		onFocus?: any;
		isLoading?: boolean;
		onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
		width?: string
		backgroundColor?: string;
		style?: React.CSSProperties;
		disabled?: boolean
		required?: boolean
		autocomplete?: string,
		containerDivWidth?: string,
		onMouseDown?: (event: any) => void
		onClick?: (event: any) => void
		checked?: boolean
		isError?: boolean,
	}, any>
{

	render(): ReactElement
	{
		return (
			this.props.isLoading
				? <div style={ {
					width: '50%',
					alignItems: 'center',
					paddingLeft: '10px'
				} }><Skeleton height={ 14 }/></div>
				: <>
					<div style={ { width: (this.props.containerDivWidth) ? this.props.containerDivWidth : '100%' } }>
						{ (this.props.label) && <FormLabel content={ this.props.label } isRequired={ this.props.required }/> }
						<div style={ {
							display: 'inline-flex',
							width: (this.props.width) ? `${ this.props.width }` : '',
							alignItems: 'center',
							fontSize: CssVariableEnum['--font-size-main'],
							lineHeight: '20px',
							position: 'relative',
							borderRadius: CssVariableEnum['--border-radius-main'],
							boxShadow: !this.props.isError ? CssVariableEnum['--input-box-shadow'] : 'none',
							backgroundColor:
								(this.props.disabled)
									? CssVariableEnum['--color-grey-200']
									: (this.props.backgroundColor)
										? this.props.backgroundColor
										: CssVariableEnum['--color-grey-25'],
							cursor: 'text',
							padding: CssVariableEnum['--input-padding'],
							height: CssVariableEnum['--input-height'],
							border: this.props.isError ? '1px solid red' : 'none',
							...this.props.style
						} }>
							<input
								type={ this.props.type }
								value={ this.props.value }
								onChange={ this.props.onChange }
								onBlur={ this.props.onBlur }
								onFocus={ this.props.onFocus }
								onKeyDown={ this.handleKeyDown }
								autoComplete={ this.props.autocomplete }
								style={ {
									opacity: (this.props.disabled) ? 0.4 : 1,
									fontSize: 'inherit',
									lineHeight: 'inherit',
									border: 'none',
									background: 'none',
									width: '100%',
									display: 'block',
									padding: 0,
									outline: 0
								} }
								name={ this.props.name }
								placeholder={ this.props.placeholder }
								disabled={ this.props.disabled }
								onMouseDown={ this.props.onMouseDown }
								onClick={ this.props.onClick }
								checked={ this.props.checked }
							/>
						</div>
					</div>
				</>
		);
	}

	private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
	{
		if (event.key === 'Enter') {
			this.props.onKeyDown && this.props.onKeyDown(event);
		}
	};
}