import React, { ReactElement } from 'react';

export default class ParticularLegalNoticeView extends React.Component
	<any, any>
{
	constructor(props: {})
	{
		super(props);
		this.state = {
			showLoader: true
		};
	}

	render(): ReactElement
	{
		return (
			<h1> 🚧 En Construction </h1>
		);
	}
}