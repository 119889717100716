import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { ModalContext } from '@/Provider/ModalProvider';
import UserAdminView from '@/Modules/User/Admin/View/UserAdminView';
import { AuthContext } from '@/Provider/AuthProvider';

const UserAdminViewWrapper = (props: any) => {
  const flashMessageContext = useContext(FlashMessageContext);
  const authContext = useContext(AuthContext);
  const modalContext = useContext(ModalContext);

  return <UserAdminView
    authContext={ authContext }
    flashMessageContext={ flashMessageContext }
    modalContext = { modalContext}
    { ...props }
  />;
};

export default UserAdminViewWrapper;