import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

export default class TableCell extends React.Component
	<
		{
			children: ReactNode;
			isLoading?: boolean;
			onClick?: (event?: any) => any;
			style?: CSSProperties
		},
		{
			isHovered: boolean
		}
	>
{

	state = {
		isHovered: false,
	};

	render(): ReactElement
	{
		return (
			<>
				{ this.props.isLoading
					? <div style={ { width: '50%', alignItems: 'center', paddingLeft: '10px' } }><Skeleton height={ 14 }/></div>
					: <div style={ {
						position: 'relative',
						width: '100%'
					} }>
						<div style={ {
							overflowX: 'hidden',
							height: '100%',
						} }>
							<div style={ {
								userSelect: 'none',
								transition: 'background 20ms ease-in 0s',
								cursor: 'pointer',
								position: 'relative',
								display: 'block',
								fontSize: '14px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								width: '100%',
								minHeight: '32px',
								padding: '8px 8px',
								...this.props.style,
							} }
									 onMouseEnter={ () => this.setState({ isHovered: true }) }
									 onMouseLeave={ () => this.setState({ isHovered: false }) }
									 onClick={ this.props.onClick }
							>
								{ this.props.children }
							</div>
						</div>
					</div>
				}
			</>
		);
	}
}