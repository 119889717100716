import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import AvatarComponent from '@/Modules/App/Components/AvatarComponent';
import { AuthContext } from '@/Provider/AuthProvider';

const AvatarComponentWrapper = (props: any) => {

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  return <AvatarComponent
    authContext={ authContext }
    navigation={ navigate }
    onAvatarClick={ props.onAvatarClick }
    { ...props }
  />;
};

export default AvatarComponentWrapper;