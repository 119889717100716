import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { TableHeaderListInterface } from '@/Modules/App/Components/Atom/Table/TableComponent';

export class TableComponentStyle
{
	static containerHeaderSticky(): CSSProperties
	{
		return {
			position: 'relative',
			zIndex: 0,
			display: 'flex',
			backgroundColor: CssVariableEnum['--color-grey-100'],
			height: 60,
			alignItems: 'flex-end',
			width: '100%',
		};
	}

	static containerHeader(props: any): CSSProperties
	{
		return {
			backgroundColor: (props.isStickyHeader) ? CssVariableEnum['--color-white'] : 'transparent',
			borderRadius: '15px 15px 0 0 ',
			width: '100%',
			height: (props.isStickyHeader) ? 50 : '',
			padding: (props.isStickyHeader) ? '10px 10px 0 10px' : 0,
			display: 'flex',
			alignItems: 'flex-end',
		};
	}

	static headerStyle(width: number): CSSProperties
	{
		return {
			position: 'relative',
			flex: 'none',
			width: width,
			display: 'flex',
			alignItems: 'center',
		};
	}

	static cellStyle(header: TableHeaderListInterface): CSSProperties
	{
		return {
			fontSize: 12,
			fontWeight: 600,
			color: CssVariableEnum['--color-grey-400'],
			cursor: (header.orderTag) ? 'pointer' : 'default',
		};
	}

	static tableSizePaginate(props: any, state: any, index: number): CSSProperties
	{
		return {
			position: 'absolute',
			zIndex: 200,
			right: 0,
			height: '32px',
			width: '5px',
			marginLeft: '-3px',
			cursor: (props.isResizeColumns) ? 'col-resize' : 'inherit',
			opacity: (state.resizeHovered === index) ? `0.8` : `0`,
			backgroundColor: CssVariableEnum['--color-blue-light-500'],
			transitionProperty: 'opacity, transform',
			transitionDuration: '270ms',
			transitionTimingFunction: 'ease',
		};
	}
}