import { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class QuoteStatusEnum
{
  value: string;
  label: string;
  css: { backgroundColor: string, color: string, icon?: ReactElement | null };

  constructor(value: string, label: string, css: { backgroundColor: string, color: string, icon?: ReactElement | null })
  {
    this.value = value;
    this.label = label;
    this.css = css;
  }

  static PENDING = new QuoteStatusEnum(
    'PENDING',
    'En attente',
    {
      backgroundColor: CssVariableEnum['--color-blue-light-200'],
      color: CssVariableEnum['--color-blue-light-500'],
    }
  );
  static ACCEPTED = new QuoteStatusEnum(
    'ACCEPTED',
    'Accepté',
    {
      backgroundColor: CssVariableEnum['--color-green-200'],
      color: CssVariableEnum['--color-green-500'],
    }
  );
  static REFUSED = new QuoteStatusEnum(
    'REFUSED',
    'Refusé',
    {
      backgroundColor: CssVariableEnum['--color-error-200'],
      color: CssVariableEnum['--color-error-500'],
    }
  );
  static INVOICED = new QuoteStatusEnum(
    'INVOICED',
    'Facturé',
    {
      backgroundColor: CssVariableEnum['--color-blue-200'],
      color: CssVariableEnum['--color-blue-500'],
    }
  );
  static EXPIRED = new QuoteStatusEnum(
    'EXPIRED',
    'Expiré',
    {
      backgroundColor: CssVariableEnum['--color-yellow-200'],
      color: CssVariableEnum['--color-yellow-500'],
    }
  );

  static options: QuoteStatusEnum[] = [
    QuoteStatusEnum.PENDING,
    QuoteStatusEnum.ACCEPTED,
    QuoteStatusEnum.REFUSED,
    QuoteStatusEnum.INVOICED,
    QuoteStatusEnum.EXPIRED
  ];

  static findByValue(value: string): QuoteStatusEnum | undefined
  {
    return this.options.find(option => option.value === value);
  }
}