import React, { ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { ApiAdminCollaboratorService } from '@/Service/Admin/ApiAdminCollaboratorService';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';

export default class SearchCollaboratorComponent extends React.Component
  <
    {
      selectedClient: ClientInterface;
      collaborator: CollaboratorInterface | null;
      onSelectedCollaborator: (collaborator: CollaboratorInterface) => void
    }
    ,
    {
      selectedCollaborator: CollaboratorInterface | null,
      collaboratorSearchResult: CollaboratorInterface[]
    }
  >
{
  collaboratorService: ApiAdminCollaboratorService;
  wrapperRef: React.RefObject<HTMLDivElement>;
  state = {
    selectedCollaborator: this.props.collaborator || null,
    collaboratorSearchResult: [] as CollaboratorInterface[]
  };

  constructor(props: any)
  {
    super(props);

    // Service
    this.collaboratorService = new ApiAdminCollaboratorService();

    // Ref
    this.wrapperRef = React.createRef<HTMLDivElement>();

    // Bind
    this.onChangeCollaboratorSearch = this.onChangeCollaboratorSearch.bind(this);
    this.getAllCollaborators = this.getAllCollaborators.bind(this);
    this.onCollaboratorSelected = this.onCollaboratorSelected.bind(this);
    this.resetCollaboratorSelection = this.resetCollaboratorSelection.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }


  componentDidMount(): void
  {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount()
  {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event: MouseEvent) {
    if (!this.wrapperRef.current?.contains(event.target as Node)) {
      this.setState({ collaboratorSearchResult: [] });
    }
  }

  componentDidUpdate(prevProps: any): void
  {
    if (this.props.collaborator !== prevProps.collaborator) {
      this.setState({ selectedCollaborator: this.props.collaborator });
    }
  }

  render(): ReactElement
  {
    return (
      <>
        { !this.state.selectedCollaborator ? (
          <>
            <Input
              type="text"
              width={ '100%' }
              name={ 'associate-collaborator-legal-notice' }
              autocomplete={'off'}
              placeholder="Rechercher un collaborateur"
              onChange={ (event) => this.onChangeCollaboratorSearch(event) }
              onFocus={ this.getAllCollaborators }
              style={ { flex: '0 0 300px' } }
            />
            { this.state.collaboratorSearchResult.length > 0 && (
              <>
                <div
                  ref={ this.wrapperRef }
                  style={ {
                    position: 'absolute',
                    zIndex: 200,
                    backgroundColor: CssVariableEnum['--color-white'],
                    borderRadius: '8px',
                    boxShadow: '0 2px 20px #00000029',
                    width: '100%',
                    padding: '5px 10px',
                    top: 35,
                    fontWeight: 500,
                    border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
                    transitionProperty: 'opacity, transform',
                    transitionDuration: '270ms',
                    transitionTimingFunction: 'ease'
                } }>
                  <div style={ { overflowY: 'scroll' } }>
                    <div style={ { maxHeight: 150 } }>
                      { this.state.collaboratorSearchResult.map((collaborator: CollaboratorInterface) => (
                        <div
                          key={ collaborator.id }
                          style={ {
                            cursor: (!collaborator.extSellsyId) ? 'not-allowed' : 'pointer',
                            padding: '5px 10px',
                            color: (!collaborator.extSellsyId) ? CssVariableEnum['--color-grey-400'] : CssVariableEnum['--color-grey-900']
                          } }
                          onClick={ () =>
                          {
                            if (collaborator.extSellsyId) {
                              this.onCollaboratorSelected(collaborator);
                            }
                          } }
                        >
                          <span style={ { fontWeight: 500 } }>
                            { collaborator.email }
                            { (!collaborator.extSellsyId)
                              ? <span style={{ fontSize: '12px', marginLeft: '10px' }}>(Collabaroteur non associé a Sellsy)</span>
                              : ''
                            }
                          </span>
                        </div>
                      )) }
                    </div>
                  </div>
                </div>
              </>
            ) }
          </>
        ) : (
          <>
            <div style={ { display: 'flex', width: '100%', alignItems: 'flex-end' } }>
              <Input
                type="text"
                width={ '100%' }
                name={ 'associate-user' }
                onChange={ () => null }
                style={ { flex: '1 0 0%' } }
                value={ this.state.selectedCollaborator&&
                  `${ (this.state.selectedCollaborator as CollaboratorInterface).email }`
                }
              />
              <Button
                type={ 'default' }
                onClick={ this.resetCollaboratorSelection }
              >
              Modifier
              </Button>
            </div>
          </>
        ) }
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private getAllCollaborators(): void
  {
    const activeCollaborators = this.props.selectedClient.collaborators.filter(
      (collaborator: CollaboratorInterface) => collaborator.status.toString() === ActiveStatusEnum.ACTIVE.value
    );

    this.setState({ collaboratorSearchResult: activeCollaborators });
  }

  private async onChangeCollaboratorSearch(event: React.ChangeEvent<any>): Promise<void>
  {
    const term = event.target.value;
    if (term === '') {
      this.getAllCollaborators();
    } else {
      await this.searchCollaborator(term);
    }
  }

  private async searchCollaborator(term: string): Promise<void>
  {
    const results = await this.collaboratorService.search(this.props.selectedClient.id, term);
    this.setState({ collaboratorSearchResult: results });
  }

  private onCollaboratorSelected(collaborator: CollaboratorInterface): void
  {
    this.setState({ selectedCollaborator: collaborator });
    this.props.onSelectedCollaborator(collaborator);
  }

  private resetCollaboratorSelection(): void
  {
    this.setState({ selectedCollaborator: null });
  }

  private stringToCapitalize(stringToConvert: string): string
  {
    return stringToConvert[0].toUpperCase() + stringToConvert.slice(1).toLowerCase();
  }

  //</editor-fold>
}