import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export default class Title extends React.Component
	<
		{
			children: ReactNode;
			type: HeadingType;
			style?: CSSProperties;
			borderBottom?: boolean;
		},
		any
	>
{
	render(): ReactElement
	{
		//<editor-fold desc="// Switch Types" defaultstate="collapsed">

		const { type, children, style } = this.props;

		let fontSize = 20;
		let lineHeight = 1.1;

		switch (type) {
			case 'h1':
				fontSize = 56;
				lineHeight = 1.1;
				break;
			case 'h2':
				FontStyle.h2();
				break;
			case 'h3':
				fontSize = 32;
				lineHeight = 1.4;
				break;
			case 'h4':
				fontSize = 24;
				lineHeight = 1.4;
				break;
			case 'h5':
				fontSize = 20;
				lineHeight = 1.4;
				break;
			case 'h6':
				fontSize = 18;
				lineHeight = 1.4;
				break;
			default:
				break;
		}

		//</editor-fold>

		return (
			<>
				<div
					style={ {
						fontSize: fontSize,
						lineHeightStep: lineHeight,
						fontWeight: 600,
						lineHeight: '26px',
						margin: '20px 0',
						paddingBottom: (this.props.borderBottom) ? '4px' : '',
						borderBottom: (this.props.borderBottom) ? '1px solid rgb(233, 233, 231)' : '',
						...style
					} }>
					{ children }
				</div>
			</>
		);
	}
}