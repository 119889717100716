import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiLegalNoticeService extends BaseService {
  legalNoticeUrl: string = `${ BaseService.API_URL }/v1/legal-notices`;

  async list(clientId: number, params?: string): Promise<any>
  {
    try {
      const url: string = this.legalNoticeUrl + `/${ clientId }`;
      const paramUrl: string = url + params ? url + '?' + params :  url;

      const response: Response = await fetch(
        paramUrl,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async create(legalNoticeData: any): Promise<any>
  {
    try {
      const response: Response = await fetch(
        this.legalNoticeUrl,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(legalNoticeData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

}