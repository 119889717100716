import { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class PaymentStateEnum
{
	value: string;
	label: string;
	css: { backgroundColor: string, color: string, icon?: ReactElement | null };

	constructor(value: string, label: string, css: { backgroundColor: string, color: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static PENDING = new PaymentStateEnum(
		'PENDING',
		'En attente',
		{
			backgroundColor: CssVariableEnum['--color-blue-light-200'],
			color: CssVariableEnum['--color-blue-light-500'],
		}
	);
	static UNPAID = new PaymentStateEnum(
		'UNPAID',
		'Impayé',
		{
			backgroundColor: CssVariableEnum['--color-yellow-200'],
			color: CssVariableEnum['--color-yellow-500'],
		}
	);
	static VALIDATE = new PaymentStateEnum(
		'VALIDATE',
		'Validé',
		{
			backgroundColor: CssVariableEnum['--color-yellow-200'],
			color: CssVariableEnum['--color-yellow-500'],
		}
	);
	static PARTIAL = new PaymentStateEnum(
		'PARTIAL',
		'Partiel',
		{
			backgroundColor: CssVariableEnum['--color-yellow-200'],
			color: CssVariableEnum['--color-yellow-500'],
		}
	);
	static PAID = new PaymentStateEnum(
		'PAID',
		'Payé',
		{
			backgroundColor: CssVariableEnum['--color-yellow-200'],
			color: CssVariableEnum['--color-yellow-500'],
		}
	);

	static CANCEL = new PaymentStateEnum(
		'CANCEL',
		'Annuler',
		{
			backgroundColor: CssVariableEnum['--color-error-200'],
			color: CssVariableEnum['--color-error-500'],
		}
	);

	static options: PaymentStateEnum[] = [
		PaymentStateEnum.PENDING,
		PaymentStateEnum.UNPAID,
		PaymentStateEnum.VALIDATE,
		PaymentStateEnum.PARTIAL,
		PaymentStateEnum.PAID,
		PaymentStateEnum.CANCEL
	];

	static findByValue(value: string): PaymentStateEnum | undefined
	{
		return this.options.find(option => option.value === value);
	}
}