export default class AddressModel {
  street: string;
  number: number | null;
  additionalData: string;
  zipCode: string;
  city: string;
  country: string;

  constructor() {
    this.street = '';
    this.number = null;
    this.additionalData = '';
    this.zipCode = '';
    this.city = '';
    this.country = '';
  }

  static prepare(addressData: any): AddressModel
  {
    let model = new AddressModel();
    model.street = addressData.street || '';
    model.number = addressData.number || 0;
    model.additionalData = addressData.additionalData || '';
    model.zipCode = addressData.zipCode || '';
    model.city = addressData.city || '';
    model.country = addressData.country || '';

    return model;
  }
}