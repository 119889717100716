import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';

export class ClientService
{

	public checkIsRoleOwner(collaborators: CollaboratorInterface[], authUserId: number): boolean
	{
		const isRoleOwner = collaborators.find((collaborator: any) =>
		{
			return collaborator.user.id === authUserId && collaborator.role === 'ROLE_OWNER';
		});

		return !!(isRoleOwner);
	}
}