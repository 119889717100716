import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '@/Modules/App/Components/Layout/Layout';
import LayoutAdmin from '@/Modules/App/Components/Layout/LayoutAdmin';
import LayoutAuth from '@/Modules/App/Components/Layout/LayoutAuth';
import LayoutPublic from '@/Modules/App/Components/Layout/LayoutPublic';
import { LayoutWrapperProps } from '@/Modules/App/Type/LayoutWrapperTypes';
import { AuthContext } from '@/Provider/AuthProvider';
import { AuthCompanyContext } from '@/Provider/AuthCompanyProvider';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import LayoutDeveloper from '@/Modules/App/Components/Layout/LayoutDeveloper';
import { NotificationContext, NotificationContextType } from '@/Provider/NotificationProvider';

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ layoutType, ...props }) =>
{
	const navigate = useNavigate();
	const authContext = useContext(AuthContext) as AuthContextType;
	const companyContext = useContext(AuthCompanyContext) as AuthCompanyContextType;
	const notificationContext = useContext(NotificationContext) as NotificationContextType;
	const flashMessageContext = useContext(FlashMessageContext) as FlashMessageContextType;

	switch (layoutType) {
		case 'developer':
			return (
				<LayoutDeveloper
					authContext={ authContext }
					flashMessageContext={ flashMessageContext }
					navigation={ navigate }
					{ ...props }
				/>
			);
		case 'admin':
			return (
				<LayoutAdmin
					navigation={ navigate }
					authContext={ authContext }
					companyContext={ companyContext }
					notificationContext={ notificationContext }
					flashMessageContext={ flashMessageContext }
					{ ...props }
				/>
			);
		case 'auth':
			return <LayoutAuth navigation={ navigate } { ...props } />;
		case 'public':
			return <LayoutPublic navigation={ navigate } { ...props } />;
		default:
			return <Layout navigation={ navigate } { ...props } />;
	}
};

export default LayoutWrapper;
