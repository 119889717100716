import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import LegalNoticeAdminView from '@/Modules/LegalNotice/Admin/View/LegalNoticeAdminView';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalContext } from '@/Provider/ModalProvider';
import { NotificationContext } from '@/Provider/NotificationProvider';
import { AuthCompanyContext } from '@/Provider/AuthCompanyProvider';

const LegalNoticeAdminViewWrapper = (props: any) => {
  const navigate = useNavigate();
  const flashMessageContext = useContext(FlashMessageContext);
  const authCompanyContext = useContext(AuthCompanyContext);
  const location = useLocation();
  const modalContext = useContext(ModalContext);
  const notificationContext = useContext(NotificationContext);

  return <LegalNoticeAdminView
    flashMessageContext={ flashMessageContext }
    authCompanyContext={ authCompanyContext }
    navigation={ navigate }
    modalContext = { modalContext }
    notificationContext = { notificationContext }
    location={ location }
    { ...props }
  />;
};

export default LegalNoticeAdminViewWrapper;