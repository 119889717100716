import React from 'react';
import { Link } from 'react-router-dom';
import {
	LuBug,
	LuUsers,
	LuLandmark
} from 'react-icons/lu';

export default class MainNavigationDeveloperComponent extends React.Component
{
	render()
	{
		return (
			<>
				<header>
					<nav>
						<ul className="main-menu">
							<li className="menu-item">
								<Link to="">
									<LuBug fontSize={ 18 }/>
									<span style={ { marginLeft: 5 } }>Débogage</span>
								</Link>
							</li>
							<li className="menu-item">
								<Link to="/developer/users">
									<LuUsers fontSize={ 18 }/>
									<span style={ { marginLeft: 5 } }>Utilisateurs</span>
								</Link>
							</li>
							<li className="menu-item">
								<Link to="/developer/clients">
									<LuLandmark fontSize={ 18 }/>
									<span style={ { marginLeft: 5 } }>Donneur d'ordre</span>
								</Link>
							</li>
						</ul>
					</nav>
				</header>
			</>
		);
	}
}
