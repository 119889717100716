import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAdminCompanyService extends BaseService {
  companyUrl: string = `${ BaseService.API_URL }/v1/admin/companies`;

  async list(): Promise<any>
  {
    try {
      const response = await fetch(
        this.companyUrl,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async show(companyId: number): Promise<any> {
    try {
      const response = await fetch(
        `${ this.companyUrl }/${ companyId }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}