import React, { CSSProperties, ReactElement, ReactNode } from 'react';

export default class TableCol extends React.Component
	<
		{
			children: ReactNode,
			columnSize?: number,
			width?: any,
			height?: number,
			onResize?: any,
			isBorderRight?: boolean,
			isEditMode?: boolean,
			style?: CSSProperties,
		},
		any
	>
{

	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					position: 'relative',
					backgroundClip: 'padding-box',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					width: this.calculateColumnWidth(this.props.columnSize),
					borderRight: (this.props.isBorderRight) ? '1px solid rgb(233, 233, 231)' : '',
					padding: (this.props.isEditMode) ? '5px' : '',
					...this.props.style
				} }>
					{ this.props.children }
				</div>
			</>
		);
	}

	calculateColumnWidth(columnSize: number | undefined): string
	{
		if (typeof columnSize === 'number' && columnSize > 0) {
			// Convert columnSize to percentage width based on 12-column grid
			const width = (columnSize / 12) * 100;
			return `${ width }%`;
		} else {
			return 'auto';
		}
	}
}