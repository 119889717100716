import React, { ReactElement } from 'react';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { FormBuilderSectionInterface } from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';
import { ApiAdminFormBuilderSectionService } from '@/Service/Admin/ApiAdminFormBuilderSectionService';

interface ComponentProps
{
	section: FormBuilderSectionInterface,
	onCreateSubSection: (parentSectionId: number, formBuilderSection: Partial<FormBuilderSectionInterface>) => Promise<any>
}

interface ComponentState
{
	subTitle: string | null,
}

export default class FormBuilderCreateSubSectionFormComponent extends React.Component<ComponentProps, ComponentState>
{
	formBuilderSectionService: ApiAdminFormBuilderSectionService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = { subTitle: '' };

		// Services
		this.formBuilderSectionService = new ApiAdminFormBuilderSectionService();
	}

	render(): ReactElement
	{
		return (
			<>
				<div style={ { padding: 20 } }>
					<Input
						label={ 'Titre de la sous-section' }
						style={ { width: '100%' } }
						type={ 'text' }
						placeholder={ 'Titre de sous-section' }
						name={ 'input-new-section' }
						onChange={ (event: any) =>  this.onChangeSubSectionTitle(event)}
					/>
					<div style={ {
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						marginTop: 10
					} }>
						<Button
							label={ 'Créer' }
							type={ 'default-blue' }
							onClick={ async () => await this.handleCreateSubSection() }
							disabled={ (this.state.subTitle === '') }
						/>
					</div>
				</div>
			</>
		);
	}

	private onChangeSubSectionTitle(event: any): void
	{
		this.setState({ subTitle: event.target.value });
	}

	private async handleCreateSubSection(): Promise<void>
	{
		if(this.props.section.id && this.state.subTitle) {
		await this.props.onCreateSubSection(
				this.props.section.id,
				{
					subTitle: this.state.subTitle,
					sectionParentTypeOption: null
				});
		} else {
			console.log('Something wrong with create SubSection');
		}
	}
}