export enum HttpStatusCodeEnum {
  HTTP_OK = 200,
  HTTP_CREATED = 201,
  HTTP_NO_CONTENT = 204,
  HTTP_BAD_REQUEST = 400,
  HTTP_UNAUTHORIZED = 401,
  HTTP_FORBIDDEN = 403,
  HTTP_NOT_FOUND = 404,
  HTTP_NOT_ALLOWED = 405,
  HTTP_TOO_MANY_REQUEST = 429,
  HTTP_INTERNAL_SERVER = 500,
}