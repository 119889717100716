import { useNavigate, useParams } from 'react-router-dom';
import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { RespondInvitationView } from '@/Modules/Collaborator/View/RespondInvitationView';
import { ModalContext } from '@/Provider/ModalProvider';

const RespondInvitationViewWrapper = (props: any) => {
	const navigate = useNavigate();
	const invitationCode = useParams();
	const flashMessageContext = useContext(FlashMessageContext);
	const modalContext = useContext(ModalContext)

	return <RespondInvitationView
		flashMessageContext={ flashMessageContext }
		navigation={ navigate }
		invitationCode={ invitationCode }
		modalContext={ modalContext }
		{ ...props }
	/>;
};

export default RespondInvitationViewWrapper;