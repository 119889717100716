export class GenderUserEnum
{
	value: string;
	label: string;

	constructor(value: string, label: string)
	{
		this.value = value;
		this.label = label;
	}

	static M = new GenderUserEnum( 'M', 'Homme');
	static W = new GenderUserEnum('W', 'Femme');
	static NG = new GenderUserEnum('NG', 'Non genré');

	static options: GenderUserEnum[] = [
		GenderUserEnum.M,
		GenderUserEnum.W,
		GenderUserEnum.NG,
	];

	static findByValue(selectedOption: GenderUserEnum | string): any
	{
		return this.options.find(
			option => option.value === selectedOption
		);
	}
}