export class BilledToTypeEnum
{
	value: string;
	label: string;

	constructor(value: string, label: string) {
		this.value = value;
		this.label = label;
	}

	static ORDER_GIVER = new BilledToTypeEnum('ORDER_GIVER', 'Donneur d\'ordre');
	static FINAL_CUSTOMER = new BilledToTypeEnum( 'FINAL_CUSTOMER', 'Client final');

	static options: BilledToTypeEnum[] = [
		BilledToTypeEnum.ORDER_GIVER,
		BilledToTypeEnum.FINAL_CUSTOMER
	];

	static findByValue(selectedOption: string): any
	{
		return BilledToTypeEnum.options.find(
			option => option.value === selectedOption
		);
	}
}