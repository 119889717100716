import React, { Component, ReactElement } from 'react';
import { ModalContextType } from '@/Provider/ModalProvider';
import { ViewLegalNoticeState } from '@/Modules/LegalNotice/Admin/View/CreateLegalNoticeView';
import { LegalNoticeModalStyle } from '@/Modules/LegalNotice/Style/LegalNoticeModalStyle';
import LegalNoticeRender from '@/Modules/LegalNotice/Components/Render/LegalNoticeRender';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import LegalNoticePriceComponent from '@/Modules/LegalNotice/Components/LegalNoticePriceComponent';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { formatDateUtils } from '@/Utils/DateUtils';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import Swal from 'sweetalert2';
import { Alert } from 'react-bootstrap';
import { LuInfo } from 'react-icons/lu';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';

interface ComponentProps
{
  modalContext: ModalContextType,
  legalNotice: ViewLegalNoticeState,
  selectedClient: ClientInterface,
  selectedDepartment: DepartmentInterface,
  selectedNewspaper: NewspaperInterface,
  selectedPrimaryCategory: FormBuilderCategoryInterface,
  selectedSecondaryCategory: FormBuilderCategoryInterface,
  isHeaderCharacterCount?: number,
  consumer: ConsumerDataInterface,
  billingAddress: AddressInterface,
  sendTo: any,
  onCreate: (type: string) => void
}

export class LegalNoticeModal extends Component<ComponentProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        <div style={ { width: 'calc(1200px - 2px)' } }>
          <div style={ {
            display: 'grid',
            gridTemplateRows: 'auto 70px',
            borderTop: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
            columnGap: 20,
          } }>
            <div style={ LegalNoticeModalStyle.mainContainerStyle() }>
              <div style={ { gridColumn: '1 / 2' } }>
                { this.detailsRender() }
              </div>
              <div style={ {
                gridColumn: '2 / 3',
                backgroundColor: CssVariableEnum['--color-grey-200'],
                padding: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              } }>
                <LegalNoticeRender
                  legalNotice={ this.props.legalNotice }
                  consumer={ this.props.consumer }
                  isShow={ true }
                />
              </div>
            </div>
            { this.buttonActionsRender() }
          </div>
        </div>
      </>
    );
  }

  //<editor-fold desc="Render methods" defaultstate="collapsed">

  private detailsRender(): ReactElement
  {
    return (
      <>
        <div style={ LegalNoticeModalStyle.detailsContainerStyle() }>
          { this.legalNoticeDetailsRender() }
          <div style={ { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 } }>
            { this.clientRender() }
            { this.consumerRender() }
            { this.billingAddressRender() }
          </div>

          <div style={ LegalNoticeModalStyle.detailsDisplayFlexColumnStyle() }>
            <div style={ { marginTop: 30 } }>
              <div style={ FontStyle.h4() }> Prix de l'annonce</div>
            </div>
            <LegalNoticePriceComponent
              legalNotice={ this.props.legalNotice }
              selectedDepartment={ this.props.selectedDepartment }
              selectedNewspaper={ this.props.selectedNewspaper }
              selectedCategory={ this.props.selectedSecondaryCategory }
              consumer={ this.props.consumer }
              isHeaderCharacterCount={ this.props.isHeaderCharacterCount }
              isAdmin={ true }
            />
          </div>

          { this.sendToRender() }

        </div>
      </>
    );
  }

  private clientRender(): ReactElement
  {
    const clientProps = this.props.selectedClient;

    return (
      <div>
        <div style={ { ...FontStyle.littleGrey(), marginBottom: 4 } }> Donneur d'ordre</div>
        <div>
          <div style={ { fontWeight: 600, marginBottom: 2, color: CssVariableEnum['--color-grey-900'] } }>
            { this.stringToCapitalize(clientProps?.name) }
          </div>
          <div style={ FontStyle.normal() }>
            { `${ clientProps.address?.number }, ${ this.stringToCapitalize(clientProps?.address?.street) }` } <br/>
            { `${ clientProps.address?.zipCode } ${ this.stringToCapitalize(clientProps?.address?.city) }` }
          </div>
        </div>
      </div>
    );
  }

  private consumerRender(): ReactElement
  {
    const consumerProps = this.props.consumer;
    const addressNumber = consumerProps?.address?.number ? `${consumerProps.address.number}, ` : '';

    return (
      <div>
        <div style={{ ...FontStyle.littleGrey(), marginBottom: 4 }}> Client Final</div>
        <div>
          <div style={{ fontWeight: 600, marginBottom: 2, color: CssVariableEnum['--color-grey-900'] }}>
            {this.stringToCapitalize(consumerProps?.name as string)}
          </div>
          <div style={FontStyle.normal()}>
            {`${ addressNumber } ${ this.stringToCapitalize(consumerProps?.address?.street) }`} <br />
            {`${ consumerProps.address?.zipCode } ${ this.stringToCapitalize(consumerProps?.address?.city) }`}
          </div>
        </div>
      </div>
    );
  }

  private billingAddressRender(): ReactElement
  {
    const billingAddressProps = this.props.billingAddress;
    const addressNumber = billingAddressProps?.number ? `${billingAddressProps.number}, ` : '';

    return (
      <div>
        <div style={{ ...FontStyle.littleGrey(), marginBottom: 4 }}> Adresses de Facturation</div>
        <div>
          <div style={{ fontWeight: 600, marginBottom: 2, color: CssVariableEnum['--color-grey-900'] }}>
            {this.stringToCapitalize(billingAddressProps?.name as string)}
          </div>
          <div style={FontStyle.normal()}>
            {`${ addressNumber }${ billingAddressProps?.street }`} <br />
            {`${ billingAddressProps.zipCode } ${ billingAddressProps?.city }`}
          </div>
        </div>
      </div>
    );
  }

  private legalNoticeDetailsRender(): ReactElement
  {
    const publishType = (typeof this.props.legalNotice.option?.publishType === 'string')
      ? NewspaperTypeEnum.findByValue(this.props.legalNotice.option?.publishType)?.label
      : this.props.legalNotice.option?.publishType?.label || ''
    ;

    return (
      <>
        <div style={ { display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', marginBottom: 30, gap: 20 } }>
          { this.lineDetails('Publication:', publishType) }
          { this.lineDetails('Journal:', this.props.selectedNewspaper.name) }
          { this.lineDetails('Département:', `${this.props.selectedDepartment.name} (${this.props.selectedDepartment.code})`) }
          { this.lineDetails('Parution:', formatDateUtils(this.props.legalNotice?.publishDate as Date)) }
        </div>
      </>
    );
  }

  private sendToRender(): ReactElement
  {
    return (
      <>
        <div style={ LegalNoticeModalStyle.sendToContainerStyle() }>
          <div style={ { ...FontStyle.h4(), marginBottom: 15 } }> Envoie des Justificatifs</div>
          { this.lineDetailsSendTo('Facture / Devis : ', this.props.sendTo?.invoice) }
          { this.lineDetailsSendTo('Attestation : ', this.props.sendTo?.certificate) }
          { this.lineDetailsSendTo('Justificatif : ', this.props.sendTo?.receipt) }
          { this.lineDetailsSendTo('Avoir : ', this.props.sendTo?.credit) }
        </div>
      </>
    );
  }

  private buttonActionsRender(): ReactElement
  {
    const isQuotePending = Boolean(this.props.legalNotice?.quoteStatus && this.props.legalNotice?.quoteStatus.value === QuoteStatusEnum.PENDING.value);
    const isQuoteAccepted = this.props.legalNotice?.quoteStatus?.value === QuoteStatusEnum.ACCEPTED.value;
    const isQuoteRefused = this.props.legalNotice?.quoteStatus?.value === QuoteStatusEnum.REFUSED.value;
    const isDraft = this.props.legalNotice?.status?.value === PublishStateEnum.DRAFT.value;
    const isQuote = this.props.legalNotice?.status?.value === PublishStateEnum.QUOTE.value;
    const isNew = !this.props.legalNotice?.status;

    return (
      <>
        <div style={ LegalNoticeModalStyle.buttonActionsContainerStyle() }>
          <div style={ { display: 'flex' } }>
            { (isDraft || isQuote || isNew) && (
              <>
                <Button
                  type={ 'default' }
                  style={{ margin: 0 }}
                  onClick={ () => this.onButtonAction(PublishStateEnum.DRAFT) }
                  disabled={ isQuote }
                >
                  Enregistrer en tant que brouillon
                </Button>

                <Button
                  type={ 'default' }
                  onClick={ () => this.onButtonAction(PublishStateEnum.QUOTE) }
                  disabled={ isQuoteAccepted || isQuoteRefused }
                >
                  Demande de devis
                </Button>
              </>
            )}

            { isQuotePending && (
              <Alert
                key={ 'warning' } variant={ 'warning' }
                style={ LegalNoticeModalStyle.alertContainerStyle() }
              >
                <div className="d-flex align-items-center">
                  <LuInfo className="me-2"/>
                  <div style={ { fontWeight: 'bold' } }>
                    Devis en attente de confirmation
                  </div>
                </div>
              </Alert>
            ) }

            { !isQuotePending && (isQuoteAccepted || isQuoteRefused) && (
              <Alert
                variant={ isQuoteAccepted ? 'success' : 'danger' }
                style={ LegalNoticeModalStyle.alertContainerStyle() }
              >
                <div className="d-flex align-items-center">
                  <LuInfo className="me-2"/>
                  <div style={ { fontWeight: 'bold' } }>
                    { isQuoteAccepted
                      ? 'Devis validé en attente de planification.'
                      : 'Devis refusé, annonce légale bloquée.'
                    }
                  </div>
                </div>
              </Alert>
            ) }
          </div>

          <Button
            type={ 'default-blue' }
            onClick={ () => this.onValidate(this.props) }
            disabled={ isQuotePending }
          >
            { this.props.legalNotice.isForcePublishDate ? 'Publier' : 'Planifier' }
          </Button>
        </div>
      </>
    );
  }

  private lineDetails(label: string, data: string | number | null): ReactElement
  {
    return (
      <React.Fragment key={ label }>
        <div>
          <div style={{ ...FontStyle.littleGrey(), marginBottom: 4 }}>{ label }</div>
          <div>
            <div style={ FontStyle.normal() }>
              { data }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  private lineDetailsSendTo(label: string, emails: string[] | null): ReactElement
  {
    return (
      <React.Fragment key={ label }>
        <div style={ { display: 'grid', gridTemplateColumns: '90px auto', gap: 5 } }>
          <div style={ { fontSize: 12, fontWeight: 500, color: CssVariableEnum['--color-grey-400'], minWidth: 90 } }>
            { label }
          </div>
          <div style={ LegalNoticeModalStyle.sendToLineStyle() }>
            { emails && emails.map((email: string, index: number) =>
              {
                return <span
                  style={ LegalNoticeModalStyle.sendToTagStyle() }
                  key={ index }
                >
									{ email }
								</span>;
              }
            ) }
          </div>
        </div>
      </React.Fragment>
    );
  }

  //</editor-fold>

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private async onValidate(propsData: any): Promise<void>
  {
    const isForcePublishDate = propsData.legalNotice.isForcePublishDate;
    const actionVerb = isForcePublishDate ? 'Publier' : 'Planifier';

    this.props.modalContext.isOpen(false);

    try {
      const result = await Swal.fire({
        title: `Voulez-vous vraiment ${ actionVerb } cette annonce ?`,
        text: 'Vous pouvez annuler pour changer d\'avis.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Oui, je veux ${ actionVerb } !`,
        cancelButtonText: 'Non, annuler'
      });

      if (result.isConfirmed) {
        this.props.modalContext.isOpen(false);
        this.props.onCreate(isForcePublishDate ? PublishStateEnum.PUBLISH.value : PublishStateEnum.PLANNED.value);
      }
    } catch (error) {
      console.error('Erreur lors de la validation de la modal:', error);
      await Swal.fire('Erreur', 'Un problème est survenu lors de la validation.', 'error');
    }
  }

  private onButtonAction(publishState: PublishStateEnum): void
  {
    this.props.onCreate(publishState.value);
    this.props.modalContext.isOpen(false);
  }

  private stringToCapitalize(stringToConvert: string): string
  {
    return stringToConvert[0].toUpperCase() + stringToConvert.slice(1).toLowerCase();
  }

  //</editor-fold>
}