import React, { ReactElement } from 'react';
import LegalNoticeFomCardComponent from '@/Modules/LegalNotice/Components/Form/Admin/FormCardComponent';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';

interface ComponentProps
{
  isDisplayBlock: boolean,
  isBodacc: boolean,
  onSelectedIsBodacc: (event: any) => void
}

export default class BlockBodaccComponent extends React.Component<ComponentProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        { this.props.isDisplayBlock &&
          <>
            <LegalNoticeFomCardComponent
              title={"Bodacc"}
              children={
                <>
                  <>
                    <Checkbox
                      label="Voulez-vous ajouter le Bodacc ?"
                      name="isBodacc"
                      isChecked={ this.props.isBodacc }
                      onCheckedChange={ (event: any) => this.props.onSelectedIsBodacc(event) }
                      style={ { display: 'flex' } }
                    />
                  </>
                </>
              }
            />
          </>
        }
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">
  //</editor-fold>
}