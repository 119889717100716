import React, { ReactElement } from 'react';

export default class MainWrapper extends React.Component<any, any>
{
	render(): ReactElement
	{
		return (
			<div style={
				{ padding: '0 30px',
					columnGap: '20px',
						...this.props.style,
				}
			}>
				{ this.props.children }
			</div>
		);
	}
}