import React, { ReactElement } from 'react';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { UserRoleEnum } from '@/Enum/UserRoleEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class NotFoundView extends React.Component
{
  render(): ReactElement
  {
    const localStorageUser: any = LocalStorageService.getUserLocalStorage();
    return (
      <>
        <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
          <img
            src="/img/publisur-signature-form.svg"
            style={{
              position: 'absolute',
              zIndex: 1,
              opacity: 0.15,
              height: '100%'
          }}
            alt=""
          />
          <div style={{ textAlign: 'center', position: 'relative', zIndex: 2}}>
            <div style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img src="/img/logo-publisur-dark.svg" width={150} alt=""/>
            </div>
            <div style={ FontStyle.h2() }> Page non accessible</div>
            <div style={ { ...FontStyle.h4(CssVariableEnum['--color-grey-400']), marginBottom: 20  }}>
              Il semble que nous ne trouvons pas ce que vous cherchez. <br/>
              Retournez à la page d'accueil ou utilisez la barre de recherche pour trouver ce dont vous avez besoin.
            </div>
            <Button
              label={'Retour à l\'accueil'}
              type={'default-blue'}
              onClick={() => window.location.href = this.handleHref(localStorageUser)}
            />
          </div>
        </div>
      </>
    );
  }

  private handleHref(localStorageUser: any)
  {
    if (!localStorageUser) {
      return '/';
    }

    if (localStorageUser.userRole
      && (localStorageUser.userRole === UserRoleEnum.ROLE_ADMIN || localStorageUser.userRole === UserRoleEnum.ROLE_SUPER_ADMIN)
    ) {
      return '/admin';
    } else {
      return '/';
    }
  }
}
