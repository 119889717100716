import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card } from 'react-bootstrap';

export default class FormBuilderSectionSkeleton extends React.Component
	<{ count: number }, any>
{
	render(): ReactElement[]
	{
		return (
			Array(this.props.count).fill(0).map((item, index) => (
				<Card className="mb-2" key={ index }>
					<Card.Body style={ {
						height: 42,
						display: 'flex',
						alignItems: 'center',
						padding: '0 25px',
						justifyContent: 'space-between'
					} }
					>
						<div>
							<Skeleton key={ item.key } height={ 22 } width={ 200 }/>
						</div>
						<div className="d-flex justify-content-end align-items-center">
							<Skeleton key={ item.key } height={ 22 } width={ 100 }/>
						</div>
					</Card.Body>
				</Card>
			))
		);
	}
}