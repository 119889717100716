import React, { ReactElement, } from 'react';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { ButtonType } from '@/Modules/App/Components/Atom/Button/Type/ButtonType';
import Skeleton from 'react-loading-skeleton';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import BreadcrumbsWrapper from '@/Modules/App/Wrapper/BreadcrumbsWrapper';
import HeroSectionStyle from '@/Modules/App/Style/Components/HeroSectionStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

interface ComponentProps
{
	title: string,
	extendTitle?: ReactElement,
	icon: any,
	isLoading?: boolean,
	buttonAction?: {
		buttonType: ButtonType,
		buttonLabel: string,
		buttonIcon: ReactElement,
		onClick: (event: any) => void,
	} | null,
	buttonType?: any,
	buttonIcon?: any,
	buttonLabel?: any,
	buttonLink?: any,
	tableFilter?: ReactElement
}

export default class HeroSection extends React.Component
	<ComponentProps, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={ HeroSectionStyle.container() }>
					<div style={ HeroSectionStyle.grid(this.props.tableFilter) }>
						<div>
							<BreadcrumbsWrapper isLoading={ this.props.isLoading }/>
							{ this.props.isLoading
								? <Skeleton width={ 200 } height={ 28 } style={{ marginBottom: 20, marginLeft: 30 }} baseColor={ CssVariableEnum['--color-grey-200'] }/>
								:
								<div style={{ ...FontStyle.h2(), marginBottom: 10, padding: '0 30px' }}>
									<span>{ this.props.icon } </span>
									<span>{ (this.props.title) ? this.props.title : this.props.extendTitle }</span>
								</div>
							}
						</div>
						<div style={ { width: '100%' } }>
							{ this.props.tableFilter && this.props.tableFilter }
							<div style={ { display: 'flex', justifyContent: 'flex-end', marginBottom: 10 } }>
								{ this.props.buttonAction &&
                  <Button
                    type={ this.props.buttonAction.buttonType }
                    iconLeft={ this.props.buttonAction.buttonIcon }
                    onClick={ this.props.buttonAction?.onClick }
                  >
										{ this.props.buttonAction.buttonLabel }
                  </Button>
								}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}