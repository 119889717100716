import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import AuthClientView from '@/Modules/AuthClient/View/AuthClientView';
import { AuthContext } from '@/Provider/AuthProvider';
import { AuthClientContext } from '@/Provider/AuthClientProvider';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';

const AuthClientViewWrapper = (props: any) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const clientContext = useContext(AuthClientContext);
  const flashMessageContext = useContext(FlashMessageContext);

  return <AuthClientView
    authContext={ authContext }
    clientContext={ clientContext }
    flashMessageContext={ flashMessageContext }
    navigation={ navigate }
    { ...props }
  />;
};

export default AuthClientViewWrapper;