import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthContext } from '@/Provider/AuthProvider';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { AuthCompanyContext } from '@/Provider/AuthCompanyProvider';
import AuthCompanyView from '@/Modules/AuthCompany/View/AuthCompanyView';

const AuthCompanyViewWrapper = (props: any) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const companyContext = useContext(AuthCompanyContext);
  const flashMessageContext = useContext(FlashMessageContext);

  if (authContext?.isLoading) {
    return <LoaderFullPageComponent />;
  }

  if (!authContext?.user) {
    return <div>User not authenticated</div>;
  }

  return <AuthCompanyView
    authContext={ authContext }
    companyContext={ companyContext }
    flashMessageContext={ flashMessageContext }
    navigation={ navigate }
    { ...props }
  />;
};

export default AuthCompanyViewWrapper;