import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalContext } from '@/Provider/ModalProvider';
import LegalNoticeView from '@/Modules/LegalNotice/View/LegalNoticeView';
import { NotificationContext } from '@/Provider/NotificationProvider';

const LegalNoticeViewWrapper = (props: any) => {
	const navigate = useNavigate();
	const location = useLocation();
	const flashMessageContext = useContext(FlashMessageContext);
	const modalContext = useContext(ModalContext);
	const notificationContext = useContext(NotificationContext);

	return <LegalNoticeView
		flashMessageContext={ flashMessageContext }
		modalContext = { modalContext }
		location={ location }
		notificationContext={ notificationContext }
		navigation={ navigate }
		{ ...props }
	/>;
};

export default LegalNoticeViewWrapper;