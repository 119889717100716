import { useNavigate } from 'react-router-dom';
import React from 'react';
import PaymentCallback from '@/Modules/App/View/PaymentCallback';

const PaymentCallbackWrapper = (props: any) => {
  const navigate = useNavigate();

  return <PaymentCallback
    navigation={ navigate }
    paymentStatus={ props.paymentStatus }
  />;
};

export default PaymentCallbackWrapper;