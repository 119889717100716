import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class DashboardAdminViewStyle
{
  static containerCardExport(): CSSProperties
  {
    return {
      display: 'flex',
      width: '450px',
      flexDirection: 'column',
      backgroundColor: CssVariableEnum['--color-white'],
      border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
      borderRadius: '6px',
      padding: '10px',
      marginTop: '10px'
    };
  }

  static containerCardCounter(): CSSProperties
  {
    return {
      display: 'flex',
      minWidth: '300px',
      flexDirection: 'column',
      backgroundColor: CssVariableEnum['--color-white'],
      border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
      borderRadius: '6px',
      padding: '10px',
      cursor: 'pointer'
    };
  }

  static bigNumberStats(): CSSProperties
  {
    return {
      fontSize: 46,
      fontWeight: 600,
      color: CssVariableEnum['--color-grey-900']
    }
  }

  static statsCompare(): CSSProperties
  {
    return {
      backgroundColor: CssVariableEnum['--color-green-100'],
      color: CssVariableEnum['--color-green-500'],
      fontWeight: 500,
      padding: '2px 10px',
      borderRadius: CssVariableEnum['--border-radius-main'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };
  }

  static textStatusActive(): CSSProperties
  {
    return {
      fontSize: 22,
      fontWeight: 600,
      color: CssVariableEnum['--color-grey-500']
    };
  }
}