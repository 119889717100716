import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import AskForgotPasswordView from '@/Modules/App/View/AskForgotPasswordView';

const AskForgotPasswordWrapper = (props: any) => {
  const flashMessageContext = useContext(FlashMessageContext);
  const navigate = useNavigate();

  return <AskForgotPasswordView
    flashMessageContext={ flashMessageContext }
    navigation={ navigate }
    { ...props }
  />;
};

export default AskForgotPasswordWrapper;