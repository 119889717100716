import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { PricingLineInterface } from '@/Modules/Pricing/Interface/PricingLineInterface';

export class ApiAdminPricingService extends BaseService
{
  pricingUrl: string = `${ BaseService.API_URL }/v1/admin/pricing`;

  //<editor-fold desc="Department methods" defaultstate="collapsed">

  async editDepartment(editDepartmentData: any, departmentId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/departments/' + departmentId,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders(),
          body: JSON.stringify(editDepartmentData)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>

  //<editor-fold desc="Annex methods" defaultstate="collapsed">

  async listAnnex(type: string): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/annex?type=' + type,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async createAnnex(createAnnexData: { type: string, price: number }): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/annex',
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(createAnnexData)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async editAnnex(editAnnexData: { type: string, price: number }, annexId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/annex/' + annexId,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders(),
          body: JSON.stringify(editAnnexData)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async deleteAnnex(type: string, annexId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/annex/' + annexId + '?type=' + type,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>

  //<editor-fold desc="Pricing category methods" defaultstate="collapsed">

  async listCategories(formBuilderCategoryId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/categories/' + formBuilderCategoryId,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async createCategory(createCategoryData: { price: number,  annexId: number}, formBuilderCategoryId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/categories/' + formBuilderCategoryId,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(createCategoryData)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async editCategory(editCategoryData: { price: number }, pricingCategoryId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/categories/' + pricingCategoryId,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders(),
          body: JSON.stringify(editCategoryData)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async deletePricingCategory(pricingCategoryId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/categories/' + pricingCategoryId,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async deletePricingCategories(): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/categories',
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>

  //<editor-fold desc="Pricing Line methods" defaultstate="collapsed">

  async listPricingLine(): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/line',
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async search(searchLabel: string): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/line/' + searchLabel,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async createPriceLine(priceLine: PricingLineInterface): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/line',
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(priceLine)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async editPriceLine(priceLine: PricingLineInterface, priceLineId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/line/' + priceLineId,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders(),
          body: JSON.stringify(priceLine)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async deletePriceLine(priceLineId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.pricingUrl + '/line/' + priceLineId,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>
}