import { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class PaymentSellsyStatusEnum
{
	value: string;
	label: string;
	css: { backgroundColor: string, color: string, icon?: ReactElement | null };

	constructor(value: string, label: string, css: { backgroundColor: string, color: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static DUE = new PaymentSellsyStatusEnum(
		'DUE',
		'A régler',
		{ backgroundColor: CssVariableEnum['--color-green-100'], color: CssVariableEnum['--color-green-500'], }
	);
	static PAYINPROGRESS = new PaymentSellsyStatusEnum(
		'PAYINPROGRESS',
		'Paiement partiel',
		{ backgroundColor: CssVariableEnum['--color-yellow-100'], color: CssVariableEnum['--color-yellow-500'], }
	);
	static PAID = new PaymentSellsyStatusEnum(
		'PAID',
		'Payée',
		{ backgroundColor: CssVariableEnum['--color-blue-100'], color: CssVariableEnum['--color-blue-500'], }
	);

	static options: PaymentSellsyStatusEnum[] = [
		PaymentSellsyStatusEnum.DUE,
		PaymentSellsyStatusEnum.PAYINPROGRESS,
		PaymentSellsyStatusEnum.PAID,
	];

	static findByValue(selectedOption: PaymentSellsyStatusEnum | string): any
	{
		return PaymentSellsyStatusEnum.options.find(
			option => option.value === selectedOption
		);
	}
}