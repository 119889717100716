import BaseService from '../Common/BaseService';
import { AuthResponseInterface } from '@/Modules/Auth/Interface/AuthResponseInterface';
import { ErrorResponseInterface } from '@/Modules/App/Interface/ErrorResponseInterface';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { HttpStatusCodeEnum } from '@/Enum/HttpStatusCode';
import { jwtDecode } from 'jwt-decode';

export class ApiAuthService extends BaseService {
  static authUrl: string = `${ ApiAuthService.API_URL }/auth`;

  async auth(email: string, password: string): Promise<AuthResponseInterface | ErrorResponseInterface> {
    try {
      const response = await fetch(
        ApiAuthService.authUrl,
        {
          method: HttpVerbEnum.POST,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password })
        }
      );

      // Check Request error
      if (!response.ok) {
        return await response.json();
      }

      // Response data
      const data = await response.json();

      // Set data in LocalStorage
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(jwtDecode(data.token)));

      return { token: data.token, user: jwtDecode(data.token) };
    } catch (error: any) {
      return {
        status: HttpStatusCodeEnum.HTTP_BAD_REQUEST,
        errorMessage: error
      };
    }
  }
}