import React, { createRef, ReactElement, RefObject } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { Notification, NotificationContextType } from '@/Provider/NotificationProvider';
import { LuCheckCircle, LuTrash2, LuXCircle } from 'react-icons/lu';
import { timeSince } from '@/Utils/DateUtils';
import { NavigateFunction } from 'react-router-dom';

interface ViewProps
{
	notificationContext: NotificationContextType,
	handleOpen: () => void,
	navigation: NavigateFunction,
	isOpen: boolean,
}

export default class NotificationView extends React.Component<ViewProps, any>
{
	containerRef: RefObject<HTMLDivElement>;
	navigation: NavigateFunction;

	constructor(props: any)
	{
		super(props);
		this.navigation = props.navigation;
		this.containerRef = createRef();
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount(): void
	{
		this.props.notificationContext.refreshNotifications();
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event: MouseEvent)
	{
		event.stopPropagation();
		if (this.props.isOpen && this.containerRef.current && !this.containerRef.current.contains(event.target as Node)) {
			this.props.handleOpen();
		}
	}

	render(): ReactElement
	{
		return (
			<div style={ {
				position: 'relative',
				top: 50,
			} }>
				<div
					ref={ this.containerRef }
					style={ { position: 'absolute', zIndex: 500, top: 0, right: 0, width: 400, height: '100%', padding: 10 } }
				>
					<div style={ {
						transform: this.props.isOpen ? 'translateX(0)' : 'translateX(100%)',
						opacity: this.props.isOpen ? 1 : 0,
						transition: 'transform 0.5s ease, opacity 0.5s ease',
						backgroundColor: CssVariableEnum['--color-grey-100'],
						height: 'calc(100vh - 70px)',
						width: '100%',
						borderRadius: 15,
						boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px',
						border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
						padding: 20,
						overflowY: 'scroll',
						position: 'relative',
					} }>
						<div style={ {
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: 20,
							borderBottom: `1px solid ${ CssVariableEnum['--color-grey-200'] }`
						} }>
							<div style={ { ...FontStyle.h3() } }>Notifications</div>
							<Button
								label={ 'Marquer comme lues' }
								type={ 'inline-default-blue' }
								onClick={ () => this.props.notificationContext.allRead(true) }
							/>
						</div>
						<div>
							{ this.notificationsRender() }
						</div>
					</div>
				</div>
			</div>
		);
	}

	private notificationsRender(): ReactElement
	{
		let notifications = this.props.notificationContext.getNotifications();

		if (notifications && notifications.length > 0) {
			return (
				<>
					{ notifications.map((notification: Notification, index: number) => (
						<div
							key={ index }
							style={ {
								marginBottom: '10px',
								padding: '10px',
								borderRadius: '15px',
								position: 'relative',
								display: 'flex',
								alignItems: 'flex-start',
								backgroundColor: CssVariableEnum['--color-white'],
								cursor: 'pointer'
							} }
							onClick={ () =>
							{
								this.props.notificationContext.isRead(notification.id, true);
								//notification.path && this.navigation(notification.path);
							} }
						>
							<div style={ { marginRight: 10 } }>
								{ notification.type === 'success' && <LuCheckCircle color={ CssVariableEnum['--color-green-500'] }/> }
								{ notification.type === 'error' && <LuXCircle color={ CssVariableEnum['--color-error-500'] }/> }
								{ notification.type === 'info' && 'ℹ️ ' }
							</div>
							<div>
								<div style={ { fontSize: 12, lineHeight: '15px' } }
										 dangerouslySetInnerHTML={ { __html: notification.message } }/>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 8 }}>
									<div style={ { ...FontStyle.littleGrey()} }>
										{ timeSince(notification.date) }
									</div>
									<div
										style={{ fontSize: '12px', color: CssVariableEnum['--color-error-400'],  fontWeight: 400  }}
										onClick={() => this.props.notificationContext.removeNotification(notification.id)}
									>
										<LuTrash2 />
									</div>
								</div>
							</div>
							{ !notification.isRead &&
                <div style={ { position: 'absolute', top: 5, right: 5 } }>
                  <div style={ {
										width: 10,
										height: 10,
										borderRadius: '50%',
										backgroundColor: CssVariableEnum['--color-blue-500']
									} }></div>
                </div>
							}
						</div>
					)) }
				</>
			);
		}

		return (
			<div>Aucune notification</div>
		);
	}

}