import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class HeroSectionStyle
{
	static container(): CSSProperties
	{
		return {
			position: 'fixed',
			top: 50,
			left: 240,
			zIndex: 100,
			width: 'calc(100% - 240px)',
			backgroundColor: CssVariableEnum['--color-grey-100'],
			borderTopLeftRadius: 30,
			borderBottom: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
			paddingRight: 30,
		};
	}

	static grid(isFilterProps: any): CSSProperties
	{
		return { display: 'grid', gridTemplateColumns: (isFilterProps) ? '25% auto' : 'auto 300px', alignItems: 'flex-end' };
	}
}