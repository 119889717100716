import React, { ReactElement } from 'react';
import { AuthResponseInterface } from '@/Modules/Auth/Interface/AuthResponseInterface';
import { ErrorResponseInterface } from '@/Modules/App/Interface/ErrorResponseInterface';
import { ApiAuthService } from '@/Service/Api/ApiAuthService';
import { ApiUserService } from '@/Service/Api/ApiUserService';
import { AuthProviderState } from '@/Provider/Interface/Auth/AuthProviderState';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';

export const AuthContext = React.createContext<AuthContextType | null>(null);

export class AuthProvider extends React.Component<any, AuthProviderState>
{
  authService: ApiAuthService;
  userService: ApiUserService;
  userAdminService: ApiAdminUserService;

  constructor(props: any)
  {
    super(props);

    this.state = {
      user: null,
      isUserFetch: false,
      isLoading: true
    };

    // Services
    this.authService = new ApiAuthService();
    this.userService = new ApiUserService();
    this.userAdminService = new ApiAdminUserService();

    // Bind
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async componentDidMount(): Promise<void>
  {
    await this.storedUser();
  }

  async storedUser(): Promise<any>
  {
    const storedUser = LocalStorageService.getUserLocalStorage();
    if (storedUser && !this.state.isUserFetch) {
      try {
        const user = await this.fetchUserByRole(storedUser.userRole, storedUser.userId);
        this.setState({ user: user, isUserFetch: true, isLoading: false });
      } catch (error: any) {
        console.error('[AUTHPROVIDER] - Something wrong on load user:', error);
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
    }
  }

  async login(email: string, password: string): Promise<any>
  {
    try {
      const responseAuth: AuthResponseInterface | ErrorResponseInterface = await this.authService.auth(email, password);

      if ('user' in responseAuth && !this.state.isUserFetch) {
        const user = await this.fetchUserByRole(
          responseAuth.user.userRole,
          responseAuth.user.userId
        );
        LocalStorageService.setUserLocalStorage(responseAuth.user);
        this.setState({ user: user, isUserFetch: true, isLoading: false });
      } else {
        if ('errorMessage' in responseAuth) {
          throw new Error(responseAuth.errorMessage);
        }
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async fetchUserByRole(role: string, userId: number): Promise<any>
  {
    switch (role) {
      case 'ROLE_USER':
        return this.userService.show(userId);
      case 'ROLE_ADMIN':
      case 'ROLE_SUPER_ADMIN':
      case 'ROLE_DEVELOPER':
        return this.userAdminService.show(userId);
      default:
        throw new Error(`Unsupported role: ${role}`);
    }
  }

  async logout(): Promise<void>
  {
    LocalStorageService.clearLocalStorage();
    this.setState({ user: null, isUserFetch: false, isLoading: false });
  }

  render(): ReactElement
  {
    return (
      <AuthContext.Provider value={{
        user: this.state.user,
        login: this.login,
        logout: this.logout,
        isLoading: this.state.isLoading
      }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
