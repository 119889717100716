import React, { ReactElement } from 'react';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { BsCopy } from 'react-icons/bs';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ApiAdminCollaboratorService } from '@/Service/Admin/ApiAdminCollaboratorService';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { ModalContextType } from '@/Provider/ModalProvider';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import TagEnum from '@/Modules/App/Components/Atom/Tags/TagEnum';
import TableComponent, { TableHeaderListInterface } from '@/Modules/App/Components/Atom/Table/TableComponent';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { FilterParamsInterface } from '@/Modules/App/Interface/FilterParamsInterface';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { NavigateFunction } from 'react-router-dom';
import { ContactSellsyInterface } from '@/Modules/Client/Interface/ContactSellsyInterface';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { SearchSellsyInterface } from '@/Modules/Client/Interface/SearchSellsyInterface';
import { Alert } from 'react-bootstrap';

export default class CollaboratorShowAdminView extends React.Component
	<
		{
			authCompanyContext: AuthCompanyContextType,
			flashMessageContext: FlashMessageContextType,
			navigation: NavigateFunction,
			selectedCollaborator: CollaboratorInterface,
			selectedClient: ClientInterface,
			currentItemId: number | null,
			handleUpdated: (updatedCollaboratorData: CollaboratorInterface) => void,
			handleDeleted: (collaborator: CollaboratorInterface) => void,
			modalContext: ModalContextType,
			isOpen: (isOpen: boolean) => void
		},
		{
			isEditMode: boolean;
			email: string;
			selectedRole: any | null;
			selectedStatus: any | null;
			errorMessage: string;
			selectedLegalNoticeId: number | null,
			isOpen: boolean
			paginate: PaginateInterface,
			filterParams: FilterParamsInterface,
			sellsyData: ContactSellsyInterface,
			collaboratorExistingExtSellsyIds: string[],
			sellsyListContact: SearchSellsyInterface,
			isSellsyDataLoading: boolean,
			extSellsyId: string
		}
	>
{
	collaboratorService: ApiAdminCollaboratorService;
	apiClientSellsyService: ApiAdminSellsyService;
	sellsyClientService : ApiAdminSellsyService;

	constructor(props: any)
	{
		super(props);

		this.state = {
			isEditMode: false,
			email: this.props.selectedCollaborator.email,
			selectedRole: this.props.selectedCollaborator.role,
			selectedStatus: this.props.selectedCollaborator.status,
			selectedLegalNoticeId: null,
			isOpen: false,
			errorMessage: '',
			paginate: {} as PaginateInterface,
			filterParams: {
				page: 1,
				itemsPerPage: 10,
				search: null,
				filters: null,
				orderBy: null,
				sort: null,
			},
			sellsyData: {} as ContactSellsyInterface,
			sellsyListContact: {} as SearchSellsyInterface,
			collaboratorExistingExtSellsyIds: [] as string[],
			isSellsyDataLoading: true,
			extSellsyId: ''
		};

		// Services
		this.collaboratorService = new ApiAdminCollaboratorService();
		this.apiClientSellsyService = new ApiAdminSellsyService();
		this.sellsyClientService = new ApiAdminSellsyService();

		// Bind
		this.handleUpdate = this.handleUpdate.bind(this);
		this.onRowClick = this.onRowClick.bind(this);
		this.updateCollaborator = this.updateCollaborator.bind(this);
		this.inputSelectRoleStatusContent = this.inputSelectRoleStatusContent.bind(this);
		this.inputSelectActiveStatusContent = this.inputSelectActiveStatusContent.bind(this);
		this.onSelectedCollaboratorRole = this.onSelectedCollaboratorRole.bind(this);
		this.onSelectedCollaboratorStatus = this.onSelectedCollaboratorStatus.bind(this);
		this.buildTableLegalNotice = this.buildTableLegalNotice.bind(this);
		this.buildFilterParams = this.buildFilterParams.bind(this);
		this.cancelEditMode = this.cancelEditMode.bind(this);
		this.refreshSellsyContactList = this.refreshSellsyContactList.bind(this);
	}

	async componentDidMount(): Promise<void>
	{
		if (this.props.selectedCollaborator) {
			this.setState({ isOpen: true });
		}

		// Filter ext sellsy ids
		const existingExtSellsyIds = this.getExistingCollaboratorExtSellsyIds();

		// Get list of contact (sellsy)
		const getListContact = await this.apiClientSellsyService.listCompanyContact(
			this.props.authCompanyContext.authCompany.id,
			parseInt(this.props.selectedClient.extSellsyId)
		);

		this.setState({
			sellsyListContact: getListContact,
			collaboratorExistingExtSellsyIds: existingExtSellsyIds
		});

		// Get data contact (sellsy)
		if (this.props.selectedCollaborator.extSellsyId) {
			const getSellsyContact = await this.apiClientSellsyService.getContact(
				this.props.authCompanyContext.authCompany.id,
				parseInt(this.props.selectedCollaborator.extSellsyId)
			);
			this.setState({ sellsyData: getSellsyContact, isSellsyDataLoading: false });
		} else {
			this.setState({ isSellsyDataLoading: false });
		}

		this.props.modalContext.actions(
			[
				{
					label: (this.state.isEditMode)
						? 'Sauvegarder'
						: 'Editer',
					buttonType: 'default',
					action: (event?: any) =>
					{
						this.setState({ isEditMode: true });
						event.stopPropagation();
					}
				},
				{
					label: 'Supprimer',
					buttonType: 'danger',
					action: () => this.handleDelete(this.props.selectedCollaborator.id)
				},
			],
		);

		await this.refreshList();
	}

	componentDidUpdate(prevProps: any): void
	{
		if (this.props.currentItemId && this.props.currentItemId !== prevProps.currentItemId) {
			this.setState({ isEditMode: false });
		}

		this.props.modalContext.actions(
			[
				{
					label: (this.state.isEditMode) ? 'Sauvegarder' : 'Editer',
					buttonType: 'default',
					action: (this.state.isEditMode)
						? this.handleUpdate
						: (event?: any) =>
						{
							this.setState({ isEditMode: true });
							event.stopPropagation();
						}
				},
				{
					label: (this.state.isEditMode) ? 'Annuler' : 'Supprimer',
					buttonType: (this.state.isEditMode) ? 'warning' : 'danger',
					action: (event: any) => (this.state.isEditMode)
						? this.cancelEditMode(event)
						: this.handleDelete(this.props.selectedCollaborator.id)
				},
			],
		);
	}

	render(): ReactElement
	{
		if (this.state.isSellsyDataLoading) {
			return <LoaderComponent />;
		}

		return (
			<>
				<div style={ {
					backgroundColor: CssVariableEnum['--color-grey-50'],
					width: '1000px',
					minHeight: '600px',
					boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
				} }>
					<div style={ {
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
					} }>
						<div style={{ display: 'flex' }}>
							<div style={ {
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '80px',
								width: '80px',
								borderRadius: '50%',
								backgroundColor: 'white',
								margin: '10px 20px',
								boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
							} }>
								<div style={ {
									height: '73px',
									width: '73px',
									borderRadius: '50%',
									backgroundColor: CssVariableEnum['--color-blue-600'],
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									fontSize: '32px',
									color: 'white'
								} }>
									{ this.props.selectedCollaborator.user.firstname.charAt(0) }
									{ this.props.selectedCollaborator.user.lastname.charAt(0) }
								</div>
							</div>
							{ this.props.selectedCollaborator.extSellsyId &&
								<>
									<div style={ {
										display: 'flex',
										width: '400px',
										flexDirection: 'column',
										backgroundColor: CssVariableEnum['--color-purple-50'],
										border: `1px solid ${ CssVariableEnum['--color-purple-500'] }`,
										borderRadius: '6px',
										padding: '5px 10px'
									} }>
										<Title type={ 'h6' } style={ {
											marginTop: '0',
											marginBottom: '5px',
											color: CssVariableEnum['--color-purple-500']
										} }>
											Sellsy Détails (# { this.state.sellsyData.id })
										</Title>
										{ this.lineDetailsHtmlContent(
											'Email',
											(this.state.sellsyData.email) ? this.state.sellsyData.email : '-',
											null
										) }
										{ this.lineDetailsHtmlContent(
											'Nom',
											(this.state.sellsyData.last_name) ? this.state.sellsyData.last_name + ' ' + this.state.sellsyData.first_name : '-',
											null
										) }
									</div>
								</>
							}
						</div>
						<div style={ {
							marginTop: '10px',
							padding: '0px 20px',
							flexGrow: 1
						} }>
							<div style={ {
								display: 'flex',
								alignItems: 'center',
								gap: '16px'
							} }>
								<div style={ {
									fontSize: '26px',
									fontWeight: 600,
									color: CssVariableEnum['--color-grey-900'],
								} }>
									{ this.props.selectedCollaborator.user.firstname } { this.props.selectedCollaborator.user.lastname }
								</div>
								<Button
									type={ 'default' }
									iconRight={ <BsCopy style={ { fontSize: '10px' } }/> }
									onClick={ () => navigator.clipboard.writeText(`#${ this.props.selectedCollaborator.user.tag }`) }
									style={ { fontSize: '12px' } }
								>
									#{ this.props.selectedCollaborator.user.tag }
								</Button>
								{ this.state.isEditMode
									? this.inputSelectActiveStatusContent()
									: <TagEnum enumName={ 'ActiveStatusEnum' } value={ this.state.selectedStatus }>
										{ ActiveStatusEnum.findByValue(this.state.selectedStatus)?.label }
									</TagEnum>
								}
								{ this.state.isEditMode && this.props.selectedCollaborator.role.toString() !== CollaboratorRoleEnum.ROLE_OWNER.value
									? this.inputSelectRoleStatusContent()
									: <TagEnum enumName={ 'CollaboratorRoleEnum' } value={ this.state.selectedRole }>
										{ CollaboratorRoleEnum.findByValue(this.state.selectedRole)?.label }
									</TagEnum>
								}
							</div>
							{ this.state.isEditMode
								? <>
									<div style={ {
										display: 'flex',
										width: '100%',
										flexDirection: 'column',
										backgroundColor: CssVariableEnum['--color-white'],
										border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
										borderRadius: '6px',
										padding: '5px 10px',
										marginTop: '5px'
									} }>
										<Title type={ 'h6' } style={ { marginTop: '0' } }>Editer les informations du Collaborateur</Title>
										<div style={ { color: CssVariableEnum['--color-grey-500'], marginTop: '5px' } }>
											<Input
												style={ { width: '600px' } }
												type="text"
												name={ 'company-siren' }
												value={ this.state.email }
												onChange={
													(event) =>
														this.setState((prevState) => ({
															...prevState,
															email: event.target.value
														}))
												}
											/>
										</div>
										<div style={ { color: CssVariableEnum['--color-grey-500'] } }>
											{ this.state.isEditMode&&
												<>
													{ this.filterSellsyContacts().length > 0 ? (
														<>
															<Title type={ 'h6' }
																		 style={ { marginTop: '5px', marginBottom: '5px', fontSize: '14px' } }>
																Associer le Collaborateur à un contact Sellsy
															</Title>
															<div style={{ display: 'flex' }}>
																<SelectComponent
																	style={ { marginBottom: '15px', width: '100%' } }
																	listOptions={ this.filterSellsyContacts() }
																	onSelectedOption={ (event: any) => this.handleContactSellsySelect(event) }
																	renderOptionLabel={ (contact) => `${ contact.email } #${ contact.id }` }
																/>
																<Button type={'default-dark'} onClick={ this.refreshSellsyContactList }>Relancer la liste Contact de Sellsy</Button>
															</div>
														</>
													) : (
														<>
															<div style={ { marginTop: '10px', width: '300px' } }>
																<Alert key={ 'warning' } variant={ 'warning' }
																			 style={ { marginTop: '0', fontSize: '14px' } }>
																	Tout les contacts Sellsy sont affectés, merci d'en créer un nouveau
																</Alert>
																{ (!this.state.extSellsyId)
																	? <Button
																		type="default-dark"
																		onClick={ () => this.handleCreateNewSellsyContact() }
																		style={ { marginBottom: '40px' } }
																	>
																		Créer un nouveau contact Sellsy
																	</Button>
																	: <>
																		<div>
																			<img
																				style={ {
																					width: '35px',
																					marginLeft: '15px',
																					marginTop: '21px',
																					padding: '5px',
																					borderRadius: '5px',
																					backgroundColor: CssVariableEnum['--color-purple-200']
																				} }
																				src="/img/logo-sellsy.png" alt="Sellsy Logo"
																			/>
																			<div style={ { color: CssVariableEnum['--color-purple-200'] } }>Contact lié avec
																				Sellsy
																			</div>
																		</div>
																	</>
																}
															</div>
														</>
													) }
												</>
											}
										</div>
									</div>
								</>
								: <div style={ { color: CssVariableEnum['--color-grey-500'], marginTop: '5px' } }>
									{ this.props.selectedCollaborator.email }
								</div>
							}
					</div>
					<div style={ { padding: 20 } }>
						<TableComponent
							paginateList={ this.props.selectedCollaborator.legalNotices }
							tableHeaderList={ this.buildTableLegalNotice() }
							params={ this.buildFilterParams }
							onClickRow={ this.onRowClick }
							notFoundValuesText={ 'Ce collaborateur n\'a créé aucune annonce légale' }
							onRefreshList={ this.refreshList }
							isResizeColumns={ true }
							isStickyHeader={ true }
						/>
					</div>
				</div>
			</div>
	</>
	)
		;
	}

	private async refreshList(): Promise<void>
	{
		return;
	}

	// <editor-fold desc="Content Html" defaultstate="collapsed">

	private inputSelectRoleStatusContent(): ReactElement
	{
		return (
			<>
				<SelectComponent
					style={ { flex: '0 0 150px' } }
					listOptions={ CollaboratorRoleEnum.roleOptionsWithoutOwner }
					onSelectedOption={ (event: any) => this.onSelectedCollaboratorRole(event) }
					selectedValue={ (this.state.selectedRole) ? CollaboratorRoleEnum.findByValue(this.state.selectedRole) : null }
					renderOptionLabel={ (option) => `${ option.label }` }
				/>
			</>
		);
	}

	private inputSelectActiveStatusContent(): ReactElement
	{
		return (
			<>
				<SelectComponent
					style={ { flex: '0 0 150px' } }
					listOptions={ ActiveStatusEnum.roleOptions }
					onSelectedOption={ (event: any) => this.onSelectedCollaboratorStatus(event) }
					selectedValue={ (this.state.selectedStatus) ? ActiveStatusEnum.findByValue(this.state.selectedStatus) : null }
					renderOptionLabel={ option => option.label }
				/>
			</>
		);
	}

	private buildTableLegalNotice(): TableHeaderListInterface[]
	{
		return [
			{
				columnTitle: 'N° d\'annonce',
				orderTag: null,
				minWidth: 100,
				width: 100,
				justifyContent: 'center',
				fontWeight: 400,
				type: 'string',
				dataTag: 'tag'
			},
			{
				columnTitle: 'Référence',
				orderTag: null,
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'string',
				dataTag: 'reference'
			},
			{
				columnTitle: 'Publication au...',
				orderTag: null,
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'date',
				dataTag: 'publishDate'
			},
			{
				columnTitle: 'Journal',
				orderTag: null,
				minWidth: 100,
				width: 200,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'twoLinesString',
				dataTag: ['newspaper.name', 'newspaper.type']
			},
			{
				columnTitle: 'Département',
				orderTag: null,
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'twoLinesString',
				dataTag: ['publishDepartment.name', 'publishDepartment.code']
			},
			{
				columnTitle: 'Status',
				orderTag: null,
				minWidth: 100,
				width: 100,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'tag',
				dataTag: { label: 'status', enumClass: PublishStateEnum }
			},
			{
				columnTitle: 'Paiement',
				orderTag: null,
				minWidth: 100,
				width: 100,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'tag',
				dataTag: { label: 'paymentStatus', enumClass: PaymentStateEnum }
			},
		];
	}

	private onRowClick(event: any, legalNotice: LegalNoticeInterface): void
	{
		this.setState({
			selectedLegalNoticeId: legalNotice.id
		}, () =>
		{
			this.props.modalContext.isOpen(false);
			this.props.navigation(`/admin/legal-notice?legalNoticeId=${ this.state.selectedLegalNoticeId }&from=/admin/clients?clientId=${ this.props.selectedClient.id }`);
		});
	}

	private buildFilterParams(orderBy: string, sort: string): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				orderBy: orderBy,
				sort: sort
			}
		}), async (): Promise<void> => await this.refreshList());
	}

	// </editor-fold>

	// <editor-fold desc="Private methods" defaultstate="collapsed">

	private onSelectedCollaboratorRole(collaboratorRole: CollaboratorRoleEnum)
	{
		this.setState(prevState => ({
			...prevState,
			selectedRole: collaboratorRole.value
		}));
	}

	private onSelectedCollaboratorStatus(status: ActiveStatusEnum)
	{
		this.setState(prevState => ({
			...prevState,
			selectedStatus: status.value
		}));
	}

	async handleUpdate(event: any)
	{
		event.preventDefault();

		const updatedCollaboratorData = {
			email: this.state.email,
			role: this.state.selectedRole,
			status: this.state.selectedStatus,
			extSellsyId: parseInt(this.state.extSellsyId)
		};

		this.updateCollaborator(updatedCollaboratorData);
		this.setState({ isEditMode: false });
		this.props.modalContext.isOpen(false);
		this.props.modalContext.errorMessage('');
	};

	async updateCollaborator(updatedCollaboratorData: any)
	{
		const client = this.props.selectedClient;

		if (client) {
			const updatedCollaborator = await this.collaboratorService.edit(
				updatedCollaboratorData,
				client.id,
				this.props.selectedCollaborator.id
			);

			if (updatedCollaborator && updatedCollaborator.errorMessage) {
				this.setState({ errorMessage: updatedCollaborator.errorMessage });
				return;
			}

			this.setState({ isEditMode: false });
			this.props.handleUpdated(updatedCollaborator);
			// Set Flash message
			this.props.flashMessageContext.flashMessage(
				'Mise à jour réussie',
				'Le collaborateur a été édité avec succès',
				'success'
			);
		}
	}

	private cancelEditMode(event: any): void
	{
		event.stopPropagation();

		this.setState({isEditMode: false});
		this.props.modalContext.isOpen(true);
	}

	async handleDelete(collaboratorId: number)
	{
		const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer ce collaborateur ?');
		if (confirmation) {
			try {
				await this.collaboratorService.delete(
					this.props.selectedClient.id,
					collaboratorId
				);

				const deletedCollaborator = await this.collaboratorService.show(
					this.props.selectedClient.id,
					collaboratorId
				);

				this.props.handleDeleted(deletedCollaborator);
			} catch (error: any) {
				console.error('Erreur lors de la suppression du collaborateur :', error);
			}
		}
		this.props.modalContext.isOpen(false);
		this.props.modalContext.errorMessage('');
	}

	private lineDetailsHtmlContent(label: string, data: any, renderEdit: ReactElement | null): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					width: '100%',
					marginBottom: '5px'
				} }>
					<div style={ {
						width: '140px',
						color: CssVariableEnum['--color-grey-400']
					} }>
						{ label }
					</div>
					{ (this.state.isEditMode && renderEdit)
						? renderEdit
						: <div style={ {
							fontWeight: 600,
							color: CssVariableEnum['--color-grey-900'],
						} }>
							{ data }
						</div>
					}
				</div>
			</>
		);
	}

	private handleContactSellsySelect(contact: ContactSellsyInterface): any
	{
		this.setState({ extSellsyId: contact.id.toString() });
	}

	private getExistingCollaboratorExtSellsyIds(): string[]
	{
		if (this.props.selectedClient && this.props.selectedClient.collaborators) {
			return this.props.selectedClient.collaborators
				.map((collaborator: any) => collaborator.extSellsyId)
				.filter((id: string | null) => id !== null)
				.map((id: string | null) => id!.toString());
		}

		return [];
	}

	private async refreshSellsyContactList(): Promise<void>
	{
		// Filter ext sellsy ids
		const existingExtSellsyIds = this.getExistingCollaboratorExtSellsyIds();

		// Get list of contact (sellsy)
		const getListContact = await this.apiClientSellsyService.listCompanyContact(
			this.props.authCompanyContext.authCompany.id,
			parseInt(this.props.selectedClient.extSellsyId)
		);

		this.setState({
			sellsyListContact: getListContact,
			collaboratorExistingExtSellsyIds: existingExtSellsyIds
		});
	}

	private filterSellsyContacts(): ContactSellsyInterface[]
	{
		if (Array.isArray(this.state.sellsyListContact.data) && this.state.sellsyListContact.data.every(item => 'id' in item)) {
			return (this.state.sellsyListContact.data as ContactSellsyInterface[]).filter(
				(contact: ContactSellsyInterface) => !this.state.collaboratorExistingExtSellsyIds.includes(contact.id.toString())
			);
		}

		return [];
	}

	private async handleCreateNewSellsyContact(): Promise<void>
	{
		const newContact = await this.sellsyClientService.createContact(
			this.props.authCompanyContext.authCompany.id,
			{
				civility: (this.props.selectedCollaborator.user?.civility === 'M')
					? 'mr'
					: 'mrs'
				,
				first_name: this.props.selectedCollaborator.user?.firstname,
				last_name: this.props.selectedCollaborator.user?.lastname,
				email: this.state.email
				,
				phone_number: this.props.selectedCollaborator.user?.phone,
				position: this.state.selectedRole?.label,
				note: 'Updated Collaborator from Publisur.dev'
			}
		);

		if (this.props.selectedCollaborator && newContact) {
			const linkContact = await this.sellsyClientService.linkContactToCompany(
				this.props.authCompanyContext.authCompany.id,
				parseInt(this.props.selectedClient.extSellsyId),
				newContact.id,
				false
			);

			if (linkContact.httpStatus === 201) {
				this.setState({ extSellsyId: newContact.id });

				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'SELLSY - Contact créé avec succès',
					`Votre nouveau contact a bien été créé et associé à Sellsy`,
					'success'
				);
			}
		}
	}

	// </editor-fold>
}