import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalContext } from '@/Provider/ModalProvider';
import ParticularLegalNoticeView from '@/Modules/LegalNotice/Particular/View/ParticularLegalNoticeView';

const ParticularLegalNoticeViewWrapper = (props: any) => {
  const navigate = useNavigate();
  const flashMessageContext = useContext(FlashMessageContext);
  const location = useLocation();
  const modalContext = useContext(ModalContext);

  return <ParticularLegalNoticeView
    flashMessageContext={ flashMessageContext }
    navigation={ navigate }
    modalContext = { modalContext }
    location={ location }
    { ...props }
  />;
};

export default ParticularLegalNoticeViewWrapper;