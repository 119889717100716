import React, { ReactElement } from 'react';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import { LegalNoticeContentHeader } from '@/Modules/LegalNotice/Style/LegalNoticeContentHeader';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { Alert } from 'react-bootstrap';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuXCircle } from 'react-icons/lu';

interface ComponentProps
{
	isConsumerFormFilled: boolean,
	selectedClient: ClientInterface | null,
	isHeader: boolean,
	isLogo: boolean,
	onCheckIsHeader: (event: React.ChangeEvent<HTMLInputElement>) => void
	onCheckIsLogo: (event: React.ChangeEvent<HTMLInputElement>) => void
	onSelectedLogo: (event: any) => void
}

export default class HeaderOptionContentComponent extends React.Component<ComponentProps, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={ LegalNoticeContentHeader.mainContainerStyle() }>
					<div style={ LegalNoticeContentHeader.mainContainerBodyStyle() }>
						<div>
							Options d'annonce
						</div>
					</div>
					{ (!this.props.isConsumerFormFilled || !this.props.selectedClient)
						? this.renderAlert()
						: this.renderOptions()
					}
				</div>
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private renderAlert(): ReactElement
	{
		return (
			<>
				{ (!this.props.isConsumerFormFilled || !this.props.selectedClient) &&
          <Alert style={ { fontSize: '12px', padding: '5px', margin: 0 } } variant={ 'warning' }>
            Pour accéder aux options, merci de remplir les selections suivantes : <br/>
						{ (!this.props.selectedClient)
							? <span> • Client </span>
							: ''
						}
						{ (!this.props.isConsumerFormFilled)
							? <span> • Société (client final) </span>
							: ''
						}
          </Alert>
				}
			</>
		);
	}

	private renderOptions(): ReactElement
	{
		return (
			<>
				<div style={ { display: 'flex', justifyContent: 'space-between', position: 'relative' } }>
					<div className={ this.props.isConsumerFormFilled ? '' : 'disabled-item' }>
						<Checkbox
							label="Entête d'annonce ?"
							name="isHeader"
							isChecked={ this.props.isHeader }
							onCheckedChange={ (event: any) => this.props.onCheckIsHeader(event) }
						/>
					</div>
					{ !this.props.isLogo &&
            <div
              className={ (this.props.selectedClient && this.props.selectedClient.logos.length > 0) ? '' : 'disabled-item' }>
							{ (this.props.selectedClient?.logos.length === 0)
								? <>
									<Alert style={ { fontSize: '12px', padding: '3px', marginBottom: '0px' } } variant={ 'warning' }>
										Aucun logo disponible.
									</Alert>
								</>
								: <Checkbox
									label="Voulez vous mettre le logo ?"
									name="isLogo"
									isChecked={ this.props.isLogo }
									onCheckedChange={ (event: any) => this.props.onCheckIsLogo(event) }
								/>

							}
            </div>
					}

					{ this.handleDisplayLogo() }

				</div>
			</>
		);
	}

	private handleDisplayLogo(): ReactElement | null
	{
		if (!this.props.isLogo) {
			return null;
		}

		const { selectedClient } = this.props;

		if (selectedClient?.logos && selectedClient?.logos.length === 1) {
			const logo = selectedClient.logos[0];

			return (
				<div style={ {
					display: 'flex',
					alignItems: 'center',
					width: '50%',
					justifyContent: 'flex-end',
					gap: 5
				} }>
					<div style={ {
						height: 32,
						minWidth: 100,
						borderRadius: 6,
						backgroundColor: CssVariableEnum['--color-white'],
						border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
						display: 'flex',
						alignItems: 'center',
						padding: '0 6px'
					} }>
						<img
							src={ logo.logoUrl }
							alt={ logo.name }
							style={ { maxHeight: '100%', maxWidth: '100%' } }
						/>
					</div>
					<Button
						iconLeft={ <LuXCircle/> }
						type={ 'danger' }
						onClick={ (event: any) => this.props.onCheckIsLogo(event) }
					/>
				</div>
			);
		}


		if (selectedClient?.logos && selectedClient?.logos?.length > 1) {
			return (
				<div style={ {
					display: 'flex',
					alignItems: 'center',
					width: '50%',
					justifyContent: 'flex-end',
					gap: 5
				} }>
					<SelectComponent
						buttonWidth={150}
						listOptions={ selectedClient.logos }
						onSelectedOption={ (event: any) => this.props.onSelectedLogo(event) }
						renderOptionLabel={ (option) => option.name }
					/>
					<Button
						iconLeft={ <LuXCircle/> }
						type={ 'danger' }
						onClick={ (event: any) => this.props.onCheckIsLogo(event) }
					/>
				</div>
			);
		}

		return null;
	}

	//</editor-fold>
}