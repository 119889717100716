import React, { CSSProperties, ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import {
	LuX,
	LuPhone,
	LuAlignCenterVertical,
	LuAlignStartVertical,
	LuAlignEndVertical,
	LuMaximize, LuImagePlus, LuMail,
	LuGlobe,
	LuArrowLeftSquare,
	LuArrowRightSquare,
	LuSquare,
	LuAlignCenterHorizontal,
	LuAlignStartHorizontal,
	LuAlignEndHorizontal,
	LuUpload
} from 'react-icons/lu';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import FormLabel from '@/Modules/App/Components/Atom/Form/FormLabel';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { toPng } from 'html-to-image';
import CustomLogoFormComponent from '@/Modules/Client/Components/Form/CustomLogoFormComponent';
import OverviewCustomLogoComponent from '@/Modules/Client/Components/Form/OverviewCustomLogoComponent';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';

interface ComponentProps
{
	client: ClientInterface,
	modalContext: ModalContextType,
	flashMessageContext: FlashMessageContextType,
	onCreateLogo: (formData: any) => void,
}

export interface TextContentCss
{
	label: string | null,
	css: {
		fontSize: number,
		fontWeight: number,
		lineHeight: number,
		textAlign: 'right' | 'center' | 'left',
	}
}

export interface TextContentLogoInterface
{
	name: TextContentCss,
	info: TextContentCss,
	status: TextContentCss,
	address: TextContentCss,
	email: TextContentCss,
	phone: TextContentCss,
	website: TextContentCss,
}

interface ComponentState
{
	isLoadingComponent: boolean,
	formData: {
		logoName: string,
		logoFile: string,
		textContent: TextContentLogoInterface
		textPosition: string,
		logoWidth: number | '',
		logoHeight: number | '',
		logoVerticalAlign: CSSProperties,
		logoPosition: {
			position: 'relative' | 'absolute',
			top: number | '',
			bottom: number | '',
			right: number | '',
			left: number | '',
		},
	},
	textImageAlignCenter: 'top' | 'middle' | 'bottom'
	textWrap: 'right' | 'none' | 'left',
	logoPreviewUrl: string,
	logoStaticUrl: string,
	logoCustomPreviewUrl: string,
	logoLawyer: string,
	isAddLogoModal: boolean,
	isCustomLogo: boolean
}

export default class AddCustomLogoClientFormComponent extends React.Component
	<ComponentProps, ComponentState>
{
	fileInputRef = React.createRef<HTMLInputElement>();
	viewGenerateLogoModal: ModalContextType;
	divImageRef = React.createRef<HTMLDivElement>();
	imageRef = React.createRef<HTMLImageElement>();

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Modal
		this.viewGenerateLogoModal = this.props.modalContext;

		// Bind
		this.onFileChange = this.onFileChange.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onNameChange = this.onNameChange.bind(this);
		this.onCreateLogo = this.onCreateLogo.bind(this);
		this.onClickInputFile = this.onClickInputFile.bind(this);
		this.onSelectedTextPosition = this.onSelectedTextPosition.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.handleGenerateImage = this.handleGenerateImage.bind(this);
		this.initializeTextContent = this.initializeTextContent.bind(this);
		this.updateLogoHeight = this.updateLogoHeight.bind(this);
		this.handleToggleTextWrap = this.handleToggleTextWrap.bind(this);
		this.handleTextImageVerticalAlign = this.handleTextImageVerticalAlign.bind(this);
		this.resetImage = this.resetImage.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<div style={ { width: 1000, height: 700, display: 'grid', gridTemplateColumns: 'auto 400px' } }>
					{ this.builderFormLogoRender() }
					<div style={ {
						position: 'relative',
						width: '100%',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: CssVariableEnum['--color-grey-100'],
						borderBottomRightRadius: 20,
					} }>
						{ this.buildMenuLogo() }
						{ this.logoRender() }
						<div style={ { position: 'absolute', bottom: 20, right: 20 } }>
							<Button
								type="default"
								iconLeft={ <LuImagePlus/> }
								onClick={ this.handleGenerateImage }
							>
								Générer l'image
							</Button>
						</div>
					</div>
				</div>
			</>
		);
	}

	//<editor-fold desc="Init State / ComponentMount... methods" defaultstate="collapsed">

	private initState(): ComponentState
	{
		return {
			isLoadingComponent: true,
			formData: {
				logoName: '',
				logoFile: '',
				textContent: {
					name: {} as TextContentCss,
					info: {} as TextContentCss,
					status: {} as TextContentCss,
					address: {} as TextContentCss,
					email: {} as TextContentCss,
					phone: {} as TextContentCss,
					website: {} as TextContentCss,
				},
				textPosition: 'top',
				logoWidth: 80,
				logoHeight: '',
				logoVerticalAlign: { justifyContent: 'center' },
				logoPosition: {
					position: 'relative',
					top: '',
					bottom: '',
					left: '',
					right: ''
				}
			},
			textWrap: 'none',
			logoPreviewUrl: '',
			logoStaticUrl: '',
			logoCustomPreviewUrl: '',
			logoLawyer: '',
			isAddLogoModal: false,
			isCustomLogo: false,
			textImageAlignCenter: 'top',
		};
	}

	componentDidMount(): void
	{
		// Init text Content Css
		this.initializeTextContent();
	}

	componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState): void
	{
		if (prevState.formData.logoWidth !== this.state.formData.logoWidth) {
			this.updateLogoHeight();
		}
	}

	private initializeTextContent(): void
	{
		const updatedFormData = { ...this.state.formData };

		Object.keys(updatedFormData.textContent).forEach((key) =>
		{
			const textContentEntry = updatedFormData.textContent[key as keyof TextContentLogoInterface];

			if (textContentEntry !== null && typeof textContentEntry === 'object') {
				if (!textContentEntry.label) {
					textContentEntry.label = '';
				}

				if (!textContentEntry.css) {
					textContentEntry.css = { fontSize: 12, fontWeight: 400, lineHeight: 12, textAlign: 'left' };
				} else {
					if (!textContentEntry.css.fontSize) {
						textContentEntry.css.fontSize = 12;
					}
					if (!textContentEntry.css.fontWeight) {
						textContentEntry.css.fontWeight = 400;
					}
					if (!textContentEntry.css.lineHeight) {
						textContentEntry.css.lineHeight = 12;
					}
					if (!textContentEntry.css.textAlign) {
						textContentEntry.css.textAlign = 'left';
					}
				}

				updatedFormData.textContent[key as keyof TextContentLogoInterface] = textContentEntry;
			}
		});

		// Mettre à jour l'état avec les valeurs initialisées
		this.setState({ formData: updatedFormData }, () => this.setState({ isLoadingComponent: false }));
	}

	//</editor-fold>

	//<editor-fold desc="Content Render methods" defaultstate="collapsed">

	private builderFormLogoRender(): ReactElement
	{
		return (
			<form style={ { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '25px' } }
						onSubmit={ this.onCreateLogo }>
				<Input
					label="Nom du logo"
					width={ '100%' }
					type="text"
					name="logoName"
					value={ this.state.formData.logoName || '' }
					onChange={ (event: any) => this.onNameChange(event) }
				/>

				<div style={ { display: 'grid', gridTemplateColumns: '330px 200px', gap: 20 } }>
					{ !this.state.logoPreviewUrl && (
						<div
							onDragOver={ this.onDragOver }
							onDrop={ this.onDrop }
							style={ {
								border: `1px dashed ${ CssVariableEnum['--color-blue-light-300'] }`,
								borderRadius: 15,
								padding: '20px',
								textAlign: 'center',
								display: 'flex',
								gap: 10,
								flexDirection: 'column',
								alignItems: 'center',
								cursor: 'pointer',
								backgroundColor: CssVariableEnum['--color-blue-light-100'],
								fontSize: 12,
								lineHeight: '14px'
							} }
							onClick={ this.onClickInputFile }
						>
							<LuUpload size={ 20 } color={ CssVariableEnum['--color-blue-light-600'] }/>
							Glissez-déposez le logo du client ici ou cliquez pour sélectionner un logo
							<input
								type="file"
								id="logoFile"
								ref={ this.fileInputRef }
								style={ { display: 'none' } }
								onChange={ (event) => this.onFileChange(event.nativeEvent) }
							/>
						</div>
					) }
					{ this.state.logoPreviewUrl && (
						<>
							<div>
								<FormLabel content={ 'Aperçu du logo' }/>
								<div style={ { display: 'flex' } }>
									<div style={ { border: '1px dashed #ccc', width: '140px' } }>
										<img
											src={ this.state.logoPreviewUrl }
											alt="Aperçu du logo"
											style={ { maxWidth: '100%', maxHeight: '200px', padding: '5px' } }
										/>
										<Button
											style={ { position: 'absolute' } }
											type={ 'default' }
											iconLeft={ <LuX/> }
											onClick={ (event: any) => this.resetImage(event) }
										/>
									</div>
								</div>
							</div>
						</>
					) }
					<div style={ { display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 15 } }>
						<SelectComponent
							key={ this.state.logoLawyer }
							buttonWidth={ 200 }
							label={ 'Jumeler un logo (Notaires)' }
							listOptions={ this.buildStaticLogoList() }
							onSelectedOption={ (event: any) => this.onSelectedStaticLogo(event) }
							renderOptionLabel={ (option) => option.label }
						/>
						<Checkbox
							label={ 'logo avocats' }
							name={ 'logo-avocat' }
							options={ {
								rowLeftCheckbox: true
							} }
							isChecked={ Boolean(this.state.logoLawyer) }
							onCheckedChange={ (event: any) => this.handleLogoLawyerChange(event) }
						/>
					</div>
				</div>

				{ !this.state.isLoadingComponent &&
          <CustomLogoFormComponent
            textContent={ this.state.formData.textContent }
            onChange={ this.onChangeTextContentLogo.bind(this) }
            onChangeTextContentCss={ this.handleTextContentCss.bind(this) }
          />
				}
			</form>
		);
	}

	private buildStaticLogoList(): any[]
	{
		return [
			{ label: 'Aucun', imageUrl: '' },
			{ label: 'Notaire', imageUrl: '/img/static/logo-notaire.jpeg' },
			{ label: 'Notaires', imageUrl: '/img/static/logo-notaires.jpeg' },
			{ label: 'Notaires de France', imageUrl: '/img/static/logo-notaire-de-france.jpeg' }
		];
	}

	private buildMenuLogo(): ReactElement
	{
		return (
			<div style={ {
				position: 'absolute',
				top: 8,
				backgroundColor: CssVariableEnum['--color-white'],
				width: '95%',
				margin: '0 5px',
				padding: 10,
				borderRadius: 5
			} }>
				<div style={ { display: 'flex', gap: 20, flexWrap: 'wrap' } }>
					<div style={ { display: 'flex', flexDirection: 'column' } }>
						<div style={ { fontSize: 10, color: CssVariableEnum['--color-grey-400'], marginBottom: 5 } }>
							Taille
						</div>
						<div style={ { display: 'flex', alignItems: 'center', gap: 2 } }>
							<LuMaximize/>
							<Input
								type="number"
								name={ 'logo-width' }
								width={ '70px' }
								value={ this.state.formData.logoWidth || '' }
								onChange={ (event: any) => this.handleLogoWidthChange(event) }
							/>
						</div>
					</div>
					<div style={ { display: 'flex', flexDirection: 'column' } }>
						<div style={ { fontSize: 10, color: CssVariableEnum['--color-grey-400'], marginBottom: 5 } }>
							Alignement
						</div>
						<div style={ { display: 'flex', alignItems: 'center', gap: 2 } }>
							<Button
								style={ { margin: 0 } }
								type={ 'default' }
								iconLeft={ <LuAlignStartVertical/> }
								onClick={ () => this.handleVerticalAlignLogo('flex-start') }
							/>
							<Button
								type={ 'default' }
								iconLeft={ <LuAlignCenterVertical/> }
								onClick={ () => this.handleVerticalAlignLogo('center') }
							/>
							<Button
								type={ 'default' }
								iconLeft={ <LuAlignEndVertical/> }
								onClick={ () => this.handleVerticalAlignLogo('flex-end') }
							/>
							<Button
								type={ 'default' }
								iconLeft={ <LuAlignStartHorizontal/> }
								onClick={ () => this.handleTextImageVerticalAlign('top') }
							/>
							<Button
								type={ 'default' }
								iconLeft={ <LuAlignCenterHorizontal/> }
								onClick={ () => this.handleTextImageVerticalAlign('middle') }
							/>
							<Button
								type={ 'default' }
								iconLeft={ <LuAlignEndHorizontal/> }
								onClick={ () => this.handleTextImageVerticalAlign('bottom') }
							/>
						</div>
					</div>
					<div style={ { display: 'flex', flexDirection: 'column' } }>
						<div style={ { fontSize: 10, color: CssVariableEnum['--color-grey-400'], marginBottom: 5 } }>
							Position Image
						</div>
						<div style={ { display: 'flex', alignItems: 'center', gap: 2 } }>
							<Button
								style={ { margin: 0 } }
								type="default"
								iconLeft={ <LuArrowLeftSquare/> }
								onClick={ () => this.handleToggleTextWrap('left') }
							/>
							<Button
								type="default"
								iconLeft={ <LuSquare/> }
								onClick={ () => this.handleToggleTextWrap('none') }
							/>
							<Button
								type="default"
								iconLeft={ <LuArrowRightSquare/> }
								onClick={ () => this.handleToggleTextWrap('right') }
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private logoRender(): ReactElement
	{
		// Const
		const { isLoadingComponent } = this.state;
		const {
			textContent,
			logoVerticalAlign,
			logoWidth,
			logoHeight,
		} = this.state.formData;

		return (
			<div style={ {
				border: '1px dashed #ccc',
				width: 288,
				minHeight: (logoHeight) ? logoHeight : '',
				backgroundColor: CssVariableEnum['--color-white'],
				overflow: 'hidden'
			} }>
				<div
					ref={ this.divImageRef }
					style={ {
						padding: '5px 3px 0px 3px',
						position: 'relative',
						...(!this.state.logoLawyer && !this.state.logoStaticUrl && !this.state.logoPreviewUrl) && { minHeight: 80 },
						backgroundColor: CssVariableEnum['--color-white'],
						display: 'block',
						...(this.state.textWrap === 'none' && { display: 'flex', flexDirection: 'column' }),
					} }>
					<div style={ {
						float: (this.state.textWrap) ? this.state.textWrap : 'none',
						marginRight: (this.state.textWrap !== 'none') ? '10px' : '0',
						width: (this.state.textWrap !== 'none') ? logoWidth : '100%',
						...(this.state.textWrap === 'none' && { ...logoVerticalAlign, display: 'flex' }),
					} }>
						{ !this.state.logoLawyer && this.state.logoPreviewUrl &&
              <img
                ref={ this.imageRef }
                src={ this.state.logoPreviewUrl }
                alt="Aperçu du logo"
                style={ {
									maxWidth: 288,
									width: logoWidth,
									verticalAlign: this.state.textImageAlignCenter,
									padding: '3px',
									objectFit: 'contain'
								} }
              />
						}
						{ (this.state.logoStaticUrl || this.state.logoLawyer) &&
              <img
                src={ this.state.logoStaticUrl || this.state.logoLawyer }
                alt={ 'logo-static-notaires' }
                style={ {
									maxWidth: 288,
									width: logoWidth as number * 0.7,
									verticalAlign: this.state.textImageAlignCenter,
									padding: '3px',
									objectFit: 'contain'
								} }
              />
						}
					</div>
					{ (!isLoadingComponent && textContent) &&
            <div>
							{ textContent.info?.label &&
                <div style={ {
									fontSize: textContent.info.css?.fontSize,
									fontWeight: textContent.info.css?.fontWeight,
									textAlign: textContent.info.css?.textAlign,
								} }>
									{ textContent.info?.label }
                </div>
							}
							{ textContent.name?.label &&
                <div style={ {
									fontSize: textContent.name.css?.fontSize,
									fontWeight: textContent.name.css?.fontWeight,
									lineHeight: `${ textContent.name.css?.lineHeight }px`,
									textAlign: textContent.name.css?.textAlign,
								} }>
									{ textContent.name?.label.split('\n').map((line, index) => (
										<React.Fragment key={ index }>
											{ line }
											<br/>
										</React.Fragment>
									)) }
                </div>
							}
							{ textContent.status?.label &&
                <div style={ {
									fontSize: textContent.status.css?.fontSize,
									fontWeight: textContent.status.css?.fontWeight,
									textAlign: textContent.status.css?.textAlign,
								} }>
									{ textContent.status?.label }
                </div>
							}
              <div>
								{ textContent.address?.label &&
                  <div style={ {
										fontSize: textContent.address.css?.fontSize,
										fontWeight: textContent.address.css?.fontWeight,
										textAlign: textContent.address.css?.textAlign,
										lineHeight: `${ textContent.address.css?.lineHeight }px`,
										whiteSpace: 'pre-wrap',
									} }>
										{ textContent.address?.label.split('\n').map((line, index) => (
											<React.Fragment key={ index }>
												{ line }
												<br/>
											</React.Fragment>
										)) }
                  </div>
								}
                <div style={ { textAlign: 'center', lineHeight: `${textContent.phone.css?.lineHeight}px` } }>
									{ textContent.phone?.label &&
                    <div style={ {
											fontSize: textContent.phone.css?.fontSize,
											fontWeight: textContent.phone.css?.fontWeight,
											textAlign: textContent.phone.css?.textAlign,
											display: 'inline-flex',
											alignItems: 'center',
											...textContent.email?.label && { marginRight: 3 },
										} }>
                      <LuPhone style={ { alignSelf: 'center', marginRight: 1 } }/>
											{ textContent.phone?.label }
											{ (textContent.email?.label) && ' - ' }
                    </div>
									}
									{ textContent.email.label &&
                    <div style={ {
											fontSize: textContent.email.css?.fontSize,
											fontWeight: textContent.email.css?.fontWeight,
											textAlign: textContent.email.css?.textAlign,
											display: 'inline-flex',
											alignItems: 'center',
											...textContent.website?.label && { marginRight: 3 },
										} }>
                      <LuMail style={ { alignSelf: 'center', marginRight: 1 } }/>
											{ textContent.email?.label }
											{ (textContent.website?.label) && ' - ' }
                    </div>
									}
									{ textContent.website?.label &&
                    <div style={ {
											fontSize: textContent.website.css?.fontSize,
											fontWeight: textContent.website.css?.fontWeight,
											textAlign: textContent.website.css?.textAlign,
											display: 'inline-flex',
											alignItems: 'center'
										} }>
                      <LuGlobe style={ { alignSelf: 'center', marginRight: 1 } }/>
											{ textContent.website?.label }
                    </div>
									}
                </div>
              </div>
            </div>
					}
				</div>
			</div>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Handle methods" defaultstate="collapsed">

	private handleGenerateImage(): void
	{
		if(!this.state.formData.logoName) {
			this.props.modalContext.errorMessage('Merci de donner un nom au logo');
			return;
		}

		if (this.divImageRef.current) {
			toPng(this.divImageRef.current)
				.then((dataUrl) =>
				{
					const link = document.createElement('a');
					this.setState(
						{ logoCustomPreviewUrl: dataUrl },
						() => this.viewGenerateLogoModal.content(
							'Aperçu de l\'image',
							<OverviewCustomLogoComponent
								options={ {
									previewUrl: this.state.logoCustomPreviewUrl,
									logoWidth: 288,
								} }
								onCancel={ () => this.viewGenerateLogoModal.isOpen(false) }
								onCreate={ (event: any) => this.onCreateLogo(event) }
							/>
						)
					);
				})
				.catch((error) =>
				{
					console.error('Failed to generate image', error);
				});
		}
	}

	private onChangeTextContentLogo(event: any): void
	{
		const { name, value } = event.target;

		this.setState((prevState: ComponentState) =>
		{
			const updatedTextContent = {
				...prevState.formData.textContent,
				[name]: {
					...prevState.formData.textContent[name as keyof TextContentLogoInterface],
					label: value
				}
			};

			return {
				formData: {
					...prevState.formData,
					textContent: updatedTextContent
				}
			};
		});
	}

	private handleLogoLawyerChange(event: any): void {
		const isChecked = event.target.checked;

		if (!isChecked) {
			this.setState({
				logoLawyer: '',
				logoStaticUrl: ''
			});
		} else if (isChecked) {
			this.setState({
				logoStaticUrl: '',
				logoLawyer: '/img/static/les-avocats.jpg',
				logoPreviewUrl: ''
			});
		}
	}


	private onSelectedStaticLogo(selectedOption: any): void
	{
		this.setState({
			logoStaticUrl: selectedOption.imageUrl,
			logoLawyer: '',
		});
	}

	private handleLogoWidthChange(event: any): void
	{
		const newWidth: number = Number(event.target.value);
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				logoWidth: newWidth
			}
		}));
	}

	private updateLogoHeight(): void
	{
		if (this.imageRef.current) {
			const logoHeight: number = this.imageRef.current.clientHeight;
			this.setState((prevState: any) => ({
				formData: {
					...prevState.formData,
					logoHeight: logoHeight + 20
				}
			}));
		}
	}

	private handleVerticalAlignLogo(align: string): void
	{
		const newAlign: CSSProperties = { justifyContent: align };
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				logoVerticalAlign: newAlign
			},
			textWrap: 'none',
		}));
	}

	private handleToggleTextWrap(position: 'right' | 'left' | 'none'): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				logoVerticalAlign: { justifyContent: '' }
			},
			textWrap: position,
		}));
	}

	private handleTextImageVerticalAlign(position: 'top' | 'middle' | 'bottom'): void
	{
		this.setState({
			textImageAlignCenter: position,
		});
	}

	private handleTextContentCss(event: any, name: string, action: string): void
	{
		const { value } = event.target;

		this.setState((prevState: ComponentState) =>
		{
			const updatedFormData = { ...prevState.formData };
			const textContentEntry = updatedFormData.textContent[name as keyof TextContentLogoInterface];

			if (textContentEntry && typeof textContentEntry === 'object' && textContentEntry.css) {
				switch (action) {
					case 'fontWeight':
						textContentEntry.css.fontWeight = textContentEntry.css.fontWeight === 400 ? 700 : 400;
						break;
					case 'fontSize':
						textContentEntry.css.fontSize = parseInt(value, 10) || 12;
						break;
					case 'lineHeight':
						textContentEntry.css.lineHeight = parseInt(value, 10) || 12;
						break;
					case 'textAlignLeft':
						textContentEntry.css.textAlign = 'left';
						break;
					case 'textAlignCenter':
						textContentEntry.css.textAlign = 'center';
						break;
					case 'textAlignRight':
						textContentEntry.css.textAlign = 'right';
						break;

					default:
						return null;
				}
				return { formData: updatedFormData };
			}

			return null;
		});
	}

	private onDragOver(event: any): void
	{
		event.preventDefault();
	}

	private onDrop(event: any): void
	{
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files && files[0]) {
			const file = files[0];
			this.setState({
				formData: { ...this.state.formData, logoFile: file },
				logoPreviewUrl: URL.createObjectURL(file),
			});
		}
	}

	private onNameChange(event: any): void
	{
		this.setState({ formData: { ...this.state.formData, logoName: event.target.value } });
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private onFileChange(event: any): void
	{
		event.preventDefault();
		if (event.target.files && event.target.files[0]) {
			this.setState({
				formData: { ...this.state.formData, logoFile: event.target.files[0] },
				logoPreviewUrl: URL.createObjectURL(event.target.files[0])
			});
		}
	}

	private onSelectedTextPosition(event: any): void
	{
		this.setState((prevState: any) =>
		{
			return {
				formData: {
					...prevState.formData,
					textPosition: event.label
				}
			};
		});
	}

	private onClickInputFile(): void
	{
		this.fileInputRef.current?.click();
	}

	private onCreateLogo(event: any): void
	{
		event.preventDefault();
		this.viewGenerateLogoModal.isOpen(false);
		this.props.modalContext.isLoading(true);

		fetch(this.state.logoCustomPreviewUrl)
			.then(res => res.blob())
			.then(blob =>
			{
				const newFile = new File([blob], 'customLogo.png', { type: 'image/png' });
				const formData = new FormData();
				formData.append('logoFile', newFile);
				formData.append('logoName', this.state.formData.logoName);

				// Props FormData
				this.props.onCreateLogo(formData);

				this.props.modalContext.isOpen(false);
				this.props.modalContext.errorMessage('');

				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'Mise à jour réussie',
					'Votre logo a été ajouté avec succès',
					'success'
				);

				this.props.modalContext.isOpen(false);
				this.resetForm(event);
			});
	}

	private resetImage(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		if (this.state.logoPreviewUrl) {
			URL.revokeObjectURL(this.state.logoPreviewUrl);
		}

		this.setState({
			logoPreviewUrl: '',
		});
	}

	private resetForm(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		if (this.state.logoPreviewUrl) {
			URL.revokeObjectURL(this.state.logoPreviewUrl);
		}

		this.setState({
			formData: {
				logoName: '',
				logoFile: '',
				textContent: {
					name: {} as TextContentCss,
					info: {} as TextContentCss,
					status: {} as TextContentCss,
					address: {} as TextContentCss,
					email: {} as TextContentCss,
					phone: {} as TextContentCss,
					website: {} as TextContentCss,
				},
				textPosition: '',
				logoWidth: 80,
				logoHeight: '',
				logoVerticalAlign: { justifyContent: 'center' },
				logoPosition: {
					position: 'relative',
					top: '',
					bottom: '',
					left: '',
					right: ''
				}
			},
			logoPreviewUrl: '',
		});
	}

	private resetCustomLogo(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState((prevState: any) =>
		{
			return {
				formData: {
					...prevState.formData
				},
				logoCustomPreviewUrl: ''
			};
		});
	}

	//</editor-fold>

}