import React, { ReactElement } from 'react';
import { LegalNoticePriceStyle } from '@/Modules/LegalNotice/Style/LegalNoticePriceStyle';
import { ViewLegalNoticeState } from '@/Modules/LegalNotice/Admin/View/CreateLegalNoticeView';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { stripHtmlTags } from '@/Utils/StripHtmlTags';
import Skeleton from 'react-loading-skeleton';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';

interface ComponentProps
{
  legalNotice: ViewLegalNoticeState,
  selectedDepartment: DepartmentInterface,
  selectedNewspaper: NewspaperInterface,
  selectedCategory: FormBuilderCategoryInterface,
  validateLegalNotice: (event: any) => void,
  isHeaderCharacterCount?: number,
  isFormComplete: boolean,
  isCompactRender: boolean
  isAdmin: boolean,
}

interface ComponentState
{
  isOpen: boolean,
  isFixedPrice: boolean,
  annexPrice: number,
  printPrice: number,
  shippingCostPrice: number,
  bodaccPrice: number,
  externPrice: number,
  isPriceLoaded: boolean
}

export default class LegalNoticePriceFormComponent extends React.Component<ComponentProps, ComponentState>
{
	legalNoticePriceService: LegalNoticePriceService;
  apiPublicService: ApiPublicService;

	constructor(props: any)
	{
		super(props);

		// Service
		this.legalNoticePriceService = new LegalNoticePriceService(this.props.isAdmin);
    this.apiPublicService = new ApiPublicService();

    // State
    this.state = {
      isOpen: !this.props.isCompactRender,
      isFixedPrice: false,
      annexPrice: 0,
      printPrice: 0,
      shippingCostPrice: 0,
      bodaccPrice: 0,
      externPrice: 0,
      isPriceLoaded: false
    };
  }

	render(): ReactElement
	{
		return (
			<>
				<div style={ LegalNoticePriceStyle.mainContainerStyle() }>
					<div style={ LegalNoticePriceStyle.bodyContainerStyle() }>
						<div style={ { flexGrow: 1 } }>
							{ this.compactPriceRender() }
						</div>
					</div>
				</div>
			</>
		);
	}

	//<editor-fold desc="Render methods" defaultstate="collapsed">

	private compactPriceRender(): ReactElement
	{
		const calculatedPrice = this.calculatedPrice();

		return (
			<>
				{ this.subTotalRender(calculatedPrice.price) }
			</>
		);
	}

	private subTotalRender(price: number): ReactElement
	{
		const goldenRatio = 1.618;
		const littleGreySize = `${ (24 / goldenRatio / goldenRatio) }px`;

		return (
			<>
				<div style={ LegalNoticePriceStyle.compactContainerStyle() }>
					{ !this.state.isFixedPrice && !this.state.isPriceLoaded &&
            <>
              <div>
                <div style={ { lineHeight: 1, fontSize: littleGreySize, color: CssVariableEnum['--color-grey-500'] } }>
                  Nombre de caractères
                </div>
                <div style={{ textAlign: 'right', fontSize: 24, lineHeight: 'normal'}}>{ this.calculatedPrice().nbCharacter }</div>
              </div>
              <div style={ { height: 36, width: 1, backgroundColor: CssVariableEnum['--color-grey-900'] } }></div>
            </>
					}
					<div style={ {
						display: 'flex',
						alignItems: 'flex-end',
						justifyContent: 'flex-end',
						flexDirection: 'column',
						minWidth: 150,
					} }>
						<div style={ { lineHeight: 1, fontSize: littleGreySize, color: CssVariableEnum['--color-grey-500'] } }>
							Total <span>(HT)</span>
						</div>
						<div style={ { fontSize: 24, fontWeight: 600, lineHeight: 'normal' } }>
							{ (!this.state.isPriceLoaded)
								? (price % 1 === 0) ? `${ price }.00 €` : `${ price.toFixed(2) } €`
								: <Skeleton height={ 26 } width={ 150 }/>
							}
						</div>
					</div>
				</div>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="State methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.updateState();
	}

	private async updateState(partialState: Partial<ComponentState> = {}): Promise<void>
	{
		this.setState({ isPriceLoaded: true });

    // Init vars price
    const isFixedPrice = this.legalNoticePriceService.isFixedPrice(
      this.props.selectedDepartment,
      this.props.selectedCategory
    );
    const annexPrice = parseFloat(
      this.legalNoticePriceService.getAnnexPrice(
        this.props.selectedDepartment,
        this.props.selectedCategory,
        isFixedPrice
      ).toFixed(2)
    );
    const printPrice = await this.handlePrintPrice();
    const getExternPrice = await this.legalNoticePriceService.getExternPrice();

    // Calculated
    const calculatedShippingCostPrice = await this.legalNoticePriceService.getShippingCostPrice(this.props.legalNotice.numberOfCopies);
    const calculatedPrintPrice = printPrice * this.props.legalNotice.numberOfCopies;
    const bodaccPrice = this.props.legalNotice.option.isBodacc ? await this.legalNoticePriceService.getBodacPrice() : 0;
    const externPrice = (
      (this.props.selectedNewspaper?.editorial && this.props.selectedNewspaper.editorial !== 'heraultjuridique') &&
      (this.props.selectedNewspaper?.name && this.props.selectedNewspaper.name !== 'L\'Echo du Languedoc')
    ) ? getExternPrice : 0;

    this.setState((prevState: any) => ({
      ...prevState,
      ...partialState,
      isFixedPrice,
      annexPrice,
      shippingCostPrice: calculatedShippingCostPrice,
      printPrice: calculatedPrintPrice,
      bodaccPrice,
      externPrice,
      isOpen: partialState.isOpen ?? prevState.isOpen,
      isPriceLoaded: false
    }));
  }

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

  private async handlePrintPrice(): Promise<number>
  {
    if (this.props.selectedNewspaper?.editorial && this.props.selectedNewspaper.editorial !== 'heraultjuridique') {
      const getNewspaper = await this.apiPublicService.getNewspaper(
        this.props.selectedNewspaper.id,
      );

      return getNewspaper.receiptPrice;
    } else {
      return await this.legalNoticePriceService.getPrintPrice();
    }
  }

  private calculatedPrice(): { price: number, nbCharacter?: number }
  {
    let totalPrice = this.state.isFixedPrice
      ? this.state.annexPrice
      : this.state.annexPrice * (
        stripHtmlTags(this.props.legalNotice.content).length
      + stripHtmlTags(this.props.legalNotice.title).length
      + stripHtmlTags(this.props.legalNotice.signature).length
      + (this.props.isHeaderCharacterCount || 0)
    );

    // Additional Price for PAPER
    if (this.props.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value) {
      totalPrice += this.state.printPrice;
      totalPrice += this.state.shippingCostPrice;
    }

    if (this.props.legalNotice.option.isBodacc) {
      totalPrice += this.state.bodaccPrice;
    }

    totalPrice += this.state.externPrice;

    return {
      price: parseFloat((totalPrice).toFixed(2)),
      nbCharacter: this.state.isFixedPrice
        ? undefined
        : (
          stripHtmlTags(this.props.legalNotice.content).length
          + stripHtmlTags(this.props.legalNotice.title).length
          + stripHtmlTags(this.props.legalNotice.signature).length
          + (this.props.isHeaderCharacterCount || 0)
        )
    };
  }

	//</editor-fold>
}
