import React, { ReactElement } from 'react';
import { ApiAdminFormBuilderSectionService } from '@/Service/Admin/ApiAdminFormBuilderSectionService';
import { FormBuilderSectionInterface } from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';
import FormBuilderSectionSkeleton from '@/Modules/App/Components/Skeleton/FormBuilderSectionSkeleton';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import { LuPlus } from 'react-icons/lu';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import FormBuilderStyle from '@/Modules/App/Style/Components/FormBuilderStyle';
import FormBuilderSectionComponent from '@/Modules/FormBuilder/Components/FormBuilderSectionComponent';
import { ApiAdminFormBuilderInputService } from '@/Service/Admin/ApiAdminFormBuilderInputService';
import { FormBuilderInputsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderInputsInterface';
import { ApiAdminFormBuilderInputOptionService } from '@/Service/Admin/ApiAdminFormBuilderInputOptionService';
import { FormBuilderOptionsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderOptionsInterface';

interface ComponentProps
{
	flashMessageContext: FlashMessageContextType,
	modalContext: ModalContextType,
}

interface ComponentState
{
	isLoading: boolean,
	sectionList: FormBuilderSectionInterface[],
	isSectionMultiple: boolean
	openSectionId: string | number | null,
	sectionTitle: string | null,
	subSectionTitle: string | null,
}

export default class FormBuilderSectionView extends React.Component<ComponentProps, ComponentState>
{
	formBuilderSectionService: ApiAdminFormBuilderSectionService;
	formBuilderInputService: ApiAdminFormBuilderInputService;
	formBuilderInputOptionService: ApiAdminFormBuilderInputOptionService;

	// <editor-fold desc="Ctr" defaultstate="collapsed">

	constructor(props: any)
	{
		super(props);

		this.state = this.initState();

		// Services
		this.formBuilderSectionService = new ApiAdminFormBuilderSectionService();
		this.formBuilderInputService = new ApiAdminFormBuilderInputService();
		this.formBuilderInputOptionService = new ApiAdminFormBuilderInputOptionService();

		// Bind
		this.refreshSections = this.refreshSections.bind(this);
		this.onCheckIsMultiple = this.onCheckIsMultiple.bind(this);
		this.setLoading = this.setLoading.bind(this);
	}

	// </editor-fold>

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <div style={ { marginTop: 25 } }><FormBuilderSectionSkeleton count={ this.state.sectionList.length }/>
			</div>;
		}

		return (
			<>
				<div style={ FormBuilderStyle.view().actionButtonHead }>
					<Button
						label={ 'Ajouter une section' }
						type={ 'default-blue' }
						iconLeft={ <LuPlus/> }
						onClick={ (event: any) => this.addNewSectionModal(event) }
					/>
				</div>
				<div style={ FormBuilderStyle.view().sectionContainerList }>
					{ (this.state.isLoading)
						? <FormBuilderSectionSkeleton count={ this.state.sectionList.length }/>
						: this.state.sectionList.map((section: FormBuilderSectionInterface) => (
							<FormBuilderSectionComponent
								key={ section.id.toString() }
								isLoading={ this.state.isLoading }
								flashMessageContext={ this.props.flashMessageContext }
								modalContext={ this.props.modalContext }
								refreshSections={ this.refreshSections.bind(this) }
								refreshInputs={ this.refreshSectionInputs }
								section={ section }
								isOpen={ this.state.openSectionId === section.id }
								onCreateSection={ this.onCreateSection.bind(this) }
								onUpdateSection={ this.handleUpdateSection.bind(this) }
								onDeleteSection={ this.onDeleteSection.bind(this) }
								onCreateSubSection={ this.onCreateSubSection.bind(this) }
								onCreateInput={ this.onCreateInput.bind(this) }
								onUpdateInput={ this.onEditInput.bind(this) }
								onDeleteInput={ this.onDeleteInput.bind(this) }
								onCreateInputOption={ this.onCreateInputOption.bind(this) }
								onEditInputOption={ this.onEditInputOption.bind(this) }
								onDeleteInputOption={ this.onDeleteInputOption.bind(this) }
							/>
						))
					}
				</div>
			</>
		);
	}

	// <editor-fold desc="State / Component Mount" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.refreshSections();
	}

	componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState): void
	{
		if (prevState.isSectionMultiple !== this.state.isSectionMultiple) {
			this.props.modalContext.isOpen(false);
			this.addNewSectionModal();
		}
	}

	private initState(): ComponentState
	{
		return {
			isLoading: true,
			sectionList: [],
			isSectionMultiple: false,
			openSectionId: null,
			sectionTitle: '',
			subSectionTitle: '',
		};
	}

	// </editor-fold>

	// <editor-fold desc="Refresh Methods" defaultstate="collapsed">

	private async refreshSections(sectionId?: number): Promise<FormBuilderSectionInterface[] | FormBuilderSectionInterface | undefined>
	{
		try {
			this.setState({ isLoading: true });

			if (sectionId) {
				const sectionShow = await this.formBuilderSectionService.show(sectionId);

				if (sectionShow) {
					this.setState({ isLoading: false });
					return sectionShow;
				}
			}

			const sectionList = await this.formBuilderSectionService.list();

			const filteredSections = sectionList.filter((section: FormBuilderSectionInterface) => section.parentId === null);
			this.setState({ sectionList: filteredSections, isLoading: false });

			if (sectionList && !sectionId) {
				return sectionList;
			}

		} catch (error) {
			console.error('Error fetching form builder sections:', error);
		}
	}


	private async refreshSectionInputs(sectionId: number): Promise<any>
	{
		try {
			return await this.formBuilderInputService.list(sectionId);

		} catch (error: any) {
			console.error('Error fetching formBuilder section inputs:', error);
		}
	}

	// </editor-fold>

	// <editor-fold desc="Section Content" defaultstate="collapsed">

	private addNewSectionModal(event?: any): void
	{
		if (event) {
			event.stopPropagation();
		}

		this.props.modalContext.content(
			'Ajouter une nouvelle section',
			<div style={ {
				height: '100%',
				width: '100%',
				padding: 20,
				display: 'flex',
				flexDirection: 'column',
				gap: 20,
				alignItems: 'flex-end'
			} }>
				<Checkbox
					options={ {
						rowRightCheckbox: true
					} }
					label={ 'Section multiple' }
					name={ 'multiple-section' }
					onCheckedChange={ (event: any) => this.onCheckIsMultiple(event.target.checked) }
					isChecked={ this.state.isSectionMultiple }
				/>

				<Input
					label={ 'Titre de la section' }
					style={ { width: '100%' } }
					type={ 'text' }
					placeholder={ 'Titre de section' }
					name={ 'input-new-section' }
					onChange={ (event: any) => this.setState({ sectionTitle: event.target.value }) }
				/>

				{ this.state.isSectionMultiple &&
          <Input
            label={ 'Titre de la sous-section' }
            style={ { width: '100%' } }
            type={ 'text' }
            placeholder={ 'Titre de sous-section' }
            name={ 'input-new-section' }
            onChange={ (event: any) => this.setState({ subSectionTitle: event.target.value }) }
          />
				}

				<Button
					label={ 'Créer' }
					type={ 'default-blue' }
					onClick={ () => this.onCreateSection() }
				/>
			</div>
		);
	}

	// </editor-fold>

	// <editor-fold desc="CRUD section methods" defaultstate="collapsed">

	private async onCreateSection(): Promise<void>
	{
		try {
			// Loading
			this.setLoading(true);
			this.props.modalContext.isOpen(false);

			// API Create section
			const response = await this.formBuilderSectionService.create(
				{
					title: (this.state.sectionTitle) ? this.state.sectionTitle : '',
					status: PublishStateEnum.DRAFT.value,
					isDuplicable: false,
					isRequired: false,
					isLocked: false,
				}
			);

			if (this.state.isSectionMultiple && this.state.subSectionTitle) {
				const newSubSection = await this.onCreateSubSection(response.id, {
					subTitle: this.state.subSectionTitle,
					sectionParentTypeOption: null,
					isDuplicable: false,
				});

				// Handle error
				if (newSubSection.errorMessage) {
					this.props.modalContext.errorMessage(newSubSection.errorMessage);
					this.props.modalContext.isOpen(true);
					this.setLoading(false);
					return;
				}
			}

			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);
				this.setLoading(false);
				return;
			}

			// Refresh Section
			await this.refreshSections();

			this.props.modalContext.errorMessage('');
			this.props.flashMessageContext.flashMessage(
				'Section créée',
				`Votre nouvelle section ${ this.state.sectionTitle } a été créée`,
				'success'
			);

		} catch (error: any) {
			console.log('Something wrong with create Section', error.errorMessage);
		}
	}

	private async onDeleteSection(sectionId: number): Promise<void>
	{
		try {
			// Loading
			this.setLoading(true);

			// API Delete section
			const response = await this.formBuilderSectionService.delete(sectionId);

			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);

				this.setLoading(false);
				// FlashMessage
				this.props.flashMessageContext.flashMessage(
					'Suppression impossible',
					`Une erreur est survenue lors de la demande de suppression de la section`,
					'error'
				);

				return;
			}

			// Refresh Section
			await this.refreshSections();

			// FlashMessage
			this.props.flashMessageContext.flashMessage(
				'Section supprimée',
				`Votre section a été supprimée`,
				'success'
			);

		} catch (error: any) {
			console.log('Something wrong with delete Section', error.errorMessage);
		}
	}

	private async handleUpdateSection(updatedSection: FormBuilderSectionInterface): Promise<void>
	{
		try {
			// Loading
			this.setLoading(true);

			// API Edit Section
			const response = await this.formBuilderSectionService.edit(updatedSection, updatedSection.id);
			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);
				// Loading
				this.setLoading(false);
				return;
			}

			// Refresh Section
			await this.refreshSections();
			this.props.modalContext.isLoading(false);
			this.props.flashMessageContext.flashMessage(
				'Mise à jour réussie',
				`Votre section ${ updatedSection.title } a bien été mise à jour`,
				'success'
			);

		} catch (error: any) {
			console.log('Something wrong with edit Section', error.errorMessage);
		}
	}


	// </editor-fold>

	// <editor-fold desc="CRUD subSection methods" defaultstate="collapsed">

	private async onCreateSubSection(parentSectionId: number, formBuilderSection: Partial<FormBuilderSectionInterface>): Promise<any>
	{
		try {
			// Loading
			this.setLoading(true);

			// API Create section
			const response = await this.formBuilderSectionService.createSubSection(parentSectionId, formBuilderSection);

			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);
				// Loading
				this.setLoading(false);
				return;
			}

			// Refresh Section
			await this.refreshSections();

			this.props.modalContext.errorMessage('');
			this.props.flashMessageContext.flashMessage(
				'Section créée',
				`Votre nouvelle section ${ formBuilderSection.title } a été créée`,
				'success'
			);

		} catch (error: any) {
			console.log('Something wrong with create Section', error.errorMessage);
		}
	}

	// </editor-fold>

	// <editor-fold desc="CRUD Input methods" defaultstate="collapsed">

	private async onCreateInput(inputData: Partial<FormBuilderInputsInterface>, sectionId: number): Promise<FormBuilderInputsInterface | null | undefined>
	{
		try {
			// Loading
			this.setState({ isLoading: true });

			// Close modal
			this.props.modalContext.isOpen(false);

			// API Create section
			const response = await this.formBuilderInputService.create(inputData, sectionId);

			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);
				return;
			}

			// Refresh Section
			await this.refreshSections();

			this.props.modalContext.errorMessage('');
			this.props.flashMessageContext.flashMessage(
				'Champ créé',
				`Votre nouveau champ ${ inputData.label } a été créée`,
				'success'
			);

			return response;

		} catch (error: any) {
			console.log('Something wrong with create Input', error.errorMessage);
		}
	}

	private async onEditInput(editInputData: Partial<FormBuilderInputsInterface>, inputId: number): Promise<any>
	{
		try {
			// Loading
			this.setState({ isLoading: true });

			// Close modal
			this.props.modalContext.isOpen(false);

			// API Create section
			const response = await this.formBuilderInputService.edit(editInputData, inputId);

			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);
				return;
			}

			// Refresh Section
			await this.refreshSections();

			this.props.modalContext.errorMessage('');
			this.props.flashMessageContext.flashMessage(
				'Champ créé',
				`Votre nouveau champ ${ response.label } a été créée`,
				'success'
			);

			return response;

		} catch (error: any) {
			console.log('Something wrong with create Input', error.errorMessage);
		}
	}

	private async onDeleteInput(inputId: number, sectionId: number): Promise<any>
	{
		try {
			// Loading
			this.setLoading(true);

			// API Delete section
			const response = await this.formBuilderInputService.delete(inputId);

			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);

				this.setLoading(false);
				// FlashMessage
				this.props.flashMessageContext.flashMessage(
					'Suppression impossible',
					`Une erreur est survenue lors de la demande de suppression de la section`,
					'error'
				);

				return;
			}

			// FlashMessage
			this.props.flashMessageContext.flashMessage(
				'Input supprimée',
				`Votre input a été supprimée`,
				'success'
			);

			// Refresh
			const refreshInputList = await this.refreshSectionInputs(sectionId);

			this.setLoading(false);

			return refreshInputList;

		} catch (error: any) {
			console.log('Something wrong with delete Input', error.errorMessage);
		}
	}

	// </editor-fold>

	// <editor-fold desc="CRUD Input Options methods" defaultstate="collapsed">

	private async onCreateInputOption(optionLabel: {
		label: string
	}, inputId: number): Promise<FormBuilderOptionsInterface | null | undefined>
	{
		try {
			// Loading
			this.setState({ isLoading: true });
			this.props.modalContext.isOpen(true);

			// API Create section
			const response = await this.formBuilderInputOptionService.create(optionLabel, inputId);

			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);
				return;
			}

			// Refresh Section
			await this.refreshSections();

			this.props.modalContext.errorMessage('');
			this.props.flashMessageContext.flashMessage(
				'option créé',
				`Votre option ${ optionLabel.label } a bien été créée`,
				'success'
			);

			return response;

		} catch (error: any) {
			console.log('Something wrong with create Input Option', error.errorMessage);
		}
	}

	private async onEditInputOption(editInputOptionData: any, formBuilderInputId: number, formBuilderInputOptionId: number): Promise<any>
	{
		try {
			// Loading
			this.setState({ isLoading: true });

			// API Create section
			const response = await this.formBuilderInputOptionService.edit(editInputOptionData, formBuilderInputId, formBuilderInputOptionId);

			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);
				return;
			}

			this.props.modalContext.errorMessage('');
			this.props.flashMessageContext.flashMessage(
				'option mise à jour',
				`Votre option a bien été éditée`,
				'success'
			);

			// Refresh Section
			await this.refreshSections();

			this.setLoading(false);

		} catch (error: any) {
			console.log('Something wrong with edit Input', error.errorMessage);
		}
	}

	private async onDeleteInputOption(inputId: number, optionId: number): Promise<any>
	{
		try {
			// Loading
			this.setLoading(true);

			// API Delete section
			const response = await this.formBuilderInputOptionService.delete(inputId, optionId);

			// Handle error
			if (response.errorMessage) {
				this.props.modalContext.errorMessage(response.errorMessage);
				this.props.modalContext.isOpen(true);

				this.setLoading(false);
				// FlashMessage
				this.props.flashMessageContext.flashMessage(
					'Suppression impossible',
					`Une erreur est survenue lors de la demande de suppression de l'option`,
					'error'
				);

				return;
			}

			// FlashMessage
			this.props.flashMessageContext.flashMessage(
				'Input Option supprimée',
				`Votre option a bien été supprimée`,
				'success'
			);

			// Refresh Section
			this.refreshSections();

			this.setLoading(false);

		} catch (error: any) {
			console.log('Something wrong with delete Input option', error.errorMessage);
		}
	}

	// </editor-fold>

	// <editor-fold desc="Private Methods" defaultstate="collapsed">

	private onCheckIsMultiple(isChecked: boolean): void
	{
		this.setState({ isSectionMultiple: isChecked });
	}

	private setLoading(isLoading: boolean): void
	{
		this.setState({ isLoading: isLoading });
	}

	// </editor-fold>
}
