import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import FormBuilderView from '@/Modules/FormBuilder/Admin/View/FormBuilderView';
import { ModalContext } from '@/Provider/ModalProvider';

const FormBuilderAdminViewWrapper = (props: any) => {
  const flashMessageContext = useContext(FlashMessageContext);
  const modalContext = useContext(ModalContext);

  return <>
    <FormBuilderView
      flashMessageContext={ flashMessageContext }
      modalContext={ modalContext }
      { ...props }
    />
  </>;
};

export default FormBuilderAdminViewWrapper;