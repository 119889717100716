import React, { ReactElement, useContext } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { UserRoleEnum } from '@/Enum/UserRoleEnum';
import { MenuAdminStyle } from '@/Modules/App/Style/Menus/MenuAdminStyle';
import MenuItem from '@/Modules/App/Components/Menu/MenuItem';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { ModalContext, ModalContextType } from '@/Provider/ModalProvider';
import { AuthContext } from '@/Provider/AuthProvider';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import ConfigAdminComponent from '@/Modules/Config/Admin/Component/ConfigAdminComponent';
import { AuthCompanyContext } from '@/Provider/AuthCompanyProvider';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { SupplierInterface } from '@/Modules/Supplier/Interface/SupplierInterface';
import { NotificationContextType } from '@/Provider/NotificationProvider';

enum Paths
{
	'ADMIN' = '/admin',
	'USERS' = '/admin/users',
	'CLIENTS' = '/admin/clients',
	'LEGAL_NOTICE' = '/admin/legal-notice',
	'PRICING' = '/admin/settings/pricing',
	'FORMBUILDER' = '/admin/settings/form-builder',
  'LEGAL_NOTICE_TO_VALIDATE' = '/admin/legal-notice/validate',
  'LEGAL_NOTICE_PAYEMENT' = '/admin/legal-notice/payment',
}

interface ComponentProps
{
	authContext: AuthContextType,
	authCompanyContext: AuthCompanyContextType,
	flashMessageContext: FlashMessageContextType,
	notificationContext: NotificationContextType,
	navigation: NavigateFunction,
	location: Location,
	modalContext: ModalContextType
}

class MainNavigationAdminComponent extends React.Component<ComponentProps, any>
{
	constructor(props: ComponentProps)
	{
		super(props);

		// Bind
		this.openModalConfig = this.openModalConfig.bind(this);
		this.modalRender = this.modalRender.bind(this);
		this.onSelectedSupplier = this.onSelectedSupplier.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					height: '100%',
					paddingTop: 30,
					paddingBottom: 50,
					display: 'grid',
					gridTemplateRows: '70% auto'
				} }>
					<header>
						<nav>
							<ul>
								<MenuItem
									urlPath={ Paths.ADMIN }
									label={ 'Tableau de bord' }
									iconName={ 'LuLayoutDashboard' }
									location={ this.props.location }
								/>
								<MenuItem
									urlPath={ Paths.USERS }
									label={ 'Utilisateurs' }
									iconName={ 'LuUsers' }
									location={ this.props.location }
								/>
								<MenuItem
									urlPath={ Paths.CLIENTS }
									label={ 'Donneur d\'ordre' }
									iconName={ 'LuLandmark' }
									location={ this.props.location }
								/>

								<li style={ MenuAdminStyle.menuItemSeparateCategory() }>Annonces légales</li>
								<MenuItem
									urlPath={ Paths.LEGAL_NOTICE }
									label={ 'Annonces légales' }
									iconName={ 'LuFiles' }
									location={ this.props.location }
								/>
								<MenuItem
									urlPath={ Paths.LEGAL_NOTICE_TO_VALIDATE }
									label={ 'À valider' }
									iconName={ 'LuShoppingCart' }
									location={ this.props.location }
								/>
								<MenuItem
									urlPath={ Paths.LEGAL_NOTICE_PAYEMENT }
									label={ 'Règlements' }
									iconName={ 'LuStamp' }
									location={ this.props.location }
								/>

								{ this.props.authContext.user.role === UserRoleEnum.ROLE_SUPER_ADMIN &&
                  <>
                    <li style={ MenuAdminStyle.menuItemSeparateCategory() }>Configurations</li>
                    <MenuItem
											urlPath={ Paths.PRICING }
											label={ 'Tarifications' }
											iconName={ 'LuCircleDollarSign' }
											location={ this.props.location }
										/>
                    <MenuItem
											urlPath={ Paths.FORMBUILDER }
											label={ 'Formulaire' }
											iconName={ 'LuListTodo' }
											location={ this.props.location }
										/>
                  </>
								}
							</ul>
						</nav>
					</header>
					<div style={ { display: 'flex', alignItems: 'flex-end', height: '100%' } }>
						<div>
							<div style={ { opacity: 1, width: 100, marginLeft: '7px', marginBottom: 20 } }>
								<img src="/img/logo-hje-color.svg" alt="logo hérault juridique"/>
							</div>
							<nav>
								<ul>
									{/*<MenuItem*/}
									{/*	urlPath={ '/' }*/}
									{/*	label={ 'Historique' }*/}
									{/*	iconName={ 'LuHistory' }*/}
									{/*	location={ this.props.location }*/}
									{/*/>*/}
									<MenuItem
										urlPath={ '' }
										label={ 'Paramètres' }
										iconName={ 'LuSettings' }
										onClick={ (event: any) => this.modalRender(event) }
									 	location={ '' }
									/>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</>
		);
	}

	private modalRender(event: any): void
	{
		event.stopPropagation();

		this.props.modalContext.content(
			'Préférences',
			<>
				<div style={{ width: '1150px', height: '700px' }}>
					<ConfigAdminComponent
						companyId={ this.props.authCompanyContext.authCompany.id }
						supplier={ (this.props.authCompanyContext?.authCompany?.supplier)
							? this.props.authCompanyContext?.authCompany?.supplier : null
						}
						onSelectedSupplier={ this.onSelectedSupplier }
					/>
				</div>
			</>
		);
	}

	private onSelectedSupplier(supplier: SupplierInterface, companyId: number): void
	{
		this.props.authCompanyContext.refreshCompany(companyId, true);
		this.props.flashMessageContext.flashMessage(
			'Changement de Fournisseur enregistré avec succés',
			'Le Fournisseur courant est ' + supplier.name
		);
		this.props.modalContext.isOpen(false);
	}

	private openModalConfig(): void
	{
		this.props.modalContext.isOpen(true);
	}
}

const MainNavigationWithLocation = (props: any) =>
{
	const authContext = useContext(AuthContext);
	const authCompanyContext = useContext(AuthCompanyContext);
	const flashMessageContext = useContext(FlashMessageContext);
	const navigate = useNavigate();
	const location = useLocation();
	const modalContext = useContext(ModalContext);

	return <MainNavigationAdminComponent
		{ ...props }
		authContext={ authContext }
		authCompanyContext={ authCompanyContext }
		flashMessageContext={ flashMessageContext }
		navigation={ navigate }
		modalContext = { modalContext }
		location={ location }
	/>;
};

export default MainNavigationWithLocation;
