import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { FormBuilderInputsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderInputsInterface';

export class ApiAdminFormBuilderInputService extends BaseService
{
	formBuilderUrl: string = `${ BaseService.API_URL }/v1/admin/form-builder/inputs`;

	async list(formBuilderSectionId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl + "/" + formBuilderSectionId,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async show(inputId: number):Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl + '/' + inputId,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async create(inputData: Partial<FormBuilderInputsInterface>, sectionId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl + "/" + sectionId,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(inputData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async edit(editInputData: Partial<FormBuilderInputsInterface>, inputId: number): Promise<any>
	{
		try {
			const response = await fetch(
					this.formBuilderUrl + '/' + inputId,
					{
						method: HttpVerbEnum.PATCH,
						headers: this.buildHeaders(),
						body: JSON.stringify(editInputData)
					},
				)
			;

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async delete(inputId: number): Promise<any>
	{
		try {
			const response = await fetch(
				`${ this.formBuilderUrl }/${ inputId }`,
				{
					method: HttpVerbEnum.DELETE,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}