import React, { ReactElement } from 'react';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { BsFloppy, BsPencil, BsThreeDots } from 'react-icons/bs';
import { dateFormat } from '@/Utils/DateUtils';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { ModalContextType } from '@/Provider/ModalProvider';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import TagEnum from '@/Modules/App/Components/Atom/Tags/TagEnum';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import Table from '@/Modules/App/Components/Atom/Table/Table';
import TableHeader from '@/Modules/App/Components/Atom/Table/TableHeader';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import TableCell from '@/Modules/App/Components/Atom/Table/TableCell';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import { TableHeaderColumnInterface } from '@/Modules/App/Components/Atom/Interface/TableHeaderColumnInterface';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import Skeleton from 'react-loading-skeleton';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { LuTrash2 } from 'react-icons/lu';

interface ComponentProps
{
	selectedUser: UserInterface,
	flashMessageContext: FlashMessageContextType,
	selectedTabOffCanvas: number,
	modalContext: ModalContextType,
	currentItemId: number | null,
	handleDeleted: (userId: number) => void,
	handleUpdated: (updatedUserData: any, userId: number) => void,
	isUpdatedSuccess: boolean
	onRefresh: (selectedUser: number) => Promise<void>
	isLoading: boolean,
}

interface ComponentState
{
	isEditMode: boolean,
	selectedStatus: ActiveStatusEnum,
	selectedCollaborator: CollaboratorInterface | null,
	formData: any,
	errorMessage: string
}

export default class UserShowComponent extends React.Component
	<ComponentProps, ComponentState>
{
	// <editor-fold desc="Ctr" defaultstate="collapsed">

	constructor(props: any)
	{
		super(props);

		this.state = this.initState();

		// Bind
		this.inputSelectActiveStatusContent = this.inputSelectActiveStatusContent.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleUpdated = this.handleUpdated.bind(this);
		this.prepareFormData = this.prepareFormData.bind(this);
	}

	// </editor-fold>

	render(): ReactElement
	{
		return (
			<>
				{ (this.props.selectedTabOffCanvas === 1) &&
          <div style={ {
						display: 'flex',
						position: 'absolute',
						top: '39px',
						right: '30px',
					} }>
						{ (this.state.isEditMode)
							? this.saveEditButtonActionContent()
							: this.editAndDeleteButtonActionContent()
						}
          </div>
				}

				<div>
					{ (this.props.selectedTabOffCanvas === 1) &&
						this.rowDataUserEditContent()
					}
					{ (this.props.selectedTabOffCanvas === 2) &&
						this.tableCollaboratorsContent()
					}
				</div>

			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidUpdate(prevProps: any): void
	{
		if (
			(!prevProps.isUpdatedSuccess && this.props.isUpdatedSuccess)
			|| (this.props.currentItemId && this.props.currentItemId !== prevProps.currentItemId)) {
			this.setState({ isEditMode: false, formData: this.prepareFormData() });
		}
	}

	private initState(): ComponentState
	{
		return {
			isEditMode: false,
			selectedCollaborator: null,
			selectedStatus: this.props.selectedUser.status,
			errorMessage: '',
			formData: {
				firstname: this.props.selectedUser.firstname,
				lastname: this.props.selectedUser.lastname,
				email: this.props.selectedUser.email,
				status: this.props.selectedUser.status
			}
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private rowDataUserEditContent(): ReactElement
	{
		return (
			<>
				<div style={ {
					marginTop: '30px',
					display: 'flex',
					flexDirection: 'column',
					gap: '15px'
				} }>

					{ this.userDetailsHtmlContent() }

					{
						(this.state.errorMessage)
						&& <div className="error-message">
							{ this.state.errorMessage }
            </div>
					}
				</div>
			</>
		);
	}

	private userDetailsHtmlContent(): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					width: '100%',
					flexDirection: 'column',
					backgroundColor: CssVariableEnum['--color-white'],
					borderRadius: '15px',
					padding: '10px 15px'
				} }>
					<div style={ { ...FontStyle.h3(), marginBottom: 15 } }>Détails</div>
					{ this.lineDetailsHtmlContent(
						'tag',
						`# ${ this.props.selectedUser.tag }`,
						null
					) }
					{ this.lineDetailsHtmlContent(
						'Utilisateur depuis',
						dateFormat(this.props.selectedUser.createdAt),
						null
					) }
					{ this.lineDetailsHtmlContent(
						'Rôle',
						<TagEnum value={ this.props.selectedUser.role } enumName={ 'UserRoleEnum' }>
							{ UserRoleEnum.findByValue(this.props.selectedUser.role.toString()).label }
						</TagEnum>,
						null
					) }
					{ this.lineDetailsHtmlContent(
						'Nom',
						this.props.selectedUser.lastname,
						<Input
							type="text"
							width={ '100%' }
							name={ 'user-name' }
							value={ this.state.formData.lastname }
							containerDivWidth={ '60%' }
							onChange={
								(event) =>
									this.setState((prevState) => ({
										formData: { ...prevState.formData, lastname: event.target.value }
									}))
							}
						/>
					) }
					{ this.lineDetailsHtmlContent(
						'Prénom',
						this.props.selectedUser.firstname,
						<Input
							type="text"
							width={ '100%' }
							name={ 'user-firstname' }
							value={ this.state.formData.firstname }
							containerDivWidth={ '60%' }
							onChange={
								(event) =>
									this.setState((prevState) => ({
										formData: { ...prevState.formData, firstname: event.target.value }
									}))
							}
						/>
					) }
					{ this.lineDetailsHtmlContent(
						'email',
						this.props.selectedUser.email,
						<Input
							type="text"
							width={ '100%' }
							name={ 'user-email' }
							value={ this.state.formData.email }
							containerDivWidth={ '60%' }
							onChange={
								(event) =>
									this.setState((prevState) => ({
										formData: { ...prevState.formData, email: event.target.value }
									}))
							}
						/>
					) }
					{ this.lineDetailsHtmlContent(
						'Statut',
						<TagEnum value={ this.state.formData.status } enumName={ 'ActiveStatusEnum' }>
							{ this.activeStatus(this.props.selectedUser.status.toString()).label }
						</TagEnum>,
						<>
							<div style={ { display: 'flex', alignItems: 'center' } }>
								<TagEnum value={ this.state.formData.status } enumName={ 'ActiveStatusEnum' }>
									{ this.activeStatus(this.state.formData.status.toString()).label }
								</TagEnum>
								{ this.activeStatus(this.state.formData.status.toString()).label === 'Actif'
									? <Button
										type={ 'default' }
										onClick={ () =>
											this.setState((prevState) => ({
												formData: { ...prevState.formData, status: ActiveStatusEnum.INACTIVE.value }
											})) }
									>
										Rendre Inactif
									</Button>
									: <Button
										type={ 'default' }
										onClick={ () =>
											this.setState((prevState) => ({
												formData: { ...prevState.formData, status: ActiveStatusEnum.ACTIVE.value }
											})) }
									>
										Rendre Actif
									</Button>
								}

							</div>
						</>
					) }
				</div>
			</>
		);
	}

	private lineDetailsHtmlContent(label: string, data: any, renderEdit: ReactElement | null): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					width: '100%',
					marginBottom: '15px'
				} }>
					<div style={ {
						width: '320px',
						color: CssVariableEnum['--color-grey-400']
					} }>
						{ label }
					</div>
					{ (this.state.isEditMode && renderEdit)
						? renderEdit
						: <div style={ {
							fontWeight: 600,
							color: CssVariableEnum['--color-grey-900'],
						} }>
							{ this.props.isLoading
								? <Skeleton width={ 150 } height={ 18 } baseColor={ CssVariableEnum['--color-grey-200'] }/>
								: data
							}
						</div>
					}
				</div>
			</>
		);
	}

	private tableCollaboratorsContent(): ReactElement
	{
		return (
			<>
				{
					this.props.selectedUser.collaborators.length > 0
					&&
          <div style={ { marginTop: '20px' } }>
            <div style={ {
							display: 'flex',
							width: '100%',
							justifyContent: 'flex-end',
							alignItems: 'center',
							height: '50px'
						} }>
            </div>
						{ this.tableCollaboratorsHtmlContent() }
          </div>
				}
			</>
		);
	}

	private tableCollaboratorsHtmlContent(): ReactElement
	{
		return (
			<>
				<Table>
					<TableHeader columnHeaderList={ this.buildTableColumnCollaborators() } isFilters={ false }/>
					{ this.props.selectedUser.collaborators.map((collaborator: CollaboratorInterface) => (
						<TableRow
							key={ collaborator.id }
							borderBottom={ true }
						>
							<TableCol columnSize={ 7 }>
								<TableCell>
									<div style={ { fontWeight: 600 } }>
										{ collaborator.client.name }
									</div>
									<div style={ { color: CssVariableEnum['--color-grey-400'] } }>
										{ collaborator.email }
									</div>
								</TableCell>
							</TableCol>
							<TableCol columnSize={ 3 }>
								<TableCell>
									<TagEnum
										value={ collaborator.role.toString() }
										enumName={ 'CollaboratorRoleEnum' }
									>
										{ CollaboratorRoleEnum.findByValue(collaborator.role.toString())?.label }
									</TagEnum>
								</TableCell>
							</TableCol>
							<TableCol columnSize={ 2 }>
								<TableCell>
									<TagEnum
										value={ collaborator.status.toString() }
										enumName={ 'ActiveStatusEnum' }>
										{ ActiveStatusEnum.findByValue(collaborator.status.toString())?.label }
										{/* TODO: handle Enum */ }
									</TagEnum>
								</TableCell>
							</TableCol>
							<div style={ {
								minWidth: '70px',
								height: '100%',
							} }>
								<TableCell>
									<Button
										type={ 'inline-default' }
										iconLeft={ <BsThreeDots/> }
										onClick={ (event: any) => null }
									/>
								</TableCell>
							</div>
						</TableRow>
					)) }
				</Table>
			</>
		);
	}

	private buildTableColumnCollaborators(): TableHeaderColumnInterface[]
	{
		return [
			{ columnTitle: 'Collaborateur de', columnSize: 7, name: 'collaborators' },
			{ columnTitle: 'Rôle', columnSize: 3, name: 'role' },
			{ columnTitle: 'Statut', columnSize: 2, name: 'status' },
		];
	}

	//</editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	private saveEditButtonActionContent(): ReactElement
	{
		return (
			<>
				<Button
					type={ 'default' }
					onClick={ () => this.setState({ isEditMode: false }) }
				>
					Annuler
				</Button>
				<Button
					type={ 'default-blue' }
					iconLeft={ <BsFloppy/> }
					onClick={ this.handleUpdated }
				>
					Sauvegarder
				</Button>
			</>
		);
	}

	private editAndDeleteButtonActionContent(): ReactElement
	{
		return (
			<>
				<Button
					type={ 'default' }
					iconLeft={ <BsPencil/> }
					onClick={ () => this.setState({ isEditMode: true }) }
				>
					Editer
				</Button>
				<Button
					type={ 'danger' }
					iconLeft={ <LuTrash2/> }
					onClick={ () => this.handleDelete() }
				/>
			</>
		);
	}

	private activeStatus(activeStatus: string): any
	{
		return ActiveStatusEnum.roleOptions.find(
			role => role.value === activeStatus
		);
	}

	private inputSelectActiveStatusContent(): ReactElement
	{
		return (
			<>
				<SelectComponent
					onSelectedOption={ this.activeStatus(this.props.selectedUser.status.toString()) }
					listOptions={ ActiveStatusEnum.roleOptions }
					renderOptionLabel={ option => option.label }
				/>
			</>
		);
	}

	private handleDelete(): void
	{
		this.props.handleDeleted(this.props.selectedUser.id);
	}

	private async handleUpdated(): Promise<void>
	{
		const updatedUserData = {
			firstname: this.state.formData.firstname,
			lastname: this.state.formData.lastname,
			email: this.state.formData.email,
			status: this.state.formData.status
		};

		this.props.handleUpdated(updatedUserData, this.props.selectedUser.id);
	}

	private prepareFormData()
	{
		return {
			firstname: this.props.selectedUser.firstname,
			lastname: this.props.selectedUser.lastname,
			email: this.props.selectedUser.email,
			status: this.props.selectedUser.status
		};
	}

	//</editor-fold>
}