import React, { ReactElement } from 'react';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Button from '@/Modules/App/Components/Atom/Button/Button';

interface ComponentProps
{
  email?: string|null,
  onFormSubmit: (formData: { email: string; password: string; }) => void
}

export interface AuthViewState
{
  email: string,
  password: string
}

export default class AuthFormComponent extends React.Component<ComponentProps, AuthViewState>
{

  constructor(props: any)
  {
    super(props);

    // State
    this.state = {
      email: this.props.email || '',
      password: ''
    };

    // Bind methods
    this.onChangeInput = this.onChangeInput.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  render(): ReactElement
  {
    return (
      <>
        <div className="auth-form">
          <div
            className="auth-form__container"
            style={ { display: 'flex', flexDirection: 'column', gap: 10 } }
            onKeyDown={ this.handleKeyDown }
          >
            <Input
              type={ 'text' }
              name={ 'email' }
              label={ 'Email' }
              style={ { height: 42 } }
              width={ '100%' }
              placeholder={ 'Renseigner votre email' }
              value={ this.state.email }
              onChange={ this.onChangeInput }
              required
            />
            <Input
              type={ 'password' }
              name={ 'password' }
              label={ 'Mot de passe' }
              style={ { height: 42 } }
              width={ '100%' }
              placeholder={ '******' }
              value={ this.state.password }
              onChange={ this.onChangeInput }
              required
            />
            <div style={ { width: '100%', display: 'flex', justifyContent: 'flex-end' } }>
              <Button type={ 'default-blue' } onClick={ (event: any) => this.submitHandler(event) }>
                Se connecter
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  private onChangeInput(event: React.ChangeEvent<HTMLInputElement>): void
  {
    const { name, value } = event.target;
    this.setState({ [name]: value } as Pick<AuthViewState, keyof AuthViewState>);
  }

  private handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void
  {
    if (event.key === 'Enter') {
      this.submitHandler(event as unknown as React.FormEvent<HTMLFormElement>);
    }
  }

  private submitHandler(event: React.FormEvent<HTMLFormElement>): void
  {
    event.preventDefault();
    const saltedPassword = process.env.REACT_APP_SALT + this.state.password;
    const encodedPassword = btoa(saltedPassword);

    this.props.onFormSubmit({
      email: this.state.email,
      password: encodedPassword,
    });
  }
}
