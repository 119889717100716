import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class BaseStyle
{
  static displayFlex(justifyContent: string, alignItems?: string): CSSProperties
  {
    return { width: '100%', display: 'flex', justifyContent, alignItems };
  }

  static cardContainer(): CSSProperties
  {
    return {
      backgroundColor: CssVariableEnum['--color-white'],
      borderRadius: '8px',
      padding: '20px 10px',
      border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
    };
  }
}