import React, { ReactElement } from 'react';
import Button from '@/Modules/App/Components/Atom/Button/Button';

interface ComponentProps
{
	options: {
		previewUrl: string,
		logoWidth: number,
	}
	onCreate: (event: any) => void,
	onCancel: () => void,
}

interface ComponentState
{
	logoWidth: number
}

export default class OverviewCustomLogoComponent extends React.Component<ComponentProps, ComponentState>
{
	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Bind
		this.handleLogoWidthChange = this.handleLogoWidthChange.bind(this);
	}

	render(): ReactElement
	{
		const { options } = this.props;

		return (
			<>
				<div style={ { display: 'grid', gridTemplateRows: '400px 50px' } }>
					<div style={ {
						overflowY: 'scroll',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					} }>
						<div style={ { border: '1px dashed #ccc', width: 288 } }>
							<img
								src={ options.previewUrl }
								alt="Aperçu du logo"
								style={ { maxWidth: 288, width: this.state.logoWidth, padding: '5px' } }
							/>
						</div>
						<div style={ { width: 288 } }>
							<div style={ {
								hyphens: 'auto',
								textAlign: 'justify',
								fontSize: 12,
								lineHeight: '14px',
								wordSpacing: '-1px'
							} }>
								Aux termes d’une délibération en date du 23 juillet 2024, l’assemblée générale extraordinaire des
								associés, statuant en application de l’article L. 225-248 du Code de commerce, a décidé qu’il n’y
								avait pas lieu à dissolution de la société.
							</div>
						</div>
					</div>
					<div style={ {
						width: '100%',
						height: 50,
						position: 'absolute',
						bottom: 0,
						padding: 10,
						display: 'flex',
						backgroundColor: 'transparent',
						justifyContent: 'flex-end',
						alignItems: 'center',
					} }>
						<Button
							type={'default-blue'}
							label={'Enregistrer'}
							onClick={ this.props.onCreate }
						/>
						<Button
							type={'danger'}
							label={'Annuler'}
							onClick={ this.props.onCancel }
						/>
					</div>
				</div>
			</>
		);
	}

	componentDidMount(): void
	{
		this.setState({ logoWidth: this.props.options.logoWidth });
	}

	private initState(): ComponentState
	{
		return {
			logoWidth: 0,
		};
	}

	private handleLogoWidthChange(event: any): void
	{
		const newWidth = Number(event.target.value);
		this.setState({ logoWidth: newWidth });
	}

}