import React, { ReactElement } from 'react';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { LuArrowRight, LuArrowLeft } from 'react-icons/lu';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

interface PaginationProps
{
  tag: string,
  currentPage: number,
  list: PaginateInterface,
  itemsPerPage: (currentPage: number, itemPerPage: number) => void
}

interface PaginationState
{
  elementPerPage: number,
  isLoading: boolean
}

export default class Pagination extends React.Component<PaginationProps, PaginationState>
{
  constructor(props: PaginationProps)
  {
    super(props);
    this.state = {
      elementPerPage: 0,
      isLoading: true,
    };

    // bind
    this.selectedElementPerPage = this.selectedElementPerPage.bind(this);
  }

  componentDidMount(): void
  {
    const savedElementsPerPage = localStorage.getItem(`elementsPerPage_${ this.props.tag }`);
    const elementsPerPage = savedElementsPerPage ? parseInt(savedElementsPerPage) : 10;
    this.selectedElementPerPage({
      elementPerPage: elementsPerPage,
      label: `${ elementsPerPage } éléments`
    });
  }

  render(): ReactElement
  {
    if (this.state.isLoading) {
      return <div> chargement...</div>;
    }

    return (
      <>
        <div style={ {
          backgroundColor: CssVariableEnum['--color-white'],
          borderRadius: '8px',
          padding: '10px 10px',
          border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
          marginTop: '10px',
        } }>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <SelectComponent
              containerDivWidth={ '200px' }
              listOptions={ this.buildListOption() }
              onSelectedOption={ (event: any) => this.selectedElementPerPage(event) }
              selectedValue={ this.selectedValuePageOption(this.state.elementPerPage) }
              renderOptionLabel={ option => option.label }
            />

            <div style={{ flexGrow: 1, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              { this.generatePaginationButtons() }
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                iconLeft={ <LuArrowLeft/> }
                onClick={ () => this.props.itemsPerPage(this.props.currentPage - 1, this.state.elementPerPage) }
                disabled={ this.props.currentPage === 1 }
              >
                Précédent
              </Button>
              <Button
                iconRight={ <LuArrowRight/> }
                onClick={ () => this.props.itemsPerPage(this.props.currentPage + 1, this.state.elementPerPage) }
                disabled={ this.props.list.totalPages === 1||this.props.currentPage === this.props.list.totalPages }
              >
                Suivant
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  private buildListOption(): { elementPerPage: number, label: string | null }[]
  {
    return [
      { elementPerPage: 10, label: '10 éléments' },
      { elementPerPage: 25, label: '25 éléments' },
      { elementPerPage: 50, label: '50 éléments' },
      { elementPerPage: 100, label: '100 éléments' },
      { elementPerPage: 250, label: '250 éléments' },
    ];
  }

  private selectedValuePageOption(value: number): { elementPerPage: number, label: string | null } | null
  {
    const selectedItem = this.buildListOption()
      .find(item => item.elementPerPage === value);
    return selectedItem ? selectedItem : null;
  }

  private selectedElementPerPage(numberOfElement: { elementPerPage: number, label: string | number | null }): void
  {
    this.setState({
      elementPerPage: numberOfElement.elementPerPage,
      isLoading: true
    }, () =>
    {
      localStorage.setItem(`elementsPerPage_${ this.props.tag }`, numberOfElement.elementPerPage.toString());
      this.props.itemsPerPage(1, this.state.elementPerPage);
      this.setState({ isLoading: false });
    });
  }

  private generatePaginationButtons(): ReactElement[]
  {
    const { currentPage, list } = this.props;
    const totalPages = list.totalPages || 0;
    const paginationButtons: ReactElement[] = [];

    if (totalPages <= 1) {
      for (let i = 1; i <= totalPages; i++) {
        paginationButtons.push(
          <Button
            key={ i }
            type={ i === currentPage ? 'pagination-current' : 'pagination' }
            onClick={ () => this.props.itemsPerPage(i, this.state.elementPerPage) }
          >
            { i }
          </Button>
        );
      }
    } else {
      paginationButtons.push(
        <Button
          key={ 1 }
          type={ 1 === currentPage ? 'pagination-current' : 'pagination' }
          onClick={ () => this.props.itemsPerPage(1, this.state.elementPerPage) }
        >
          1
        </Button>
      );

      if (currentPage > 4) {
        paginationButtons.push(<span key="start-ellipsis" style={{ color: 'rgb(152, 162, 179)' }}>...</span>);
      }

      for (let i = Math.max(2, currentPage - 2); i <= Math.min(totalPages - 1, currentPage + 2); i++) {
        paginationButtons.push(
          <Button
            key={ i }
            type={ i === currentPage ? 'pagination-current' : 'pagination' }
            onClick={ () => this.props.itemsPerPage(i, this.state.elementPerPage) }
          >
            { i }
          </Button>
        );
      }

      if (currentPage < totalPages - 3) {
        paginationButtons.push(<span key="end-ellipsis" style={{ color: 'rgb(152, 162, 179)' }}>...</span>);
      }

      paginationButtons.push(
        <Button
          key={ totalPages }
          type={ totalPages === currentPage ? 'pagination-current' : 'pagination' }
          onClick={ () => this.props.itemsPerPage(totalPages, this.state.elementPerPage) }
        >
          { totalPages }
        </Button>
      );
    }

    return paginationButtons;
  }
}
