import { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class ActiveStatusEnum
{
	value: string;
	label: string;
	css: { backgroundColor: string, color: string, icon?: ReactElement | null };

	constructor(value: string, label: string, css: { backgroundColor: string, color: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static ACTIVE = new ActiveStatusEnum(
		'ACTIVE',
		'Actif',
		{
			backgroundColor: CssVariableEnum['--color-green-100'],
			color: CssVariableEnum['--color-green-500'],
		}
	);
	static INACTIVE = new ActiveStatusEnum(
		'INACTIVE',
		'Inactif',
		{
			backgroundColor: CssVariableEnum['--color-error-100'],
			color: CssVariableEnum['--color-error-500'],
		}
	);

	static roleOptions: ActiveStatusEnum[] = [
		ActiveStatusEnum.ACTIVE,
		ActiveStatusEnum.INACTIVE,
	];

	static findByValue(value: string): ActiveStatusEnum | undefined
	{
		return this.roleOptions.find(option => option.value === value);
	}
}
