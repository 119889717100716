export enum FormBuilderInputTypeEnum
{
	TEXT = 'TEXT',
	TEXTAREA = 'TEXTAREA',
	NUMBER = 'NUMBER',
	SWITCH = 'switch',
	SELECTOR = 'SELECTOR',
	CHECKBOX = 'CHECKBOX',
	DATE = 'DATE',
	RADIO = 'RADIO',
}