import React, { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class FormLabel extends React.Component
	<{ content: string, isRequired?: boolean }, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={ { fontSize: '12px', color: CssVariableEnum['--color-grey-500'], marginBottom: '4px' } }>
					{ this.props.content }
					{ this.props.isRequired &&
            <span style={ { color: 'red' } }>*</span>
					}
				</div>
			</>
		);
	}
}