import React, { ReactElement } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import BadgeComponent from '@/Modules/Pricing/Components/BadgeComponent';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuPlus } from 'react-icons/lu';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Skeleton from 'react-loading-skeleton';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';

export default class DepartmentListNoAffectComponent extends React.Component
	<
		{
			departments: Array<any>,
			createAnnex: () => void,
			isLoading: boolean
			searchDepartment?: (searchTerm: string) => void,
		},
		{
			searchTerm: string | null
		}
	>
{
	state = {
		searchTerm: '',
	};

	componentDidUpdate(prevPros: any)
	{
		if (prevPros.isLoading !== this.props.isLoading) {
			this.setState({ searchTerm: '' });
		}
	}

	handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
	{
		const searchTerm = event.target.value;
		this.setState({ searchTerm: searchTerm });

		if (this.props.searchDepartment) {
			this.props.searchDepartment(searchTerm);
		}
	};

	render(): ReactElement
	{
		return (
			<>
				<div className="d-flex justify-content-between mb-2 align-items-center">
					{ this.props.isLoading
						? <Skeleton width={ 400 } height={ 15 } count={ 2 }/>
						: <div className="text-small mt-1">Afin de configurer votre grille tarifaire forfaitaire, veuillez glisser,
							déposer,<br/> les tags afin d'affecter les départements dans les annexes associées.
						</div>
					}

					<div>
						{ this.props.isLoading
							? <Skeleton width={ 162 } height={ 32 }/>
							:
							<div style={ { display: 'flex' } }>
								<Input
									type={ 'text' }
									name={ 'search-table-users' }
									placeholder={ 'Rechercher' }
									width={ '300px' }
									value={ this.state.searchTerm || '' }
									backgroundColor={ CssVariableEnum['--color-white'] }
									onChange={ this.handleSearchChange }
								/>
								<Button
									type="default-blue"
									iconLeft={ <LuPlus/> }
									onClick={ this.props.createAnnex }
								>
									Ajouter une annexe
								</Button>
							</div>
						}

					</div>
				</div>
				<div
					style={ {
						backgroundColor: 'white',
						borderRadius: 15,
						border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
						padding: 20
					} }>
					<Droppable droppableId="no-affect" direction="vertical">
						{ (provided, snapshot) => (
							<div
								{ ...provided.droppableProps }
								ref={ provided.innerRef }
								style={ {
									display: 'flex',
									flexWrap: 'wrap',
									gap: 6,
								} }
							>
								{ this.props.departments.map((department: any, index: number) => (
									this.props.isLoading
										? <Skeleton width={ 74 } height={ 26 }/>
										: <Draggable key={ department.id } draggableId={ department.id.toString() }
																 index={ index }>
											{ (provided, snapshot) => (
												<div
													ref={ provided.innerRef }
													{ ...provided.draggableProps }
													{ ...provided.dragHandleProps }
												>
													<BadgeComponent isDragging={ snapshot.isDragging } item={ department }/>
												</div>
											) }
										</Draggable>

								)) }
								{ provided.placeholder }
							</div>
						) }
					</Droppable>
				</div>
			</>
		);
	}
}