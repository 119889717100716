import React, { ReactElement } from 'react';
import { ApiAdminPricingService } from '@/Service/Admin/ApiAdminPricingService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import BadgeComponent from '@/Modules/Pricing/Components/BadgeComponent';
import DepartmentListNoAffectComponent from '@/Modules/Pricing/Components/DepartmentListNoAffectComponent';
import { PricingTypeEnum } from '@/Enum/PricingTypeEnum';
import { AnnexInterface } from '@/Modules/Pricing/Interface/AnnexInterface';
import Swal from 'sweetalert2';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import Skeleton from 'react-loading-skeleton';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import Title from '@/Modules/App/Components/Atom/Title/Title';

export default class PricingAdminFixedPriceComponent extends React.Component
	<
		{
			onRefresh: () => Promise<any>;
			onCreate: (annexNumber: number) => void
		}, any>
{
	pricingService: ApiAdminPricingService;
	appService: ApiAppService;
	state = {
		departments: [] as DepartmentInterface[],
		annexList: [],
		formData: [],
		annexDepartments: [],
		isLoading: true,
		searchTerm: '' as string | null,
	};

	constructor(props: any)
	{
		super(props);

		// Service
		this.pricingService = new ApiAdminPricingService();
		this.appService = new ApiAppService();

		// Bind
		this.renderDroppableContainers = this.renderDroppableContainers.bind(this);
		this.renderDroppableContainer = this.renderDroppableContainer.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.buildFormData = this.buildFormData.bind(this);
		this.createAnnex = this.createAnnex.bind(this);
		this.checkIfAnnexHasPricingCategory = this.checkIfAnnexHasPricingCategory.bind(this);
		this.filterDepartments = this.filterDepartments.bind(this);
		this.searchDepartments = this.searchDepartments.bind(this);
	}

	async componentDidMount()
	{
		await this.refreshDepartments();
	}

	async componentDidUpdate(prevPros: any, prevState: any)
	{
		if (prevState.searchTerm !== this.state.searchTerm) {
			await this.filterDepartments();
		}
	}

	render(): ReactElement
	{
		return (
			<>
				{ this.state.isLoading
					? <Skeleton style={{ marginTop: 25 }} width={ 250 } height={ 22 }/>
					: <Title type={ 'h4' } style={{ marginBottom: 0}}>
						Affectation des départements
					</Title>
				}

				<DragDropContext onDragEnd={ this.onDragEnd }>
					<DepartmentListNoAffectComponent
						departments={ this.state.departments }
						createAnnex={ this.createAnnex }
						searchDepartment={ this.searchDepartments }
						isLoading={ this.state.isLoading }
					/>
					{ this.renderDroppableContainers() }
				</DragDropContext>
			</>
		);
	}

	private renderDroppableContainers(): ReactElement
	{
		return (
			<>
				<div className="mt-4">
					<div className="row row-cols-1 row-cols-md-4 g-3">
						{ this.state.annexList.map((annex: any, index: number) => (
							<div className="col" key={ annex.id }>
								{ this.renderDroppableContainer(
									annex,
									this.state.annexDepartments[index],
									index
								) }
							</div>
						)) }
					</div>
				</div>
			</>
		);
	}

	private searchDepartments(searchTerm: string): void
	{
		this.setState({ searchTerm });
	}

	private async filterDepartments(): Promise<void>
	{
		const freshDepartments = await this.appService.departmentList();
		this.setState({ departments: this.buildListDepartmentNoAffect(freshDepartments) }, () =>
		{
			if (this.state.searchTerm) {
				const lowerCaseSearchTerm: string = this.state.searchTerm.toLowerCase();
				const filteredDepartments: DepartmentInterface[] = this.state.departments.filter((department: DepartmentInterface) =>
					department.name.toLowerCase().includes(lowerCaseSearchTerm)
				);
				this.setState({ departments: filteredDepartments });
			}
		});
	}

	private renderDroppableContainer(annex: any, departments: any[], index: number): ReactElement
	{
		return (
			<>
				<div style={ {
					backgroundColor: CssVariableEnum['--color-white'],
					padding: 10,
					border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
					borderRadius: 15
				} }>
					<div style={ { width: '100%', display: 'flex', height: '32px' } }>
						<div style={ { width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between' } }>
							<div style={ { fontSize: 16, fontWeight: 600, margin: '0 0 0 15px' } }>
								Annexe { annex.number }
							</div>
						</div>
					</div>
					<div
						style={ {
							backgroundColor: CssVariableEnum['--color-white'],
							border: 'none',
							padding: 10,
							borderRadius: 15,
							minHeight: 200
						} }>
						<Droppable droppableId={ annex.id.toString() } direction="vertical">
							{ (provided, snapshot) => (
								<div
									ref={ provided.innerRef }
									{ ...provided.droppableProps }
									style={ {
										display: 'flex',
										flexWrap: 'wrap',
										gap: 6,
										backgroundColor: `${ snapshot.isDraggingOver ? CssVariableEnum['--color-grey-100'] : 'white' }`,
										borderRadius: 15,
										padding: 5,
										minHeight: snapshot.isDraggingOver ? '200px' : '',
									} }
								>
									{ departments.map((department: any, index: number) => (
										<Draggable key={ department.id } draggableId={ department.id.toString() } index={ index }>
											{ (provided, snapshot) => (
												<div
													ref={ provided.innerRef }
													{ ...provided.draggableProps }
													{ ...provided.dragHandleProps }
												>
													<BadgeComponent isDragging={ snapshot.isDragging } item={ department }/>
												</div>
											) }
										</Draggable>
									)) }
									{ provided.placeholder }
								</div>
							) }
						</Droppable>
					</div>
					<div
						style={ { width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: 40 } }>
						<Button type="danger" onClick={ () => this.checkIfAnnexHasPricingCategory(annex.id) }>
							supprimer
						</Button>
					</div>
				</div>
			</>
		);
	}

	private async onDragEnd(result: any)
	{
		if (!result.destination || !result.destination.droppableId) {
			return;
		}

		const editDepartmentData = {
			type: PricingTypeEnum.FIXED_PRICE,
			pricingAnnexId: (result.destination.droppableId === 'no-affect')
				? null
				: parseInt(result.destination.droppableId)
		};

		// Edit on API
		await this.pricingService.editDepartment(
			editDepartmentData,
			parseInt(result.draggableId)
		);

		// Refresh List Departments
		await this.refreshDepartments();
	}

	//<editor-fold desc="Departments methods" defaultstate="collapsed">

	private async refreshDepartments()
	{
		this.setState({ isLoading: true });
		try {
			const freshDepartments = await this.appService.departmentList();
			const freshAnnexList = await this.pricingService.listAnnex(PricingTypeEnum.FIXED_PRICE);

			this.setState({
				departments: this.buildListDepartmentNoAffect(freshDepartments),
				annexList: freshAnnexList,
				annexDepartments: this.buildListDepartment(freshDepartments, freshAnnexList),
				formData: this.buildFormData(freshAnnexList),
				isLoading: false
			});
		} catch (error) {
			console.error('An error occurred while refreshing departments:', error);
			this.setState({ isLoading: false });
		}
	}

	private buildListDepartment(freshDepartments: [], freshAnnexList: []): any[]
	{
		const annexDepartments: any[] = [];
		freshAnnexList.forEach((annex: any) =>
		{
			const matchingDepartments = freshDepartments.filter(
				(department: any) => department.pricingAnnexFixedPrice && department.pricingAnnexFixedPrice.id === annex.id
			);

			if (matchingDepartments) {
				annexDepartments.push(matchingDepartments);
			}
		});

		return annexDepartments;
	}

	private buildListDepartmentNoAffect(freshDepartments: any[]): any[]
	{
		return freshDepartments.filter(department => !department.pricingAnnexFixedPrice);
	}

	//</editor-fold>

	private buildFormData(freshAnnexList: any []): any[]
	{
		let formData: any[] = [];
		freshAnnexList.forEach((annex: any) =>
		{
			formData.push(annex.price);
		});

		return formData;
	}

	private async createAnnex()
	{
		try {
			const newAnnex = {
				id: `new-${ Date.now() }`,
				number: this.state.annexList.length + 1,
				price: 0,
			};

			await this.pricingService.createAnnex({ type: PricingTypeEnum.FIXED_PRICE, price: 0 });

			this.setState((prevState: any) => ({
				annexList: [...prevState.annexList, newAnnex],
				formData: [...prevState.formData, '0'],
				annexDepartments: [...prevState.annexDepartments, []],
				isEditMode: newAnnex.id,
			}));

			await this.refreshDepartments();
			await this.props.onRefresh();
			await this.props.onCreate(this.state.annexList.length);

		} catch (error) {
			console.error('Error creating annex:', error);
		}
	}

	private async deleteAnnex(annexId: number)
	{
		await this.pricingService.deleteAnnex(PricingTypeEnum.FIXED_PRICE, annexId);
		await this.refreshDepartments();
		await this.props.onRefresh();

	}

	private async checkIfAnnexHasPricingCategory(annexId: number)
	{
		try {
			// Find selected Annex
			let listAnnex: AnnexInterface[] = [];
			listAnnex = await this.pricingService.listAnnex(PricingTypeEnum.FIXED_PRICE);
			const selectedAnnex = listAnnex.find((annex: AnnexInterface) => annex.id === annexId);

			// Check if annex already has pricing category
			if (selectedAnnex?.pricingCategories && selectedAnnex.pricingCategories.length > 0) {
				const result = await Swal.fire({
					title: 'Êtes-vous sûr?',
					text: 'Si vous supprimez cette annexe, tous les prix associés seront réinitialisé',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Oui, supprimez-le!'
				});

				if (result.isConfirmed) {
					Swal.fire(
						'Supprimé!',
						'L\'annexe a bien été supprimée.',
					);
					await this.deleteAnnex(annexId);
				}
			}

			// Check if annex doesn't have pricing category
			if (selectedAnnex?.pricingCategories && selectedAnnex.pricingCategories.length < 1) {
				await this.deleteAnnex(annexId);
			}

		} catch (error: any) {
			console.error(error.message);
		}
	}
}