import React, { ReactElement } from 'react';
import PricingAdminCharacterPriceComponent from '@/Modules/Pricing/Components/PricingAdminCharacterPriceComponent';
import PricingAdminFixedPriceComponent from '@/Modules/Pricing/Components/PricingAdminFixedPriceComponent';
import HeroSection from '@/Modules/App/Components/Atom/Content/HeroSection';
import { PricingTypeEnum } from '@/Enum/PricingTypeEnum';
import { ApiAdminPricingService } from '@/Service/Admin/ApiAdminPricingService';
import { ApiAdminFormBuilderCategoryService } from '@/Service/Admin/ApiAdminFormBuilderCategoryService';
import FixedPriceTableComponent from '@/Modules/Pricing/Components/FixedPriceTableComponent';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { PricingCategoryInterface } from '@/Modules/Pricing/Interface/PricingCategoryInterface';
import PricingAdminPriceLineComponent from '@/Modules/Pricing/Components/PricingAdminPriceLineComponent';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import Content from '@/Modules/App/Components/Atom/Content/Content';

interface ViewProps
{
	flashMessageContext: FlashMessageContextType;
}

interface ViewState
{
	activeTabKey: string,
	annexList: any[];
	refreshKey: number;
	categoryList: FormBuilderCategoryInterface[];
	isUpdatedAnnexList: boolean;
	selectedPrimaryTabId: number;
	selectedSecondaryTabId: number;
}

export default class PricingAdminView extends React.Component
	<ViewProps, ViewState>
{
	pricingService: ApiAdminPricingService;
	formBuilderCategoryService: ApiAdminFormBuilderCategoryService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Service
		this.pricingService = new ApiAdminPricingService();
		this.formBuilderCategoryService = new ApiAdminFormBuilderCategoryService();

		// Bind
		this.handleSelectTab = this.handleSelectTab.bind(this);
		this.saveStateToLocalStorage = this.saveStateToLocalStorage.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.annexList = this.annexList.bind(this);
		this.onCreatePricingCategory = this.onCreatePricingCategory.bind(this);
		this.onEditPrice = this.onEditPrice.bind(this);
		this.deletePricingCategory = this.deletePricingCategory.bind(this);
		this.deleteAllPricingCategories = this.deleteAllPricingCategories.bind(this);
		this.onCreateAnnex = this.onCreateAnnex.bind(this);
		this.buildTabs = this.buildTabs.bind(this);
		this.handlePrimaryTabChange = this.handlePrimaryTabChange.bind(this);
		this.handleSecondaryTabChange = this.handleSecondaryTabChange.bind(this);

		// Document title
		document.title = 'Publisur - ADMIN - Tarifications';
	}

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title="Tarification"
					icon={ null }
				/>

				<Content top={ 100 }>
					<MainWrapper>
						{ this.tabContent() }
					</MainWrapper>
				</Content>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		window.addEventListener('beforeunload', this.saveStateToLocalStorage);
		await this.refreshData();
	}

	componentWillUnmount(): void
	{
		window.removeEventListener('beforeunload', this.saveStateToLocalStorage);
		this.saveStateToLocalStorage();
	}

	private initState(): ViewState
	{
		return {
			activeTabKey: localStorage.getItem('pricing-tab-active') || 'tab-fixed-price',
			annexList: [],
			categoryList: [],
			refreshKey: 0,
			isUpdatedAnnexList: false,
			selectedPrimaryTabId: 1,
			selectedSecondaryTabId: 1,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private CRUD" defaultstate="collapsed">

	private async deleteAllPricingCategories(): Promise<void>
	{
		try {
			await this.pricingService.deletePricingCategories();
			await this.refreshData();
		} catch (error) {
			console.error('An error occurred while deleting all pricing categories:', error);
		}
	}

	private async deletePricingCategory(pricingCategoryId: number): Promise<void>
	{
		try {
			await this.pricingService.deletePricingCategory(pricingCategoryId);
			await this.refreshData();

			this.props.flashMessageContext.flashMessage(
				'Suppression réussie',
				'Les tarifs de la catégorie ont bien été supprimés',
				'success'
			);

		} catch (error: any) {
			console.error('An error occurred while delete pricing Category', error);
		}
	}

	private async onCreateAnnex(lastAnnexNumber: number): Promise<void>
	{
		if (lastAnnexNumber > this.state.annexList.length) {
			await this.deleteAllPricingCategories();
		}
	}

	private async onEditPrice(pricingData: PricingCategoryInterface[]): Promise<void>
	{
		try {
			await Promise.all(pricingData.map(async (pricing: PricingCategoryInterface) =>
			{
				await this.pricingService.editCategory({ price: pricing.price }, pricing.id as number);
			}));

			this.props.flashMessageContext.flashMessage(
				'Mise à jour réussie',
				'Votre prix a bien été édité',
				'success'
			);

			await this.refreshData();

		} catch (error: any) {
			console.error('An error occurred while display category list:', error);
		}
	}

	private async onCreatePricingCategory(createCategoryData: {
		price: number,
		annexId: number
	}, childCategoryId: number): Promise<void>
	{
		try {
			await this.pricingService.createCategory(createCategoryData, childCategoryId);
			await this.refreshData();

			this.props.flashMessageContext.flashMessage(
				'Création réussie',
				'Vos tarifs ont bien été créés, vous pouvez les éditer',
				'success'
			);


		} catch (error: any) {
			console.error('An error occurred while create Pricing category:', error);
		}
	}

	//</editor-fold>

	//<editor-fold desc="Content" defaultstate="collapsed">

	private tabContent(): ReactElement
	{
		return (
			<>
				{ this.tabsMenuHtmlContent() }
				{ (this.state.selectedSecondaryTabId === 1) &&
          <PricingAdminFixedPriceComponent
            onRefresh={ this.refreshData }
            onCreate={ this.onCreateAnnex }
          />
				}
				{ (this.state.selectedSecondaryTabId === 2) &&
          <FixedPriceTableComponent
            annexList={ this.state.annexList }
            categoryList={ this.state.categoryList }
            onCreate={ this.onCreatePricingCategory }
            onEdit={ this.onEditPrice }
            onDelete={ this.deletePricingCategory }
            key={ this.state.refreshKey }
          />
				}
				{ (this.state.selectedPrimaryTabId === 2) &&
          <PricingAdminCharacterPriceComponent/>
				}
				{
					(this.state.selectedPrimaryTabId === 3) &&
          <PricingAdminPriceLineComponent
            flashMessageContext={ this.props.flashMessageContext }
          />
				}
			</>
		);
	}

	private buildTabs(type: string = 'PRIMARY' || 'SECONDARY', tabId: number, title: string): ReactElement
	{
		return (
			<div
				style={ {
					display: 'flex',
					alignItems: 'center',
					backgroundColor: ((type === 'PRIMARY') ? this.state.selectedPrimaryTabId === tabId : this.state.selectedSecondaryTabId === tabId) ? CssVariableEnum['--color-white'] : 'transparent',
					color: ((type === 'PRIMARY') ? this.state.selectedPrimaryTabId === tabId : this.state.selectedSecondaryTabId === tabId) ? CssVariableEnum['--color-grey-900'] : CssVariableEnum['--color-grey-400'],
					border: ((type === 'PRIMARY') ? this.state.selectedPrimaryTabId === tabId : this.state.selectedSecondaryTabId === tabId) ? `1px solid ${ CssVariableEnum['--color-grey-300'] }` : 'none',
					padding: '3px 6px',
					cursor: 'pointer',
					borderRadius: 8
				} }
				onClick={ () => (type === 'PRIMARY')
					? this.handlePrimaryTabChange(tabId)
					: this.handleSecondaryTabChange(tabId)
				}>
				{ title }
			</div>
		);
	}

	private tabsMenuHtmlContent(): ReactElement
	{
		return (
			<div style={ {
				width: '100%',
				position: 'relative'
			} }>
				<div style={ {
					display: 'inline-flex',
					backgroundColor: CssVariableEnum['--color-grey-200'],
					borderRadius: 12,
					padding: '5px',
				} }>
					{ this.buildTabs('PRIMARY', 1, 'Tarifs forfaitaires') }
					{ this.buildTabs('PRIMARY', 2, 'Tarifs caractères') }
					{ this.buildTabs('PRIMARY', 3, 'Ligne tarifaire') }
				</div>
				{ this.state.selectedPrimaryTabId === 1 &&
          <div style={ {
						display: 'inline-flex',
						backgroundColor: CssVariableEnum['--color-grey-200'],
						marginLeft: 10,
						borderRadius: 12,
						padding: '5px',
						gap: '5px'
					} }>
						{ this.buildTabs('SECONDARY', 1, 'Affecter les départements') }
						{ this.buildTabs('SECONDARY', 2, 'Configuration grille forfaitaire') }
          </div>
				}
			</div>
		);
	}

	private async annexList(type: PricingTypeEnum): Promise<any>
	{
		try {
			return await this.pricingService.listAnnex(type);
		} catch (error) {
			console.error('An error occurred while refreshing annex List:', error);
		}
	}

	//</editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	private async refreshData(): Promise<any>
	{
		try {
			const annexList = await this.annexList(PricingTypeEnum.FIXED_PRICE);
			const categoryList: FormBuilderCategoryInterface[] = await this.formBuilderCategoryService.list();

			this.setState((prevState) => ({
				refreshKey: prevState.refreshKey + 1,
				annexList,
				categoryList
			}));
		} catch (error) {
			console.error('An error occurred while refreshing data:', error);
		}
	}

	private handlePrimaryTabChange(tabId: number): void
	{
		this.setState({
			selectedPrimaryTabId: tabId,
			selectedSecondaryTabId: tabId === 1 ? 1 : 0
		});
	}

	private handleSecondaryTabChange(tabId: number): void
	{
		if (this.state.selectedPrimaryTabId === 1) {
			this.setState({ selectedSecondaryTabId: tabId });
		}
	}

	private handleSelectTab(key: string | null)
	{
		this.setState({ activeTabKey: key as string });
	}

	private saveStateToLocalStorage(): void
	{
		localStorage.setItem('pricing-tab-active', this.state.activeTabKey);
	}

	//</editor-fold>

}