import React, { Component, ReactElement } from 'react';

interface FormattedContentProps {
  content: string|undefined;
}

export class FormattedContent extends Component<FormattedContentProps>
{
  render(): ReactElement
  {
    const { content } = this.props;

    return (
      <>
        { this.props.content &&
          <div dangerouslySetInnerHTML={ { __html: content as string } }/>
        }
      </>
    );
  }
}

export default FormattedContent;