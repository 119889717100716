import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { FilterParamsInterface } from '@/Modules/App/Interface/FilterParamsInterface';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import React, { ReactElement } from 'react';
import HeroSection from '@/Modules/App/Components/Atom/Content/HeroSection';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';
import Content from '@/Modules/App/Components/Atom/Content/Content';
import TableComponent, { TableHeaderListInterface } from '@/Modules/App/Components/Atom/Table/TableComponent';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import { ApiLegalNoticeService } from '@/Service/Api/ApiLegalNoticeService';
import { LuCircleDollarSign, LuFiles, LuMap, LuStar } from 'react-icons/lu';
import { NavigateFunction } from 'react-router-dom';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { ModalContextType } from '@/Provider/ModalProvider';
import TableFilters from '@/Modules/App/Components/Atom/Table/TableFilters';
import { FilterInterface } from '@/Modules/App/Components/Atom/Table/Filters';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import OffCanvasComponent from '@/Modules/App/Components/Atom/OffCanvas/OffCanvasComponent';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { BsCopy } from 'react-icons/bs';
import TagEnum from '@/Modules/App/Components/Atom/Tags/TagEnum';
import Pagination from '@/Modules/App/Components/Atom/Table/Pagination';
import LegalNoticeUserShowComponent from '@/Modules/LegalNotice/Components/LegalNoticeUserShowComponent';

interface ViewProps
{
	flashMessageContext: FlashMessageContextType
	modalContext: ModalContextType
	location: Location
	navigation: NavigateFunction
}

interface ViewState
{
	paginate: PaginateInterface,
	filterParams: FilterParamsInterface,
	selectedLegalNotice: LegalNoticeInterface | null,
	selectedLegalNoticeIndex: number,
	selectedTabOffCanvas: number,
	isLoading: boolean,
	currentItemId: number | null,
	isOpenShowOffCanvas: boolean,
	fromUrl: string | null,
	errorMessage: string | null
	isCreatedMode: boolean,
}

export default class LegalNoticeView extends React.Component
	<ViewProps, ViewState>
{
	legalNoticeService: ApiLegalNoticeService;
	navigation: NavigateFunction;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Navigation
		this.navigation = props.navigation;

		// Services
		this.legalNoticeService = new ApiLegalNoticeService();

		// Bind
		this.itemPerPage = this.itemPerPage.bind(this);
		this.onRowClick = this.onRowClick.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title="Annonces légales"
					icon={ null }
					tableFilter={
						<TableFilters
							searchInput={
								{
									name: 'search-legal-notice',
									placeholder: 'Rechercher une annonce légale...',
									search: this.state.filterParams.search || '',
									onChangeSearch: (event: any) => this.onChangeLegalNoticeSearch(event),
									resetSearchInput: this.resetSearchInput.bind(this)
								}
							}
							filters={ { list: this.buildFilterLegalNoticeList(), selectedFilters: this.selectedFilters.bind(this) } }
							buttonCreate={ {
								label: 'Ajouter une annonce légale',
								onClick: (event: any) => this.props.navigation('/legal-notices/create')
							} }
						/>
					}
				/>
				<Content>
					<MainWrapper>
						{ !this.state.isLoading &&
              <TableComponent
                paginateList={ this.state.paginate.items }
                tableHeaderList={ this.buildTableHeaderLegalNotice() }
                params={ this.buildFilterParams }
                onClickRow={ this.onRowClick }
                notFoundValuesText={ 'Aucune annonce légale n\'a été trouvée' }
                onRefreshList={ this.refreshList }
                isResizeColumns={ true }
                isStickyHeader={ true }
              />
						}
						<Pagination
							currentPage={ this.state.filterParams.page }
							tag={ 'legal-notice' }
							list={ this.state.paginate }
							itemsPerPage={ this.itemPerPage }
						/>
					</MainWrapper>
				</Content>
				<OffCanvasComponent
					show={ this.state.isOpenShowOffCanvas }
					onHide={ () => this.setState({
						isOpenShowOffCanvas: false,
						selectedTabOffCanvas: 1,
						selectedLegalNotice: null
					}) }
					onPrevious={ () => this.navigateLegalNotice(true) }
					onNext={ () => this.navigateLegalNotice(false) }
					tabs={ this.buildTabsOffCanvas }
					isNavigateArrow={ true }
					isTabNeeded={ true }
					onTabChange={ (selectedTab: number) => this.handleTabChange(selectedTab) }
					renderTitle={ this.renderTitle() }
				>
					{ this.state.selectedLegalNotice && (
						<LegalNoticeUserShowComponent
							key={ this.state.selectedLegalNotice.id }
							modalContext={ this.props.modalContext }
							selectedTabOffCanvas={ this.state.selectedTabOffCanvas }
							selectedLegalNotice={ this.state.selectedLegalNotice }
							navigation={ this.navigation }
							flashMessageContext={ this.props.flashMessageContext }
							currentItemId={ this.state.currentItemId }
						/>
					) }
				</OffCanvasComponent>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.refreshList();
	}

	componentDidUpdate(prevProps: any): void
	{
		if (this.props.location && this.props.location.search !== prevProps.location.search) {
			this.checkForReload();
		}
	}

	private initState(): ViewState
	{
		return {
			paginate: {} as PaginateInterface,
			filterParams: {
				page: 1,
				itemsPerPage: 10,
				search: null,
				filters: null,
				orderBy: null,
				sort: null,
			},
			selectedLegalNotice: null as LegalNoticeInterface | null,
			selectedLegalNoticeIndex: -1,
			selectedTabOffCanvas: 1,
			isLoading: true,
			currentItemId: null,
			isOpenShowOffCanvas: false,
			fromUrl: null,
			errorMessage: null,
			isCreatedMode: false
		};
	}

	//</editor-fold>

	//<editor-fold desc="Table Filters methods" defaultstate="collapsed">

	private async searchLegalNotice(term: string): Promise<void>
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				search: (term !== '') ? term : '',
				page: 1
			}
		}), async () => await this.refreshList());
	}

	private async onChangeLegalNoticeSearch(event: React.ChangeEvent<HTMLInputElement>): Promise<void>
	{
		const term = event.target.value;
		await this.searchLegalNotice(term);
	}

	private async resetSearchInput(): Promise<void>
	{
		await this.searchLegalNotice('');
	}

	private buildFilterLegalNoticeList(): FilterInterface[]
	{
		return [
			{ label: 'Statut', tag: 'status', icon: <LuStar/>, childList: PublishStateEnum.options, type: 'select' },
			{
				label: 'Paiement',
				tag: 'paymentStatus',
				icon: <LuCircleDollarSign/>,
				childList: PaymentStateEnum.options,
				type: 'select'
			},
			{ label: 'Département', tag: 'department', icon: <LuMap/>, childList: [], type: 'search' },
			{ label: 'Journal', tag: 'newspaper', icon: <LuFiles/>, childList: [], type: 'search' },
		];
	}

	//</editor-fold>

	//<editor-fold desc="OffCanvas methods" defaultstate="collapsed">

	private navigateLegalNotice(isPrevious: boolean): void
	{
		const { selectedLegalNoticeIndex, paginate } = this.state;
		let navigateIndex = isPrevious ? selectedLegalNoticeIndex - 1 : selectedLegalNoticeIndex + 1;

		// Check index
		if (navigateIndex >= 0 && navigateIndex < paginate.items.length) {
			this.setState({
				selectedLegalNotice: paginate.items[navigateIndex],
				selectedLegalNoticeIndex: navigateIndex,
				currentItemId: this.state.selectedLegalNotice?.id as number,
			});
		}
	}

	private buildTabsOffCanvas(): { id: number, name: string }[]
	{
		return [
			{ id: 1, name: 'Résumé' },
			{ id: 2, name: 'Contenu' },
			{ id: 3, name: 'Documents' },
			{ id: 4, name: 'Emails' },
		];
	}

	private handleTabChange(selectedTab: number): void
	{
		this.setState({ selectedTabOffCanvas: selectedTab });
	}

	private renderTitle(): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					alignItems: 'center',
					gap: '16px',
					marginBottom: '15px'
				} }>
					<div style={ {
						fontSize: '26px',
						fontWeight: 600,
						color: CssVariableEnum['--color-grey-900'],
					} }>
						Annonce légale
					</div>
					<Button
						type={ 'default' }
						iconRight={ <BsCopy style={ { fontSize: '10px' } }/> }
						onClick={ () => navigator.clipboard.writeText(`${ this.state.selectedLegalNotice?.tag }`) }
						style={ { fontSize: '12px' } }
					>
						{ this.state.selectedLegalNotice?.tag }
					</Button>
					{ this.state.selectedLegalNotice &&
            <TagEnum
              style={ { height: '32px', marginRight: '10px', minWidth: '71px' } }
              value={ (this.state.selectedLegalNotice) ? this.state.selectedLegalNotice.status.toString() : '' }
              enumName={ 'PublishStateEnum' }
            >
							{ PublishStateEnum.findByValue(this.state.selectedLegalNotice.status.toString())?.label }
            </TagEnum>
					}
				</div>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private selectedFilters(selectedFilters: any[]): void
	{
		const filtersObject = selectedFilters.reduce((acc, filter) =>
		{
			acc[filter.filterName] = filter.filterValue;
			return acc;
		}, {});

		this.setState(prevState => ({
				filterParams: {
					...prevState.filterParams,
					filters: filtersObject
				}
			}), async () => await this.refreshList()
		);
	}

	private async refreshList(): Promise<void>
	{
		try {
			const legalNoticeListResponse = await this.legalNoticeService.list(
				parseInt(LocalStorageService.getAuthClientLocalStorage()),
				paramsToString(this.state.filterParams)
			);

			if (legalNoticeListResponse) {
				this.setState({
					paginate: {
						items: legalNoticeListResponse.items,
						itemsPerPage: legalNoticeListResponse.itemsPerPage,
						totalCount: legalNoticeListResponse.length,
						totalPages: legalNoticeListResponse.totalPages
					}
				}, () =>
				{
					this.setState({ isLoading: false });
					//this.checkUrl();
				});
			} else {
				console.log('error');
			}

		} catch (error: any) {
			console.log('Something wrong on refresh user list', error.errorMessage);
		}
	}

	private buildTableHeaderLegalNotice(): TableHeaderListInterface[]
	{
		return [
			{
				columnTitle: 'N° d\'annonce',
				orderTag: 'tag',
				minWidth: 100,
				width: 100,
				justifyContent: 'center',
				fontWeight: 400,
				type: 'string',
				dataTag: 'tag'
			},
			{
				columnTitle: 'Publication au...',
				orderTag: 'publishDate',
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'date',
				dataTag: 'publishDate'
			},
			{
				columnTitle: 'Collaborateur',
				orderTag: 'collaborator',
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'string[]',
				dataTag: ['collaborator.user.firstname', 'collaborator.user.lastname']
			},
			{
				columnTitle: 'Client Final',
				orderTag: null,
				minWidth: 100,
				width: 250,
				justifyContent: 'left',
				fontWeight: 600,
				type: 'uppercaseString',
				dataTag: 'consumer.name'
			},
			{
				columnTitle: 'Journal',
				orderTag: 'newspaper',
				minWidth: 100,
				width: 200,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'twoLinesString',
				dataTag: ['newspaper.name', 'newspaper.type']
			},
			{
				columnTitle: 'Département',
				orderTag: null,
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'twoLinesString',
				dataTag: ['publishDepartment.name', 'publishDepartment.code']
			},
			{
				columnTitle: 'Statut',
				orderTag: 'status',
				minWidth: 100,
				width: 200,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'tag',
				dataTag: { label: 'status', enumClass: PublishStateEnum }
			},
			{
				columnTitle: 'Paiement',
				orderTag: 'paymentStatus',
				minWidth: 100,
				width: 150,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'tag',
				dataTag: { label: 'paymentStatus', enumClass: PaymentStateEnum }
			},
		];
	}

	private buildFilterParams(orderBy: string, sort: string): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				orderBy: orderBy,
				sort: sort
			}
		}), async (): Promise<void> => await this.refreshList());
	}

	private onRowClick(event: any, legalNotice: LegalNoticeInterface): void
	{
		if (event) {
			event.stopPropagation();
		}

		const index: number = this.state.paginate.items.findIndex(
			(legalNoticeItem: LegalNoticeInterface) => legalNoticeItem.id === legalNotice.id
		);

		this.setState({
			selectedLegalNotice: legalNotice,
			isOpenShowOffCanvas: true,
			selectedLegalNoticeIndex: index,
			currentItemId: legalNotice.id
		});
	}

	private itemPerPage(currentPage: number, itemPerPage: number): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				page: currentPage,
				itemsPerPage: itemPerPage
			}
		}), async () => await this.refreshList());
	}

	private checkForReload(): void
	{
		const searchParams = new URLSearchParams(this.props.location.search);
		if (searchParams.get('reload') === 'true') {
			this.refreshList();
			this.props.navigation(this.props.location.pathname);
		}
	}

	//</editor-fold>
}