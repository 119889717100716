import React, { ReactElement } from 'react';
import { CardActionProps, CardActionType } from '@/Modules/App/Type/CardActionComponentTypes';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuSearch } from "react-icons/lu";

export default class CardActionComponent extends React.Component
	<CardActionProps>
{
	state = {
		isHovered: null,
	};

	componentDidMount()
	{
		document.addEventListener('mousedown', this.handleClickOutMenuAction);
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousedown', this.handleClickOutMenuAction);
	}

	handleClickOutMenuAction = (event: MouseEvent): void =>
	{
		if (
			this.props.isOpen
			&& this.props.menuActionRef.current
			&& !this.props.menuActionRef.current.contains(event.target as Node)) {
			this.props.onClose && this.props.onClose();
		}
	};

	render(): ReactElement | null
	{
		if (!this.props.isOpen) {
			return null;
		}

		return (
			<>
				<div
					ref={ this.props.menuActionRef }
					style={ {
						position: 'absolute',
						zIndex: 2000,
						pointerEvents: 'auto',
						top: '100%',
						transform: (this.props.transform !== undefined && this.props.transform !== null) ? this.props.transform : ''
					} }>
					<div style={ {
						display: 'flex',
						alignItems: 'center',
						position: 'relative',
						flexDirection: 'column',
						left: 0,
						opacity: 1,
						transitionProperty: 'opacity, transform',
						transitionDuration: '270ms',
						transitionTimingFunction: 'ease',
					} }>
						<div style={ {
							borderRadius: '6px',
							background: CssVariableEnum['--color-white'],
							boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px',
							border: '1px solid' + CssVariableEnum['--color-grey-300'],
							position: 'relative',
							maxWidth: 'calc(-24px + 100vw)',
							overflow: 'hidden'
						} }>
							<div style={ {
								display: 'flex',
								flexDirection: 'column',
								minWidth: '220px',
								maxWidth: 'calc(-24px + 100vw)',
								height: '100%',
								maxHeight: '70vh'
							} }>
								<div style={ {
									zIndex: 1,
									flexGrow: 1,
									minHeight: 0,
									transform: 'translateZ(0px)',
									overflow: 'hidden, auto',
									marginRight: 0,
									marginBottom: 0,
								} }>
									{ this.buildItemsContent() }
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	private buildItemsContent(): ReactElement
	{
		const { buildActionsContent } = this.props;
		const { isHovered } = this.state;

		return (

			<>
				{ buildActionsContent.map((group, groupIndex) => (
					<div
						key={ `group-${ groupIndex }` }
						className={ `action-group level-${ groupIndex + 1 }` }
						style={ {
							paddingTop: '6px',
							paddingBottom: '6px'
						} }
					>
						{ group.map((item: CardActionType, itemIndex: number) => (
							<div
								key={ `item-${ groupIndex }-${ itemIndex }` }
								onClick={ item.isClickable ? () => item.action && item.action() : undefined }
								style={ {
									userSelect: 'none',
									cursor: 'pointer',
									width: 'calc(100% - 8px)',
									marginLeft: '4px',
									marginRight: '4px',
									borderRadius: '6px',
									backgroundColor: !item.inputSearch && item.name === isHovered ? CssVariableEnum['--color-grey-100'] : ''
								} }
								onMouseEnter={ () => this.setState({ isHovered: item.name }) }
								onMouseLeave={ () => this.setState({ isHovered: '' }) }
							>
								{ !item.inputSearch &&
                  <div style={ {
										display: 'flex',
										alignItems: 'center',
										lineHeight: '120%',
										width: '100%',
										userSelect: 'none',
										minHeight: '28px',
										fontSize: '14px',
										color: (item.name === isHovered) ? CssVariableEnum['--color-grey-900'] : CssVariableEnum['--color-grey-600'],
									} }>
										{ item.icon &&
                      <div style={ {
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												marginLeft: '10px',
												marginRight: '4px',
											} }>
												{ item.icon }
                      </div>
										}
                    <div style={ {
											marginLeft: '6px',
											marginRight: '12px',
											minWidth: '0px',
											flex: '1 1 auto',
										} }>
                      <div style={ {
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis'
											} }>
												{ item.name }
                      </div>
                    </div>
                  </div>
								}
								{ item.inputSearch &&
                  <div style={{ display: 'flex', alignItems: 'flex-end'}}>
										{ item.inputSearch }
										<Button
											type={'default-blue'}
											iconLeft={<LuSearch />}
											onClick={ () => item.action && item.action() }
										/>
                  </div>

								}
							</div>
						)) }
					</div>
				)) }
			</>
		);
	}
}