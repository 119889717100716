import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class SelectStyle
{
	static container(props: any, state: any): CSSProperties
	{
		return {
			position: 'relative',
			display: 'flex',
			minWidth: '100px',
			width: (props.buttonWidth) ? props.buttonWidth : '100%',
			opacity: props.disabled ? 0.4 : 1,
			backgroundColor: props.disabled ? '#f0f0f0' : CssVariableEnum['--color-grey-25'],
			borderRadius: CssVariableEnum['--border-radius-main'],
			boxShadow: (state.isOpenSelect) ? CssVariableEnum['--input-box-shadow-focus'] : CssVariableEnum['--input-box-shadow'],
			padding: CssVariableEnum['--input-padding'],
			height: CssVariableEnum['--input-height'],
			color: CssVariableEnum['--color-grey-900'],
			fontWeight: 500,
			alignItems: 'center',
			border: (state.isOpenSelect) ? `0.5px solid ${ CssVariableEnum['--color-blue-600'] }` : 'none',
			cursor: props.disabled ? 'not-allowed' : 'pointer',
			marginBottom: (props.isMarginBottom) ? '10px' : 0,
			...props.style,
		};
	}

	static selectedOption(state: any): CSSProperties
	{
		return {
			width: '100%',
			minWidth: '100%',
			paddingRight: '25px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontWeight: 400,
			color: (state.selectedOption) ? 'inherit' : CssVariableEnum['--color-grey-400'],
			whiteSpace: 'nowrap'
		};
	}

	static openMenu(props: any, state: any): CSSProperties
	{
		return {
			position: 'absolute',
			zIndex: 1000,
			backgroundColor: CssVariableEnum['--color-white'],
			borderRadius: '10px',
			boxShadow: '0 2px 20px #00000029',
			minWidth: '100px',
			width: (props.selectMenuWidth) ? props.selectMenuWidth : '100%',
			top: (state.menuPosition.top) ? state.menuPosition.top : null,
			bottom: (state.menuPosition.bottom) ? state.menuPosition.bottom : null,
			left: (state.menuPosition.left) ? state.menuPosition.left : null,
			right: (state.menuPosition.right) ? state.menuPosition.right : null,
			fontWeight: 500,
			border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`
		};
	}

	static label(): CSSProperties
	{
		return {
			fontSize: '12px',
			color: '#666666',
			marginBottom: '4px'
		};
	}
}