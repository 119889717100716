import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAdminSupplierService extends BaseService {
  supplierUrl: string = `${ BaseService.API_URL }/v1/admin/suppliers`;

  async list(): Promise<any> {
    try {
      const response = await fetch(
        `${ this.supplierUrl }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async edit(companyId: number, supplierId: number): Promise<any> {
    try {
      const response = await fetch(
        `${ this.supplierUrl }/${companyId}/${supplierId}`,
        {
          method: HttpVerbEnum.PATCH,
          headers: this.buildHeaders()
        }
      );

      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}