import React, { ReactElement } from 'react';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import InviteCollaboratorModel from '@/Modules/Collaborator/Model/InviteCollaboratorModel';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import Button from '@/Modules/App/Components/Atom/Button/Button';

export default class InviteCollaboratorForm extends React.Component <any, any>
{
	// Properties
	state = {
		formData: new InviteCollaboratorModel()
	};

	constructor(props: any)
	{
		super(props);

		this.submitHandler = this.submitHandler.bind(this);
	}

	render(): ReactElement
	{
		return (
			<div style={{ display: 'flex', flexDirection: 'column', gap: 15}}>
				<Input
					type={ 'text' }
					label={ 'Email collaborateur' }
					name={ 'inviteMail' }
					width={ '100%' }
					placeholder={ 'john.doe@unknow.com' }
					value={ this.state.formData.inviteEmail }
					onChange={
						event =>
							this.setState((prevState: { formData: any; }) => ({
								formData: {
									...prevState.formData,
									inviteEmail: event.target.value
								}
							}))
					}
					required={ true }
				/>
				<SelectComponent
					label={ 'Fonction du futur Collaborateur ' }
					listOptions={ CollaboratorRoleEnum.roleOptionsWithoutOwner }
					onSelectedOption={ (event: any) => this.onSelectedOption(event) }
					renderOptionLabel={ (option) => option.label }
				/>
				<Input
					type={ 'text' }
					width={ '100%'}
					label={ 'Message pour le futur Collaborateur' }
					name="additonnalData"
					placeholder="Merci de rejoindre mon cabinet"
					value={ this.state.formData.additionalInfo }
					onChange={
						event =>
							this.setState((prevState: { formData: any; }) => ({
								formData: {
									...prevState.formData,
									additionalInfo: event.target.value
								}
							}))
					}
					required={ true }
				/>
				<div style={ { width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 20} }>
					<Button type={ 'default-blue' } onClick={ this.submitHandler }>
						Inviter le collaborateur
					</Button>
				</div>
			</div>
		)
			;
	}

	async submitHandler(event: React.FormEvent<HTMLFormElement>): Promise<void>
	{
		event.preventDefault();
		this.props.onFormSubmit(this.state.formData);
	}

	private onSelectedOption(role: CollaboratorRoleEnum): void
	{
		this.setState((prevState: { formData: any; }) => ({
			formData: {
				...prevState.formData,
				collaboratorRole: role.value
			}
		}));
	}
}