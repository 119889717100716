import React, { createRef, ReactElement } from 'react';
import { BsChevronExpand } from 'react-icons/bs';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { NavigateFunction } from 'react-router-dom';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import HeaderSwitchCompanyStyle from '@/Modules/App/Style/HeaderSwitchCompanyStyle';
import TransitionStyle from '@/Modules/App/Style/Base/TransitionStyle';
import CardStyle from '@/Modules/App/Style/Components/CardStyle';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { MenuAdminStyle } from '@/Modules/App/Style/Menus/MenuAdminStyle';
import { AuthClientContextType } from '@/Provider/Interface/AuthClient/AuthClientContextType';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ApiClientService } from '@/Service/Api/ApiClientService';

interface ComponentProps
{
	flashMessageContext: FlashMessageContextType,
	clientContext: AuthClientContextType,
	authContext: AuthContextType,
	navigation: NavigateFunction
	isThemeDark: boolean
}

interface ComponentState
{
	isOpen: boolean,
	isLoading: boolean,
	errorMessage: string | null,
	hoveredClientId: number | null
}

export default class HeaderSwitchClient extends React.Component<ComponentProps, ComponentState>
{
	clientService: ApiClientService;

	private menuClientRef = createRef<HTMLDivElement>();

	constructor(props: any)
	{
		super(props);

		this.state = this.initState();

		// Services
		this.clientService = new ApiClientService();
	}

	render(): ReactElement
	{
		if (!this.props.clientContext) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<div ref={ this.menuClientRef } style={ HeaderSwitchCompanyStyle.container() }>
					<div style={ HeaderSwitchCompanyStyle.switch(this.props.isThemeDark) }
							 onClick={ this.handleOpenClientMenu.bind(this) }>
						{ this.getCurrentClientName() }
						<BsChevronExpand style={ { marginLeft: '15px' } } className="icon bi-chevron-expand"/>
					</div>
					{ this.selectCompanyContent(this.state.isOpen) }
				</div>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		if (this.props.clientContext.fetchClients) {
			await this.props.clientContext.fetchClients();
		}
		document.addEventListener('mousedown', this.handleCloseClientMenu);
	}

	componentWillUnmount(): void
	{
		document.removeEventListener('mousedown', this.handleCloseClientMenu);
	}

	private initState(): ComponentState
	{
		return {
			isOpen: false,
			isLoading: true,
			errorMessage: null,
			hoveredClientId: null
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private selectCompanyContent(isOpen: boolean): ReactElement
	{
		// Filter company
		const filteredClients: ClientInterface[] = this.props.clientContext.clients.filter(
			(client: ClientInterface): boolean => client.id !== (this.props.clientContext.authClient
				&& this.props.clientContext.authClient.id)
		);

		return (
			<div style={ { ...TransitionStyle.transitionAbsolute(isOpen), top: 40, left: '260px' } }>
				{ isOpen &&
          <div style={ CardStyle.main('', true) }>
            <div style={ CardStyle.container() }>
              <div style={ { display: 'flex', gap: 10, alignItems: 'center' } }>
                <div style={ HeaderSwitchCompanyStyle.cardOpenImage() }>
                  <div style={ { fontSize: 35 } }>🧑‍💻</div>
                </div>
                <div style={ { display: 'flex', flexDirection: 'column', height: '100%' } }>
                  <span style={ FontStyle.littleGrey() }>Entreprise active</span>
                  <div style={ FontStyle.normalMedium() }>
										{ this.props.clientContext.authClient && this.props.clientContext.authClient.name }
                  </div>
                </div>
              </div>
            </div>
						{ filteredClients.length > 0 && <div style={ HeaderSwitchCompanyStyle.cardOpenMenuCompany() }>
              <div>
								{ filteredClients.map((client: ClientInterface) => (
									<li
										style={ MenuAdminStyle.menuItem('3px 9px', this.state.hoveredClientId, client.id) }
										key={ client.id }
										value={ client.id }
										data-company-name={ client.name }
										onClick={ this.onSwitchClient.bind(this) }
										onMouseEnter={ () => this.setState({ hoveredClientId: client.id }) }
										onMouseLeave={ () => this.setState({ hoveredClientId: null }) }
									>
										<div>{ client.name }</div>
									</li>
								)) }
              </div>
            </div> }
          </div>
				}
			</div>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Open / Close menu methods" defaultstate="collapsed">

	private handleOpenClientMenu()
	{
		this.setState({ isOpen: true });
	}

	handleCloseClientMenu = (event: MouseEvent): void =>
	{
		if (this.menuClientRef.current && !this.menuClientRef.current.contains(event.target as Node)) {
			setTimeout(() =>
			{
				this.setState({ isOpen: false });
			}, 300);
		}
	};
	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private getCurrentClientName(): string
	{
		const { clientContext } = this.props;
		return clientContext?.authClient?.name ?? 'No Client Selected';
	}

	private onSwitchClient(event: React.MouseEvent<HTMLLIElement>): void
	{
		const target = event.currentTarget as HTMLLIElement;
		const clientId = target.getAttribute('value');
		const clientName = target.dataset.clientName;
		// Display Loader
		this.setState({ isOpen: false });

		if (clientId) {
			this.switchClient(parseInt(clientId), clientName as string);
		}
	}

	private async switchClient(clientId: number, clientName: string): Promise<void>
	{
		try {
			await this.props.clientContext.refreshClient(clientId);
			// Set Flash message
			this.props.flashMessageContext.flashMessage(
				'Changement d\'entreprise réussi',
				`Vous êtes maintenant connecté sur ${ clientName }`,
				'success'
			);
			// Navigate to /admin
			this.props.navigation('/');
		} catch (error) {
			console.error('Error switching client:', error);
			this.props.flashMessageContext.flashMessage(
				'Erreur lors du changement d\'entreprise',
				'Une erreur est survenue lors du changement d\'entreprise. Veuillez réessayer.',
				'error'
			);
		}
	}

	//</editor-fold>
}
