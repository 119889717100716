import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class ModalStyle
{
	static backdrop(): CSSProperties
	{
		return 	{
			position: 'absolute',
			height: '100vh',
			width: '100%',
			backgroundColor: CssVariableEnum['--color-grey-800-opacity-50'],
			backdropFilter: 'blur(3px)',
			top: 0,
			left: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 1500,
		};
	}

	static container(): CSSProperties
	{
		return {
			position: 'relative',
			minHeight: '150px',
			minWidth: '500px',
			maxWidth: '1200px',
			backgroundColor: 'white',
			borderRadius: '20px',
			border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
		};
	}

	static errorMessage(): CSSProperties
	{
		return {
			backgroundColor: CssVariableEnum['--color-error-100'],
			color: CssVariableEnum['--color-error-600'],
			position: 'relative',
			transitionProperty: 'opacity, transform',
			transitionDuration: '500ms',
			transitionTimingFunction: 'ease'
		};
	}
}