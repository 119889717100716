import { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class UserRoleEnum
{
	value: string;
	label: string;
	css: { backgroundColor: string, color: string, icon?: ReactElement | null };

	constructor(value: string, label: string, css: { backgroundColor: string, color: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static ROLE_DEVELOPER = new UserRoleEnum(
		'ROLE_DEVELOPER',
		'Développeur',
		{
			backgroundColor: CssVariableEnum['--color-error-100'],
			color: CssVariableEnum['--color-error-500'],
		}
	);
	static ROLE_SUPER_ADMIN = new UserRoleEnum(
		'ROLE_SUPER_ADMIN',
		'Super Admin',
		{
			backgroundColor: CssVariableEnum['--color-orange-100'],
			color: CssVariableEnum['--color-orange-500'],
		}
	);
	static ROLE_ADMIN = new UserRoleEnum(
		'ROLE_ADMIN',
		'Admin',
		{
			backgroundColor: CssVariableEnum['--color-yellow-100'],
			color: CssVariableEnum['--color-yellow-500'],
		}
	);
	static ROLE_USER = new UserRoleEnum(
		'ROLE_USER',
		'Utilisateur',
		{
			backgroundColor: CssVariableEnum['--color-purple-100'],
			color: CssVariableEnum['--color-purple-500'],
		}
	);

	static options: UserRoleEnum[] = [
		UserRoleEnum.ROLE_DEVELOPER,
		UserRoleEnum.ROLE_SUPER_ADMIN,
		UserRoleEnum.ROLE_ADMIN,
		UserRoleEnum.ROLE_USER,
	];

	static filteredOptions: UserRoleEnum[] = [
		UserRoleEnum.ROLE_ADMIN,
		UserRoleEnum.ROLE_USER,
	];

	static findByValue(selectedOption: UserRoleEnum | string): any
	{
		return UserRoleEnum.options.find(
			option => option.value === selectedOption
		);
	}
}