import React, { CSSProperties, ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class LineSeparator extends React.Component
	<
		{
			style?: CSSProperties,
		}, any>
{

	render(): ReactElement
	{
		return (
			<>
				<div style={{
					height: '1px',
					width: '100%',
					backgroundColor: CssVariableEnum['--color-grey-200'],
					margin: 0,
					...this.props.style
				}}>

				</div>
			</>
		);
	};
}