export default class UserCivilityEnum {
  value: string;
  label: string;
  css: string;

  constructor(value: string, label: string, css: string) {
    this.value = value;
    this.label = label;
    this.css = css;
  }

  static M = new UserCivilityEnum('M', 'M', '');
  static W = new UserCivilityEnum('W', 'F', '');
  static NI = new UserCivilityEnum('NI', 'NI', '');

  static roleOptions: UserCivilityEnum[] = [
    UserCivilityEnum.M,
    UserCivilityEnum.W,
    UserCivilityEnum.NI,
  ];

  static findByValue(value: string): UserCivilityEnum|undefined
  {
    return this.roleOptions.find(option => option.value === value);
  }
}
