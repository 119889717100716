import React, { CSSProperties, ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import { PaymentSellsyStatusEnum } from '@/Modules/Payment/Enum/PaymentSellsyStatusEnum';

export default class TagEnum extends React.Component
	<{
		children: React.ReactNode
		value: string
		enumName: string,
		style?: CSSProperties
	}, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					height: '24px',
					padding: '5px 8px',
					backgroundColor: this.generateTagFromEnum(this.props.enumName, this.props.value)?.backgroundColor,
					border: this.generateTagFromEnum(this.props.enumName, this.props.value)?.border,
					color: this.generateTagFromEnum(this.props.enumName, this.props.value)?.color,
					borderRadius: '6px',
					display: 'inline-flex',
					justifyContent: 'center',
					alignItems: 'center',
					...this.props.style
				} }>
					{ this.generateTagFromEnum(this.props.enumName, this.props.value)?.icon &&
            <div style={ { display: 'flex', alignItems: 'center', marginRight: '5px' } }>
							{ this.generateTagFromEnum(this.props.enumName, this.props.value)?.icon }
            </div>
					}
					<span>{ this.props.children }</span>
				</div>
			</>
		);
	};


	private generateTagFromEnum(enumName: string, value: any): {
		enum: any,
		backgroundColor: CssVariableEnum | string | undefined,
		border?: string,
		color: CssVariableEnum | string | undefined,
		icon: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null | undefined;
	} | null
	{
		// const
		const activeStatus = {
			enum: ActiveStatusEnum.findByValue(this.props.value),
			backgroundColor: (value === ActiveStatusEnum.ACTIVE.value) ? CssVariableEnum['--color-green-200'] : CssVariableEnum['--color-error-200'],
			color: (value === ActiveStatusEnum.ACTIVE.value) ? CssVariableEnum['--color-green-700'] : CssVariableEnum['--color-error-700'],
			icon: null
		};
		const collaboratorRole = {
			enum: CollaboratorRoleEnum.findByValue(this.props.value),
			backgroundColor: CollaboratorRoleEnum.findByValue(this.props.value)?.css.backgroundColor,
			color: CollaboratorRoleEnum.findByValue(this.props.value)?.css.color,
			icon: null
		};
		const publishStatusEnum = {
			enum: PublishStateEnum.findByValue(this.props.value),
			backgroundColor: PublishStateEnum.findByValue(this.props.value)?.css.backgroundColor,
			border: PublishStateEnum.findByValue(this.props.value)?.css.border,
			color: PublishStateEnum.findByValue(this.props.value)?.css.color,
			icon: null
		};
		const paymentStateEnum = {
			enum: PaymentStateEnum.findByValue(this.props.value),
			backgroundColor: PaymentStateEnum.findByValue(this.props.value)?.css.backgroundColor,
			color: PaymentStateEnum.findByValue(this.props.value)?.css.color,
			icon: null
		};
		const paymentSellsyStatusEnum = {
			enum: PaymentSellsyStatusEnum.findByValue(this.props.value),
			backgroundColor: PaymentSellsyStatusEnum.findByValue(this.props.value)?.css.backgroundColor,
			color: PaymentSellsyStatusEnum.findByValue(this.props.value)?.css.color,
			icon: null
		};
		const quoteStatusEnum = {
			enum: QuoteStatusEnum.findByValue(this.props.value),
			backgroundColor: QuoteStatusEnum.findByValue(this.props.value)?.css.backgroundColor,
			color: QuoteStatusEnum.findByValue(this.props.value)?.css.color,
			icon: null
		};
		const userRoleEnum = {
			enum: UserRoleEnum.findByValue(this.props.value),
			backgroundColor: UserRoleEnum.findByValue(this.props.value)?.css.backgroundColor,
			color: UserRoleEnum.findByValue(this.props.value)?.css.color,
			icon: null
		};

		switch (enumName) {
			case 'ActiveStatusEnum':
				return activeStatus;
			case 'CollaboratorRoleEnum':
				return collaboratorRole;
			case 'PublishStateEnum':
				return  publishStatusEnum;
			case 'PaymentStateEnum':
				return  paymentStateEnum;
			case 'PaymentSellsyStatusEnum':
				return  paymentSellsyStatusEnum;
			case 'QuoteStatusEnum':
				return  quoteStatusEnum;
			case 'UserRoleEnum':
				return userRoleEnum;
			default:
				return null;
		}
	}
}