import React, { ReactElement } from 'react';
import { stringAvatar } from '@/Utils/AvatarUtils';
import Skeleton from 'react-loading-skeleton';

export default class AvatarComponent extends React.Component
	<any, { onAvatarClick?: () => void }>
{
	render(): ReactElement
	{
		return (
			<>
				{ !this.props.authContext.user
					? <Skeleton width={ 30 } height={ 30 } circle={ true }/>
					:
					<div
						className="avatar"
						style={ { backgroundColor: stringAvatar(this.props.authContext.user.firstname, this.props.authContext.user.lastname).color } }
						onClick={ this.props.onAvatarClick }
					>
						{ stringAvatar(this.props.authContext.user.firstname, this.props.authContext.user.lastname).avatar }
					</div>
				}
			</>
		);
	}
}