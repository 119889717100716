import React, { ReactElement } from 'react';
import { dateFormat } from '@/Utils/DateUtils';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { ApiCollaboratorService } from '@/Service/Api/ApiCollaboratorService';
import { BsTrash, BsFloppy, BsPencil } from 'react-icons/bs'
import LocalStorageService from '@/Service/Common/LocalStorageService';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { UserService } from '@/Service/UserService';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { AuthClientContextType } from '@/Provider/Interface/AuthClient/AuthClientContextType';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import TagEnum from '@/Modules/App/Components/Atom/Tags/TagEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';

interface ComponentProps
{
	selectedCollaborator: CollaboratorInterface;
	authContext: AuthContextType;
	clientContext: AuthClientContextType;
	flashMessageContext: FlashMessageContextType;
	currentItemId: number | null;
	handleDeleted: (collaboratorId: number) => void;
	handleUpdated: (updatedCollaboratorData: CollaboratorInterface, collaboratorId: number) => void;
	isUpdatedSuccess: boolean;
}

interface ComponentState
{
	isEditMode: boolean;
	email: string;
	selectedRole: CollaboratorRoleEnum;
	selectedStatus: ActiveStatusEnum;
	errorMessage: string;
}

export default class CollaboratorShowComponent extends React.Component
	<ComponentProps, ComponentState>
{

	// <editor-fold desc="Properties / Ctr" defaultstate="collapsed">

	// Properties
	collaboratorService: ApiCollaboratorService;

	// Ctr
	constructor(props: any)
	{
		super(props);
		this.state = this.initState();

		// Services
		this.collaboratorService = new ApiCollaboratorService();

		// Bind
		this.inputSelectRoleStatusContent = this.inputSelectRoleStatusContent.bind(this);
		this.inputSelectActiveStatusContent = this.inputSelectActiveStatusContent.bind(this);
		this.collaboratorRole = this.collaboratorRole.bind(this);
		this.collaboratorStatus = this.collaboratorStatus.bind(this);
		this.editAndDeleteButtonActionContent = this.editAndDeleteButtonActionContent.bind(this);
		this.updateCollaborator = this.updateCollaborator.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.isAllowEditCollaborator = this.isAllowEditCollaborator.bind(this);
	}

	// </editor-fold>

	render(): ReactElement
	{
		if (!this.props.selectedCollaborator) {
			return <LoaderFullPageComponent/>;
		}

		return (
			<>
				<div style={ {
					display: 'flex',
					position: 'absolute',
					top: '20px',
					right: '30px',
				} }>
					{ (this.state.isEditMode)
						? this.saveEditButtonActionContent()
						: this.editAndDeleteButtonActionContent()
					}
				</div>

				<div>
					{ this.rowDataCollaboratorEditContent() }
				</div>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidUpdate(prevProps: any): void
	{
		if (
			(!prevProps.isUpdatedSuccess && this.props.isUpdatedSuccess)
			|| (this.props.currentItemId && this.props.currentItemId !== prevProps.currentItemId)) {
			this.setState({ isEditMode: false });
		}
	}

	private initState()
	{
		return {
			isEditMode: false,
			email: this.props.selectedCollaborator.email,
			selectedRole: this.props.selectedCollaborator.role,
			selectedStatus: this.props.selectedCollaborator.status,
			errorMessage: '',
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private rowDataCollaboratorEditContent(): ReactElement
	{
		return (
			<>
				<div style={ {
					marginTop: '30px',
					display: 'flex',
					flexDirection: 'column',
					gap: '15px'
				} }>

					{ this.collaboratorDetailsHtmlContent() }

					{
						(this.state.errorMessage)
						&& <div className="error-message">
							{ this.state.errorMessage }
            </div>
					}
				</div>
			</>
		);
	}

	private collaboratorDetailsHtmlContent(): ReactElement
	{
		return (
			<div style={ {
				display: 'flex',
				width: '100%',
				flexDirection: 'column',
				backgroundColor: CssVariableEnum['--color-white'],
				border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
				borderRadius: '6px',
				padding: '5px 10px'
			} }>
				<Title type={ 'h6' } style={ { marginTop: '0' } }>Détails</Title>
				{ this.lineDetailsHtmlContent(
					'Collaborateur depuis',
					dateFormat(this.props.selectedCollaborator.createdAt),
					null
				) }
				{ this.lineDetailsHtmlContent(
					'Email',
					`${ this.props.selectedCollaborator.email }`,
					<Input
						type={ 'text' }
						name={ 'email-collaborator-edit' }
						width={ '100%' }
						value={ this.state.email }
						onChange={ event => this.setState({ email: event.target.value }) }
					/>
				) }
				{ this.lineDetailsHtmlContent(
					'Rôle',
					<TagEnum value={ this.props.selectedCollaborator.role.toString() } enumName={ 'CollaboratorRoleEnum' }>
						{ CollaboratorRoleEnum.findByValue(this.props.selectedCollaborator.role.toString())?.label }
					</TagEnum>,
					null
				) }
				{ this.lineDetailsHtmlContent(
					'Statut',
					<TagEnum value={ this.state.selectedStatus.toString() } enumName={ 'ActiveStatusEnum' }>
						{ ActiveStatusEnum.findByValue(this.props.selectedCollaborator.status.toString())?.label }
					</TagEnum>,
					<>
						<div style={ { display: 'flex', alignItems: 'center' } }>
							<TagEnum value={ this.state.selectedStatus.toString() } enumName={ 'ActiveStatusEnum' }>
								{ ActiveStatusEnum.findByValue(this.props.selectedCollaborator.status.toString())?.label }
							</TagEnum>
							{ (this.props.selectedCollaborator.status.toString() === ActiveStatusEnum.ACTIVE.value)
								? <Button
									type={ 'default' }
									onClick={ () =>
										this.setState({
											selectedStatus: ActiveStatusEnum.INACTIVE
										}) }
								>
									Rendre Inactif
								</Button>
								: <Button
									type={ 'default' }
									onClick={ () =>
										this.setState({
											selectedStatus: ActiveStatusEnum.ACTIVE
										}) }
								>
									Rendre Actif
								</Button>
							}
						</div>
					</>
				) }
			</div>
		);
	}

	private lineDetailsHtmlContent(label: string, data: any, renderEdit: ReactElement | null): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					width: '100%',
					marginBottom: '15px'
				} }>
					<div style={ {
						width: '320px',
						color: CssVariableEnum['--color-grey-400']
					} }>
						{ label }
					</div>
					{ (this.state.isEditMode && renderEdit)
						? renderEdit
						: <div style={ {
							fontWeight: 600,
							color: CssVariableEnum['--color-grey-900'],
						} }>
							{ data }
						</div>
					}
				</div>
			</>
		);
	}

	//</editor-fold>

	// <editor-fold desc="Private Functions" defaultstate="collapsed">

	private collaboratorRole(collaboratorRole: string): any
	{
		return CollaboratorRoleEnum.roleOptions.find(
			role => role.value === collaboratorRole
		);
	}

	private collaboratorStatus(collaboratorStatus: string): any
	{
		return ActiveStatusEnum.roleOptions.find(
			role => role.value === collaboratorStatus
		);
	}

	private inputSelectRoleStatusContent(): ReactElement
	{
		const filteredRoleOptions = CollaboratorRoleEnum.roleOptions.filter(
			role => role.value !== CollaboratorRoleEnum.ROLE_OWNER.value
		);

		return (
			<>
				<SelectComponent
					onSelectedOption={ this.collaboratorRole(this.props.selectedCollaborator.role.toString()) }
					listOptions={ filteredRoleOptions }
					renderOptionLabel={ option => option.label }
				/>
			</>
		);
	}

	private inputSelectActiveStatusContent(): ReactElement
	{
		return (
			<>
				<SelectComponent
					onSelectedOption={ this.collaboratorStatus(this.props.selectedCollaborator.status.toString()) }
					listOptions={ ActiveStatusEnum.roleOptions }
					renderOptionLabel={ option => option.label }
				/>
			</>
		);
	}

	private saveEditButtonActionContent(): ReactElement
	{
		return (
			<>
				<Button
					type={ 'default' }
					onClick={ () => this.setState({ isEditMode: false }) }
				>
					Annuler
				</Button>
				<Button
					type={ 'default-blue' }
					iconLeft={ <BsFloppy/> }
					onClick={ this.handleUpdate }
				>
					Sauvegarder
				</Button>
			</>
		);
	}

	private editAndDeleteButtonActionContent(): ReactElement
	{
		return (
			<>
				{
					(UserService.isRoleOwner(this.props.authContext.user, parseInt(LocalStorageService.getAuthClientLocalStorage())))
					&& <Button
            type={ 'default' }
            iconLeft={ <BsPencil/> }
            onClick={ () => this.setState({ isEditMode: true }) }
          >
            Editer
          </Button>
				}
				{
					(
						this.props.selectedCollaborator.status.toString() !== ActiveStatusEnum.INACTIVE.value
						&& UserService.isRoleOwner(this.props.authContext.user, parseInt(LocalStorageService.getAuthClientLocalStorage()))
					)
					&& <Button
            type={ 'danger' }
            iconLeft={ <BsTrash/> }
            onClick={ () => this.handleDelete(this.props.selectedCollaborator.id) }
          />
				}
			</>
		);
	}

	async handleUpdate(event: any)
	{
		event.preventDefault();

		const updatedCollaboratorData = {
			email: this.state.email,
			role: this.state.selectedRole,
			status: this.state.selectedStatus
		};

		this.updateCollaborator(updatedCollaboratorData);
	};

	async updateCollaborator(updatedCollaboratorData: any)
	{
		this.props.handleUpdated(updatedCollaboratorData, this.props.selectedCollaborator.id);
	}

	async handleDelete(collaboratorId: number)
	{
		this.props.handleDeleted(collaboratorId);
	}

	private isAllowEditCollaborator()
	{
		const getUserCollaboratorOwner = UserService.getCollaboratorFromUser(
			this.props.authContext.user,
			parseInt(LocalStorageService.getAuthClientLocalStorage())
		);

		return this.props.selectedCollaborator.id !== (getUserCollaboratorOwner && getUserCollaboratorOwner.id);
	}

	private activeStatus(activeStatus: string): any
	{
		return ActiveStatusEnum.roleOptions.find(
			role => role.value === activeStatus
		);
	}

	// </editor-fold>
}
