import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalContext } from '@/Provider/ModalProvider';
import LegalNoticeAdminValidateView from '@/Modules/LegalNotice/Admin/View/LegalNoticeAdminValidateView';
import { AuthCompanyContext } from '@/Provider/AuthCompanyProvider';
import { AuthContext } from '@/Provider/AuthProvider';

const LegalNoticeAdminValidateWrapper = (props: any) => {
  const navigate = useNavigate();
  const flashMessageContext = useContext(FlashMessageContext);
  const authContext = useContext(AuthContext);
  const authCompanyContext = useContext(AuthCompanyContext);
  const location = useLocation();
  const modalContext = useContext(ModalContext);


  return <LegalNoticeAdminValidateView
    authContext={ authContext }
    authCompanyContext={ authCompanyContext }
    flashMessageContext={ flashMessageContext }
    navigation={ navigate }
    modalContext = { modalContext }
    location={ location }
    { ...props }
  />;
};

export default LegalNoticeAdminValidateWrapper;