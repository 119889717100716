import { TableHeaderListInterface } from '@/Modules/App/Components/Atom/Table/TableComponent';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';

export class UserAdminViewService
{
  static buildTabsOffCanvas(): { id: number, name: string }[]
  {
    return [
      { id: 1, name: 'Détails' },
      { id: 2, name: 'Collaborateurs' },
    ];
  }

  static buildTableHeaderUser(props: any): TableHeaderListInterface[]
  {
    const headers: TableHeaderListInterface[] = [
      {
        columnTitle: 'Utilisateur',
        orderTag: 'lastname',
        minWidth: 100,
        width: 500,
        fontWeight: 600,
        justifyContent: 'left',
        type: 'user',
        dataTag: ['firstname', 'lastname', 'email']
      },
      {
        columnTitle: 'Téléphone',
        orderTag: 'phone',
        minWidth: 100,
        width: 200,
        fontWeight: 400,
        justifyContent: 'left',
        type: 'string',
        dataTag: 'phone'
      },
      {
        columnTitle: 'Crée le...',
        orderTag: 'createdAt',
        minWidth: 100,
        width: 200,
        fontWeight: 400,
        justifyContent: 'left',
        type: 'date',
        dataTag: 'createdAt'
      },
      {
        columnTitle: 'Rôle',
        orderTag: 'role',
        minWidth: 100,
        width: 200,
        fontWeight: 400,
        justifyContent: 'left',
        type: 'tag',
        dataTag: { label: 'role', enumClass: UserRoleEnum }
      },
      {
        columnTitle: 'Statut',
        orderTag: 'status',
        minWidth: 100,
        width: 200,
        fontWeight: 400,
        justifyContent: 'left',
        type: 'tag',
        dataTag: { label: 'status', enumClass: ActiveStatusEnum }
      }
    ];

    if (props.authContext && props.authContext.user.role === UserRoleEnum.ROLE_DEVELOPER.value) {
      headers.splice(1, 0, {
        columnTitle: 'Entreprise',
        orderTag: 'company',
        minWidth: 100,
        width: 200,
        fontWeight: 500,
        justifyContent: 'left',
        type: 'company',
        dataTag: ['company.name', 'company.city']
      });
    }

    return headers;
  }
}