import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';

export class ApiCollaboratorService extends BaseService
{
  collaboratorUrl: string = `${ BaseService.API_URL }/v1/collaborators`;

  async list(clientId: number, params?: string): Promise<any>
  {
    try {
      const url: string = params ? `${ this.collaboratorUrl }/${ clientId }?params` : `${ this.collaboratorUrl }/${ clientId }`;
      const response: Response = await fetch(
        url,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async show(clientId: string, collaboratorId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.collaboratorUrl }/${ clientId }/${ collaboratorId }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async create(clientModel: ClientModel): Promise<any>
  {
    try {
      const response = await fetch(
        this.collaboratorUrl,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(clientModel),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async edit(editCollaboratorData: any, clientId: number, collaboratorId: number): Promise<any>
  {
    try {
      const response = await fetch(
          this.collaboratorUrl + '/' + clientId + '/' + collaboratorId,
          {
            method: HttpVerbEnum.PATCH,
            headers: this.buildHeaders(),
            body: JSON.stringify(editCollaboratorData)
          },
        )
      ;

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async delete(clientId: number, collaboratorId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.collaboratorUrl }/${ clientId }/${ collaboratorId }`,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}