import React, { CSSProperties, ReactElement, ReactNode } from 'react';

export default class BlockGroup extends React.Component
	<
		{
			children: ReactNode,
			style?: CSSProperties,
		},
		any
	>
{
	render(): ReactElement
	{
		return (
			<div style={ {
				position: 'relative',
				width: '100%',
				display: 'flex',
				gap: 10,
				...this.props.style
			} }>
				{ this.props.children }
			</div>
		);
	};
}