import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import CreateLegalNoticeView from '@/Modules/LegalNotice/Admin/View/CreateLegalNoticeView';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalContext } from '@/Provider/ModalProvider';
import { AuthCompanyContext } from '@/Provider/AuthCompanyProvider';
import { NotificationContext } from '@/Provider/NotificationProvider';

const CreateLegalNoticeAdminViewWrapper = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authCompanyContext = useContext(AuthCompanyContext);
  const flashMessageContext = useContext(FlashMessageContext);
  const modalContext = useContext(ModalContext);
  const notificationContext = useContext(NotificationContext);

  return <CreateLegalNoticeView
    flashMessageContext={ flashMessageContext }
    authCompanyContext={ authCompanyContext }
    modalContext = { modalContext }
    notificationContext={ notificationContext }
    location={ location }
    navigation={ navigate }
    { ...props }
  />;
};

export default CreateLegalNoticeAdminViewWrapper;