import React, { ReactElement } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BsFillDashCircleFill, BsFillPlusCircleFill, BsGripVertical } from 'react-icons/bs';
import { FormBuilderInputsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderInputsInterface';
import { FormBuilderOptionsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderOptionsInterface';
import Skeleton from 'react-loading-skeleton';

interface ComponentProps
{
	sectionInput: FormBuilderInputsInterface | null,
	listOption: FormBuilderOptionsInterface[],
	isLoading: boolean,
	onCreate: (label: string) => Promise<void>,
	onEdit: (optionData: FormBuilderOptionsInterface, optionId: number) => void;
	onDelete: (optionId: number) => void
}

interface ComponentState
{
	options: FormBuilderOptionsInterface[],
	formData: {
		label: string
	},
	formDataList: FormBuilderOptionsInterface[]
}

export default class FormBuilderSectionInputOptionsComponent extends React.Component
	<ComponentProps, ComponentState>
{

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Bind
		this.refreshListOptions = this.refreshListOptions.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.onKeyDownCreate = this.onKeyDownCreate.bind(this);
		this.onKeyDownEdit = this.onKeyDownEdit.bind(this);
		this.handleCreateNewOption = this.handleCreateNewOption.bind(this);
		this.onChangeInputNewOption = this.onChangeInputNewOption.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<section className="row formBuilder-section mt-3">
					<div className="col-8 formBuilder-section__actions">
						<div className="h6"> Options :</div>
						<DragDropContext onDragEnd={ (result: any) => this.onDragEnd(result) }>
							{ this.listOptionHtmlContent() }
							{ this.addNewOptionHtmlContent() }
						</DragDropContext>
					</div>
				</section>
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		this.buildFormDataList();
		await this.refreshListOptions();
	}

	private initState(): ComponentState
	{
		return {
			options: [],
			formData: {
				label: ''
			},
			formDataList: [] as FormBuilderOptionsInterface[],
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private listOptionHtmlContent(): ReactElement
	{
		return (
			<>
				<Droppable droppableId={ (this.props.sectionInput) ? this.props.sectionInput.id.toString() : '0' }>
					{
						(provided: any) => (
							<div
								className="formBuilder-list"
								ref={ provided.innerRef }
								{ ...provided.droppableProps }
							>
								{
									this.state.options.length > 0 && this.state.options.map((value: any, index: any) => (
										(this.props.isLoading)
											? <Skeleton key={ value.id.toString() } height={ 32 } width={ 150 }/>
											: <Draggable
												draggableId={ value.id.toString() }
												index={ index }
												key={ value.id.toString() }
											>
												{ (provided: any) => (
													<div
														className="formBuilder-list__input"
														key={ value.id.toString() }
														{ ...provided.draggableProps }
														{ ...provided.dragHandleProps }
														ref={ provided.innerRef }
													>
														<div
															className="icon"
															onClick={ () => this.props.onDelete(value.id) }
														>
															<BsFillDashCircleFill/>
														</div>
														<div className="icon draggable">
															<BsGripVertical/>
														</div>
														<input
															type="text"
															name="label"
															className="input-ellipsis"
															value={ (this.state.formDataList[index]) ? this.state.formDataList[index].label : '' }
															onChange={ (event: React.ChangeEvent<HTMLInputElement>) => this.onChangeEditOption(event, index) }
															onKeyDown={ (event: any) => this.onKeyDownEdit(event, index, value.id) }
														/>
													</div>
												) }
											</Draggable>
									))
								}
								{ provided.placeholder }
							</div>
						)
					}
				</Droppable>
			</>
		);
	}

	private addNewOptionHtmlContent(): ReactElement
	{
		return (
			<>
				<div className="formBuilder-list__input add">
					<div className="icon">
						<BsFillPlusCircleFill/>
					</div>
					<input
						type="text"
						placeholder="Ajouter un élément..."
						value={ this.state.formData.label }
						onKeyDown={ this.onKeyDownCreate }
						onChange={ this.onChangeInputNewOption }
					/>
				</div>
			</>
		);
	}

	private onDragEnd(result: any): void
	{
		// If not destination on DraggableItem
		if (!result.destination) {
			return;
		}

		const newSortOrder = result.destination.index + 1;
		const optionId = parseInt(result.draggableId);

		const foundOption = this.state.formDataList.find(option => option.id === optionId);
		if (foundOption && typeof foundOption.id === 'number') {
			const updatedOption: FormBuilderOptionsInterface = {
				...foundOption,
				sortOrder: newSortOrder
			};

			this.props.onEdit(updatedOption, foundOption.id);
		}
	}

	private onKeyDownCreate(event: any)
	{
		if (event.key === 'Enter') {
			event.preventDefault();
			this.handleCreateNewOption();
		}
	}

	private onChangeInputNewOption(event: React.ChangeEvent<HTMLInputElement>): void
	{
		this.setState(prevState => ({
			formData: {
				...this.state.formData,
				label: event.target.value
			}
		}));
	}

	private onChangeEditOption(event: any, index: number): void
	{
		const newFormDataList = [...this.state.formDataList] as FormBuilderOptionsInterface[];
		const currentOption = newFormDataList[index];

		if (currentOption) {
			newFormDataList[index] = {
				...currentOption,
				label: event.target.value
			};

			this.setState({ formDataList: newFormDataList });
		}
	}

	private onKeyDownEdit(event: any, index: number, optionId: number): void
	{
		if (event.key === 'Enter') {
			event.preventDefault();
			this.props.onEdit(this.state.formDataList[index], optionId);
		}
	}

	private handleCreateNewOption(): void
	{
		this.props.onCreate(this.state.formData.label);
	}

	private buildFormDataList(): void
	{
		let formDataList: FormBuilderOptionsInterface[] = [];
		if (this.props.listOption.length > 0) {
			this.props.listOption.forEach((option: FormBuilderOptionsInterface) =>
			{
				formDataList.push(option);
			});
		}

		this.setState({ formDataList: formDataList });
	}

	private async refreshListOptions(): Promise<void>
	{
		this.setState({
			options: this.props.listOption
		});
	}

	//</editor-fold>
}