import React, { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class BadgeComponent extends React.Component
	<
		{
			item: any,
			isDragging?: boolean
		},
		any
	>
{
	render(): ReactElement
	{
		return (
			<>
				<div
					key={ (Math.random() * 10000).toString() }
					className={ `${ this.props.isDragging ? 'bg-lightgreen' : 'bg-neutral-lightGray2' }` }
					style={{
						fontSize: 12,
						padding: '3px 6px',
						backgroundColor: CssVariableEnum['--color-blue-50'],
						color: CssVariableEnum['--color-blue-500'],
						border: `1px solid ${ CssVariableEnum['--color-blue-500']}`,
						borderRadius: 6
					}}
				>
					<div className="d-flex">
						<div>{ this.props.item.name } ({ this.props.item.code })</div>
					</div>
				</div>
			</>
		);
	}
}