import React, { ReactElement } from 'react';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import { BsPlusLg } from 'react-icons/bs';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { GenderUserEnum } from '@/Modules/User/Enum/GenderUserEnum';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import { Tooltip } from 'react-tooltip';
import { CreateUserFormComponentStyle } from '@/Modules/User/Style/CreateUserFormComponentStyle';

export interface FormDataCreateUserInterface
{
	lastname: string,
	firstname: string,
	civility: string,
	email: string,
	phone: string | null
	status: string
	role: string
	companyId: number | null,
	inviteCode: string | null,
}

interface ComponentProps
{
	isAdmin: boolean,
	onFormSubmit: (formData: FormDataCreateUserInterface, event?: any) => void
	errorMessage?: string,
	allowedUserRole?: boolean,
	resetErrorMessage?: () => void
}

interface ComponentState
{
	formData: FormDataCreateUserInterface
	errorMessage: string
	isFormValid: boolean
}

export default class CreateUserFormComponent extends React.Component<ComponentProps, ComponentState>
{
	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Bind
		this.submitHandler = this.submitHandler.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<div style={ CreateUserFormComponentStyle.containerStyle() }>
					<div style={ CreateUserFormComponentStyle.displayFlexGap10DirectionColumn() }>
						<div style={ CreateUserFormComponentStyle.bodyContainerStyle() }>
							<SelectComponent
								label={ 'Civilité' }
								listOptions={ GenderUserEnum.options }
								onSelectedOption={ (option: any) => this.setState((prevState: ComponentState) => ({
									formData: {
										...prevState.formData,
										civility: option.value
									}
								})) }
								renderOptionLabel={ option => option.label }
								buttonWidth={ 200 }
								selectMenuWidth={ 160 }
								required={ true }
							/>
							<div style={ { display: 'flex', gap: '20px' } }>
								<Input
									type={ 'text' }
									name={ 'lastname' }
									label={ 'Nom' }
									placeholder={ 'Doe' }
									value={ this.state.formData.lastname }
									onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
									width={ '100%' }
									required={ true }
								/>
								<Input
									type={ 'text' }
									name={ 'firstname' }
									label={ 'Prénom' }
									placeholder={ 'John' }
									value={ this.state.formData.firstname }
									onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
									width={ '100%' }
									required={ true }
								/>
							</div>
							<div style={ { display: 'flex', gap: '20px' } }>
								<Input
									type={ 'text' }
									name={ 'phone' }
									label={ 'Téléphone' }
									placeholder={ '067898XXXX' }
									value={ this.state.formData.phone || '' }
									onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
									width={ '100%' }
									required={ true }
								/>

								{ this.props.allowedUserRole &&
									<SelectComponent
										label={ 'Rôle' }
										listOptions={ UserRoleEnum.filteredOptions }
										onSelectedOption={ (option: any) => this.setState((prevState: any) => ({
											formData: {
												...prevState.formData,
												role: option.value
											}
										})) }
										renderOptionLabel={ option => option.label }
										buttonWidth={ 200 }
										selectMenuWidth={ 160 }
										required={ true }
									/>
								}

							</div>
							<Input
								type={ 'text' }
								name={ 'email' }
								label={ 'Email' }
								placeholder={ 'john.doe@societe.com' }
								value={ this.state.formData.email }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
								width={ '100%' }
								required={ true }
							/>
						</div>
					</div>
					<div style={ CreateUserFormComponentStyle.footerContainerStyle() }>
						<a id={ 'required-field-user-form' } href={ '#required-field-user-form' }>
							<Button
								type={ 'default-blue' }
								iconLeft={ <BsPlusLg/> }
								onClick={ (event: any) => this.submitHandler(event) }
								disabled={ !this.state.isFormValid }
							>
								Nouvel utilisateur
							</Button>

							{ this.isCheckRequiredField() &&
                <Tooltip
                  anchorSelect="#required-field-user-form"
                  content={ 'Merci de remplir tous les champs pour créer l\'utilisateur' }
                />
							}
						</a>
					</div>
				</div>
			</>
		);
	}

	componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState): void
	{
		if (prevState.formData !== this.state.formData) {
			const isFormValid: boolean = this.isCheckRequiredField();
			if (isFormValid !== this.state.isFormValid) {
				this.setState({ isFormValid });
			}
		}
	}

	private initState(): ComponentState
	{
		return {
			formData: {
				lastname: '',
				firstname: '',
				civility: '',
				email: '',
				phone: '',
				status: '',
				role: UserRoleEnum.ROLE_USER.value,
				companyId: null,
				inviteCode: null,
			},
			errorMessage: '',
			isFormValid: false,
		};
	}

	private isCheckRequiredField(): boolean
	{
		const { lastname, firstname, civility, email, role } = this.state.formData;
		return lastname !== '' &&
			firstname !== '' &&
			civility !== '' &&
			email !== '' &&
			role !== '' &&
			(!this.props.allowedUserRole || this.props.allowedUserRole);
	}

	async submitHandler(event: React.FormEvent<HTMLFormElement>): Promise<any>
	{
		event.preventDefault();
		let formData = { ...this.state.formData };
		if (formData.phone && formData.phone.length === 0) {
			formData.phone = null;
		}

		this.setState({ formData: formData }, () => this.props.onFormSubmit(this.state.formData, event));

	}
}