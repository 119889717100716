import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthContext } from '@/Provider/AuthProvider';
import { AuthClientContext } from '@/Provider/AuthClientProvider';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import HeaderSwitchClient from '@/Modules/App/Components/HeaderSwitchClient';

const HeaderSwitchClientWrapper = (props: any) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const clientContext = useContext(AuthClientContext);
  const flashMessageContext = useContext(FlashMessageContext);

  // LoaderFullPageComponent
  if (!authContext?.user && !clientContext?.authClient) {
    return <LoaderFullPageComponent/>;
  }

  return <HeaderSwitchClient
    authContext={ authContext }
    clientContext={ clientContext }
    flashMessageContext={ flashMessageContext }
    navigation={ navigate }
    { ...props }
  />;
};

export default HeaderSwitchClientWrapper;