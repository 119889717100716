import React, { ReactElement } from 'react';
import SearchClientComponent from '@/Modules/Client/Components/SearchClientComponent';
import SearchCollaboratorComponent from '@/Modules/Collaborator/Components/SearchCollaboratorComponent';
import LegalNoticeFomCardComponent from '@/Modules/LegalNotice/Components/Form/Admin/FormCardComponent';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { Alert } from 'react-bootstrap';
import { LuInfo } from 'react-icons/lu';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

interface ComponentProps
{
  selectedClient: ClientInterface|null,
  selectedCollaborator: CollaboratorInterface|null,
  onSelectedClient: (event: any) => void
  onSelectedCollaborator: (event: any) => void,
  onReset: () => void;
}

export default class BlockClientComponent extends React.Component<ComponentProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        <LegalNoticeFomCardComponent
          title={ 'Choisir un donneur d\'ordre' }
          children={
            <>
              <div style={ { position: 'relative' } }>
                <SearchClientComponent
                  client={ this.props.selectedClient }
                  onSelectedClient={ this.props.onSelectedClient }
                />
              </div>
              { this.props.selectedClient?.options?.commentary &&
                <>
                  <Alert
                    key={ 'info' }
                    variant={ 'warning' }
                    style={{ marginTop: '10px', padding: 10, borderRadius: 10, backgroundColor: CssVariableEnum['--color-yellow-100'] }}
                  >
                    <div style={{ display: 'grid', gridTemplateColumns: '20px auto', gap: 5, alignItems: 'flex-start' }}>
                      <LuInfo style={{ fontSize: 18, color: CssVariableEnum['--color-yellow-500'], textAlign: 'center'}}/>
                      <p style={{ fontSize: 12, fontWeight: 500, color: CssVariableEnum['--color-yellow-600'] }}>
                        Note : { this.props.selectedClient?.options.commentary }
                      </p>
                    </div>
                  </Alert>
                </>
              }
              { this.props.selectedClient &&
                <>
                  <div style={{ ...FontStyle.h4(), marginTop: 20, marginBottom: 5 }}>
                    Choisir un collaborateur
                  </div>
                  <div style={ { position: 'relative' } }>
                    <SearchCollaboratorComponent
                      selectedClient={ this.props.selectedClient }
                      collaborator={ this.props.selectedCollaborator }
                      onSelectedCollaborator={ this.props.onSelectedCollaborator }
                    />
                  </div>
                </>
              }
            </>
          }
        />
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">
  //</editor-fold>
}