import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class CardStyle
{
	static main(background?: string, isBoxShadow?: boolean): CSSProperties
	{
		return {
			backgroundColor: (background) ? background : CssVariableEnum['--color-white'],
			borderRadius: '10px',
			border: CssVariableEnum['--border-main'],
			minWidth: '300px',
			boxShadow: (isBoxShadow) ? 'rgba(0, 0, 0, 0.2) 0px 18px 30px -10px' : 'none',
		};
	}

	static container(): CSSProperties
	{
		return {
			padding: 15
		};
	}

	static cardContainer(backgroundColor?: string): CSSProperties
	{
		return {
			backgroundColor: (backgroundColor) ? backgroundColor : CssVariableEnum['--color-white'],
			borderRadius: '15px',
			padding: '20px 10px',
			border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
		};
	}
}