import React, { ReactElement } from 'react';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import CreateUserFormComponent, {
	FormDataCreateUserInterface
} from '@/Modules/User/Components/Form/CreateUserFormComponent';
import UserShowComponent from '@/Modules/User/Components/UserShowComponent';
import HeroSection from '@/Modules/App/Components/Atom/Content/HeroSection';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';
import { LuUserCog, LuStar } from 'react-icons/lu';
import Pagination from '@/Modules/App/Components/Atom/Table/Pagination';
import OffCanvasComponent from '@/Modules/App/Components/Atom/OffCanvas/OffCanvasComponent';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import TableComponent from '@/Modules/App/Components/Atom/Table/TableComponent';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FilterParamsInterface } from '@/Modules/App/Interface/FilterParamsInterface';
import TableFilters from '@/Modules/App/Components/Atom/Table/TableFilters';
import { FilterInterface } from '@/Modules/App/Components/Atom/Table/Filters';
import { UserAdminViewService } from '@/Modules/User/Service/Admin/UserAdminViewService';
import Content from '@/Modules/App/Components/Atom/Content/Content';

interface ViewProps
{
	authContext: AuthContextType,
	flashMessageContext: FlashMessageContextType;
	modalContext: ModalContextType;
}

interface ViewState
{
	paginate: PaginateInterface,
	selectedUser: UserInterface | null,
	selectedUserIndex: number,
	filterParams: FilterParamsInterface,
	isLoading: boolean,
	currentItemId: number | null;
	selectedTabOffCanvas: number,
	isOpenShowOffCanvas: boolean;
	isCreatedMode: boolean;
	isUpdatedSuccess: boolean;
	errorMessage: string;
}

export default class UserAdminView extends React.Component<ViewProps, ViewState>
{
	userService: ApiAdminUserService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Service
		this.userService = new ApiAdminUserService();

		// Bind
		this.handleDeleted = this.handleDeleted.bind(this);
		this.handleUpdated = this.handleUpdated.bind(this);
		this.createNewUser = this.createNewUser.bind(this);
		this.refreshList = this.refreshList.bind(this);
		this.itemPerPage = this.itemPerPage.bind(this);
		this.selectedFilters = this.selectedFilters.bind(this);
		this.onRowClick = this.onRowClick.bind(this);
		this.buildFilterParams = this.buildFilterParams.bind(this);

		// Document title
		document.title = 'Publisur - ADMIN - Utilisateurs';
	}

	render(): ReactElement
	{
		const { isLoading, paginate, filterParams } = this.state;

		return (
			<>
				<HeroSection
					title="Utilisateurs"
					icon={ null }
					isLoading={ isLoading }
					tableFilter={
						<TableFilters
							isLoading={ isLoading }
							searchInput={
								{
									name: 'search-user',
									placeholder: 'Rechercher un utilisateur...',
									search: this.state.filterParams.search || '',
									onChangeSearch: (event: any) => this.onChangeUserSearch(event),
									resetSearchInput: this.resetSearchInput.bind(this),
									onKeyDown: (event: any) => this.onKeyDownSearch(event),
								}
							}
							filters={ { list: this.buildFilterUserList(), selectedFilters: this.selectedFilters } }
							buttonCreate={ { label: 'Ajouter un utilisateur', onClick: (event: any) => this.createNewUser(event) } }
						/> }
				/>
				<Content>
					<MainWrapper style={ { marginBottom: 20 } }>
						<TableComponent
							key={ paginate.items.toString() }
							isLoading={ isLoading }
							paginateList={ paginate.items }
							tableHeaderList={ UserAdminViewService.buildTableHeaderUser(this.props) }
							params={ this.buildFilterParams }
							onClickRow={ this.onRowClick }
							notFoundValuesText={ 'Aucun utilisateur n\'a été trouvée' }
							onRefreshList={ this.refreshList }
							isResizeColumns={ true }
							isStickyHeader={ true }
						/>

						<Pagination
							currentPage={ filterParams.page }
							tag={ 'user' }
							list={ paginate }
							itemsPerPage={ this.itemPerPage }
						/>

						<div style={ { height: 30 } }></div>
					</MainWrapper>
				</Content>

				<OffCanvasComponent
					show={ this.state.isOpenShowOffCanvas }
					onHide={ () => this.setState({ isOpenShowOffCanvas: false, selectedTabOffCanvas: 1, selectedUser: null }) }
					onPrevious={ () => this.navigateUser(true) }
					onNext={ () => this.navigateUser(false) }
					tabs={ UserAdminViewService.buildTabsOffCanvas }
					isNavigateArrow={ true }
					isTabNeeded={ true }
					selectedTab={ this.state.selectedTabOffCanvas }
					onTabChange={ (selectedTab: number) => this.handleTabChange(selectedTab) }
					title={ (this.state.selectedUser) ? `${ this.state.selectedUser.firstname } ${ this.state.selectedUser.lastname }` : null }
				>
					{ this.state.selectedUser && (
						<UserShowComponent
							isLoading={ isLoading }
							selectedTabOffCanvas={ this.state.selectedTabOffCanvas }
							selectedUser={ this.state.selectedUser }
							modalContext={ this.props.modalContext }
							flashMessageContext={ this.props.flashMessageContext }
							handleDeleted={ this.handleDeleted }
							handleUpdated={ this.handleUpdated }
							isUpdatedSuccess={ this.state.isUpdatedSuccess }
							currentItemId={ this.state.currentItemId }
							onRefresh={ this.refreshList }
						/>
					) }
				</OffCanvasComponent>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.refreshList();
	}

	private initState(): ViewState
	{
		return {
			filterParams: {
				page: 1,
				itemsPerPage: 10,
				search: null,
				filters: null,
				orderBy: null,
				sort: null,
			},
			paginate: {
				items: [],
				itemsPerPage: 10,
				totalCount: 0,
				totalPages: 1
			},
			selectedUser: null as UserInterface | null,
			selectedUserIndex: -1,
			selectedTabOffCanvas: 1,
			isLoading: true,
			currentItemId: null,
			isOpenShowOffCanvas: false,
			isCreatedMode: false,
			isUpdatedSuccess: false,
			errorMessage: ''
		};
	}

	//</editor-fold>

	//<editor-fold desc="Refresh List" defaultstate="collapsed">

	private async refreshList(): Promise<void>
	{
		try {
			const userListResponse = await this.userService.list(paramsToString(this.state.filterParams));

			this.setState({
				paginate: {
					items: userListResponse.items,
					itemsPerPage: userListResponse.itemsPerPage,
					totalCount: userListResponse.length,
					totalPages: userListResponse.totalPages
				},
			}, () => this.setState({ isLoading: false }));
		} catch (error: any) {
			console.log('Something wrong on refresh user list', error.errorMessage);
		}
	}

	//</editor-fold>

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private createNewUser(event: any): void
	{
		event.stopPropagation();

		this.setState({ isCreatedMode: true });

		this.props.modalContext.content(
			'Ajouter un utilisateur',
			<CreateUserFormComponent
				key={ this.state.errorMessage }
				isAdmin={ true }
				onFormSubmit={ this.handleFormCreate.bind(this) }
				errorMessage={ this.state.errorMessage }
				allowedUserRole={ true }
			/>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Filters methods" defaultstate="collapsed">

	private buildFilterParams(orderBy: string, sort: string): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				orderBy: orderBy,
				sort: sort
			},
			isLoading: true
		}), () => this.refreshList());
	}

	private onRowClick(event: any, user: UserInterface): void
	{
		if (event) {
			event.stopPropagation();
		}

		const index: number = this.state.paginate.items.findIndex(
			(userSelected: UserInterface) => userSelected.id === user.id
		);

		this.setState({
			selectedUser: user,
			isOpenShowOffCanvas: true,
			selectedUserIndex: index,
			currentItemId: user.id
		});
	}

	private handleTabChange(selectedTab: number)
	{
		this.setState({ selectedTabOffCanvas: selectedTab });
	}

	//</editor-fold>

	//<editor-fold desc="Handle filters / Params" defaultstate="collapsed">

	private buildFilterUserList(): FilterInterface[]
	{
		return [
			{ label: 'Statut', tag: 'status', icon: <LuStar/>, childList: ActiveStatusEnum.roleOptions, type: 'select' },
			{ label: 'Rôle', tag: 'role', icon: <LuUserCog/>, childList: UserRoleEnum.options, type: 'select' }
		];
	}

	private selectedFilters(selectedFilters: any[]): void
	{
		const filtersObject = selectedFilters.reduce((acc, filter) =>
		{
			acc[filter.filterName] = filter.filterValue;
			return acc;
		}, {});

		this.setState(prevState => ({
				filterParams: {
					...prevState.filterParams,
					filters: filtersObject
				},
				isLoading: true,
			}), () => this.refreshList()
		);
	}

	//</editor-fold>

	//<editor-fold desc="Handle CRUD" defaultstate="collapsed">

	private async handleFormCreate(formData: FormDataCreateUserInterface): Promise<void>
	{
		// Add data Active Status
		formData.status = ActiveStatusEnum.ACTIVE.value;
		try {
			this.setState({ isLoading: true });
			this.props.modalContext.isLoading(true);
			await this.userService.create(formData)
				.then(async (response: any) =>
				{
					if (response.errorMessage) {
						this.props.modalContext.errorMessage(response.errorMessage);
						this.props.modalContext.isOpen(true);
						this.props.modalContext.isLoading(false);
						return;
					}

					this.refreshList();

					// Close menu and OffCanvas, set refresh users
					this.setState({
						errorMessage: '',
						isOpenShowOffCanvas: false,
						isCreatedMode: false,
					});

					this.props.flashMessageContext.flashMessage(
						'Utilisateur créé avec succès',
						'Votre nouvel utilisateur a bien été créé, vous désormais l\'associer en tant que collaborateur',
						'success'
					);
					this.props.modalContext.isOpen(false);
					this.props.modalContext.isLoading(false);
					this.props.modalContext.errorMessage('');

				})
				.catch((error: any) =>
				{
					this.setState({ errorMessage: error.message });
				});

		} catch (error: any) {
			console.log('Issue from create user : ', error.errorMessage);
		}
	}

	private async handleDeleted(userId: number): Promise<void>
	{
		try {
			this.setState({ isLoading: true });
			await this.userService.delete(userId);

			this.refreshList();

			// Set Flash message
			this.props.flashMessageContext.flashMessage(
				'Suppression réussie',
				'L\'utilisateur a été supprimé avec succès',
				'success',
			);

			// Close menu and OffCanvas, set refresh users
			this.setState({ isOpenShowOffCanvas: false });
		} catch (error: any) {
			console.error('Something wrong on Deleted User :', error);
		}
	}

	private async handleUpdated(updatedUserData: any, userId: number): Promise<void>
	{
		try {
			const updatedUser = await this.userService.edit(updatedUserData, userId);
			this.setState({ isLoading: true });

			if (updatedUser.errorMessage) {
				this.setState({
					errorMessage: updatedUser.errorMessage,
					isLoading: false
				}, () => this.props.flashMessageContext.flashMessage(
					'Erreur',
					this.state.errorMessage,
					'error'
				));

				return;
			}
			// Fetch Clients
			this.refreshList();

			// Set Flash message
			this.props.flashMessageContext.flashMessage(
				'Mise à jour réussie',
				'L\'utilisateur a été mis à jour avec succès',
				'success'
			);

			// Refresh Users
			this.setState({
				selectedUser: updatedUser,
				isUpdatedSuccess: true
			});
			// Reset isUpdatedSuccess
			setTimeout(() =>
			{
				this.setState({ isUpdatedSuccess: false });
			}, 500);
		} catch (error: any) {
			console.error('Something wrong on Updated User :', error);
		}
	}

	//</editor-fold>

	//<editor-fold desc="Handle Search" defaultstate="collapsed">

	private searchUser(term: string): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				search: (term !== '') ? term : '',
				page: 1
			}
		}), () => this.refreshList());
	}

	private onKeyDownSearch(event: any): void
	{
		if (event.key === 'Enter') {
			this.searchUser(this.state.filterParams.search || '');
		}
	}

	private onChangeUserSearch(event: React.ChangeEvent<HTMLInputElement>): void
	{
		const term: string = event.target.value;
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				search: term
			}
		}), () => {
			if (term === '') {
				this.refreshList();
			}
		});
	}

	private resetSearchInput(): void
	{
		this.searchUser('');
	}

	private itemPerPage(currentPage: number, itemPerPage: number): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				page: currentPage,
				itemsPerPage: itemPerPage
			}
		}), () => this.refreshList());
	}

	//</editor-fold>

	//<editor-fold desc="Private Method" defaultstate="collapsed">

	private navigateUser(isPrevious: boolean): void
	{
		const { selectedUserIndex } = this.state;
		let navigateIndex = isPrevious ? selectedUserIndex - 1 : selectedUserIndex + 1;

		// Check index

		if (navigateIndex >= 0 && navigateIndex < (this.state.paginate.items.length)) {
			this.setState({
				selectedUser: this.state.paginate && this.state.paginate.items[navigateIndex],
				selectedUserIndex: navigateIndex,
				currentItemId: this.state.selectedUser?.id as number,
			});
		}
	}

	//</editor-fold>
}