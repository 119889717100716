import React, { ReactElement } from 'react';
import CreateClientFormComponent from '@/Modules/Client/Components/Form/CreateClientFormComponent';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { Modal } from 'react-bootstrap';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { AuthClientViewProps, AuthClientViewState } from '@/Modules/AuthClient/Type/AuthClientViewTypes';
import { ClientModel } from '@/Modules/Client/Model/ClientModel';
import UserCivilityEnum from '@/Enum/UserCivilityEnum';
import { UserRoleEnum } from '@/Enum/UserRoleEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import CardStyle from '@/Modules/App/Style/Components/CardStyle';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

export default class AuthClientView extends React.Component
	<AuthClientViewProps, AuthClientViewState>
{
	clientService: ApiClientService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.clientService = new ApiClientService();

		// Bind
		this.handleCreateClientForm = this.handleCreateClientForm.bind(this);
		this.authClientHandler = this.authClientHandler.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent />;
		}

		return (
			<>
				<div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' } }>
					<div style={ { textAlign: 'center', marginBottom: 30 } }>
						<h1 style={ FontStyle.h1() }>
							👋
							Bonjour { this.handleUserCivility(this.props.authContext.user.civility) }. { this.props.authContext.user.lastname }
						</h1>
						<div style={ FontStyle.normal(CssVariableEnum['--color-grey-400']) }>
							Sur quel cabinet souhaitez vous travailler aujoud'hui ?
						</div>
					</div>
					<div>
						{
							this.state.clients.length > 0 ?
								this.state.clients.map(
									(client: ClientInterface) => (
										<div
											style={ { ...CardStyle.main(), marginBottom: 10, cursor: 'pointer' } }
											key={ client.id }
											onClick={ () => this.authClientHandler(client.id) }
										>
											<div style={ CardStyle.container() }>
												<span style={ FontStyle.normalMedium() }>{ client.name }</span>&nbsp;
											</div>
										</div>
									)
								)
								: <p className="text-center text-danger">
									Vous ne faites partie d'aucun cabinet. Créer un nouveau cabinet, où attendez une invitation.
								</p>
						}
					</div>
					<div style={ {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: 20
					} }>
					</div>
				</div>
				{ this.modalContent() }
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		if (!localStorage.getItem('user')) {
			this.props.navigation('/auth');
		} else {
			const user = localStorage.getItem('user');
			const userObj = user ? JSON.parse(user) : null;

			if (userObj.userRole !== UserRoleEnum.ROLE_USER) {
				this.props.navigation('/auth');
			}
		}

		try {
			const clients = await this.clientService.list();
			this.setState({ clients, isLoading: false });
		} catch (error: any) {
			this.setState({ errorMessage: 'Loading error', isLoading: false });
		}
	}

	private initState(): AuthClientViewState
	{
		return {
			clients: [],
			isLoading: true,
			isOpenModal: false,
			errorMessage: null
		};
	}

	//</editor-fold>

	//<editor-fold desc="Modal methods" defaultstate="collapsed">

	private modalContent()
	{
		return (
			<>
				<Modal
					show={ this.state.isOpenModal }
					onHide={ this.closeModal }
					backdrop="static"
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="form-client-modal"
				>
					<Modal.Header closeButton>
						<Modal.Title>
							<h2 className="h2 text-center">Créer un nouveau cabinet</h2>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{ this.state.errorMessage
								&& <div className="error-message" role="alert">{ this.state.errorMessage }</div>
							}
							<CreateClientFormComponent
								authContext={ this.props.authContext }
								onFormSubmit={ this.handleCreateClientForm }
								modalContext={ null }
								isFromAdmin={ false }
								flashMessageContext={ null }
							/>
						</div>
					</Modal.Body>
				</Modal>
			</>
		);
	}

	private openModal()
	{
		this.setState({ isOpenModal: true });
	}

	private closeModal()
	{
		this.setState({ isOpenModal: false });
	}

	//</editor-fold>

	//<editor-fold desc="Clients methods" defaultstate="collapsed">

	private async handleCreateClientForm(formData: ClientModel)
	{
		await this.clientService.create(formData)
			.then(async (response: any) =>
			{
				if (response.errorMessage) {
					this.setState({ errorMessage: response.errorMessage });
					return;
				}
				// Fetch Clients
				const clients = await this.clientService.list();
				this.setState({ clients, isLoading: false, isOpenModal: false });
				// Refresh Client context
				this.props.clientContext.fetchClients();
				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'Client créé avec succès',
					`Le client ${ formData.name } a bien été créé`,
					'success'
				);
			})
			.catch((error: any) =>
			{
				this.setState({ errorMessage: error.message });
			})
		;
	}

	private async authClientHandler(clientId: number)
	{
		await this.props.clientContext.refreshClient(clientId);
		LocalStorageService.setAuthClientIdLocalStorage(clientId);
		this.props.navigation('/');
	}

	//</editor-fold>

	private handleUserCivility(civility: string): string
	{
		if (civility !== UserCivilityEnum.M.value) {
			if (civility === UserCivilityEnum.W.value) {
				return 'Mme';
			} else {
				return '';
			}
		}

		return civility;
	}
}