import React, { useContext } from 'react';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';
import PricingAdminView from '@/Modules/Pricing/Admin/View/PricingAdminView';

const PricingAdminViewWrapper = (props: any) => {
  const flashMessageContext = useContext(FlashMessageContext);

  return <>
    <PricingAdminView
      flashMessageContext={ flashMessageContext }
      { ...props }
    />
  </>;
};

export default PricingAdminViewWrapper;