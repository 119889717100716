import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAdminCollaboratorService extends BaseService
{
  collaboratorUrl: string = `${ BaseService.API_URL }/v1/admin/collaborators`;

  async list(): Promise<any>
  {
    try {
      const response = await fetch(
        this.collaboratorUrl,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async search(clientId: number, term: string): Promise<any> {
    try {
      const url = `${this.collaboratorUrl}/search/${clientId}?term=${encodeURIComponent(term)}`;
      const response = await fetch(url, {
        method: HttpVerbEnum.GET,
        headers: this.buildHeaders(),
      });

      return await response.json();
    } catch (error: any) {
      console.error('Error searching collaborators:', error.message);
    }
  }

  async show(clientId: number, collaboratorId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.collaboratorUrl }/${ clientId }/${ collaboratorId }`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders()
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async create(collaboratorData: any, clientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.collaboratorUrl }/${ clientId }`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(collaboratorData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async switchOwner(switchData: any, clientId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.collaboratorUrl }/switch-owner/${ clientId }`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(switchData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async edit(editCollaboratorData: any, clientId: number, collaboratorId: number)
  {
    try {
      const response = await fetch(
          this.collaboratorUrl + '/' + clientId + '/' + collaboratorId,
          {
            method: HttpVerbEnum.PATCH,
            headers: this.buildHeaders(),
            body: JSON.stringify(editCollaboratorData)
          },
        )
      ;

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async delete(clientId: number, collaboratorId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.collaboratorUrl }/${ clientId }/${ collaboratorId }`,
        {
          method: HttpVerbEnum.DELETE,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}