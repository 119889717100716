import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class FontStyle
{
	static normal(color?: CssVariableEnum ): CSSProperties
	{
		return {
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.3,
			color: (color) ? color : CssVariableEnum['--color-grey-900'],
		};
	}

	static normalMedium(color?: CssVariableEnum ): CSSProperties
	{
		return {
			fontSize: 14,
			fontWeight: 500,
			lineHeight: 1.3,
			color: (color) ? color : CssVariableEnum['--color-grey-900'],
		};
	}

	static underline(): CSSProperties
	{
		return {
			fontSize: 'inherit',
			fontWeight: 500,
			textDecoration: 'underline',
		};
	}

	static littleGrey(): CSSProperties
	{
		return { fontSize: '12px', color: CssVariableEnum['--color-grey-400'],  fontWeight: 400 };
	}

	static h1(color?: CssVariableEnum)
	{
		return {
			fontSize: 32,
			fontWeight: 600,
			letterSpacing: 'normal',
			color: (color) ? color : CssVariableEnum['--color-grey-900'],
		};
	}

	static h2(color?: CssVariableEnum)
	{
		return {
			fontSize: 24,
			fontWeight: 500,
			letterSpacing: 'normal',
			color: (color) ? color : CssVariableEnum['--color-grey-900'],
			lineHeight: 1.2
		};
	}

	static h3(color?: CssVariableEnum)
	{
		return {
			fontSize: 18,
			fontWeight: 500,
			letterSpacing: 'normal',
			color: (color) ? color : CssVariableEnum['--color-grey-900'],
		};
	}

	static h4(color?: CssVariableEnum)
	{
		return {
			fontSize: 16,
			fontWeight: 500,
			letterSpacing: 'normal',
			color: (color) ? color : CssVariableEnum['--color-grey-900'],
		};
	}
}
