import React, { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { LuPlus, LuX } from 'react-icons/lu';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { TableFilterComponentStyle } from '@/Modules/App/Style/TableFilterComponentStyle';
import { FilterInterface } from '@/Modules/App/Components/Atom/Table/Filters';
import Filters from '@/Modules/App/Components/Atom/Table/Filters';
import Skeleton from 'react-loading-skeleton';

interface ComponentProps
{
	isLoading?: boolean;
	searchInput: {
		name: string,
		placeholder: string,
		search: string | null,
		onChangeSearch: (event: any) => void,
		resetSearchInput: () => void,
		onKeyDown?: (event: any) => void
	},
	filters: {
		list: FilterInterface[],
		selectedFilters: (selectedFilters: any[]) => void
	},
	buttonCreate: {
		label: string,
		onClick: (event: any) => void
	} | null
}

export default class TableFilters extends React.Component<ComponentProps, any>
{
	render(): ReactElement
	{
		const { searchInput, isLoading, buttonCreate } = this.props;

		return (
			<>
				<div>
					<div style={ { display: 'flex', marginBottom: 10 } }>
						<div style={ { position: 'relative' } }>
							{ (isLoading)
								? <Skeleton width={ 300 } height={ 32 } baseColor={ CssVariableEnum['--color-grey-200'] }/>
								:
								<>
									<div style={ { display: 'flex', alignItems: 'center', gap: 10 } }>
										<div style={ { display: 'flex', alignItems: 'center', position: 'relative' } }>
											<Input
												style={ { backgroundColor: CssVariableEnum['--color-white'] } }
												type="text"
												name={ searchInput.name }
												placeholder={ searchInput.placeholder }
												width="300px"
												value={ searchInput.search || '' }
												backgroundColor={ CssVariableEnum['--color-transparent'] }
												onChange={ searchInput.onChangeSearch }
												onKeyDown={ searchInput.onKeyDown }
											/>
											{ searchInput.search &&
                        <div
                          style={ TableFilterComponentStyle.resetSearchInput() }
                          onClick={ this.props.searchInput.resetSearchInput }
                        >
                          <LuX/>
                        </div>
											}
										</div>

										{ searchInput.search &&
                      <span style={ { fontSize: 10 } }>Press Enter</span>
										}
									</div>
								</>
							}
						</div>

						<div style={ { width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 10 } }>
							<Filters
								isLoading={ this.props.isLoading }
								filterList={ this.props.filters.list }
								onSelectedFilters={ this.props.filters.selectedFilters }
							/>

							{ buttonCreate !== null && (
								isLoading
									? <Skeleton width={ 200 } height={ 32 } baseColor={ CssVariableEnum['--color-grey-200'] }/>
									: buttonCreate && (
									<Button
										type="default-blue"
										iconLeft={ <LuPlus/> }
										onClick={ buttonCreate.onClick }
									>
										{ buttonCreate.label }
									</Button>
								)
							) }
						</div>
					</div>
				</div>
			</>
		);
	}
}