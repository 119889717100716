import { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class InviteStateEnum
{

	value: string;
	label: string;
	css: { backgroundColor: string, color: string, icon?: ReactElement | null };

	constructor(value: string, label: string, css: { backgroundColor: string, color: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static PENDING = new InviteStateEnum('PENDING', 'En attente', {
		backgroundColor: CssVariableEnum['--color-blue-light-100'],
		color: CssVariableEnum['--color-blue-light-500'],
	});
	static ACCEPTED = new InviteStateEnum('ACCEPTED', 'Accepté', {
		backgroundColor: CssVariableEnum['--color-green-100'],
		color: CssVariableEnum['--color-green-500'],
	});
	static DECLINED = new InviteStateEnum('DECLINED', 'Refusé', {
		backgroundColor: CssVariableEnum['--color-green-100'],
		color: CssVariableEnum['--color-green-500'],
	});
	static EXPIRED = new InviteStateEnum('EXPIRED', 'Expiré', {
		backgroundColor: CssVariableEnum['--color-green-100'],
		color: CssVariableEnum['--color-green-500'],
	});

	static roleOptions: InviteStateEnum[] = [
		InviteStateEnum.PENDING,
		InviteStateEnum.ACCEPTED,
		InviteStateEnum.DECLINED,
		InviteStateEnum.EXPIRED
	];

	static findByValue(value: string): InviteStateEnum | undefined
	{
		return this.roleOptions.find(option => option.value === value);
	}
}