import React, { ReactElement } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class LoaderAwaitSendMailComponent extends React.Component
	<{ progress: number }, { progress: number }>
{

	constructor(props: any) {
		super(props);
		this.state = {
			progress: 0
		};
	}

	render(): ReactElement {
		return (
			<div className="loader">
				<div className="loader__container">
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: 8,
						border: `1px solid ${CssVariableEnum['--color-grey-300']}`,
						padding: 25,
						backgroundColor: CssVariableEnum['--color-white'],
						gap: 20,
					}}>
						<ProgressBar
							now={this.props.progress}
							label={`${this.props.progress}%`}
							striped={true}
							animated={this.props.progress < 100}
							style={{ width: '100%' }}
						/>
						<div style={{ textAlign: 'center'}}>
							Veuillez patienter... <br/>
							Les emails sont en cours d'envoi
						</div>
					</div>
				</div>
			</div>
		);
	}
}
