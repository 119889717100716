import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class TableFilterComponentStyle
{
  static cardContainer(): CSSProperties
  {
    return {
      backgroundColor: CssVariableEnum['--color-white'],
      borderRadius: '8px',
      position: 'relative',
      padding: '10px 10px',
      border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
      marginBottom: '10px',
    };
  }

  static resetSearchInput(): CSSProperties
  {
    return {
      position: 'absolute',
      right: '8px',
      top: '5px',
      cursor: 'pointer',
    };
  }
}