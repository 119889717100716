import React, { ReactElement } from 'react';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { Card, Col, Form } from 'react-bootstrap';
import { BsFileLock, BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import { FormBuilderSectionInterface } from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';
import { ApiAdminFormBuilderSectionService } from '@/Service/Admin/ApiAdminFormBuilderSectionService';
import Block from '@/Modules/App/Components/Atom/Block/Block';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';

interface ComponentProps {
	secondaryCategoryList: FormBuilderCategoryInterface;
	isOpen: boolean;
	onToggleSection: (categoryId: string | number | null) => void;
	onCreate: (
		sectionId: number,
		categoryId: number
	) => void;
	onSwitchSortOrder: (
		sectionId: number,
		categoryId: number,
		sortOrder: number
	) => void;
	onDelete: (sectionId: number, categoryId: number) => void;
}

interface ComponentState {
	isSectionSelected: boolean,
	sectionSearchResults: FormBuilderSectionInterface[],
	selectedSection: FormBuilderSectionInterface | null,
}

export default class FormBuilderConstructorSection extends React.Component<ComponentProps, ComponentState>
{
	formBuilderSectionService: ApiAdminFormBuilderSectionService;

	constructor(props: any)
	{
		super(props);
		this.state = this.initState();

		// Service
		this.formBuilderSectionService = new ApiAdminFormBuilderSectionService();

		// Bind
		this.searchSection = this.searchSection.bind(this);
		this.handleSectionSelect = this.handleSectionSelect.bind(this);
		this.addNewSectionToCategory = this.addNewSectionToCategory.bind(this);
		this.deleteSection = this.deleteSection.bind(this);
	}

	render(): ReactElement
	{
		return (
			<Block theme={ 'light' } style={ { marginBottom: 10 } }>
				{
					(this.props.isOpen)
						? this.cardOpenHtmlContent(this.props.secondaryCategoryList)
						: this.cardClosedHtmlContent(this.props.secondaryCategoryList)
				}
			</Block>
		);
	}

	// <editor-fold desc="State" defaultstate="collapsed">

	private initState(): ComponentState
	{
		return {
			isSectionSelected: false,
			sectionSearchResults: [],
			selectedSection: null,
		};
	}

	// </editor-fold>

	//<editor-fold desc="Content Section methods" defaultstate="collapsed">

	private cardClosedHtmlContent(category: FormBuilderCategoryInterface): ReactElement
	{
		return (
			<div style={ { display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' } }>
				<div style={ FontStyle.h4() }> { category.label } </div>
				<div
					className="btn btn-default btn-default--blue"
					onClick={ () => this.props.onToggleSection(category.id) }
				>
					Modifier
				</div>
			</div>
		);
	}

	private cardOpenHtmlContent(category: FormBuilderCategoryInterface): ReactElement
	{
		return (
			<>
				<div style={ {
					fontSize: 16,
					fontWeight: 500,
					margin: 0,
					borderBottom: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,

				} }>
					{ category.label }
				</div>
				<div style={ { marginTop: 15, display: 'flex', flexDirection: 'column', gap: 10 } }>
					<div style={ { width: 300 } }>
						{ this.searchSectionHtmlContent(category.id) }
					</div>
					<div style={{ maxWidth: 700 }}>
						{ this.sectionHtmlContent(category) }
					</div>
				</div>
			</>
		);
	}

	private sectionHtmlContent(category: FormBuilderCategoryInterface): ReactElement
	{
		return (
			<>
				{ this.staticSectionsHtmlContent() }
				{ this.dynamicSectionsHtmlContent(category) }
			</>
		);
	}

	private staticSectionsHtmlContent(): ReactElement
	{
		return (
			<>
				{ this.buildStaticSections().map((section: {
					id: number,
					title: string
				}) =>
					(
						<Card
							className="mb-2 cursor-normal bg-neutral-lightGray2"
							key={ (Math.random() * 10000).toString() }
						>
							<Card.Body className="p-3">
								<div className={ `d-flex justify-content-between` }>
									<div className="color-grey"> { section.title }</div>
									<BsFileLock className="color-grey" width={ 40 } height={ 40 }/>
								</div>
							</Card.Body>
						</Card>
					))
				}
			</>
		);
	}

	private dynamicSectionsHtmlContent(category: FormBuilderCategoryInterface): ReactElement
	{
		const formBuilderSections = category.formBuilderSections || [];

		return (
			<>
				{formBuilderSections.map((section: FormBuilderSectionInterface, index: number) => (
					<React.Fragment key={section.id}>
						<div className="d-flex align-items-center">
							<Col xs={1}>
								<div className="d-flex align-items-center flex-column">
									{/* Désactiver Up si la section est la première */}
									<div
										className={`width-50 height-50-25 ${index === 0 ? 'bg-neutral-lightGray2 cursor-not-allowed' : 'bg-neutral-lightGray2-hover cursor-pointer'} border-radius-main d-flex justify-content-center align-items-center p-1`}
										onClick={() => index !== 0 && this.sectionSortOrderUp(category, section, true)}
										style={index === 0 ? { pointerEvents: 'none', opacity: 0.5 } : {}}
									>
										<BsFillCaretUpFill />
									</div>
									{/* Désactiver Down si la section est la dernière */}
									<div
										className={`width-50 height-50-25 ${index === formBuilderSections.length - 1 ? 'bg-neutral-lightGray2 cursor-not-allowed' : 'bg-neutral-lightGray2-hover cursor-pointer'} border-radius-main d-flex justify-content-center align-items-center p-1`}
										onClick={() => index !== formBuilderSections.length - 1 && this.sectionSortOrderUp(category, section, false)}
										style={index === formBuilderSections.length - 1 ? { pointerEvents: 'none', opacity: 0.5 } : {}}
									>
										<BsFillCaretDownFill />
									</div>
								</div>
							</Col>
							<Col xs={11}>
								<Card className="mb-2 cursor-normal" key={(Math.random() * 10000).toString()}>
									<Card.Body className="p-3">
										<div className={`d-flex justify-content-between align-items-center`}>
											<div>{section.title}</div>
											<div
												className="btn btn-default btn-default--red"
												onClick={() => this.deleteSection(section.id, category.id)}
											>
												Supprimer
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</div>
					</React.Fragment>
				))}
			</>
		);
	}

	private searchSectionHtmlContent(categoryId: number)
	{
		return (
			<>
				<Form.Group className="block-field">
					{ !this.state.isSectionSelected ? (
						<>
							<Form.Control
								type="text"
								placeholder="Rechercher une section"
								onChange={ (event) => this.onChangeSectionSearch(event) }
							/>
							{ this.state.sectionSearchResults.length > 0 && (
								<ul className="autocomplete-search-results">
									{ this.state.sectionSearchResults.map((section: FormBuilderSectionInterface) => (
										<li key={ (Math.random() * 10000).toString() }
												className="autocomplete-search-item d-flex justify-content-between align-items-center"
												onClick={ () => this.addNewSectionToCategory(section, categoryId) }
										>
											{ section.title }
											<button
												className="btn btn-default btn-default--blue"
											>
												Ajouter
											</button>
										</li>
									)) }
								</ul>
							) }
						</>
					) : (
						<div className="autocomplete-selected-item d-flex justify-content-between">
							{
								this.state.selectedSection &&
								`${ (this.state.selectedSection as FormBuilderSectionInterface).title }`
							}
						</div>
					) }
				</Form.Group>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	private buildStaticSections(): {
		id: number,
		title: string
	}[]
	{
		return [
			{ id: 1, title: 'Type de l\'annonce' },
			{ id: 1, title: 'La société' },
			{ id: 1, title: 'Siège Social' }
		];
	}

	private async searchSection(term: string): Promise<void>
	{
		const results = await this.formBuilderSectionService.search(term);
		this.setState(({ sectionSearchResults: results }));
	}

	private async onChangeSectionSearch(event: React.ChangeEvent<any>): Promise<void>
	{
		const term = event.target.value;
		if (term.length > 1) {
			await this.searchSection(term);
		}
	}

	private handleSectionSelect(section: FormBuilderSectionInterface): void
	{
		this.setState({ selectedSection: section, isSectionSelected: true });
	}

	private async addNewSectionToCategory(section: FormBuilderSectionInterface, categoryId: number)
	{
		this.props.onCreate(section.id, categoryId);
		this.setState({ selectedSection: null, isSectionSelected: false });
	}

	private async deleteSection(sectionId: number, categoryId: number)
	{
		await this.props.onDelete(sectionId, categoryId);
	}

	private sectionSortOrderUp(category: FormBuilderCategoryInterface, section: FormBuilderSectionInterface, isUp: boolean)
	{
		const getSortOrderObj = section.sortOrders.find((sortOrder: any) =>
			sortOrder.formBuilderCategoryId === category.id
		);

		const currentSortOrder = getSortOrderObj.sortOrder;
		const maxSortOrder = category.formBuilderSections ? category.formBuilderSections.length : 0;

		let newSortOrder = isUp ? currentSortOrder - 1 : currentSortOrder + 1;

		if (newSortOrder < 1) {
			newSortOrder = 1;
		} else if (newSortOrder > maxSortOrder) {
			newSortOrder = maxSortOrder;
		}

		if (newSortOrder !== currentSortOrder) {
			this.props.onSwitchSortOrder(section.id, category.id, newSortOrder);
		}
	}

	//</editor-fold />
}