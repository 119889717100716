import React, { ReactElement } from 'react';
import { BsFillPlusCircleFill, BsFillDashCircleFill, BsGripVertical } from 'react-icons/bs';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import 'react-loading-skeleton/dist/skeleton.css'
import FormBuilderInputSkeleton from '@/Modules/App/Components/Skeleton/FormBuilderInputSkeleton';
import { FormBuilderCategoryTypeEnum } from '@/Enum/FormBuilderCategoryTypeEnum';
import Form from 'react-bootstrap/Form';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';

export default class FormBuilderCategoryInputListComponent extends React.Component
	<
		{
			categories: FormBuilderCategoryInterface[] ,
			categoryType: string,
			parent: FormBuilderCategoryInterface|null,
			onCreate: (formData: Partial<FormBuilderCategoryInterface> ) => void,
			onEdit: (category: FormBuilderCategoryInterface ) => void,
			onDelete: (categoryId: number, type: string) => void,
			errorMessage: string | null,
			isLoading: boolean,
			isLoadingUpdate: (isLoading: boolean) => void
		},
		{
			formDataList: FormBuilderCategoryInterface[],
			formData: {
				label: string,
				categoryType: string
			},
			errorMessage: string | null,
			isLoading: boolean
		}
	>
{
	state = {
		formDataList: [] as FormBuilderCategoryInterface[],
		formData: {
			label: '',
			categoryType: LegalNoticeTypeEnum.NONE.value
		},
		errorMessage: null,
		isLoading: this.props.isLoading
	};

	constructor(props: any)
	{
		super(props);

		// Bind
		this.buildFormData = this.buildFormData.bind(this);
		this.onChangeCreateCategory = this.onChangeCreateCategory.bind(this);
		this.onKeyDownCreate = this.onKeyDownCreate.bind(this);
		this.onChangeEditCategory = this.onChangeEditCategory.bind(this);
		this.onKeyDownEdit = this.onKeyDownEdit.bind(this);
	}

	componentDidMount()
	{
		this.buildFormData();
		this.props.isLoadingUpdate(false);
	}

	componentDidUpdate(prevProps: any)
	{
		if (prevProps.categories !== this.props.categories) {
			this.buildFormData();
			setTimeout(() => {
				this.props.isLoadingUpdate(false);
			}, 500);
		}
	}

	render(): ReactElement
	{
		return (
			<>
				<div className="d-flex">
					<Droppable droppableId={ this.props.categoryType }>
						{
							(provided: any) => (
								<div
									className={ (this.props.categoryType === FormBuilderCategoryTypeEnum.PRIMARY) ? 'formBuilder-list w-100' : 'formBuilder-list'}
									ref={ provided.innerRef }
									{ ...provided.droppableProps }
								>
									{ (this.props.isLoading)
										? <FormBuilderInputSkeleton count={ this.props.categories.length } />
										: (
											this.props.categories.map((category: FormBuilderCategoryInterface, index: number) => (
												<React.Fragment key={ category.id.toString() }>
													{ this.listCategoriesHtmlContent(category, index) }
												</React.Fragment>
											))
										)
									}
									{ provided.placeholder }
								</div>
							)
						}
					</Droppable>
				</div>
				{ this.addNewCategoryHtmlContent() }
				{
					(this.state.errorMessage)
						? <div className="error-message" role="alert">{ this.state.errorMessage }</div>
						: ''
				}
			</>
		);
	}

	//<editor-fold desc="HTML methods" defaultstate="collapsed">

	private addNewCategoryHtmlContent(): ReactElement
	{
		return (
			<>
				<div className="formBuilder-list__input add">
					<div className="icon">
						<BsFillPlusCircleFill/>
					</div>
					<input
						type="text"
						value={ this.state.formData.label }
						onKeyDown={ this.onKeyDownCreate }
						onChange={ this.onChangeCreateCategory }
						placeholder="Ajouter un élément..."
					/>
				</div>
			</>
		);
	}

	private selectCategoryTypeContent(category: FormBuilderCategoryInterface, indexCategory: number): ReactElement
	{
		return (
			<>
				<div className="mx-2 pt-1 w-75">
					<Form.Select
						className="m-0"
						size="sm"
						value={ category.categoryType }
						onChange={ (event: any) => this.onChangeSelectCategoryType(event, indexCategory) }
					>
						<option disabled>Merci de choisir un type de Category</option>
						{ LegalNoticeTypeEnum.typeList.map((type: any, index: number) => (
							<option key={index} value={type.value}>{type.label}</option>
						))}
					</Form.Select>
				</div>
			</>
		);
	}

	private listCategoriesHtmlContent(category: FormBuilderCategoryInterface, index: number)
	{
		return (
			<React.Fragment key={category.id}>
				<Draggable
					draggableId={ category.id.toString() }
					index={ index }
					key={ category.id.toString() }
				>
					{ (provided: any) => (
						<div
							className="formBuilder-list__input"
							{ ...provided.draggableProps }
							{ ...provided.dragHandleProps }
							ref={ provided.innerRef }
						>
							<div
								className="icon"
								onClick={ () => this.props.onDelete(category.id, category.type) }
							>
								<BsFillDashCircleFill/>
							</div>
							<div className="icon draggable">
								<BsGripVertical/>
							</div>
							<input
								type="text"
								name="label"
								className="input-ellipsis"
								value={ (this.state.formDataList[index]) ? this.state.formDataList[index].label : '' }
								onChange={ (event: React.ChangeEvent<HTMLInputElement>) => this.onChangeEditCategory(event, index) }
								onKeyDown={ (event: any) => this.onKeyDownEdit(event, index) }
							/>
							{ this.props.categoryType === FormBuilderCategoryTypeEnum.PRIMARY
								&& this.selectCategoryTypeContent(category, index)
							}
						</div>
					) }
				</Draggable>
			</React.Fragment>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private onKeyDownCreate(event: any): void
	{
		if (event.key === 'Enter') {
			event.preventDefault();
			this.onCreateCategory(event);
		}
	}

	private async onCreateCategory(event: any): Promise<any>
	{
		event.preventDefault();

		const createdData: Partial<FormBuilderCategoryInterface> = {
			label: event.target.value,
			type: this.props.categoryType,
			categoryType: (this.props.parent && this.props.parent.id) ? this.props.parent.categoryType : LegalNoticeTypeEnum.NONE.value,
			parent: (this.props.parent && this.props.parent) ?? null
		};

		this.props.onCreate(createdData);
		// Reset state
		this.setState({ formData: { label: '', categoryType: LegalNoticeTypeEnum.NONE.value }});
		// Construct form data
		this.buildFormData();
	}

	private onChangeCreateCategory(event: React.ChangeEvent<HTMLInputElement>): void
	{
		this.setState(prevState => ({
			formData: {
				...this.state.formData,
				label: event.target.value,
			}
		}));
	}

	private onChangeEditCategory(event: any, index: number): void
	{
		const newFormDataList = [...this.state.formDataList] as FormBuilderCategoryInterface[];
		const currentCategory = newFormDataList[index];

		if (currentCategory) {
			newFormDataList[index] = {
				...currentCategory,
				label: event.target.value
			};

			this.setState({ formDataList: newFormDataList });
		}
	}

	private onKeyDownEdit(event: any, index: number): void
	{
		if (event.key === 'Enter') {
			event.preventDefault();
			// Props
			this.props.onEdit(this.state.formDataList[index]);
			this.props.isLoadingUpdate(true);
		}
	}

	private onChangeSelectCategoryType(event: any, index: number): void
	{
		const newFormDataList = [...this.state.formDataList] as FormBuilderCategoryInterface[];

		if (newFormDataList[index]) {
			newFormDataList[index].categoryType = event.target.value;
			this.setState({ formDataList: newFormDataList });
			// Props
			this.props.onEdit(this.state.formDataList[index]);
			this.props.isLoadingUpdate(true);
		}
	}

	private buildFormData(): void
	{
		let formDataList: FormBuilderCategoryInterface[] = [];
		if (this.props.categories.length > 0) {
			this.props.categories.forEach((category: FormBuilderCategoryInterface) =>
			{
				formDataList.push(category);
			});
		}

		this.setState({ formDataList: formDataList });
	}

	//</editor-fold>
}