import React, { ReactElement } from 'react';
import { ApiInviteCollaboratorService } from '@/Service/Api/ApiInviteCollaboratorService';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { InviteCollaboratorInterface } from '@/Modules/Collaborator/Interface/InviteCollaboratorInterface';
import AuthFormComponent, { AuthViewState } from '@/Modules/Auth/Components/Form/AuthFormComponent';
import { ApiAuthService } from '@/Service/Api/ApiAuthService';
import CreateUserFormComponent from '@/Modules/User/Components/Form/CreateUserFormComponent';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { NavigateFunction } from 'react-router-dom';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { ModalContextType } from '@/Provider/ModalProvider';
import CardStyle from '@/Modules/App/Style/Components/CardStyle';

interface ViewProps
{
	flashMessageContext: FlashMessageContextType;
	navigation: NavigateFunction;
	modalContext: ModalContextType
	invitationCode: any,
}

interface ViewState
{
	errorMessage: string | null,
	inviteCollaborator: InviteCollaboratorInterface | null,
	isOpenModal: boolean,
	isCreateFormUser: boolean,
	isUserCreated: boolean
}

export class RespondInvitationView extends React.Component
	<ViewProps, ViewState>
{
	//<editor-fold desc="Ctr / Properties" defaultstate="collapsed">

	// Properties
	inviteCollaboratorService: ApiInviteCollaboratorService;
	apiAuthService: ApiAuthService;
	publicService: ApiPublicService;

	// Ctr
	constructor(props: any)
	{
		super(props);
		this.state = this.initState();

		// Service
		this.publicService = new ApiPublicService();
		this.apiAuthService = new ApiAuthService();
		this.inviteCollaboratorService = new ApiInviteCollaboratorService();

		this.fetchInviteCollaborator = this.fetchInviteCollaborator.bind(this);
		this.inviteContent = this.inviteContent.bind(this);
		this.handleAuthForm = this.handleAuthForm.bind(this);
		this.handleFormCreate = this.handleFormCreate.bind(this);
	}

	//</editor-fold>

	render(): ReactElement
	{
		if (!this.state.inviteCollaborator) {
			return <LoaderFullPageComponent/>;
		}

		return (
			<>
				<div style={ {
					height: 800,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center'
				} }>
					<div style={ FontStyle.h1() }>👋 Bienvenue sur Publisur !</div>
					{ this.inviteContent() }
				</div>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		const response = await this.fetchInviteCollaborator();
		this.setState({ inviteCollaborator: response });
	}

	componentDidUpdate(prevProps: ViewProps, prevState: ViewState): void
	{
		if( prevState.errorMessage !== this.state.errorMessage ) {
			this.props.modalContext.errorMessage(this.state.errorMessage);
		}
	}

	private initState(): ViewState
	{
		return {
			errorMessage: null,
			inviteCollaborator: null,
			isOpenModal: false,
			isCreateFormUser: false,
			isUserCreated: false
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content additional JSX" defaultstate="collapsed">

	private inviteContent(): ReactElement | undefined
	{
		if (this.state.inviteCollaborator) {
			return (
				<>
					<div className="d-flex justify-content-center align-items-center w-100 flex-column">
						<p style={ { ...FontStyle.littleGrey(), textAlign: 'center' } }>
							Vous avez été invité à rejoindre le
							cabinet <span style={ { textDecoration: 'underline' } }>
							{ this.state.inviteCollaborator.client.name }</span>.<br/>
							Merci de bien vouloir confirmer votre affiliation
							en tant que collaborateur
						</p>
						<div style={ { ...CardStyle.main(), marginBottom: 10, marginTop: 20 } }>
							<div style={ { ...CardStyle.container(), textAlign: 'center' } }>
								<span style={ FontStyle.normalMedium() }>{ this.state.inviteCollaborator.client.name }</span>
							</div>
						</div>
						{
							this.state.inviteCollaborator.additionalInfo && (
								<div style={ { marginTop: 20 } }>
									<p style={ { textAlign: 'center' } }>
										<span style={ { textDecoration: 'underline' } }>Votre message : </span><br/>
										<p>{ this.state.inviteCollaborator.additionalInfo }</p>
									</p>
								</div>
							)
						}
						<div style={ {
							backgroundColor: CssVariableEnum['--color-grey-100'],
							borderRadius: 15,
							width: 500,
							height: 150,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
							marginTop: 20
						} }>
							<div style={ FontStyle.h3() }> Vous posséder déjà un compte utilisateur ?</div>
							<div className="d-flex justify-content-between mt-3 w-50">
								<Button
									type={ 'default-blue' }
									onClick={ (event: any) =>
									{
										this.setState({ errorMessage: null });
										this.modalConnexion(event);
									} }
								>
									Se connecter
								</Button>
								<Button
									type={ 'default-blue' }
									onClick={ (event: any) =>
									{
										this.setState({ errorMessage: null });
										this.modalCreateFormUser(event);
									} }
								>
									Créer un compte
								</Button>
							</div>
						</div>
					</div>
				</>
			);
		}
	}

	private modalConnexion(event: any): void
	{
		event.stopPropagation();

		this.props.modalContext.content(
			'Se connecter',
			<div style={ { padding: '20px 50px' } }>
				<AuthFormComponent onFormSubmit={ this.handleAuthForm }/>
			</div>
		);
	}

	private modalCreateFormUser(event: any): void
	{
		event.stopPropagation();

		this.props.modalContext.content(
			'Créer un compte',
			<CreateUserFormComponent
				isAdmin={ false }
				onFormSubmit={ this.handleFormCreate }
				allowedUserRole={ false }
			/>
		);
	}

	// </editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	async fetchInviteCollaborator(): Promise<any>
	{
		try {
			const response = await this.inviteCollaboratorService.show(this.props.invitationCode.code);

			if (response.errorMessage) {
				this.props.navigation('/not-found');
				return;
			}

			return response;

		} catch (error) {
			this.setState({
				errorMessage: 'Une erreur est survenue lors de la récupération des détails de l\'invitation.'
			});
		}
	}

	private async handleAuthForm(formData: AuthViewState): Promise<void>
	{
		try {
			const response: any = await this.apiAuthService.auth(formData.email, formData.password);

			// Handle error message
			if (response.errorMessage) {
				this.setState({ errorMessage: response.errorMessage });
				return;
			}

			if ('user' in response) {
				await this.addUserToInviteCollaborator(response.user.userId);
			}
			//this.addClientToUser(response);
		} catch (error: any) {
			// Set error message
			this.setState({ errorMessage: error.message });
		}
	}

	private async handleFormCreate(formData: any): Promise<void>
	{
		// set Data to FormData
		formData.status = ActiveStatusEnum.ACTIVE.value;
		formData.companyId = this.state.inviteCollaborator?.client.company.id;
		formData.inviteCode = this.state.inviteCollaborator?.code;

		await this.publicService.create(formData)
			.then(async (response: any): Promise<void> =>
			{
				if (response.errorMessage) {
					this.setState({ errorMessage: response.errorMessage });
					return;
				}
				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'Utilisateur créé avec succès',
					`L'utilisateur ${ formData.firstname } ${ formData.lastname } a bien été créé`,
					'success'
				);
				this.setState({ isCreateFormUser: false });
				this.props.modalContext.isOpen(false);
				this.props.navigation('/auth');

			})
			.catch((error: any): void =>
			{
				this.setState({ errorMessage: error.message });
			})
		;
	}

	private async addUserToInviteCollaborator(userId: number): Promise<void>
	{
		const response = await this.inviteCollaboratorService.respond(
			this.props.invitationCode.code,
			userId
		);

		if (response.errorMessage) {
			this.setState({ errorMessage: response.errorMessage });
			return;
		}

		// Message
		const redirectMessage = `${ response.message } 
   : Vous allez être redirigé vers le choix du cabinet dans quelques secondes.`;

		this.props.flashMessageContext.flashMessage(redirectMessage, 'success');

		setTimeout(() =>
		{
			window.location.href = '/auth/client';
		}, 3000);
	}

	//</editor-fold>
}