import { ReactElement } from 'react';

export class ClientTypeEnum
{
	value: string;
	label: string;
	css: { backgroundColor: string, color: string, icon?: ReactElement | null };

	constructor(value: string, label: string, css: { backgroundColor: string, color: string, icon?: ReactElement | null })
	{
		this.value = value;
		this.label = label;
		this.css = css;
	}

	static ORDER_GIVER = new ClientTypeEnum(
		'ORDER_GIVER',
		'Donneur d\'ordre',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);
	static JUDICIAL_FORUM = new ClientTypeEnum(
		'JUDICIAL_FORUM',
		'Tribunal judiciaire',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);
	static COMMERCIAL_COURT_REGISTRY = new ClientTypeEnum(
		'COMMERCIAL_COURT_REGISTRY',
		'Greffe',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);
	static COLLEAGUE = new ClientTypeEnum(
		'COLLEAGUE',
		'Confrère',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);
	static LAWYER = new ClientTypeEnum(
		'LAWYER',
		'Avocat',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);
	static CERTIFIED_ACCOUNTANT = new ClientTypeEnum(
		'CERTIFIED_ACCOUNTANT',
		'Expert Comptable',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);
	static NOTARY = new ClientTypeEnum(
		'NOTARY',
		'Notaire',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);
	static MULTIDISCIPLINARY = new ClientTypeEnum(
		'MULTIDISCIPLINARY',
		'Pluridisciplinaire',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);
	static CITY_HALL = new ClientTypeEnum(
		'CITY_HALL',
		'Mairie',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);
	static PRIVATE_COMPANY = new ClientTypeEnum(
		'PRIVATE_COMPANY',
		'Entreprise',
		{ backgroundColor: 'transparent', color: 'inherit', }
	);

	static options: ClientTypeEnum[] = [
		ClientTypeEnum.ORDER_GIVER,
		ClientTypeEnum.JUDICIAL_FORUM,
		ClientTypeEnum.COMMERCIAL_COURT_REGISTRY,
		ClientTypeEnum.COLLEAGUE,
		ClientTypeEnum.LAWYER,
		ClientTypeEnum.CERTIFIED_ACCOUNTANT,
		ClientTypeEnum.NOTARY,
		ClientTypeEnum.MULTIDISCIPLINARY,
		ClientTypeEnum.CITY_HALL,
		ClientTypeEnum.PRIVATE_COMPANY,
	];

	static findByValue(selectedOption: ClientTypeEnum | string): any
	{
		return ClientTypeEnum.options.find(
			option => option.value === selectedOption
		);
	}
}