import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import { ClientTypeEnum } from '@/Modules/Client/Enum/ClientTypeEnum';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import InviteStateEnum from '@/Enum/InviteStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';

export function mapperEnumUtils(enumClass: any, value: string): any
{
	switch (enumClass) {
		case ActiveStatusEnum:
			return ActiveStatusEnum.findByValue(value);
		case PaymentStateEnum:
			return PaymentStateEnum.findByValue(value);
		case QuoteStatusEnum:
			return QuoteStatusEnum.findByValue(value);
		case PublishStateEnum:
			return PublishStateEnum.findByValue(value);
		case UserRoleEnum:
			return UserRoleEnum.findByValue(value);
		case ClientTypeEnum:
			return ClientTypeEnum.findByValue(value);
		case CollaboratorRoleEnum:
			return CollaboratorRoleEnum.findByValue(value);
		case InviteStateEnum:
			return InviteStateEnum.findByValue(value);
		default:
			return null;
	}
}