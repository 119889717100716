import React, { ReactElement } from 'react';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import { ApiAdminSupplierService } from '@/Service/Admin/ApiAdminSupplierService';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { SupplierInterface } from '@/Modules/Supplier/Interface/SupplierInterface';
import { BaseStyle } from '@/Style/BaseStyle';
import TagEnum from '@/Modules/App/Components/Atom/Tags/TagEnum';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import { LuTruck } from 'react-icons/lu';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { Alert } from 'react-bootstrap';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import MenuItem from '@/Modules/App/Components/Menu/MenuItem';

interface ComponentProps
{
  companyId: number,
  supplier: SupplierInterface|null,
  onSelectedSupplier: (supplier: SupplierInterface, companyId: number) => void
}

interface ComponentState
{
  supplierList: any[],
  isEdit: boolean,
  selectedMenuItem: string,
}

export default class ConfigAdminComponent extends React.Component<ComponentProps, ComponentState>
{
  apiSupplierService: ApiAdminSupplierService

  constructor(props: any)
  {
    super(props);

    // Service
    this.apiSupplierService = new ApiAdminSupplierService();

    // State
    this.state = this.initState();

    // Bind
    this.onSelectedSupplier = this.onSelectedSupplier.bind(this);
  }

  render(): ReactElement
  {
    return (
      <>
        <div style={{ display: 'grid', gridTemplateColumns: '240px auto', height: '100%'}}>
          <div style={{
            backgroundColor: CssVariableEnum['--color-grey-100'],
            borderBottomLeftRadius: 15,
            borderTopRightRadius: 15,
            padding: 10,
          }}>
            <MenuItem
              urlPath={'#!'}
              label={'Fournisseurs'}
              iconName={'LuStore'}
              location={''}
              onClick={() => this.onClickSettingsItemMenu('supplier') }
            />
            <MenuItem
              urlPath={'#!'}
              label={'Mes paramètres'}
              iconName={'LuSlidersHorizontal'}
              location={''}
              onClick={() => this.onClickSettingsItemMenu('settings') }
            />
          </div>
          <div style={{ padding: '10px 20px'}}>
            { this.settingsItemRender(this.state.selectedMenuItem) }
          </div>
        </div>
      </>
    );
  }

  //<editor-fold desc="Render methods" defaultstate="collapsed">

  private initState(): ComponentState
  {
    return {
      supplierList: [],
      isEdit: false,
      selectedMenuItem: 'supplier'
    };
  }

  async componentDidMount(): Promise<any>
  {
    const supplierList = await this.apiSupplierService.list();
    this.setState({ supplierList });
  }

  //</editor-fold>

  //<editor-fold desc="Render methods" defaultstate="collapsed">

  private onClickSettingsItemMenu(name: string): void {
    this.setState({ selectedMenuItem: name });
  }

  private settingsItemRender(name: string): ReactElement | null {
    switch (name) {
      case 'supplier':
        return this.supplierRender();
      case 'settings':
        return <div>En construction</div>;
      default:
        return null;
    }
  }


  private supplierRender(): ReactElement
  {
    return (
      <>
        <div style={{ width: '40%' }}>
          { this.props.supplier &&
            <>
              <div>
                <Title type={ 'h4' }>Fournisseurs courant</Title>

                <div style={ BaseStyle.cardContainer() }>
                  <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                    <div>{ this.props.supplier.name }</div>
                    <TagEnum
                      value={ this.props.supplier.status.toString() }
                      enumName={ 'ActiveStatusEnum' }
                    >
                      { ActiveStatusEnum.findByValue(this.props.supplier.status.toString())?.label }
                    </TagEnum>
                  </div>
                  <Alert variant={'info'} style={{ marginTop: 10, marginBottom: 0, padding: 3 }}>
                    { (this.props.supplier.isConnected)
                      ? 'Le Fournisseur posséde un connecteur et il est opérationnel'
                      : 'Le Fournisseur ne posséde de pas connecteur'
                    }
                  </Alert>
                </div>
              </div>
            </>
          }

          <Button
            style={ { marginTop: 5 } }
            type={ 'default' }
            iconLeft={ <LuTruck/> }
            onClick={ () => this.setState({ isEdit: true }) }
          >
            Voulez vous changer de Fournisseur ?
          </Button>

          { this.state.isEdit &&
            <>
              <div style={ { ...BaseStyle.cardContainer(), marginTop: 10 } }>
                <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                  <Title type={ 'h6' } style={{ marginTop: 0 }}>Changer de Fournisseurs</Title>
                  <Button
                    style={{ marginTop: 5 }}
                    type={ 'warning' }
                    onClick={ (event: any) => {
                      event.preventDefault();
                      event.stopPropagation();
                      this.setState({isEdit: false });
                    }}
                  >
                    Annuler
                  </Button>
                </div>

                <SelectComponent
                  listOptions={ this.state.supplierList }
                  selectedValue={ (this.props.supplier) ? this.props.supplier : '' }
                  onSelectedOption={ this.onSelectedSupplier }
                  renderOptionLabel={ (supplier: any) => `${ supplier.name }` }
                />
              </div>
            </>
          }
        </div>
      </>
    );
  }

  //</editor-fold>

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private async onSelectedSupplier(supplier: any): Promise<void>
  {
    const editedSupplier = await this.apiSupplierService.edit(
      this.props.companyId,
      supplier.id
    );

    this.props.onSelectedSupplier(editedSupplier, this.props.companyId);
  }

  //</editor-fold>
}