import React, { CSSProperties, ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

interface CheckboxProps
{
	style?: React.CSSProperties,
	options?: {
		rowLeftCheckbox?: boolean,
		rowRightCheckbox?: boolean,
	}
	label: string,
	name: string,
	isChecked: boolean,
	onCheckedChange?: (event: any) => void,
	onClick?: (event: any, option?: any) => void,
}

export default class Checkbox extends React.Component<CheckboxProps>
{
	render(): ReactElement
	{
		const { style, label, name, options, isChecked, onCheckedChange, onClick } = this.props;

		// Conditional styles
		const containerStyle: CSSProperties = {
			...styles.container,
			...style,
			...(options?.rowRightCheckbox && { display: 'flex', flexDirection: 'row' }),
			...(options?.rowLeftCheckbox && { display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end' }),
		};

		return (
			<div style={ containerStyle }>
				{ label && <div style={ styles.label }>{ label }</div> }
				<input
					style={ styles.checkbox }
					type="checkbox"
					name={ name }
					checked={ isChecked }
					onClick={ onClick }
					onChange={ onCheckedChange }
				/>
			</div>
		);
	}
}

const styles: { [key: string]: React.CSSProperties } = {
	container: {
		width: '100%',
		gap: 10
	},
	checkbox : {
		cursor: 'pointer'
	},
	label: {
		fontSize: 12,
		color: CssVariableEnum['--color-grey-500'],
	},
	test: {
		height: 100,
	},
};
