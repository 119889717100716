import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAdminFormBuilderInputOptionService extends BaseService
{
	formBuilderUrl: string = `${ BaseService.API_URL }/v1/admin/form-builder/inputs/options`;

	async list(formBuilderInputId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl + '/' + formBuilderInputId,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async show(formBuilderInputId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl + '/' + formBuilderInputId,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async create(optionLabelData: any, formBuilderInputId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl + '/' + formBuilderInputId,
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(optionLabelData),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async edit(editInputOptionData: any, formBuilderInputId: number, formBuilderInputOptionId: number): Promise<any>
	{
		try {
			const response = await fetch(
					this.formBuilderUrl + '/' + formBuilderInputId + '/' + formBuilderInputOptionId,
					{
						method: HttpVerbEnum.PATCH,
						headers: this.buildHeaders(),
						body: JSON.stringify(editInputOptionData)
					},
				)
			;

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}

	async delete(formBuilderInputId: number, formBuilderInputOptionId: number): Promise<any>
	{
		try {
			const response = await fetch(
				this.formBuilderUrl + '/' + formBuilderInputId + '/' + formBuilderInputOptionId,
				{
					method: HttpVerbEnum.DELETE,
					headers: this.buildHeaders(),
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.error(error.message);
		}
	}
}