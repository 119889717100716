import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class InputTableCell extends React.Component
	<{
		value?: any;
		onChange?: (event: any) => any;
		type?: string
		onBlur?: any;
		isLoading?: boolean;
		onKeyDown?: (event: any) => void;
	}, any>
{

	render()
	{
		return (
			this.props.isLoading
				? <div style={ { width: '50%', alignItems: 'center', paddingLeft: '10px' } }><Skeleton height={ 14 }/></div>
				: <div style={ {
					display: 'flex',
					alignItems: 'flex-start',
					width: '100%',
					height: '100%',
				} }>
					<div style={ {
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						fontSize: '14px',
						lineHeight: '20px',
						position: 'relative',
						borderRadius: '3px',
						backgroundColor: CssVariableEnum['--color-white'],
						cursor: 'text',
						padding: '4px 10px',
						height: '100%'
					} }>
						<input
							type={(this.props.type) ? this.props.type : 'text'}
							value={ this.props.value }
							onChange={ this.props.onChange }
							onBlur={ this.props.onBlur }
							onKeyDown={ this.handleKeyDown }
							style={ {
								fontSize: 'inherit',
								lineHeight: 'inherit',
								border: 'none',
								background: 'none',
								width: '100%',
								display: 'block',
								padding: 0,
								outline: 0
							} }
						/>
					</div>
				</div>
		)
	}

	private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			this.props.onKeyDown && this.props.onKeyDown(event);
		}
	}
}