import React, { ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import ClientShowComponent from '@/Modules/Client/Components/ClientShowComponent';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import CreateClientFormComponent from '@/Modules/Client/Components/Form/CreateClientFormComponent';
import HeroSection from '@/Modules/App/Components/Atom/Content/HeroSection';
import OffCanvasComponent from '@/Modules/App/Components/Atom/OffCanvas/OffCanvasComponent';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import { LuStar, LuMap, LuFileType } from 'react-icons/lu';
import Pagination from '@/Modules/App/Components/Atom/Table/Pagination';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';
import { ClientTypeEnum } from '@/Modules/Client/Enum/ClientTypeEnum';
import { NavigateFunction } from 'react-router-dom';
import { FilterInterface } from '@/Modules/App/Components/Atom/Table/Filters';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { paramsToString } from '@/Utils/HandleParamsUtils';
import TableComponent, { TableHeaderListInterface } from '@/Modules/App/Components/Atom/Table/TableComponent';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import TableFilters from '@/Modules/App/Components/Atom/Table/TableFilters';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FilterParamsInterface } from '@/Modules/App/Interface/FilterParamsInterface';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import Content from '@/Modules/App/Components/Atom/Content/Content';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';

interface ViewProps
{
	location?: any,
	authContext: AuthContextType,
	authCompanyContext: AuthCompanyContextType,
	navigation: NavigateFunction,
	flashMessageContext: FlashMessageContextType,
	companyId: number,
	modalContext: ModalContextType
}

interface ViewState
{
	paginate: PaginateInterface,
	filterParams: FilterParamsInterface,
	departments: DepartmentInterface[],
	selectedClient: ClientInterface | null,
	selectedClientIndex: number,
	selectedTabOffCanvas: number,
	isLoading: boolean,
	currentItemId: number | null,
	isOpenShowOffCanvas: boolean,
	isCreatedMode: boolean,
	isUpdatedSuccess: boolean,
	fromUrl: string | null,
	errorMessage: null
}

export default class ClientAdminView extends React.Component<ViewProps, ViewState>
{
	clientService: ApiAdminClientService;
	appService: ApiAppService;
	navigation: NavigateFunction;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Service
		this.clientService = new ApiAdminClientService();
		this.appService = new ApiAppService();

		// Navigation
		this.navigation = props.navigation;

		// Bind
		this.onRowClick = this.onRowClick.bind(this);
		this.buildFilterParams = this.buildFilterParams.bind(this);
		this.handleFormCreate = this.handleFormCreate.bind(this);
		this.handleDeleted = this.handleDeleted.bind(this);
		this.handleUpdated = this.handleUpdated.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.checkUrl = this.checkUrl.bind(this);
		this.refreshCollaborators = this.refreshCollaborators.bind(this);
		this.buildFilterOrderByAndSortParams = this.buildFilterOrderByAndSortParams.bind(this);
		this.selectedFilters = this.selectedFilters.bind(this);
		this.itemPerPage = this.itemPerPage.bind(this);
		this.refreshList = this.refreshList.bind(this);

		// Document title
		document.title = 'Publisur - ADMIN - Donneur d\'ordre';
	}

	render(): ReactElement
	{
		const { isLoading, paginate, filterParams } = this.state;

		return (
			<>
				<HeroSection
					title="Donneur d'ordre"
					icon={ null }
					isLoading={ isLoading }
					tableFilter={
						<TableFilters
							isLoading={ isLoading }
							searchInput={
								{
									name: 'search-client',
									placeholder: 'Rechercher un donneur d\'ordre...',
									search: this.state.filterParams.search || '',
									onChangeSearch: (event: any) => this.onChangeClientSearch(event),
									resetSearchInput: this.resetSearchInput.bind(this),
									onKeyDown: (event: any) => this.onKeyDownSearch(event)
								}
							}
							filters={ { list: this.buildFilterClientList(), selectedFilters: this.selectedFilters } }
							buttonCreate={ {
								label: 'Ajouter un donneur d\'ordre',
								onClick: (event: any) => this.createNewClient(event)
							} }
						/>
					}
				/>
				<Content>
					<MainWrapper>
						<TableComponent
							key={ paginate.items.toString() }
							isLoading={ isLoading }
							paginateList={ paginate.items }
							tableHeaderList={ this.buildTableHeaderClient() }
							params={ this.buildFilterParams }
							onClickRow={ this.onRowClick }
							notFoundValuesText={ 'Aucun donneur d\'ordre n\'a été trouvée' }
							onRefreshList={ this.refreshList }
							isStickyHeader={ true }
							isResizeColumns={ true }
						/>

						<Pagination
							currentPage={ filterParams.page }
							tag={ 'client' }
							list={ paginate }
							itemsPerPage={ this.itemPerPage }
						/>

						<div style={ { height: 30 } }></div>
					</MainWrapper>
					{ this.offCanvasRender() }
				</Content>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.refreshList();

		this.setState({
			departments: await this.appService.departmentList()
		}, () =>
		{
			this.checkUrl();
		});
	}

	private initState(): ViewState
	{
		return {
			paginate: {
				items: [],
				itemsPerPage: 10,
				totalCount: 0,
				totalPages: 1
			},
			filterParams: {
				page: 1,
				itemsPerPage: 10,
				search: null,
				filters: null,
				orderBy: null,
				sort: null,
			},
			departments: [],
			selectedClient: null as ClientInterface | null,
			selectedClientIndex: -1,
			selectedTabOffCanvas: 1,
			isLoading: true,
			currentItemId: null,
			isOpenShowOffCanvas: false,
			isCreatedMode: false,
			isUpdatedSuccess: false,
			fromUrl: null,
			errorMessage: null,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Render content methods" defaultstate="collapsed">

	private offCanvasRender(): ReactElement
	{
		return (
			<>
				<OffCanvasComponent
					show={ this.state.isOpenShowOffCanvas }
					onHide={ () => this.setState({ isOpenShowOffCanvas: false, selectedTabOffCanvas: 1, selectedClient: null }) }
					onPrevious={ () => this.navigateClient(true) }
					onNext={ () => this.navigateClient(false) }
					isNavigateArrow={ (!this.state.isCreatedMode) }
					tabs={ this.buildTabsOffCanvas }
					isTabNeeded={ (!this.state.isCreatedMode) }
					selectedTab={ this.state.selectedTabOffCanvas }
					onTabChange={ (selectedTab: number) => this.handleTabChange(selectedTab) }
					isSellsyId={ Boolean(this.state.selectedClient?.extSellsyId) }
					renderTitle={
						<div style={ { display: 'flex', alignItems: 'flex-start', width: '80%', marginBottom: 20 } }>
							<div style={ FontStyle.h2() }>
								{ (this.state.selectedClient) ? this.state.selectedClient.name : null }
							</div>
						</div>
					}
				>
					{ this.state.selectedClient && (
						<ClientShowComponent
							key={ this.state.selectedClient.id }
							selectedClient={ this.state.selectedClient }
							selectedTabOffCanvas={ this.state.selectedTabOffCanvas }
							flashMessageContext={ this.props.flashMessageContext }
							authCompanyContext={ this.props.authCompanyContext }
							navigation={ this.props.navigation }
							companyId={ this.props.companyId }
							modalContext={ this.props.modalContext }
							handleDeleted={ this.handleDeleted }
							handleUpdated={ this.handleUpdated }
							isUpdatedSuccess={ this.state.isUpdatedSuccess }
							departments={ this.state.departments }
							currentItemId={ this.state.currentItemId }
							fromUrl={ this.state.fromUrl }
							onRefresh={ this.refreshCollaborators }
							isLoading={ this.state.isLoading }
						/>
					) }
				</OffCanvasComponent>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Handle filters / Params" defaultstate="collapsed">

	private buildFilterClientList(): FilterInterface[]
	{
		return [
			{ label: 'Statut', tag: 'status', icon: <LuStar/>, childList: ActiveStatusEnum.roleOptions, type: 'select' },
			{ label: 'Type', tag: 'clientType', icon: <LuFileType/>, childList: ClientTypeEnum.options, type: 'select' },
			{ label: 'Ville', tag: 'city', icon: <LuMap/>, childList: [], type: 'search' },
		];
	}

	private selectedFilters(selectedFilters: any[]): void
	{
		const filtersObject = selectedFilters.reduce((acc, filter) =>
		{
			acc[filter.filterName] = filter.filterValue;
			return acc;
		}, {});

		this.setState(prevState => ({
				filterParams: {
					...prevState.filterParams,
					filters: filtersObject
				},
			}),  () => this.refreshList()
		);
	}

	private buildFilterOrderByAndSortParams(orderBy: string, sort: string): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				orderBy: orderBy,
				sort: sort
			}
		}), () => this.refreshList());
	}

	private itemPerPage(currentPage: number, itemPerPage: number): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				page: currentPage,
				itemsPerPage: itemPerPage
			}
		}), () => this.refreshList());
	}

	private searchClient(term: string): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				search: (term !== '') ? term : '',
				page: 1
			}
		}), () => this.refreshList());
	}

	private onKeyDownSearch(event: any): void
	{
		if (event.key === 'Enter') {
			this.searchClient(this.state.filterParams.search || '');
		}
	}

	private onChangeClientSearch(event: React.ChangeEvent<HTMLInputElement>): void
	{
		const term: string = event.target.value;
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				search: term
			}
		}), () => {
			if (term === '') {
				this.refreshList();
			}
		});
	}

	private resetSearchInput(): void
	{
		this.searchClient('');
	}

	//</editor-fold>

	//<editor-fold desc="Private methods FORM / STATE" defaultstate="collapsed">

	private async refreshList(): Promise<void>
	{
		try {
			const clientListResponse = await this.clientService.list(paramsToString(this.state.filterParams));

			this.setState({
				paginate: {
					items: clientListResponse.items,
					itemsPerPage: clientListResponse.itemsPerPage,
					totalCount: clientListResponse.length,
					totalPages: clientListResponse.totalPages
				}
			}, () => this.setState({ isLoading: false }));
		} catch (error: any) {
			console.log('Something wrong on refresh user list', error.errorMessage);
		}
	}

	private handleTabChange(selectedTab: number): void
	{
		this.setState({ selectedTabOffCanvas: selectedTab });
	}

	private buildTabsOffCanvas(): { id: number, name: string }[]
	{
		return [
			{ id: 1, name: 'Détails' },
			{ id: 2, name: 'Collaborateurs' },
			{ id: 3, name: 'Commercial' },
			{ id: 4, name: 'Préférences' },
			{ id: 5, name: 'Logos' },
		];
	}

	private buildTableHeaderClient(): TableHeaderListInterface[]
	{
		const headers: TableHeaderListInterface[] = [
			{
				columnTitle: 'Dénomination',
				orderTag: 'name',
				minWidth: 100,
				width: 600,
				fontWeight: 600,
				justifyContent: 'left',
				type: 'twoLinesString',
				dataTag: ['name', 'siren']
			},
			{
				columnTitle: 'Villes',
				orderTag: 'city',
				minWidth: 100,
				width: 200,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'twoLinesString',
				dataTag: ['address.city', 'address.zipCode']
			},
			{
				columnTitle: 'Type',
				orderTag: 'clientType',
				minWidth: 100,
				width: 200,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'tag',
				dataTag: { label: 'options.type', enumClass: ClientTypeEnum }
			},
			{
				columnTitle: 'Crée le...',
				orderTag: 'createdAt',
				minWidth: 100,
				width: 200,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'date',
				dataTag: 'createdAt'
			},
			{
				columnTitle: 'Statut',
				orderTag: 'status',
				minWidth: 100,
				width: 200,
				fontWeight: 400,
				justifyContent: 'left',
				type: 'tag',
				dataTag: { label: 'status', enumClass: ActiveStatusEnum }
			}
		];

		if (this.props.authContext && this.props.authContext.user.role === UserRoleEnum.ROLE_DEVELOPER.value) {
			headers.splice(1, 0, {
				columnTitle: 'Entreprise',
				orderTag: 'company',
				minWidth: 100,
				width: 300,
				fontWeight: 500,
				justifyContent: 'left',
				type: 'company',
				dataTag: ['company.name', 'company.city']
			});
		}

		return headers;
	}

	private buildFilterParams(orderBy: string, sort: string): void
	{
		this.setState(prevState => ({
			filterParams: {
				...prevState.filterParams,
				orderBy: orderBy,
				sort: sort
			}
		}), async (): Promise<void> => await this.refreshList());
	}

	private async onRowClick(event: any, client: ClientInterface): Promise<void>
	{
		if (event) {
			event.stopPropagation();
		}

		const getClient: ClientInterface = await this.clientService.show(client.id);

		this.setState({
			selectedClient: getClient,
			isOpenShowOffCanvas: true,
			currentItemId: client.id
		});
	}

	private createNewClient(event: any): void
	{
		event.stopPropagation();

		this.props.modalContext.content(
			'Ajouter un donneur d\'ordre',
			<div style={ { padding: '0 25px 25px 25px', width: 750 } }>
				<CreateClientFormComponent
					authContext={ this.props.authContext }
					authCompanyContext={ this.props.authCompanyContext }
					onFormSubmit={ this.handleFormCreate }
					isFromAdmin={ true }
					modalContext={ this.props.modalContext }
					isLoadingModal={ this.handleLoadingModal.bind(this) }
					flashMessageContext={ this.props.flashMessageContext }
				/>
			</div>
		);
	}

	private handleLoadingModal(isLoading: boolean): void
	{
		this.props.modalContext.isLoading(isLoading);
	}

	private async handleFormCreate(formData: any): Promise<void>
	{
		// Add data Active Status
		formData.status = ActiveStatusEnum.ACTIVE.value;

		try {
			// Handle Error on client Sellsy empty
			if (formData.extSellsyId === '') {
				this.props.modalContext.isLoading(false);
				this.props.modalContext.errorMessage('Attention, vous n\'avez pas lier de client Sellsy');
				return;
			}

			const response = await this.clientService.create(formData);

			if (response.errorMessage) {
				this.setState({ errorMessage: response.errorMessage });
				this.props.modalContext.isOpen(true);
				this.props.modalContext.isLoading(false);
				this.props.modalContext.errorMessage(response.errorMessage);
				return;  // Ensure that the function exits here if there's an error
			}

			// Fetch Clients
			await this.refreshList();

			// Set Flash message
			this.props.flashMessageContext.flashMessage(
				'Client créé avec succès',
				`Votre nouveau client ${ formData.name } a bien été créé`,
				'success'
			);

			// Close menu and OffCanvas, set refresh clients
			this.setState({ isOpenShowOffCanvas: false, isCreatedMode: false });
			this.props.modalContext.isOpen(false);
			this.props.modalContext.errorMessage('');
		} catch (error: any) {
			this.setState({ errorMessage: error.message });
		}
	}

	private async refreshCollaborators(selectedClientId: number): Promise<void>
	{
		try {
			const refreshCollaborators = await this.clientService.show(selectedClientId);
			this.setState(prevState => ({
				...prevState,
				selectedClient: refreshCollaborators,
			}));

		} catch (error: any) {
			console.log('Something wrong with refresh Clients');
		}
	}

	private async handleUpdated(updatedClientData: any, clientId: number): Promise<void>
	{
		try {
			this.setState({ isLoading: true });

			const updatedClient = await this.clientService.edit(updatedClientData, clientId);

			// refresh Clients
			this.refreshList();

			this.setState({ selectedClient: updatedClient });

			if (updatedClient.errorMessage) {
				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'Erreur sur les options client',
					'Une erreur est survenu, merci de contacter un admin',
					'error'
				);
			} else if (updatedClient) {
				// Set Flash message
				this.props.flashMessageContext.flashMessage(
					'Mise à jour réussie',
					'Votre client a bien été mis à jour, vérifiez que toutes les données sont exactes',
					'success'
				);
			}

			// Refresh clients
			this.setState({ isUpdatedSuccess: true });
			// Reset isUpdatedSuccess
			setTimeout(() =>
			{
				this.setState({ isUpdatedSuccess: false });
			}, 500);
		} catch (error: any) {
			console.error('Something wrong on Updated Client :', error);
		}
	}

	private async handleDeleted(clientId: number): Promise<void>
	{
		try {
			this.setState({ isLoading: true });
			await this.clientService.delete(clientId);

			this.refreshList();

			// Set Flash message
			this.props.flashMessageContext.flashMessage(
				'Supression réussi',
				'Le client a bien été supprimé',
				'success',
			);

			// Close menu and OffCanvas, set refresh users
			this.setState({ isOpenShowOffCanvas: false });
		} catch (error: any) {
			console.error('Something wrong on Deleted Client :', error);
		}
	}

	private navigateClient(isPrevious: boolean): void
	{
		const { selectedClientIndex, paginate } = this.state;
		let navigateIndex = isPrevious ? selectedClientIndex - 1 : selectedClientIndex + 1;

		// Check index
		if (navigateIndex >= 0 && navigateIndex < paginate.items.length) {
			this.setState({
				selectedClient: paginate.items[navigateIndex],
				selectedClientIndex: navigateIndex,
				currentItemId: this.state.selectedClient?.id as number
			});
		}
	}

	private checkUrl(): void
	{
		const queryParams: URLSearchParams = new URLSearchParams(this.props.location.search);
		const clientId: string | null = queryParams.get('clientId');
		const fromUrl: string = queryParams.get('from') as string;
		const currentSelectedClientId: number | null = this.state.selectedClient && this.state.selectedClient.id;

		if (clientId && Number(clientId) !== currentSelectedClientId) {
			const selectedClient = this.state.paginate.items.find((client: ClientInterface) => client.id === Number(clientId));
			if (selectedClient) {
				this.setState({
					selectedClient,
					isOpenShowOffCanvas: true,
					selectedTabOffCanvas: 2,
					isCreatedMode: false,
					fromUrl: fromUrl
				});
			}
		}
	}

	//</editor-fold>
}