import React, { ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuX, LuImage } from "react-icons/lu";
import Title from '@/Modules/App/Components/Atom/Title/Title';
import { ModalContextType } from '@/Provider/ModalProvider';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Subtitle from '@/Modules/App/Components/Atom/Title/Subtitle';

export default class AddLogoClientFormComponent extends React.Component
	<
		{
			client: ClientInterface,
			modalContext: ModalContextType,
			flashMessageContext: FlashMessageContextType,
			onCreateLogo: (formData: any) => void,
		},
		{
			isLoadingComponent: boolean,
			formData: {
				logoName: string,
				logoFile: string
			},
			logoPreviewUrl: string,
			isAddLogoModal: boolean
		}
	>
{
	fileInputRef = React.createRef<HTMLInputElement>();

	state = {
		isLoadingComponent: true,
		formData: {
			logoName: '',
			logoFile: ''
		},
		logoPreviewUrl: '',
		isAddLogoModal: false
	};

	constructor(props: any)
	{
		super(props);

		// Bind
		this.onFileChange = this.onFileChange.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onNameChange = this.onNameChange.bind(this);
		this.onCreateLogo = this.onCreateLogo.bind(this);
		this.onClickInputFile = this.onClickInputFile.bind(this);
		this.resetForm = this.resetForm.bind(this);
	}

	componentDidMount()
	{
		this.props.modalContext.actions([
			{
				label: 'Enregistrer',
				buttonType: 'default',
				action: (event?: any) =>
				{
					event.stopPropagation();
					this.onCreateLogo(event);
				}
			},
		])
	}

	render(): ReactElement
	{
		return (
			<>
				{ this.titleFormAddLogoContent() }
				<form style={ { padding: '25px' } } className="" onSubmit={ this.onCreateLogo }>
					<Input
						label="Nom du logo"
						type="text"
						name="logoName"
						style={ { flex: '0 0 100px' } }
						value={ this.state.formData.logoName || '' }
						onChange={ (event: any) => this.onNameChange(event) }
					/>

					{ !this.state.logoPreviewUrl && (
						<div
							className="drag-drop-zone mt-2 mb-2"
							onDragOver={ this.onDragOver }
							onDrop={ this.onDrop }
							style={ { border: '2px dashed #ccc', padding: '20px', textAlign: 'center', cursor: 'pointer' } }
							onClick={ this.onClickInputFile }
						>
							Glissez-déposez votre fichier ici ou cliquez pour sélectionner un fichier
							<input
								type="file"
								id="logoFile"
								ref={ this.fileInputRef }
								style={ { display: 'none' } }
								onChange={ (event) => this.onFileChange(event.nativeEvent) }
							/>
						</div>
					)}

					{ this.state.logoPreviewUrl && (
						<>
							<div style={ { display: 'flex' } }>
								<div style={ { marginTop: '20px', border: '1px dashed #ccc', width: '140px' } }>
									<img
										src={ this.state.logoPreviewUrl }
										alt="Aperçu du logo"
										style={ { maxWidth: '100%', maxHeight: '200px', padding: '5px' } }
									/>
									<Button
										style={ { position: 'absolute' } }
										type={ 'default' }
										iconLeft={ <LuX/> }
										onClick={ (event: any) => this.resetForm(event) }
									/>
								</div>
							</div>
						</>
					) }
				</form>
			</>
		);
	}

	private titleFormAddLogoContent(): ReactElement
	{
		return (
			<>
				<div style={ {
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '20px',
					borderBottom: `1px solid ${ CssVariableEnum['--color-grey-300'] }`
				} }>
					<div style={ {
						display: 'flex',
						alignItems: 'center',
						gap: '10px'
					} }>
						<div style={ {
							height: '40px',
							width: '40px',
							borderRadius: '6px',
							border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						} }>
							<LuImage/>
						</div>
						<div>
							<Title
								type={ 'h5' }
								style={ { margin: 'none' } }>
								Ajouter un logo
							</Title>
							<Subtitle>Veuillez remplir les champs ci-dessous pour ajouter un nouveau logo</Subtitle>
						</div>
					</div>
				</div>
			</>
		);
	}

	private onFileChange(event: any): void
	{
		event.preventDefault();
		if (event.target.files && event.target.files[0]) {
			this.setState({
				formData: { ...this.state.formData, logoFile: event.target.files[0] },
				logoPreviewUrl: URL.createObjectURL(event.target.files[0])
			});
		}
	}

	private onClickInputFile(): void
	{
		this.fileInputRef.current?.click();
	}

	private onDragOver(event: any)
	{
		event.preventDefault();
	}

	private onDrop(event: any)
	{
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files && files[0]) {
			const file = files[0];
			this.setState({
				formData: { ...this.state.formData, logoFile: file },
				logoPreviewUrl: URL.createObjectURL(file),
			});
		}
	}

	private onNameChange(event: any): void
	{
		this.setState({ formData: { ...this.state.formData, logoName: event.target.value } });
	}

	private onCreateLogo(event: any): void
	{
		event.preventDefault();

		const formData = new FormData();

		formData.append('logoFile', this.state.formData.logoFile);
		formData.append('logoName', this.state.formData.logoName);

		// Props FormData
		this.props.onCreateLogo(formData);

		// Handle modal context
		this.props.modalContext.isOpen(false);
		this.props.modalContext.errorMessage('');

		// Set Flash message
		this.props.flashMessageContext.flashMessage(
			'Mise à jour réussie',
			'Votre logo a été ajouté avec succès',
			'success'
		);

		this.resetForm(event);
	}

	private resetForm(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		if (this.state.logoPreviewUrl) {
			URL.revokeObjectURL(this.state.logoPreviewUrl);
		}

		this.setState({
			formData: {
				logoName: '',
				logoFile: ''
			},
			logoPreviewUrl: '',
		});
	}
}