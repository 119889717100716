import React, { ReactElement } from 'react';
import { ComponentLayoutProps } from '@/Modules/App/Components/Layout/Layout';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class LayoutPublic extends React.Component<ComponentLayoutProps>
{
	render(): ReactElement
	{
		return (
			<>
				<div className="publisur-app-inner">
					<div style={ { display: 'grid', gridTemplateRows: '60px auto' } }>
						<div style={ {
							padding: '0 25px',
							backgroundColor: CssVariableEnum['--color-grey-900'],
							display: 'flex',
							justifyContent: 'center',
							position: 'fixed',
							top: 0,
							left: 0,
							width: '100%',
							height: 60
						} }>
							<div style={ {
								width: 100,
								display: 'flex',
								justifyContent: 'center',
								height: '100%',
								alignItems: 'center',
							} }>
								<a href={ '/auth' } style={{ width: '100%'}}>
									<img src="/img/logo-publisur-white.svg" alt="logo publisur" style={ { objectFit: 'contain' } }/>
								</a>
							</div>
						</div>
						<div style={ {
							backgroundColor: CssVariableEnum['--color-grey-100'],
							display: 'flex',
							top: 60,
							position: 'relative',
							minHeight: 'calc(100vh - 60px)',
							padding: '10px 25px'
						} }>
							<div style={ { width: '100%' } }>
								{ this.props.children }
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}