import React, { ReactElement } from 'react';
import LegalNoticeFomCardComponent from '@/Modules/LegalNotice/Components/Form/Admin/FormCardComponent';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';

interface ComponentProps
{
  isDisplayBlock: boolean,
  discount: number,
  onChangeDiscount: (event: React.ChangeEvent<HTMLInputElement>) => void,
  selectedDiscountPreference: string|null,
  onSelectedDiscountPreference: (event: any) => void
}

export default class BlockDiscountComponent extends React.Component<ComponentProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        { this.props.isDisplayBlock &&
          <>
            <LegalNoticeFomCardComponent
              title={"Remise accordée"}
              children={
                <>
                  <div style={{ display: 'flex' }}>
                    <Input
                      label={ 'Accorder une remise' }
                      type={ 'number' }
                      name={ 'customer-discount' }
                      value={ this.props.discount }
                      containerDivWidth={ "350px" }
                      onChange={ (event: any) => this.props.onChangeDiscount(event) }
                    />

                    <SelectComponent
                      style={{ position: 'relative', width: "200px" }}
                      label={ "Création de l'avoir" }
                      listOptions={ [{ label: "Donneur d'ordre", value: "CLIENT" }, { label: "Client final", value: "CONSUMER" } ] }
                      onSelectedOption={ (event: any) => this.props.onSelectedDiscountPreference(event.value) }
                      selectedValue={ this.handlePropsValue() }
                      renderOptionLabel={ (option) => `${ option.label }` }
                    />
                  </div>
                </>
              }
            />
          </>
        }
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private handlePropsValue(): { label: string, value: string }|null
  {
    return { label: "Donneur d'ordre", value: "CLIENT" };
  }

  //</editor-fold>
}