export function handleChangeInput(event: any, setState: Function): void
{
  const { name, value } = event.target;
  const subObject: string[] = name.split('.');

  setState((prevState: any) => {
    if (!prevState || !prevState.formData) {
      return prevState;
    }

    let updatedFormData = { ...prevState.formData };

    if (subObject.length > 1) {
      let subObjectRef = updatedFormData;
      for (let i = 0; i < subObject.length - 1; i++) {
        if (!subObjectRef[subObject[i]]) {
          subObjectRef[subObject[i]] = {};
        }
        subObjectRef = subObjectRef[subObject[i]];
      }

      const lastKey = subObject[subObject.length - 1];
      subObjectRef[lastKey] = (lastKey === 'number') ? parseInt(value, 10) || null : value;
    } else {
      updatedFormData[name] = value;
    }

    return { formData: updatedFormData };
  });
}
