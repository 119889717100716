import { redirect } from 'react-router-dom';
import LocalStorageService from '../Service/Common/LocalStorageService';
import { UserRoleEnum } from '@/Enum/UserRoleEnum';

export function checkProtectedRoute(
  isCheckClient: boolean = false,
  isAdmin: boolean = false,
  isSuperAdmin: boolean = false,
  isDeveloper: boolean = false
): typeof redirect | object
{
  const token = LocalStorageService.getToken();
  if (!token || _handleExpToken()) {
    return redirect('/auth');
  }

  const localStorageUser = LocalStorageService.getUserLocalStorage();

  if (isDeveloper) {
    if (localStorageUser.userRole !== UserRoleEnum.ROLE_DEVELOPER) {
      return redirect('/auth');
    }
  } else {
    if (localStorageUser.userRole === UserRoleEnum.ROLE_DEVELOPER) {
      return redirect('/developer');
    }
  }

  if (isCheckClient) {
    const authClient = localStorage.getItem('authClientId');

    if (!authClient) {
      const user = localStorage.getItem('user');
      const userObj = user ? JSON.parse(user) : null;

      if (userObj.userRole === UserRoleEnum.ROLE_ADMIN) {
        return redirect('/auth');
      } else {
        return redirect('/auth/client');
      }
    }
  }

  if (isAdmin) {
    if (localStorageUser.userRole === UserRoleEnum.ROLE_USER) {
      return redirect('/auth');
    } else if (localStorageUser.userRole === UserRoleEnum.ROLE_SUPER_ADMIN && !LocalStorageService.getAuthCompanyLocalStorage()) {
      return redirect('/auth/company');
    }

    if (isSuperAdmin && localStorageUser.userRole !== UserRoleEnum.ROLE_SUPER_ADMIN) {
      return redirect('/auth');
    }
  }

  return {};
}

function _handleExpToken(): boolean {
  // Init vars
  const user = localStorage.getItem('user');
  const expirationTime = user ? JSON.parse(user).exp : null;
  const currentTime = Math.floor(Date.now() / 1000);

  if (expirationTime && currentTime > parseInt(expirationTime)) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    return true;
  }

  return false;
}
