import React, { ReactElement } from 'react';
import LegalNoticeFomCardComponent from '@/Modules/LegalNotice/Components/Form/Admin/FormCardComponent';
import Radio from '@/Modules/App/Components/Atom/Form/Radio';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';

interface ComponentProps
{
  isDisplayBlock: boolean,
  selectedBillingOption: BilledToTypeEnum,
  onSelectedBillingOption: (data: any) => void,
}

export default class BlockBillingAddressSellsyComponent extends React.Component<ComponentProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        { this.props.isDisplayBlock &&
          <>
            <LegalNoticeFomCardComponent
              title={"Adresse de facturation"}
              children={
                <>
                  <Radio
                    label="Choisir l'adresse de facturation ?"
                    options={ this.buildRadioListOption() }
                    name="billingOption"
                    selectedValue={ this.props.selectedBillingOption.value }
                    onSelectedOption={ this.onChangeSelectedBillingOption.bind(this) }
                  />
                </>
              }
            />
          </>
        }
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private onChangeSelectedBillingOption(billingOption: any): void
  {
    this.props.onSelectedBillingOption(billingOption);
  }

  private buildRadioListOption(): { label: string, value: string }[]
  {
    return [
      { label: 'Donneur d\'ordre', value: BilledToTypeEnum.ORDER_GIVER.value },
      { label: 'Client final', value: BilledToTypeEnum.FINAL_CUSTOMER.value }
    ];
  }

  //</editor-fold>
}