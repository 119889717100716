import { CSSProperties } from 'react';

export default class TransitionStyle
{
	static transitionAbsolute(isOpen: boolean): CSSProperties
	{
		return {
			position: 'absolute',
			zIndex: 600,
			opacity: (isOpen) ? 1 : 0,
			transform: (isOpen) ? 'translateY(0)' : 'translateY(-10px)',
			transitionProperty: 'opacity, transform',
			transitionDuration: '270ms',
			transitionTimingFunction: 'ease',
		};
	}
}