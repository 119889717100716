import React, { CSSProperties, ReactElement } from 'react';

export default class TagTableEnum extends React.Component
	<{
		enumTag: {
			value: string,
			label: string,
			css: { backgroundColor: string, color: string, border: string, icon?: ReactElement | null }
		},
		style?: CSSProperties
	}, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={ {
					height: '24px',
					padding: '5px 8px',
					backgroundColor: this.props.enumTag?.css.backgroundColor,
					color: this.props.enumTag?.css.color,
					border: this.props.enumTag?.css.border,
					borderRadius: '6px',
					display: 'inline-flex',
					justifyContent: 'center',
					alignItems: 'center',
					...this.props.style
				} }>
					{ this.props.enumTag?.css.icon &&
            <div style={ { display: 'flex', alignItems: 'center', marginRight: '5px' } }>
							{ this.props.enumTag?.css.icon }
            </div>
					}
					<span>{ this.props.enumTag?.label }</span>
				</div>
			</>
		);
	};
}