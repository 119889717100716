import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';
import InviteCollaboratorModel from '@/Modules/Collaborator/Model/InviteCollaboratorModel';


export class ApiInviteCollaboratorService extends BaseService
{
	inviteCollaboratorUrlPublic: string = `${ BaseService.API_URL }/public/invitations`;
	inviteCollaboratorUrl: string = `${ BaseService.API_URL }/v1/invitations`;

	async show(code: string)
	{
		try {
			const response = await fetch(
				this.inviteCollaboratorUrlPublic + '/' + code,
				{
					method: HttpVerbEnum.GET,
					headers: this.buildHeaders()
				}
			);

			return await response.json();
		} catch (error: any) {
			console.log(error);
		}
	}

	async send(inviteCollaboratorModel: InviteCollaboratorModel, clientId: number)
	{
		try {
			const response = await fetch(
				this.inviteCollaboratorUrl + '/' + clientId + '/send',
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify(inviteCollaboratorModel)
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.log(error.errorMessage);
		}
	}

	async respond(code: string, userId: number)
	{
		try {
			const response = await fetch(
				this.inviteCollaboratorUrlPublic + '/' + code + '/accept',
				{
					method: HttpVerbEnum.POST,
					headers: this.buildHeaders(),
					body: JSON.stringify({ userId : userId })
				}
			);

			// Response data
			return await response.json();
		} catch (error: any) {
			console.log(error.errorMessage);
		}
	}

}