import React, { ReactElement } from 'react';
import FormBuilderCategoryInputListComponent
	from '@/Modules/FormBuilder/Components/FormBuilderCategoryInputListComponent';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { ApiAdminFormBuilderCategoryService } from '@/Service/Admin/ApiAdminFormBuilderCategoryService';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { DragDropContext } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';
import { FormBuilderCategoryTypeEnum } from '@/Enum/FormBuilderCategoryTypeEnum';
import { Alert } from 'react-bootstrap';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import Block from '@/Modules/App/Components/Atom/Block/Block';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import BlockGroup from '@/Modules/App/Components/Atom/Block/BlockGroup';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';

export default class FormBuilderCategoriesComponent extends React.Component
	<
		{
			flashMessageContext: FlashMessageContextType,
		},
		{
			categories: FormBuilderCategoryInterface[],
			selectedOptionRender: string,
			availableTypes: string[],
			isLoadingFullPage: boolean,
			isLoadingComponent: boolean,
			errorMessage: string | null,
		}
	>
{
	formBuilderCategoryService: ApiAdminFormBuilderCategoryService;

	constructor(props: any)
	{
		super(props);

		this.state = {
			errorMessage: '',
			categories: [],
			selectedOptionRender: '',
			availableTypes: [],
			isLoadingFullPage: true,
			isLoadingComponent: true,
		};

		// Service
		this.formBuilderCategoryService = new ApiAdminFormBuilderCategoryService();

		// Bind
		this.refreshCategories = this.refreshCategories.bind(this);
		this.loadingUpdateComponent = this.loadingUpdateComponent.bind(this);
		this.onCreate = this.onCreate.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.onChangeSelectRender = this.onChangeSelectRender.bind(this);
	}

	async componentDidMount(): Promise<void>
	{
		this.refreshCategories();
		this.refreshListAvailableType();
	}

	render(): ReactElement
	{
		if (this.state.isLoadingFullPage) {
			return <LoaderFullPageComponent/>;
		}

		return (
			<>
				<BlockGroup style={ { marginTop: 20 } }>
					<Block theme={ 'light' } style={ { display: 'flex', flexGrow: 1, justifyContent: 'space-between' } }>
						<div style={ { padding: '0 10px' } }>
							<Title type={ 'h5' }>Catégories principales :</Title>
							<DragDropContext onDragEnd={ (result: any) => this.onDragEnd(result, null) }>
								<FormBuilderCategoryInputListComponent
									categories={ this.state.categories }
									categoryType={ FormBuilderCategoryTypeEnum.PRIMARY }
									parent={ null }
									onCreate={ this.onCreate }
									onEdit={ this.onEdit }
									onDelete={ this.onDelete }
									isLoading={ this.state.isLoadingComponent }
									isLoadingUpdate={ this.loadingUpdateComponent }
									errorMessage={ this.state.errorMessage }
								/>
							</DragDropContext>
						</div>
						{ this.availableTypesContent() }
					</Block>
					<Block theme={ 'dark' }
								 style={ { width: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' } }>
						<SelectComponent
							listOptions={ this.state.categories }
							onSelectedOption={ (event: any) => this.onChangeSelectRender(event) }
							renderOptionLabel={ (option) => option.label }
						/>
					</Block>
				</BlockGroup>
				<BlockGroup style={ { marginTop: 10, flexWrap: 'wrap', gap: 10 } }>
					{ this.state.categories.map((category: FormBuilderCategoryInterface, index: number) => (
						<Block key={ category.id } theme={ 'light' } style={ { width: `calc((100% - 30px) / 4)`, padding: 15 } }>
							<div style={ { fontSize: 16, fontWeight: 600, margin: '0 0 0 15px' } }>{ category.label }</div>
							<DragDropContext onDragEnd={ (result: any) => this.onDragEnd(result, index) } key={ category.id }>
								<FormBuilderCategoryInputListComponent
									categories={ this.state.categories[index].childCategories }
									categoryType={ FormBuilderCategoryTypeEnum.SECONDARY }
									parent={ category }
									onCreate={ this.onCreate }
									onEdit={ this.onEdit }
									onDelete={ this.onDelete }
									isLoading={ this.state.isLoadingComponent }
									isLoadingUpdate={ this.loadingUpdateComponent }
									errorMessage={ this.state.errorMessage }
								/>
							</DragDropContext>
						</Block>
					)) }
				</BlockGroup>
			</>
		);
	}

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private availableTypesContent(): ReactElement
	{
		return (
			<>
				<div style={ { width: 300 } }>
					<Alert className="mb-0" variant="info">
						<Alert.Heading>
							<h6>Liste des types de catégories non affectés :</h6>
						</Alert.Heading>
						<div className="d-flex flex-column">
							{ this.state.availableTypes.map((type: string) =>
							{
								return <span key={ type }>{ LegalNoticeTypeEnum.findByValue(type)?.label }</span>;
							}) }
						</div>
					</Alert>
				</div>
			</>
		);
	}

	private onChangeSelectRender(selectedValueOption: any): void
	{
		this.setState({
			selectedOptionRender: selectedValueOption
		});
	}

	//</editor-fold>

	//<editor-fold desc="CRUD methods" defaultstate="collapsed">

	private async onCreate(formData: Partial<FormBuilderCategoryInterface>)
	{
		try {
			this.setState({ isLoadingComponent: true });
			const response = await this.formBuilderCategoryService.create(formData);
			if (response.errorMessage) {
				this.setState({ errorMessage: response.errorMessage });
				return;
			}

			// Fetch formBuilder Categories
			const refreshCategories = await this.formBuilderCategoryService.list();
			// set refresh category
			this.setState({ categories: refreshCategories }, () =>
			{
				this.setState({ isLoadingComponent: false });
				this.props.flashMessageContext.flashMessage(
					'Valeur ajoutée',
					'Votre nouvelle valeur a été ajoutée avec succès',
					'success',
				);
			});
		} catch (error: any) {
			this.setState({ errorMessage: error.message, isLoadingComponent: false });
		}
	}

	private async onEdit(formBuilderCategory: FormBuilderCategoryInterface)
	{
		try {
			const response = await this.formBuilderCategoryService.edit(formBuilderCategory, formBuilderCategory.id);
			// Fetch formBuilder Categories
			const refreshCategories = await this.formBuilderCategoryService.list();

			if (response.errorMessage) {
				this.props.flashMessageContext.flashMessage(
					'Erreur',
					'Attention la valeur ajoutée existe déjà',
					'error'
				);
				this.setState({
					errorMessage: response.errorMessage,
					isLoadingComponent: false ,
					categories: refreshCategories
				});
				return;
			}

			// set refresh category
			this.setState({ categories: refreshCategories, isLoadingFullPage: false },
				() => this.props.flashMessageContext.flashMessage(
					'Valeur mise à jour',
					'Votre valeur a été mise à jour avec succès',
					'success',
				)
			);
			// Refresh available types
			await this.refreshListAvailableType();

		} catch (error: any) {
			this.setState({ errorMessage: error.message });
		}
	}

	private async onDelete(categoryId: number, type: string): Promise<any>
	{
		try {
			let categoryToDelete;
			// If is primary Category
			if (type === FormBuilderCategoryTypeEnum.PRIMARY) {
				categoryToDelete = this.state.categories.find(category => category.id === categoryId);

				// Check if this category contains Child
				if (categoryToDelete?.childCategories
					&& categoryToDelete.childCategories.length > 0
				) {
					this.deleteCategoryAndChildConfirmation(categoryId);
				} else {
					this.deleteCategory(categoryId);
				}

			} else if (type === FormBuilderCategoryTypeEnum.SECONDARY) {
				this.deleteCategory(categoryId);
			}

		} catch (error: any) {
			this.setState({ errorMessage: error.message });
		}
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private async deleteCategoryAndChildConfirmation(categoryId: number)
	{
		const result = await Swal.fire({
			title: 'Êtes-vous sûr?',
			text: 'Si vous supprimez cette catégorie principale, toutes les catégories enfants seront supprimées',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Oui, supprimez-le!'
		});

		if (result.isConfirmed) {
			Swal.fire(
				'Supprimé!',
				'La Catégorie a bien été supprimée.',
			);

			this.deleteCategory(categoryId);
		}
	}

	private async deleteCategory(categoryId: number)
	{
		const response = await this.formBuilderCategoryService.delete(categoryId);
		if (response.errorMessage) {
			this.setState({ errorMessage: response.errorMessage });
			return;
		}

		// Refresh
		this.refreshCategories();
		this.refreshListAvailableType();
	}

	async onDragEnd(result: any, index: number | null)
	{
		const getTypeCategory = result.destination.droppableId;

		const categoryData = {
			sortOrder: result.destination.index + 1,
		};

		// If not destination on DraggableItem
		if (!result.destination) {
			return;
		}

		try {
			const movedCategory = this.getMovedCategory(result, getTypeCategory, index);
			if (movedCategory) {
				this.setState({ isLoadingComponent: true });
				await this.formBuilderCategoryService.edit(categoryData, movedCategory.id);
			}

			// Refresh categories
			this.refreshCategories();
		} catch (error) {
			console.error('Error while reordering:', error);
		}
	}

	private getMovedCategory(result: any, getTypeCategory: string, index: number | null): FormBuilderCategoryInterface
	{
		let movedCategory: FormBuilderCategoryInterface | undefined;
		if (getTypeCategory === FormBuilderCategoryTypeEnum.PRIMARY) {
			movedCategory = this.state.categories.find((category: FormBuilderCategoryInterface) =>
			{
				return category.id === parseInt(result.draggableId);
			});
		} else if (getTypeCategory === FormBuilderCategoryTypeEnum.SECONDARY && index !== null) {
			movedCategory = this.state.categories[index].childCategories.find((category: FormBuilderCategoryInterface) =>
			{
				return category.id === parseInt(result.draggableId);
			});
		}

		return movedCategory as FormBuilderCategoryInterface;
	}

	private loadingUpdateComponent(isLoading: boolean): void
	{
		this.setState({ isLoadingComponent: isLoading });
	}

	//</editor-fold>

	//<editor-fold desc="REFRESH methods" defaultstate="collapsed">

	private async refreshCategories(): Promise<void>
	{
		try {
			const categoryList = await this.formBuilderCategoryService.list();
			this.setState({ categories: categoryList, isLoadingFullPage: false, isLoadingComponent: false });
		} catch (error) {
			console.error('Error fetching categories:', error);
		}
	}

	private async refreshListAvailableType(): Promise<void>
	{
		try {
			const availableTypes = await this.formBuilderCategoryService.checkAvailableType();
			this.setState({ availableTypes: availableTypes, isLoadingFullPage: false, isLoadingComponent: false });
		} catch (error) {
			console.error('Error fetching categories:', error);
		}
	}

	//</editor-fold>
}