import React, { ReactElement } from 'react';
import UserCivilityEnum from '@/Enum/UserCivilityEnum';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { NavigateFunction } from 'react-router-dom';
import { CompanyInterface } from '@/Modules/Company/Interface/CompanyInterface';
import { ApiAdminCompanyService } from '@/Service/Admin/ApiAdminCompanyService';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import CardStyle from '@/Modules/App/Style/Components/CardStyle';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

interface ViewProps
{
	authContext: AuthContextType,
	companyContext: AuthCompanyContextType
	flashMessageContext: FlashMessageContextType,
	navigation: NavigateFunction
};

interface ViewState
{
	companies: CompanyInterface[],
	isLoading: boolean,
	errorMessage: string | null
}

export default class AuthCompanyView extends React.Component
	<ViewProps, ViewState>
{
	companyService: ApiAdminCompanyService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.companyService = new ApiAdminCompanyService();

		// Bind
		this.authCompanyHandler = this.authCompanyHandler.bind(this);
	}

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<div style={ { textAlign: 'center', marginBottom: 30 } }>
					<h1 style={ FontStyle.h1() }>
						👋
						Bonjour { this.handleUserCivility(this.props.authContext.user.civility) }. { this.props.authContext.user.lastname }
					</h1>
					<div style={ FontStyle.normal(CssVariableEnum['--color-grey-400']) }>
						Veuillez sélectionner l'entreprise sur laquelle vous souhaitez travailler aujourd'hui. Choisissez dans
						la liste ci-dessous pour accéder à votre espace dédié.
					</div>
				</div>
				<div style={ { display: 'flex', flexDirection: 'column', marginTop: '10px' } }>
					{ this.state.companies.length > 0 &&
						this.state.companies.map(
							(company: CompanyInterface) => (
								<div
									style={ { ...CardStyle.main(), marginBottom: 10, cursor: 'pointer' } }
									key={ company.id }
									onClick={ () => this.authCompanyHandler(company.id, company.name) }
								>
									<div style={ CardStyle.container() }>
										<span style={ FontStyle.normalMedium() }>{ company.name }</span>&nbsp;
										<span style={ FontStyle.littleGrey() }>({ company.city })</span>
									</div>
								</div>
							)
						)
					}
				</div>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		if (!localStorage.getItem('user')) {
			this.props.navigation('/auth');
		}

		try {
			const companies = await this.companyService.list();
			this.setState({ companies, isLoading: false });
		} catch (error: any) {
			this.setState({ errorMessage: 'Loading error', isLoading: false });
		}
	}

	private initState(): ViewState
	{
		return {
			companies: [],
			isLoading: true,
			errorMessage: null
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private async authCompanyHandler(companyId: number, companyName: string)
	{
		const {
			companyContext,
			flashMessageContext,
			navigation
		} = this.props;

		try {
			this.setState({ isLoading: true });

			await companyContext.refreshCompany(companyId, true);
			flashMessageContext.flashMessage(
				'Choix de l\'entreprise réussi',
				`Vous êtes maintenant connecté sur ${ companyName }`,
				'success'
			);
			navigation('/admin');
		} catch (error) {
			console.error('Error changing company:', error);
			flashMessageContext.flashMessage('Erreur lors du changement d\'entreprise', 'error');
		} finally {
			this.setState({ isLoading: false });
		}
	}

	private handleUserCivility(civility: string): string
	{
		if (civility !== UserCivilityEnum.M.value) {
			if (civility === UserCivilityEnum.W.value) {
				return 'Mme';
			} else {
				return '';
			}
		}

		return civility;
	}

	//</editor-fold>
}