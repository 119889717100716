import React, { ReactElement } from 'react';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuListFilter, LuX } from 'react-icons/lu';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Skeleton from 'react-loading-skeleton';

export interface FilterInterface {
	label: string;
	tag: string;
	icon: ReactElement;
	type: 'input' | 'select' | 'search';
	childList: any[];
}

export interface FilterButtonInterface {
	filter: FilterInterface;
	selectedValue: { filterName: string; filterValue: string } | null;
	isOpen: boolean;
}

interface ComponentProps
{
	isLoading?: boolean,
	filterList: FilterInterface[],
	onSelectedFilters: (selectedFilters: any[]) => void,
}

interface ComponentState
{
	isOpen: boolean;
	optionHovered: number | string | null;
	filterButtons: FilterButtonInterface[];
}

export default class Filters extends React.Component<ComponentProps, ComponentState>
{
	filterMenuRef: React.RefObject<HTMLDivElement> | null;

	constructor(props: any)
	{
		super(props);

		this.filterMenuRef = React.createRef();

		this.state = {
			isOpen: false,
			optionHovered: null,
			filterButtons: [],
		};

		this.filterSelectedValue = this.filterSelectedValue.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
	}

	componentDidMount(): void
	{
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount(): void
	{
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	toggleFilterMenu = (event: React.MouseEvent): void =>
	{
		event.stopPropagation();
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
			filterButtons: prevState.filterButtons.map(button => ({
				...button,
				isOpen: false,
			})),
		}));
	};

	handleClickOutside = (event: MouseEvent): void =>
	{
		if (this.state.isOpen && this.filterMenuRef && this.filterMenuRef.current && !this.filterMenuRef.current.contains(event.target as Node)) {
			this.setState({
				isOpen: false,
				filterButtons: this.state.filterButtons.map(button => ({
					...button,
					isOpen: false,
				})),
			});
		}
	};

	render(): ReactElement
	{
		if(this.props.isLoading) {
			return (
				<div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
					<Skeleton width={ 75 } height={ 32 } baseColor={ CssVariableEnum['--color-grey-200'] }/>
				</div>
			);
		}
		return (
			<div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
				{this.state.filterButtons && this.state.filterButtons.length > 0 &&
					this.state.filterButtons.map((filterButton: FilterButtonInterface, index: number) => (
						<React.Fragment key={index}>
							{this.appendFilterButton(filterButton, index)}
						</React.Fragment>
					))}
				{this.mainFilterButton()}
			</div>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private mainFilterButton(): ReactElement
	{
		return (
			<>
				<Button
					type={'inline-default'}
					iconLeft={<LuListFilter />}
					onClick={this.toggleFilterMenu}
				>
					Filtres
				</Button>
				{this.state.isOpen && (
					<div
						ref={this.filterMenuRef}
						style={{
							position: 'absolute',
							zIndex: 500,
							top: 34,
							right: 0,
							backgroundColor: CssVariableEnum['--color-white'],
							border: `1px solid ${CssVariableEnum['--color-grey-300']}`,
							boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
							borderRadius: 8,
							minWidth: 200,
							opacity: 1,
							transition: 'opacity 270ms ease, transform 270ms ease',
						}}
					>
						<div style={{ padding: 5 }}>
							{this.props.filterList.map((filter: FilterInterface) => (
								<div
									key={filter.label}
									style={{
										backgroundColor: this.state.optionHovered === filter.label ? CssVariableEnum['--color-grey-100'] : 'inherit',
										cursor: 'pointer',
										borderRadius: 8,
										padding: '4px 8px',
										display: 'flex',
										alignItems: 'center',
										gap: 10,
									}}
									onMouseEnter={() => this.setState({ optionHovered: filter.label })}
									onMouseLeave={() => this.setState({ optionHovered: null })}
									onClick={() => this.setFilterButton(filter)}
								>
									{filter.icon}
									<span>{filter.label}</span>
								</div>
							))}
						</div>
					</div>
				)}
			</>
		);
	}

	private setFilterButton(filter: FilterInterface): void
	{
		const exists: boolean = this.state.filterButtons.some((filterButton: FilterButtonInterface) => filterButton.filter.label === filter.label);
		if (!exists) {
			this.setState(prevState => ({
				filterButtons: [...prevState.filterButtons, { filter, isOpen: false, selectedValue: null }],
				isOpen: false,
			}));
		}
	}

	private toggleSpecificFilterMenu(index: number): void
	{
		this.setState(prevState => ({
			isOpen: false,
			filterButtons: prevState.filterButtons.map((button: FilterButtonInterface, i: number) => ({
				...button,
				isOpen: i === index ? !button.isOpen : false,
			})),
		}));
	}

	private removeFilterButton(index: number): void
	{
		this.setState(prevState => {
			const updatedFilterButtons = prevState.filterButtons.filter((_, i) => i !== index);
			return {
				filterButtons: updatedFilterButtons,
			};
		}, () => {
			const nonNullSelectedFilters = this.state.filterButtons
				.filter(button => button.selectedValue !== null)
				.map(button => button.selectedValue);

			this.props.onSelectedFilters(nonNullSelectedFilters);
		});
	}

	private filterSelectedValue(index: number, childFilter: any): void
	{
		this.setState(prevState => ({
			filterButtons: prevState.filterButtons.map((button: FilterButtonInterface, i: number) =>
				i === index ? {
					...button,
					isOpen: !button.isOpen,
					selectedValue: childFilter.value ? { filterName: button.filter.tag, filterValue: childFilter.value } : null,
				} : button
			),
		}), () => {
			const nonNullSelectedFilters = this.state.filterButtons
				.filter(button => button.selectedValue !== null)
				.map(button => button.selectedValue);

			this.props.onSelectedFilters(nonNullSelectedFilters);
		});
	}

	private appendFilterButton(filterButton: FilterButtonInterface, index: number): ReactElement
	{
		return (
			<div style={{ position: 'relative' }} key={index}>
				<Button
					type={'default'}
					iconLeft={filterButton.filter.icon}
					onClick={() => this.toggleSpecificFilterMenu(index)}
					containerStyle={{
						backgroundColor: filterButton.selectedValue ? CssVariableEnum['--color-blue-50'] : 'inherit',
						color: filterButton.selectedValue ? CssVariableEnum['--color-blue-500'] : 'inherit',
						boxShadow: filterButton.selectedValue ? `${CssVariableEnum['--color-blue-100']} 0px 0px 0px 1px inset` : `${CssVariableEnum['--color-grey-300']} 0px 0px 0px 1px inset`,
					}}
				>
					{filterButton.filter.label}
					{filterButton.selectedValue && `: ${filterButton.selectedValue.filterValue}`}
					<span
						style={{
							height: '100%',
							width: 25,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
						onClick={(event: any) => {
							event.stopPropagation();
							this.removeFilterButton(index);
						}}
					>
						<LuX />
					</span>
				</Button>
				{filterButton.isOpen && (
					<div style={{
						position: 'absolute',
						zIndex: 500,
						top: 34,
						right: 0,
						backgroundColor: CssVariableEnum['--color-white'],
						border: `1px solid ${CssVariableEnum['--color-grey-300']}`,
						boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
						borderRadius: 8,
						minWidth: 200,
						opacity: 1,
						transition: 'opacity 270ms ease, transform 270ms ease',
					}}>
						<div style={{ padding: 5 }}>
							{filterButton.filter.type === 'select' && filterButton.filter.childList.map((childFilter: any, idx: number) => (
								<div
									key={idx}
									style={{
										backgroundColor: this.state.optionHovered === childFilter.label ? CssVariableEnum['--color-grey-100'] : 'inherit',
										cursor: 'pointer',
										borderRadius: 8,
										padding: '4px 8px',
										display: 'flex',
										alignItems: 'center',
										gap: 10,
									}}
									onMouseEnter={() => this.setState({ optionHovered: childFilter.label })}
									onMouseLeave={() => this.setState({ optionHovered: null })}
									onClick={() => this.filterSelectedValue(index, childFilter)}
								>
									{childFilter.icon}
									<span>{childFilter.label}</span>
								</div>
							))}
							{filterButton.filter.type === 'search' &&
								<Input
									type={'text'}
									name={'search-filter'}
									placeholder={`Rechercher ${filterButton.filter.label.toLowerCase()}`}
									value={filterButton.selectedValue?.filterValue || ''}
									onChange={(event: any) => this.handleSearchChange(index, event.target.value)}
								/>
							}
						</div>
					</div>
				)}
			</div>
		);
	}

	private handleSearchChange(index: number, value: string): void
	{
		this.setState(prevState => ({
			filterButtons: prevState.filterButtons.map((button, idx) =>
				idx === index ? {
					...button,
					selectedValue: { filterName: button.filter.tag, filterValue: value },
				} : button
			),
		}), () => {
			const nonNullSelectedFilters = this.state.filterButtons
				.filter(button => button.selectedValue !== null)
				.map(button => button.selectedValue);

			this.props.onSelectedFilters(nonNullSelectedFilters);
		});
	}

	//</editor-fold>
}
