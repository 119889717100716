import React, { Component, createRef, ReactElement } from 'react';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { Tooltip } from 'react-tooltip';

interface TagInputProps<T>
{
	options: T[],
	initialTags: string[],
	titleSuggestOption: string,
	titleInputLabel: string,
	onChange: (value: any[]) => void,
	renderOptionLabel: (option: T) => string
}

interface TagInputState
{
	input: string,
	isOpen: boolean,
	isDeletingTag: boolean,
	selectedTags: string[],
	menuPosition: { top: number | null, bottom: number | null, position?: string }
}

export class SelectMultiple<T> extends Component<TagInputProps<T>, TagInputState>
{
	menuRef = createRef<HTMLDivElement>();
	tagContainerRef = createRef<HTMLDivElement>();

	state = {
		input: '',
		isOpen: false,
		isDeletingTag: false,
		selectedTags: [],
		menuPosition: { top: 0, bottom: 0, position: 'absolute' }
	};

	constructor(props: any)
	{
		super(props);

		// Bind
		this.appendTag = this.appendTag.bind(this);
		this.removeTag = this.removeTag.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.onInputKeyDown = this.onInputKeyDown.bind(this);
		this.calculateMenuPosition = this.calculateMenuPosition.bind(this);
	}

	componentDidMount(): void
	{
		document.addEventListener('mousedown', this.handleClickOutside);
		this.setState({
			selectedTags: this.props.initialTags || [],
		});
	}

	componentWillUnmount(): void
	{
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event: MouseEvent): void
	{
		if (!this.menuRef.current?.contains(event.target as Node) &&
			!this.tagContainerRef.current?.contains(event.target as Node)) {
			this.setState({ isOpen: false });
		}
	}

	render(): ReactElement
	{
		const hasTags = this.state.selectedTags.length > 0;

		return (
			<>
				<div
					ref={ this.tagContainerRef }
					style={ {
						position: 'relative',
						border: '1px solid #ccc',
						borderRadius: '8px',
						padding: '5px 8px',
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
						gap: '5px',
						cursor: 'text',
						minHeight: '32px',
						width: '80%',
					} }
					onFocus={ () => !this.state.isDeletingTag && this.setState({ isOpen: true }, () => this.calculateMenuPosition() ) }
					tabIndex={ 0 }
				>
					{ hasTags ? (
						this.state.selectedTags.map((tag, index) => (
							<div
								key={ index }
								style={ {
									marginRight: '5px',
									padding: '2px 8px',
									background: CssVariableEnum['--color-grey-200'],
									borderRadius: '4px',
									cursor: 'pointer'
								} }
							>
								{ tag }
								<b
									style={ { marginLeft: '5px', cursor: 'pointer' } }
									onClick={ (event) => this.removeTag(event, tag) }
								>
									×
								</b>
							</div>
						))
					) : (
						<span style={ { color: '#999', cursor: 'text' } }>{ this.props.titleInputLabel }</span>
					) }
					{ this.state.isOpen && this.buildSuggestContent() }
				</div>
			</>
		);
	}

	private buildSuggestContent(): ReactElement
	{
		return (
			<>
				<div
					ref={ this.menuRef }
					style={ {
						position: 'absolute',
						top: this.state.menuPosition.top,
						bottom: this.state.menuPosition.bottom,
						left: 0,
						right: '0',
						width: '100%',
						backgroundColor: '#fff',
						boxShadow: '0 0.05rem 0.6rem rgba(0, 0, 0, 0.15)',
						padding: '10px',
						borderRadius: '8px',
						zIndex: 1000,
						maxHeight: 300
					} }
				>
					<div>
						<a
							id={ 'press-enter' }
							href={'#press-enter'}
							onClick={(event) => { event.preventDefault(); }}
						>
							<Input
								type="text"
								style={ { display: 'flex' } }
								label="Ajouter un email"
								name="email"
								onKeyDown={ this.onInputKeyDown }
								onFocus={ () => this.setState({ isOpen: true }) }
							/>

							<Tooltip
								anchorSelect="#press-enter"
								content={ 'Appuyer sur entrée pour ajouter le nouveau mail' }
							/>
						</a>
					</div>
					<div style={ {
						fontSize: '12px',
						marginTop: '10px',
						color: CssVariableEnum['--color-grey-400'],
						fontWeight: 500
					} }>
						{ this.props.titleSuggestOption }
					</div>
					{ this.props.options.map((option: any) =>
					{
						return (
							<div
								key={ option.id }
								style={ { marginTop: '10px', marginLeft: '10px', cursor: 'pointer' } }
								onClick={ () => this.appendTag(option) }
							>
								{ this.props.renderOptionLabel(option) }
							</div>
						);
					})
					}
				</div>
			</>
		);
	}

	private simulateClickOutsideEffect(): void
	{
		this.setState({ isOpen: false });
		if (this.tagContainerRef.current) {
			this.tagContainerRef.current.blur();
		}
	}

	private onInputKeyDown(event: any): void
	{
		if (event.key === 'Enter') {
			event.preventDefault();
			const newTag = event.currentTarget.value.trim();

			if (newTag && !this.state.selectedTags.includes(newTag as never)) {
				this.setState(prevState => ({
					selectedTags: [...prevState.selectedTags, newTag],
					input: '',
					isOpen: false
				}), () =>
				{
					this.props.onChange(this.state.selectedTags);
				});
			}
		}
	}

	private appendTag(option: any): void
	{
		const optionLabel = this.props.renderOptionLabel(option);

		if (!this.state.selectedTags.includes(optionLabel as never)) {
			this.setState(prevState => ({
				selectedTags: [...prevState.selectedTags, optionLabel],
				isOpen: false
			}), () =>
			{
				this.props.onChange(this.state.selectedTags);
				this.simulateClickOutsideEffect();
			});
		}
	}

	private removeTag(event: React.MouseEvent, tagToRemove: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState(prevState => ({
			selectedTags: prevState.selectedTags.filter(tag => tag !== tagToRemove)
		}), () => this.props.onChange(this.state.selectedTags));
	}

	private calculateMenuPosition(): void
	{
		const tagContainerRef = this.tagContainerRef.current;

		if (this.tagContainerRef.current) {
			const containerRect = tagContainerRef?.getBoundingClientRect();
			const maxWidthMenu = 200;

			if (containerRect) {
				const isMenuAbove: boolean = (containerRect.bottom + maxWidthMenu) > window.innerHeight;

				this.setState({
					menuPosition: {
						top: isMenuAbove ? null : 35,
						bottom: isMenuAbove ? 35 : null,
					}
				});
			}

		}
	};

}