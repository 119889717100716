import React, { CSSProperties, ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { LuSearch, LuChevronUp, LuChevronDown } from 'react-icons/lu';
import SelectStyle from '@/Modules/App/Style/Components/SelectStyle';
import TransitionStyle from '@/Modules/App/Style/Base/TransitionStyle';

interface ComponentProps
{
	listOptions: any[],
	onSelectedOption: any,
	renderOptionLabel: (option: any) => React.ReactNode
	label?: string
	style?: CSSProperties,
	buttonWidth?: number,
	selectMenuWidth?: number
	selectedValue?: any | null,
	disabled?: boolean,
	isMarginBottom?: boolean
	isSearchNeeded?: boolean
	required?: boolean,
	selectionText?: string | null,
	containerDivWidth?: string,
}

interface ComponentState
{
	isLoading: boolean,
	isOpenSelect: boolean,
	searchValue: string,
	selectContainerRef: any,
	selectedOption: any,
	optionHovered: number | null,
	menuPosition: {
		top: number | string,
		bottom: number | string,
		left: number | string,
		right: number | string,
		position?: string
	}
}

export default class SelectComponent extends React.Component
	<ComponentProps, ComponentState>
{
	constructor(props: any)
	{
		super(props);

		this.state = this.initState();

		// Bind
		this.onSelectOption = this.onSelectOption.bind(this);
		this.onClickOutside = this.onClickOutside.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<div style={ { display: 'flex', flexDirection: (this.props.label) ? 'column' : 'row' } }>
					{ this.props.label &&
						<div style={ SelectStyle.label() }>
							{ this.props.label }
							{ this.props.required &&
								<span style={ { color: 'red' } }>*</span>
							}
						</div>
					}
					<div ref={ this.state.selectContainerRef }
							 style={ SelectStyle.container(this.props, this.state) }
							 onClick={ (event): void => this.openSelect(event) }
					>
						<div style={ SelectStyle.selectedOption(this.state) }>
							{ this.state.selectedOption
								? this.props.renderOptionLabel(this.state.selectedOption)
								: (this.props.selectionText) ? this.props.selectionText : 'Sélectionner une option'
							}
						</div>
						<div style={ { position: 'absolute', right: 10, } }
								 onClick={ (event): void => this.openSelect(event) }
						>
							{ this.state.isOpenSelect
								? <LuChevronUp fontSize={ 18 }/>
								: <LuChevronDown fontSize={ 18 }/>
							}
						</div>
						<div style={ {
							...TransitionStyle.transitionAbsolute(this.state.isOpenSelect),
							width: '100%'
						} }>
							{ (this.state.isOpenSelect) &&
								<div style={ SelectStyle.openMenu(this.props, this.state) }>

									{/* SEARCH INPUT */ }
									{ this.props.isSearchNeeded &&
										<div style={ {
											width: '100%',
											borderBottom: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
											display: 'flex',
											alignItems: 'center',
											padding: '5px 10px',
											color: CssVariableEnum['--color-grey-900']
										} }>
											<LuSearch fontSize={ 18 }/>
											<Input
												type="text"
												placeholder="Rechercher..."
												name="searchValue"
												value={ this.state.searchValue }
												onChange={ this.onSearchChange }
												onFocus={ () => this.setState({ isOpenSelect: true }) }
												style={ {
													width: '100%',
													padding: '10px',
													boxSizing: 'border-box',
													boxShadow: 'none',
													fontSize: '14px',
													color: CssVariableEnum['--color-grey-900'],
													backgroundColor: CssVariableEnum['--color-white']
												} }
											/>
										</div>
									}
									{/* END SEARCH INPUT */ }

									<div style={ this.menuContainerStyle() }>
										<ul style={ this.menuOptionsStyle() }>
											{ this.filteredOptions().map((option: any) => (
												<li
													style={ (this.state.optionHovered === (option.id || option.label)) ? this.hoveredOptionStyle() : { padding: '5px 10px' } }
													key={ (option.id || option.label) }
													onMouseEnter={ () => this.setState({ optionHovered: (option.id || option.label) }) }
													onMouseLeave={ () => this.setState({ optionHovered: null }) }
													onClick={ (event) => this.onSelectOption(event, option) }
												>
													{ this.props.renderOptionLabel(option) }
												</li>
											))
											}
										</ul>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		document.addEventListener('click', this.onClickOutside);
		if (this.props.selectedValue) {
			this.setState({ selectedOption: this.props.selectedValue, isLoading: false });
		}
	}

	componentWillUnmount(): void
	{
		document.removeEventListener('click', this.onClickOutside);
	}

	private initState(): ComponentState
	{
		return {
			isLoading: true,
			isOpenSelect: false,
			searchValue: '',
			selectContainerRef: React.createRef(),
			selectedOption: null,
			optionHovered: null,
			menuPosition: {
				top: '',
				bottom: '',
				left: '',
				right: '',
				position: 'absolute'
			}
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private Method" defaultstate="collapsed">

	private openSelect(event?: any): void
	{
		event.stopPropagation();

		if (!this.props.disabled) {
			if (!this.props.isSearchNeeded) {
				this.setState(prevState => ({ isOpenSelect: !prevState.isOpenSelect }),
					() => this.calculateMenuPosition());
			}
			this.setState({ isOpenSelect: true },
				() => this.calculateMenuPosition());
		}
	}

	private onSelectOption(event: React.MouseEvent<HTMLLIElement, MouseEvent>, selectedOption: any): void
	{
		event.stopPropagation();
		this.props.onSelectedOption(selectedOption);
		// Close select after choice
		this.setState({ selectedOption: selectedOption, isOpenSelect: false });
	}

	private onClickOutside(event: any): void
	{
		event.stopPropagation();

		if (this.state.selectContainerRef.current && !this.state.selectContainerRef.current.contains(event.target)) {
			const isSearchInput = event.target.name === 'searchValue';
			if (!isSearchInput) {
				this.setState({ isOpenSelect: false });
			}
		}
	}

	private onSearchChange(event: any): void
	{
		this.setState({ searchValue: event.target.value });
	}

	private filteredOptions(): any[]
	{
		const { listOptions, isSearchNeeded } = this.props;
		const { searchValue } = this.state;

		const options = Array.isArray(listOptions) ? listOptions : [];

		if (isSearchNeeded) {
			return options.filter(option =>
			{
				const label = this.props.renderOptionLabel(option);
				return label && label.toString().toLowerCase().includes(searchValue.toLowerCase());
			});
		}

		return options;
	}

	//</editor-fold>

	//<editor-fold desc="Style methods" defaultstate="collapsed">

	private menuContainerStyle(): React.CSSProperties
	{
		return {
			padding: '5px',
			overflowY: 'scroll'
		};
	}

	private menuOptionsStyle(): React.CSSProperties
	{
		return {
			display: 'flex',
			flexDirection: 'column',
			maxHeight: '200px'
		};
	}

	private hoveredOptionStyle(): React.CSSProperties
	{
		return {
			backgroundColor: CssVariableEnum['--color-grey-100'],
			borderRadius: '3px',
			padding: '5px 10px',
			fontWeight: 500,
			color: CssVariableEnum['--color-grey-900'],
			cursor: 'pointer'
		};
	}

	private calculateMenuPosition(): void
	{
		const tagContainerRef = this.state.selectContainerRef.current;

		if (tagContainerRef) {
			const containerRect = tagContainerRef.getBoundingClientRect();
			const maxWidthMenu = 200;
			const menuWidth = 300; // Supposons que la largeur du menu soit de 300px

			const isMenuAbove: boolean = (containerRect.bottom + maxWidthMenu) > window.innerHeight;

			// Déterminez si le menu doit s'ouvrir vers la gauche ou la droite
			const isMenuRight: boolean = (containerRect.left + menuWidth) > window.innerWidth;

			this.setState({
				menuPosition: {
					top: isMenuAbove ? '' : '16px',
					bottom: isMenuAbove ? '-16px' : '',
					left: isMenuRight ? '' : '-10px',
					right: isMenuRight ? '10px' : ''
				}
			});
		}
	}


	//</editor-fold>
}