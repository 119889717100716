import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class HeaderSwitchCompanyStyle
{
	static container(): CSSProperties
	{
		return {
			height: '100%',
			display: 'flex',
			alignItems: 'center'
		};
	}

	static switch(isThemeDark: boolean): CSSProperties
	{
		return {
			position: 'relative',
			color: (isThemeDark) ? CssVariableEnum['--color-grey-300'] : CssVariableEnum['--color-grey-900'],
			marginLeft: '10px',
			cursor: 'pointer',
			backgroundColor: (isThemeDark) ? CssVariableEnum['--color-grey-800'] : CssVariableEnum['--color-grey-100'],
			borderRadius: '8px',
			padding: '5px 20px',
			display: 'inline-flex',
			alignItems: 'center',
			transition: 'background-color 200ms ease'
		};
	}

	static cardOpenImage(): CSSProperties
	{
		return {
			height: '32px',
			width: '32px',
			backgroundColor: 'transparent',
			marginRight: '8px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		};
	}

	static cardOpenMenuCompany(): CSSProperties
	{
		return {
			backgroundColor: CssVariableEnum['--color-grey-100'],
			color: CssVariableEnum['--color-grey-900'],
			padding: '10px 4px',
			borderTop: '1px solid #E6E6E6',
			borderRadius: '0 0 10px 10px'
		};
	}
}