import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { FormBuilderInputTypeEnum } from '@/Enum/FormBuilderInputTypeEnum';

export default class InputMedium extends React.Component
	<
		{
			className?: string;
			type: FormBuilderInputTypeEnum;
			name?: string;
			placeholder?: string;
			value?: string;
			onChange?: any;
			onClick?: any;
			onKeyDown?: any;
			label?: string;
			isChecked?: boolean
		},
		any>
{
	render(): ReactElement
	{
		return (
			<>
				<Form.Group className="me-2">
					{ (this.props.type !== FormBuilderInputTypeEnum.SWITCH)
						?
						<React.Fragment>
							<Form.Label className="label"> { this.props.label } </Form.Label>
							<Form.Control
								type={ this.props.type }
								name={ this.props.name }
								value={ this.props.value }
								placeholder={ this.props.placeholder }
								onClick={ this.props.onClick }
								onChange={ this.props.onChange }
								className={ `input input-medium me-2 ${ (this.props.className) ? this.props.className : '' }` }
								onKeyDown={ this.props.onKeyDown }
								onFocus={ (this.props.type === FormBuilderInputTypeEnum.DATE) ? (e: any) => (e.target.type = 'date') : undefined }
								onBlur={ (this.props.type === FormBuilderInputTypeEnum.DATE) ? (e: any) => (e.target.type = 'text') : undefined }
							/>
						</React.Fragment>
						:
						<Form.Check // prettier-ignore
							type={ this.props.type }
							id={ `default-${ this.props.type }` }
							label={ this.props.label }
							onChange={ this.props.onChange }
							className="label"
							checked={ (this.props.isChecked ? this.props.isChecked : false) }
						/>
					}
				</Form.Group>
			</>
		);
	}
}