import React, { ReactElement } from 'react';
import FormBuilderSectionSkeleton from '@/Modules/App/Components/Skeleton/FormBuilderSectionSkeleton';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { ApiAdminFormBuilderCategoryService } from '@/Service/Admin/ApiAdminFormBuilderCategoryService';
import FormBuilderConstructorSection from '@/Modules/FormBuilder/Components/FormBuilderConstructorSection';
import Section from '@/Modules/App/Components/Atom/Sections/Section';
import { ApiAdminFormBuilderSectionService } from '@/Service/Admin/ApiAdminFormBuilderSectionService';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

interface ViewProps {
	onRefresh: () => Promise<any>,
}

interface ViewState {
	isLoading: boolean,
	openSectionId: string | number | null,
	categories: FormBuilderCategoryInterface[],
	errorMessage: string | null
}

export default class FormBuilderConstructorView extends React.Component<ViewProps, ViewState>
{
	formBuilderCategoryService: ApiAdminFormBuilderCategoryService;
	formBuilderSectionService: ApiAdminFormBuilderSectionService;

	constructor(props: any)
	{
		super(props);
		this.state = this.initState();

		// Service
		this.formBuilderCategoryService = new ApiAdminFormBuilderCategoryService();
		this.formBuilderSectionService = new ApiAdminFormBuilderSectionService();

		// Bind
		this.refreshData = this.refreshData.bind(this);
		this.onCreate = this.onCreate.bind(this);
		this.onSwitchSortOrder = this.onSwitchSortOrder.bind(this);
		this.onRemove = this.onRemove.bind(this);
	}

	async componentDidMount(): Promise<void>
	{
		await this.refreshData();
	}

	render(): ReactElement
	{
		if (this.state.isLoading) {
			return <div style={ { marginTop: 25 } }><FormBuilderSectionSkeleton count={ 8 }/></div>;
		}

		return (
			<Section>
				<div style={ { marginTop: 20 } }>
					{ (this.state.isLoading)
						? <FormBuilderSectionSkeleton count={ this.state.categories.length }/>
						: this.state.categories.map((category: FormBuilderCategoryInterface) => (
							<React.Fragment key={ category.id }>
								<div style={ {
									padding: '5px 10px',
									display: 'inline-flex',
									backgroundColor: CssVariableEnum['--color-purple-100'],
									color: CssVariableEnum['--color-purple-500'],
									border: `1px solid ${ CssVariableEnum['--color-purple-500'] }`,
									height: '32px',
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: 8,
									margin: '10px 0'
								} }>
									{ category.label }
								</div>
								{ category.childCategories.map((childCategory: FormBuilderCategoryInterface) => (
									<FormBuilderConstructorSection
										key={ (Math.random() * 10000).toString() }
										secondaryCategoryList={ childCategory }
										onCreate={ this.onCreate }
										onSwitchSortOrder={ this.onSwitchSortOrder }
										onDelete={ this.onRemove }
										isOpen={ this.state.openSectionId === childCategory.id }
										onToggleSection={ () => this.handleToggleSection(childCategory.id) }
									/>
								)) }
							</React.Fragment>
						))
					}
				</div>
			</Section>
		);
	}

	// <editor-fold desc="State" defaultstate="collapsed">

	private initState(): ViewState
	{
		return {
			isLoading: true,
			openSectionId: null,
			categories: [],
			errorMessage: '',
		};
	}

	// </editor-fold>

	// <editor-fold desc="Private Methods" defaultstate="collapsed">

	private handleToggleSection(categoryId: string | number): void
	{
		this.setState((prevState: any) => ({
			openSectionId: prevState.openSectionId === categoryId ? null : categoryId
		}));
	}

	private async onCreate(sectionId: number, categoryId: number): Promise<void>
	{
		try {
			await this.formBuilderSectionService.addFormBuilderCategory(sectionId, categoryId);
			this.refreshData();
		} catch (error: any) {
			console.log(error);
		}
	}

	private async onSwitchSortOrder(sectionId: number, categoryId: number, askSortOrder: number): Promise<void>
	{
		try {
			await this.formBuilderSectionService.switchSortOrder(categoryId, sectionId, askSortOrder);
			this.refreshData();
		} catch (error: any) {
			console.log(error);
		}
	}

	private async onRemove(sectionId: number, categoryId: number): Promise<void>
	{
		try {
			await this.formBuilderSectionService.removeFormBuilderCategory(sectionId, categoryId);
			this.refreshData();
		} catch (error: any) {
			console.log(error);
		}
	}

	private async refreshData(): Promise<void>
	{
		const categories = await this.props.onRefresh();
		this.setState({
				categories: categories,
				isLoading: false
			}
		);
	}

	// </editor-fold>
}