import React, { ReactElement } from 'react';

interface RadioOption {
  label: string;
  value: string | number;
}

export default class Radio extends React.Component
  <
    {
      style?: React.CSSProperties,
      options: RadioOption[];
      label?: string;
      name: string;
      selectedValue: any;
      onSelectedOption: (value: string | number) => void;
    },
    {
      selectedValue: string | number | null;
    }
  >
{
  state = {
    selectedValue: this.props.selectedValue,
  };

  handleSelectOption = (value: string | number) => {
    this.setState({ selectedValue: value });
    this.props.onSelectedOption(value);
  };

  render(): ReactElement {
    return (
      <div style={ this.props.style }>
        { this.props.label && <div style={ {
          fontSize: '12px',
          color: '#666666',
          marginBottom:'5px',
        } }>
          { this.props.label }
        </div> }
        {this.props.options.map((option) => (
          <label key={option.value.toString()}>
            <input
              style={{ marginRight: '5px', marginLeft: '5px' }}
              type="radio"
              name={ this.props.name }
              value={ option.value }
              checked={ this.state.selectedValue === option.value }
              onChange={ () => this.handleSelectOption(option.value) }
            />
            { option.label }
          </label>
        ))}
      </div>
    );
  }
}