import React, { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';

interface LoaderFullPageComponentProps {
	timeout?: number;
}

interface LoaderFullPageComponentState {
	showLoader: boolean;
}

export default class LoaderFullPageComponent extends React.Component<LoaderFullPageComponentProps, LoaderFullPageComponentState> {
	constructor(props: LoaderFullPageComponentProps) {
		super(props);
		this.state = {
			showLoader: true
		};
	}

	componentDidMount(): void {
		// This example does not use timeout logic,
		// as requested, but you can add your data loading logic here.
	}

	render(): ReactElement {
		return (
			<>
				{this.state.showLoader && (
					<div style={{
						position: 'absolute',
						backgroundColor: 'rgba(251, 251, 251, 0.5)',
						zIndex: 10000,
						width: '100vw',
						height: '100vh',
						top: 0,
						left: 0,
						transition: '500ms'
					}}>
						<div style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							height: '100%'
						}}>
							<Spinner animation="border" variant={ 'secondary' }/>
						</div>
					</div>
				)}
			</>
		);
	}
}