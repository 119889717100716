import React, { ReactElement } from 'react';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuCaseUpper } from 'react-icons/lu';


interface ComponentProps
{
	formData: any,
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	isConsumerFormComplete: boolean,
	isTitleNeeded: boolean
}

export default class ConsumerAddressFormComponent extends React.Component<ComponentProps, any>
{
	render(): ReactElement
	{
		return (
			<>
				{ this.props.isTitleNeeded && this.props.isTitleNeeded &&
          <Title type={ 'h6' } borderBottom={ true }> Siège Social </Title>
				}
				<div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
					<div style={ { flex: '0 1 10%', display: 'flex', alignItems: 'flex-end' } }>
						<Input
							type="text"
							style={ { display: 'flex' } }
							label="Numéro"
							name="address.number"
							value={ this.props.formData.address.number || '' }
							onChange={ this.props.onChange }
							disabled={ !this.props.isConsumerFormComplete }
						/>
					</div>
					<div style={ { flex: '1 0 50%', display: 'flex', alignItems: 'flex-end' } }>
						<Input
							type="text"
							style={ { display: 'flex' } }
							label="Adresse"
							name="address.street"
							value={ this.props.formData.address.street || '' }
							onChange={ this.props.onChange }
							disabled={ !this.props.isConsumerFormComplete }
						/>
						<Button
							type={ 'default' }
							iconLeft={ <LuCaseUpper/> }
							onClick={ () => this.handleDataToToggleCase('address.street') }
						/>
					</div>
				</div>
				<div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%', alignItems: 'flex-end' } }>
					<Input
						type="text"
						style={ { display: 'flex' } }
						label="Complément d'adresse"
						name="address.additionalData"
						value={ this.props.formData.address.additionalData || '' }
						onChange={ this.props.onChange }
						disabled={ !this.props.isConsumerFormComplete }
					/>
					<Button
						type={ 'default' }
						iconLeft={ <LuCaseUpper/> }
						onClick={ () => this.handleDataToToggleCase('address.additionalData') }
					/>
				</div>
				<div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
					<div style={ { display: 'inline-flex' } }>
						<Input
							type="text"
							label="Code Postal"
							name="address.zipCode"
							value={ this.props.formData.address.zipCode || '' }
							onChange={ this.props.onChange }
							disabled={ !this.props.isConsumerFormComplete }
						/>
					</div>
					<div style={ { display: 'flex', alignItems: 'flex-end' } }>
						<Input
							type="text"
							label="Ville"
							width={ '250px' }
							name="address.city"
							value={ this.props.formData.address.city || '' }
							onChange={ this.props.onChange }
							disabled={ !this.props.isConsumerFormComplete }
						/>
						<Button
							type={ 'default' }
							iconLeft={ <LuCaseUpper/> }
							onClick={ () => this.handleDataToToggleCase('address.city') }
						/>
					</div>
				</div>
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

  private handleDataToToggleCase(fieldName: string): void
  {
    const fieldPath = fieldName.split('.');
    let currentValue = this.props.formData;

    // Traverse the formData object to get the nested value
    for (let i = 0; i < fieldPath.length; i++) {
      currentValue = currentValue[fieldPath[i]];
    }

    const isUpperCase = currentValue === currentValue.toUpperCase();
    const updatedValue = isUpperCase ? currentValue.toLowerCase() : currentValue.toUpperCase();

    const event = {
      target: {
        name: fieldName,
        value: updatedValue,
      }
    };

    this.props.onChange(event as React.ChangeEvent<HTMLInputElement>);
  }


  //</editor-fold>
}