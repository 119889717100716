export default class LocalStorageService
{
  public static getToken()
  {
    return localStorage.getItem('token');
  }

  public static getUserLocalStorage(): any
  {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  }

  static setUserLocalStorage(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public static getAuthCompanyLocalStorage(): any
  {
    return localStorage.getItem('authCompanyId');
  }

  public static setAuthCompanyIdLocalStorage(companyId: number): void
  {
    localStorage.setItem('authCompanyId', companyId.toString());
  }

  public static getAuthClientLocalStorage(): any
  {
    return localStorage.getItem('authClientId');
  }

  public static setAuthClientIdLocalStorage(clientId: number): void
  {
    localStorage.setItem('authClientId', clientId.toString());
  }

  public static clearLocalStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('authClientId');
    localStorage.removeItem('authCompanyId');
  }
}