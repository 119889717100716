import React, { ReactElement, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

interface ViewProps {
  isLoading: boolean;
  width?: string | number;
  height?: string | number;
  children: ReactNode;
}

export default class LoadingWrapper extends React.Component<ViewProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        { this.props.isLoading ? (
          <Skeleton width={ this.props.width } height={ this.props.height } />
        ) : (
          this.props.children
        )}
      </>
    );
  }
}
