export enum CssVariableEnum
{
	// Color
	'--color-main' = 'rgb(55, 53, 47)',
	'--color-grey-light' = 'rgba(242, 241, 238, 0.6)',

	// ========= COLOR PALETTE =========//
	// Color White
	'--color-white' = '#ffffff',

	// Transparente
	'--color-transparent' = 'transparent',

	// Color Grey
	'--color-grey-25' = '#FCFCFD',
	'--color-grey-50' = '#F9FAFB',
	'--color-grey-100' = '#F2F4F7',
	'--color-grey-200' = '#E4E7EC',
	'--color-grey-300' = '#D0D5DD',
	'--color-grey-400' = '#98A2B3',
	'--color-grey-500' = '#667085',
	'--color-grey-600' = '#475467',
	'--color-grey-700' = '#344054',
	'--color-grey-800' = '#1D2939',
	'--color-grey-800-opacity-50' = 'rgba(29,41,57,0.5)',
	'--color-grey-900' = '#101828',

	// Blue light
	'--color-blue-light-25' = '#F5FBFF',
	'--color-blue-light-50' = '#F0F9FF',
	'--color-blue-light-100' = '#E0F2FE',
	'--color-blue-light-200' = '#B9E6FE',
	'--color-blue-light-300' = '#7CD4FD',
	'--color-blue-light-400' = '#36BFFA',
	'--color-blue-light-500' = '#0BA5EC',
	'--color-blue-light-600' = '#0086C9',
	'--color-blue-light-700' = '#026AA2',
	'--color-blue-light-800' = '#065986',
	'--color-blue-light-900' = '#0B4A6F',
	'--color-blue-light-950' = '#062C41',

	// Blue
	'--color-blue-25' = '#F5FAFF',
	'--color-blue-50' = '#EFF8FF',
	'--color-blue-100' = '#D1E9FF',
	'--color-blue-200' = '#B2DDFF',
	'--color-blue-300' = '#84CAFF',
	'--color-blue-400' = '#53B1FD',
	'--color-blue-500' = '#2E90FA',
	'--color-blue-600' = '#1570EF',
	'--color-blue-700' = '#175CD3',
	'--color-blue-800' = '#1849A9',
	'--color-blue-900' = '#194185',
	'--color-blue-950' = '#102A56',


	// Purple
	'--color-purple-25' = '#FAFAFF',
	'--color-purple-50' = '#F4F3FF',
	'--color-purple-100' = '#EBE9FE',
	'--color-purple-200' = '#D9D6FE',
	'--color-purple-300' = '#BDB4FE',
	'--color-purple-400' = '#9B8AFB',
	'--color-purple-500' = '#7A5AF8',
	'--color-purple-600' = '#6938EF',
	'--color-purple-700' = '#5925DC',
	'--color-purple-800' = '#4A1FB8',
	'--color-purple-900' = '#3E1C96',
	'--color-purple-950' = '#27115F',

	// Green
	'--color-green-25' = '#F6FEF9',
	'--color-green-50' = '#ECFDF3',
	'--color-green-100' = '#D1FADF',
	'--color-green-200' = '#A6F4C5',
	'--color-green-300' = '#6CE9A6',
	'--color-green-400' = '#32D583',
	'--color-green-500' = '#12B76A',
	'--color-green-600' = '#039855',
	'--color-green-700' = '#027A48',
	'--color-green-800' = '#05603A',
	'--color-green-900' = '#054F31',
	'--color-green-950' = '#053321',

	// Warning
	'--color-yellow-25' = '#FFFCF5',
	'--color-yellow-50' = '#FFFAEB',
	'--color-yellow-100' = '#FEF0C7',
	'--color-yellow-200' = '#FEDF89',
	'--color-yellow-300' = '#FEC84B',
	'--color-yellow-400' = '#FDB022',
	'--color-yellow-500' = '#F79009',
	'--color-yellow-600' = '#DC6803',
	'--color-yellow-700' = '#B54708',
	'--color-yellow-800' = '#93370D',
	'--color-yellow-900' = '#7A2E0E',
	'--color-yellow-950' = '#4E1D09',

	// Orange
	'--color-orange-25' = '#FFFAF5',
	'--color-orange-50' = '#FFF6ED',
	'--color-orange-100' = '#FFEAD5',
	'--color-orange-200' = '#FDDCAB',
	'--color-orange-300' = '#FEB273',
	'--color-orange-400' = '#FD853A',
	'--color-orange-500' = '#FB6514',
	'--color-orange-600' = '#EC4A0A',
	'--color-orange-700' = '#C4320A',
	'--color-orange-800' = '#9C2A10',
	'--color-orange-900' = '#7E2410',
	'--color-orange-950' = '#511C10',

	// Error
	'--color-error-25' = '#FFFBFA',
	'--color-error-50' = '#FEF3F2',
	'--color-error-100' = '#FEE4E2',
	'--color-error-200' = '#FECDCA',
	'--color-error-300' = '#FDA29B',
	'--color-error-400' = '#F97066',
	'--color-error-500' = '#F04438',
	'--color-error-600' = '#D92D20',
	'--color-error-700' = '#B42318',
	'--color-error-800' = '#912018',
	'--color-error-900' = '#7A271A',
	'--color-error-950' = '#55160C',

	// Font
	'--font-size-main' = '14px',

	// Input
	'--input-box-shadow' = `#D0D5DD 0px 0px 0px 1px inset`,
	'--input-box-shadow-focus'= `#1570EF 0px 0px 0px 1px inset`,
	'--input-height' = '32px',
	'--input-padding' = '4px 10px',

	// Border Radius
	'--border-radius-main' = '8px',

	// Border
	'--border-main' = '1px solid #D0D5DD',
}

export function border(color: string): string
{
	return `1px solid ${color}`;
}